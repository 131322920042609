
import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "contabilidad";
const controladorSucursal = "Sucursal";
const controladorPersona = "persona";

export const AgregarContador = async (data) => {
    const url = `${controlador}/insertarcontador`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActualizarContador = async (data) => {
    const url = `${controlador}/actualizarcontador`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarContador = async (id) => {
    const url = `${controlador}/eliminarcontador?idContabilidad=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const ObtenerContadores = async () => {
    const url = `${controlador}/ObtenerContadores`;
    return await ProcesarDatosApi('GET', url);
}


export const ObtenerContador = async (id) => {
    const url = `${controlador}/ObtenerDetalleContador/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerSucursales = async () => {
    const url = `${controladorSucursal}/ObtenerSucursales`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerPersonas = async () => {
    const url = `${controladorPersona}/ObtenerPersonas`;
    return await ProcesarDatosApi('GET', url);
}
