import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "persona";
const controladorTipos = "tiposidentificacion";
const controladorTiposTelef = "tipodetelefono"

export const AgregarPersona = async (data) => {
    const url = `${controlador}/insertarpersona`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActualizarPersona = async (data) => {
    const url = `${controlador}/actualizarpersona`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarPersona = async (id) => {
    const url = `${controlador}/eliminarpersona?idPersona=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const ObtenerPersonas = async () => {
    const url = `${controlador}/ObtenerPersonas`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerPersona = async (id) => {
    const url = `${controlador}/ObtenerDetallePersona/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerTiposIdentificacion = async () => {
    const url = `${controladorTipos}/ObtenerTiposIdentificacion`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerCorreosPersona = async (id) => {
    const url = `${controlador}/ObtenerCorreosPersona/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ActualizarCorreosPersona = async (data) => {
    const url = `${controlador}/ActualizarCorreosPersona`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ActualizarCorreoPrincipalPersona = async (data) => {
    const url = `${controlador}/ActualizarCorreoPrincipalPersona`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const EliminarCorreoPersona = async (id) => {
    const url = `${controlador}/EliminarCorreoPersona?idCorreo=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const AgregarCorreoPersona = async (data) => {
    const url = `${controlador}/InsertarCorreoPersona`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerTelefonosPersona = async (id) => {
    const url = `${controlador}/ObtenerTelefonosPersona/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ActualizarTelefonosPersona = async (data) => {
    const url = `${controlador}/ActualizarTelefonosPersona`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ActualizarTelefonoPrincipalPersona = async (data) => {
    const url = `${controlador}/ActualizarTelefonoPrincipalPersona`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const EliminarTelefonoPersona = async (id) => {
    const url = `${controlador}/EliminarTelefonoPersona?idTelefono=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const AgregarTelefonoPersona = async (data) => {
    const url = `${controlador}/InsertarTelefonoPersona`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerTiposTelefono = async () => {
    const url = `${controladorTiposTelef}/ObtenerTiposDeTelefono`;
    return await ProcesarDatosApi('GET', url);
}