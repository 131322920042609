import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import { ObtenerTourDeViaje } from '../../servicios/ServicioTourDeViaje'


export const BuscarToursDeViaje = ({ show, handleClose, className, tamano, handleAceptar }) => {

    const [listaDeToursDeViaje, setListaDeToursDeViaje] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);

    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'Descripcion', name: 'Descripción', selector: row => row.descripcion, head: "Descripción", sortable: true },
        { id: 'Pais', name: 'País', selector: row => row.pais, head: "Pais", sortable: true },       
        //{ id: 'estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: false },
    ]

    //Se indica las columnas para aplicar filtro
    const filterColumns = ['descripcion', 'pais'];

    useEffect(() => {
        ObtenerListadoDeToursDeViaje();
    }, []);


    const ObtenerListadoDeToursDeViaje = async () => {
        setPendiente(true);
        setListaDeToursDeViaje(await ObtenerTourDeViaje());
        setPendiente(false);
    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setFilaSeleccionada(fila);
    }

    const handleAceptarClick = () => {
        if (typeof handleAceptar === 'function') {
            handleAceptar(filaSeleccionada);
        }
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName={className}
                aria-labelledby="contained-modal-title-vcenter" centered size={tamano} >
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{"Tours"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid gridHeading={encabezado} gridData={listaDeToursDeViaje} selectableRows={true} pending={pendiente}
                        setFilaSeleccionada={onClickSeleccionarFila} idBuscar="id" filterColumns={filterColumns} />


                    <div className='text-right'>
                        <br />
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            size="sm"
                            disabled={bloquearBoton}
                            onClick={handleAceptarClick}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Aceptar
                        </Button>{' '}
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            size="sm"
                            onClick={handleClose}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            Cancelar
                        </Button>{' '}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}