import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ObtenerFechaVencimiento } from '../../servicios/ServicioNotificaciones';
import { FormularioModal } from '../../components/ventanaModal';
import { Card } from 'react-bootstrap';

const NotificationButton = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [paquetes, setpaquetes] = useState([]);
    const [noleidas, setnoleidas] = useState(0);
    

   
 

    const fetchData = async () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        var id = usuario.idUsuario;
        
        try {
            const data = await ObtenerFechaVencimiento(id);  
            const newNotifications = data.filter(trip => !trip.leida);

       
            if (newNotifications.length > 0) {
                setpaquetes(data);
                setnoleidas(newNotifications.length);
                localStorage.setItem('noleidas', newNotifications.length);
            }
        } catch (error) {
            console.error('Error al obtener las notificaciones:', error.message);
        }
    };

    useEffect(() => {
      
        const storednoleidas = localStorage.getItem('noleidas');
        setnoleidas(parseInt(storednoleidas) || 0);
        fetchData();
    }, []);
    ;
    
    

    const toggleNotifications = () => {
        // Marcar todas las notificaciones como le�das al abrir la ventana de notificaciones
        const unreadNotifications = paquetes.filter(trip => !trip.leida);
        if (unreadNotifications.length > 0) {
            setnoleidas(0);
            localStorage.setItem('noleidas', 0);
        }
        setShowNotifications(!showNotifications);
    };

    const handleNotificationClick = (idPaquete) => {
        // Reducir el n�mero de notificaciones no le�das
        setnoleidas(prevnoleidas => Math.max(0, prevnoleidas - 1));
        localStorage.setItem('noleidas', Math.max(0, noleidas - 1));

        window.location = `/PaqueteDeViaje?idPaquete=${idPaquete}`;
        setShowNotifications(false);
    };


    return (
        <div style={{ position: 'relative' }}>
            <button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '10px',
                    cursor: 'pointer',
                }}
                onClick={toggleNotifications}
            >
                <div style={{ position: 'relative' }}>
                    <FontAwesomeIcon icon={faBell} style={{ color: '#fff', fontSize: '24px' }} />
                    {noleidas > 0 && !showNotifications && (
                        <span
                            style={{
                                position: 'absolute',
                                top: '-10px',
                                left: '15px',
                                fontSize: '14px',
                                color: 'white',
                                backgroundColor: 'red',
                                borderRadius: '50%',  
                                padding: '4px',
                                width: '20px',  
                                height: '20px', 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {noleidas}
                        </span>
                    )}
                </div>
            </button>

            <FormularioModal
                show={showNotifications}
                handleClose={() => {
                    setShowNotifications(false);
                    setnoleidas(0);
                    localStorage.setItem('noleidas', 0);
                }}
                className=''
                tamano="lg"
            >
                <Card.Title className="card-title mb-4" style={{ color: 'orangered' }}>Informaci&oacute;n de Notificaciones</Card.Title>
                {paquetes.length > 0 ? (
                    paquetes.map((trip, index) => (
                        <Card
                            key={index}
                            className="mb-2"
                            onClick={() => handleNotificationClick(trip.idPaquete)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Card.Body>
                                El paquete de viaje {trip.descripcion} asociado a la persona {trip.nombreCompleto} tiene como fecha l&iacute;mite de pago dos dias.
                            </Card.Body>
                        </Card>
                    ))
                ) : (
                    <Card>
                        <Card.Body>No hay notificaciones</Card.Body>
                    </Card>
                )}
            </FormularioModal>
        </div>
    );
};

export default NotificationButton;
