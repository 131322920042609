import React, { useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { PuffLoader, MoonLoader } from "react-spinners";
import "../styles/spinnerPersonalizado.css";

export const SpinnerPersonalizado = ({ show }) => {
    const ref = useRef(null);

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <CSSTransition
            nodeRef={ref}
            in={show}
            classNames="fade"
            timeout={300}
            unmountOnExit
        >
            <div ref={ref} 
             className="spinner-container">
                <MoonLoader color="#d65e28" />
            </div>
        </CSSTransition>
    );
};
