import React, { useEffect, useState } from "react";
import { Button, Card, Container, Row, Col, Form } from "react-bootstrap";
import { BuscarPersona } from "../buscarPersona";
import "../../styles/clubDeViaje.css";
import { Grid } from "../grid";
import {
    InputsFormsReactHook,
} from "../Forms/InputsFormsReactHook";

import "react-datepicker/dist/react-datepicker.css";

function CardBuscarBeneficiario({
    //onClickBuscarBeneficiario,
    //BeneficiarioSeleccionado,
    setFilaSeleccionadaCuotas,
    pendiente,
    listaDeCuotas,
    errors,
    isSummitInfo,
    register,
    setValue,
    setValoresCuota,
    mostrarTipoCuotas

}) {
    //const [cuotaSemanalSeleccionada, setCuotaSemanalSeleccionada] = useState(null);
    //const [cuotaMontoSeleccionada, setCuotaMontoSeleccionada] = useState(null);
    //Estructuras
    const encabezadoCuotas = [
        {
            id: "idCuota",
            name: "idCuota",
            selector: (row) => row.idCuota,
            head: "id",
            omit: true,
        },
        {
            id: "Codigo",
            name: "Código",
            selector: (row) => row.codigo,
            head: "Código",
            sortable: true,
        },
        {
            id: "CuotaSemanal",
            name: "Cuota Semanal",
            selector: (row) =>
                `₡${row.cuotaSemanal.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                })}`,
            head: "Cuota Semanal",
            sortable: true,
        },
        {
            id: "Monto",
            name: "Monto",
            selector: (row) =>
                `₡${row.monto.toLocaleString("en-US", { minimumFractionDigits: 2 })}`,
            head: "Monto",
            sortable: true,
        },
    ];
    const onClickSeleccionarFilaCuotas = (fila) => {
        //setValue([...valoresCuota, {
        //    idCuota: fila.idCuota,
        //    MonCuota: fila.cuotaSemanal,
        //    Monto: fila.monto
        //}]);
        setFilaSeleccionadaCuotas(fila.idCuota)
        setValue("idCuota", fila.idCuota); //idcuota
        setValue("MonCuota", fila.cuotaSemanal);
        setValue("Monto", fila.monto);
        //console.log("Valor de fila.idCuota:", fila.idCuota, fila.cuotaSemanal, fila.monto);

    };
    return (
        <Card className="card-container" style={{ width: "53%" }}>
            <Card.Body>

                <Container className="d-Grid align-content-center">

                    <Row className="mt-4">
                        <Col md={12}>
                            <Card.Title className="card-title">Información tipo de cuota</Card.Title>

                            {mostrarTipoCuotas ? (
                                <div>
                                    <span>Seleccione el tipo de cuota</span>
                                    <Grid
                                        gridHeading={encabezadoCuotas}
                                        gridData={listaDeCuotas}
                                        selectableRows={false}
                                        pending={pendiente}
                                        setFilaSeleccionada={onClickSeleccionarFilaCuotas}
                                        idBuscar="idCuota"
                                    />
                                </div>
                            ) : (
                                <>
                                    <br /><br /><br />
                                </>
                            )}

                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={6}>
                            <div className="mb-3 col-md-12">
                                <InputsFormsReactHook
                                    readOnly={true}
                                    classDiv={"col-md-12"}
                                    label="Cuota semanal:"
                                    placeholder="Cuota semanal"
                                    mensajeValidacion={"La Cuota semanal es requerida"}
                                    errors={errors?.Emisor?.type}
                                    isSummit={isSummitInfo}
                                    register={{
                                        ...register("MonCuota", { required: true }),
                                    }}
                                />
                                {/*<label htmlFor="txtname" className="form-label ml-1">*/}
                                {/*    <b>Cuota semanal</b>*/}
                                {/*</label>*/}
                                {/*<input*/}
                                {/*    className={"form-control bg-white"}*/}
                                {/*    readOnly*/}
                                {/*    type="text"*/}
                                {/*    value={valoresCuota.MonCuota}*/}
                                {/*/>*/}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3 col-md-12">
                                <InputsFormsReactHook
                                    readOnly={true}
                                    classDiv={"col-md-12"}
                                    label="Monto:"
                                    placeholder="Monto"
                                    mensajeValidacion={"El Monto es requerido"}
                                    errors={errors?.Emisor?.type}
                                    isSummit={isSummitInfo}
                                    register={{
                                        ...register("Monto", { required: true }),
                                    }}
                                />
                                {/*<label htmlFor="txtname" className="form-label ml-1">*/}
                                {/*    <b>Monto</b>*/}
                                {/*</label>*/}
                                {/*<input*/}
                                {/*    className={"form-control bg-white"}*/}
                                {/*    readOnly*/}
                                {/*    type="money"*/}
                                {/*    value={valoresCuota.Monto}*/}
                                {/*/>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
}

export default CardBuscarBeneficiario