import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";
import TopbarViajero from "../../components/NavBar/TopBar";
import Sucursal from "../sucursal";
import Reportes from "../reportes";
import Usuarios from "../usuarios";
import Personas from "../personas";
import Vendedores from "../vendedores";
import Vendedor from "../Vendedor";
import Supervisores from "../supervisores";
import Contadores from "../Contadores";
import EstadosPlan from "../EstadosPlan";
import Comisiones from "../Comisiones";
import Plazos from "../plazos";
import Regalias from "../regalias";
import Cuotas from "../cuotas";
import TiposDeTelefono from "../TiposDeTelefono";
import BuscadorExpedientes from "../BuscadorExpedientes";

import Home from ".";
import ProductoComponet from "../Productos";
import FormasDePagoComponet from "../FormasDePago";
import Clientes from "../clientes/index";
import Roles from "../roles/index";
import PaquetesDeViajesComponet from "../PaquetesDeViajes";
import TourDeViajeComponet from "../TourDeViaje";
import ViajesComponet from "../Viajes";
import ClubDeViajesComponet from "../Viajes/clubDeViajes";
import PaqueteDeViajeComponet from "../Viajes/paqueteDeViaje";
import CobrosComponent from "../Cobros";
import Cliente from "../Cliente";
import ComisionesVendedor from "../Comisiones/ComisionesVendedor/ComisionesVendedor";

import SupervisoresComisiones from "../Comisiones/SupervisoresComisiones";

import ImportarCobrosComponent from "../Cobros/importarDocumentos";
import AprobarCobros from "../Contabilidad";
import VistaSupervisor from "../supervisores/VistaSupervisor";

import { ObtenerAccesoMenuPorRol } from "../../servicios/ServicioRoles";

const Content = ({ sidebarIsOpen, toggleSidebar, rol, CerrarSession }) => {
    const [accesosRol, setAccesosRol] = useState([]);
    const [loading, setLoading] = useState(true);

    const obtenerRutasRol = async () => {
        try {
            // Llama a la funci�n del servicio que necesitas
            const resultado = await ObtenerAccesoMenuPorRol(rol.idRol); // Reemplaza "obtenerDatos" con el nombre de la funci�n en tu servicio
            setAccesosRol(resultado);
        } catch (error) {
            console.error("Error al llamar al servicio:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        obtenerRutasRol();
    }, []);

    const componentesPorTarget = {
        // "Ruta": NombreComponente
        "Aprobar-cobros": AprobarCobros,
        "BuscadorExpedientes": BuscadorExpedientes,
        "clientes": Clientes,
        "ClubDeViajes": ClubDeViajesComponet,
        "Descargar-documentos-de-cobro": CobrosComponent,
        "Comisiones": Comisiones,
        "Cuotas": Cuotas,
        "FormasDePago": FormasDePagoComponet,
        "Importar-documentos-de-cobro": ImportarCobrosComponent,
        "PaquetesDeViajes": PaquetesDeViajesComponet,
        "PaqueteDeViaje": PaqueteDeViajeComponet,
        "personas": Personas,
        "Plazos": Plazos,
        "Productos": ProductoComponet,
        "Regalias": Regalias,
        "reportes": Reportes,
        "Roles": Roles,
        "sucursal": Sucursal,
        "supervisores": Supervisores,
        "tiposDeTelefono": TiposDeTelefono,
        "TourDeViaje": TourDeViajeComponet,
        "usuarios": Usuarios,
        "Contadores": Contadores,
        "vendedores": Vendedores,
        "Viajes": ViajesComponet,
        "Cliente": Cliente,
        "vendedor": Vendedor,
        "ComisionesVendedor": ComisionesVendedor,
        "SupervisoresComisiones": SupervisoresComisiones,
        "Supervisor": VistaSupervisor,
    };

    const renderRoutes = () => {
        return accesosRol.map((acceso) => {
            const { opcionMenuDescripcion, target } = acceso;
            const ComponenteAsociado = componentesPorTarget[target] || null;

            return (
                <Route
                    key={opcionMenuDescripcion}
                    exact
                    path={`/${target}`}
                    element={ComponenteAsociado ? <ComponenteAsociado /> : <div>{opcionMenuDescripcion}</div>}
                />
            );
        });
    };

    const tieneAccesoCrearViajes = accesosRol.some((acceso) => acceso.categoriaMenuDescripcion === "Viajes" && acceso.opcionMenuDescripcion === "Crear");

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Container fluid className={classNames("content", { "is-open": sidebarIsOpen })}>
            <TopbarViajero toggleSidebar={toggleSidebar} CerrarSession={CerrarSession} />
            <Routes>
                <Route exact path="/" element={<Home />} component={() => "index"} />
                {rol.descripcion === "Cliente" && <Route exact path="/Cliente" element={<Cliente />} component={() => "cliente"} />}
                {tieneAccesoCrearViajes && (
                    <>
                        <Route exact path="/ClubDeViajes" element={<ClubDeViajesComponet />} component={() => "ClubDeViajes"} />
                        <Route exact path="/PaqueteDeViaje" element={<PaqueteDeViajeComponet />} component={() => "PaqueteDeViaje"} />
                    </>
                )}
                {rol.descripcion === "Administrador" ? <Route exact path="/Roles" element={<Roles />} component={() => "roles"} /> : ""}
                {rol.descripcion === "Ventas" ? <Route exact path="/vendedor" element={<Vendedor />} component={() => "vendedor"} /> : ""}
                {rol.descripcion === "Supervisor" ? <Route exact path="/Supervisor" element={<VistaSupervisor />} component={() => "VistaSupervisor"} /> : ""}
                {renderRoutes()}
            </Routes>
        </Container>
    );
};

export default Content;
