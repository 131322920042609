import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUpRightFromSquare, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import { Button, Form, Card, Row, Col } from 'react-bootstrap';
import { ObtenerExpedientes, ObtenerClubDeViaje, ObtenerPaqueteDeViaje } from '../../servicios/ServicioBuscadorExpedientes';
import DatePicker from 'react-datepicker'; // Importa DatePicker
import 'react-datepicker/dist/react-datepicker.css'; // Importa el CSS de DatePicker
import es from 'date-fns/locale/es'; // Importa el local de espa�ol




const BuscadorExpedientes = () => {
    const [proceso, setProceso] = useState(1);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(1);
    const [pendiente, setPendiente] = useState(false);
    const [listaDeRegistros, setListaDeRegistros] = useState([]);
    const [identificacion, setIdentificacion] = useState("");
    const [nombre, setNombre] = useState("");
    const [primerApellido, setprimerApellido] = useState("");
    const [segundoApellido, setsegundoApellido] = useState("");
    const [fecha, setFecha] = useState(null);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [mostrarBusqueda, setMostrarBusqueda] = useState(true);
    const [data, setData] = useState({});




    const handleBuscarClick = async () => {
         
            setPendiente(true);
            setListaDeRegistros([]);

            const data = {
                opcionSeleccionada: opcionSeleccionada,
                identificacion: identificacion,
                nombre: nombre,
                primerApellido: primerApellido,
                segundoApellido: segundoApellido,
                fecha: fecha
            };



        // Llama a ObtenerExpedientes solo cuando se hace clic en "Buscar"
        console.log('data del buscar antes de ejecutar el sp')
        console.log(data)
        console.log('data del buscar antes de ejecutar el sp')
        const resultadosObtenidos = await ObtenerExpedientes(data);


            setListaDeRegistros(resultadosObtenidos);
            setPendiente(false);

            if (resultadosObtenidos.length === 0) {
                setMensajeRespuesta({ indicador: 1, mensaje: "No se encontraron resultados." });
            } else {
                setMostrarBusqueda(!mostrarBusqueda)
                setMensajeRespuesta({});
            }
        
    };

  


    const onClickActualizarExpediente = async () => {
        if (filaSeleccionada.tipo === "Club") {

            window.location = '/ClubDeViajes?idClub=' + filaSeleccionada.idExpediente;

        } else if (filaSeleccionada.tipo === "Paquete") {

            window.location = '/PaqueteDeViaje?idPaquete=' + filaSeleccionada.idExpediente;
        }
    };



    const areInputsEmpty = () => {
        return (
            identificacion === '' &&
            nombre === '' &&
            primerApellido === '' &&
            segundoApellido === '' &&
            fecha === null
        );
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);

        if (filaValida) {
            setFilaSeleccionada(fila);

            //prueba de reconocimiento id y tipo
            const { idExpediente, tipo } = fila;


        } else {
            setFilaSeleccionada(null);
        }
    }




    const handleOpcionSeleccionadaChange = (e) => {
        setOpcionSeleccionada(e.target.value);
    };

    const encabezadosComunes = [
        { id: 'identificacion', name: 'Identificaci�n', selector: row => row.identificacion, head: "Identificaci�n", sortable: true },
        { id: 'NumContrato', name: 'Contrato', selector: row => row.numContrato, head: "Contrato" },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'primerApellido', name: 'Primer Apellido', selector: row => row.primerApellido, head: "Primer Apellido", sortable: true },
        { id: 'segundoApellido', name: 'Segundo Apellido', selector: row => row.segundoApellido, head: "Segundo Apellido", sortable: true },
        { id: 'fechaCreacion', name: 'Fecha', selector: row => row.fechaCreacion.split('T')[0], head: "Fecha" },
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "Estado" },
        { id: 'idExpediente', name: 'ID', selector: row => row.idExpediente, omit: true },
        { id: 'tipo', name: 'Tipo', selector: row => row.tipo, head: "Tipo" },
    ];
    const filterColumns = ['identificacion', 'nombre', 'primerApellido', 'segundoApellido', 'numContrato'];

    return (
        <>
            <Card className="card-container " style={{ width: '100%' }}>
                <Card.Body>
                    <div className="container-fluid text-no">

                        <Row>
                            <Col md={8}>
                                <h1>B&uacute;squeda de expedientes</h1>
                            </Col>
                            <Col md={4} className="d-flex justify-content-end">
                            {listaDeRegistros.length > 0 && (
                                <Button
                                    className="mr-1 mb-2 card-button"
                                    variant="Dark"
                                    style={{}}
                                    type="submit"
                                    size="sm"
                                    onClick={() => setMostrarBusqueda(!mostrarBusqueda)}
                                >
                                    {mostrarBusqueda ? (
                                        <>
                                            <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                                            Ocultar Buscar
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                                            Mostrar Buscar
                                        </>
                                    )}
                                </Button>

                                )}
                            </Col>
                        </Row>
                       
                        <hr />


                        {mostrarBusqueda ? (
                            <div>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="mr-1 mb-2 card-button"
                                        variant="primary"
                                        onClick={handleBuscarClick}
                                        style={{ marginBottom: '20px' }}
                                        disabled={areInputsEmpty()} >
                                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                        Buscar
                                    </Button>
                                </div>


                                <Row>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group className='mb-4'>
                                            <label htmlFor="buscar"><b>Buscar:</b></label>
                                            <Form.Control
                                                as="select"
                                                value={opcionSeleccionada}
                                                onChange={handleOpcionSeleccionadaChange}
                                            >
                                                <option value="1">Club de Viajes</option>
                                                <option value="2">Paquetes</option>

                                            </Form.Control>
                                        </Form.Group>




                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group className='mb-4'>
                                            <label htmlFor="identificacion"><b>Identificaci&oacute;n:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Identificaci&oacute;n"
                                                value={identificacion}
                                                onChange={(e) => setIdentificacion(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group className='mb-4'>
                                            <label htmlFor="nombre"><b>Nombre:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nombre"
                                                value={nombre}
                                                onChange={(e) => setNombre(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group>
                                            <label htmlFor="primerApellido"><b>Primer Apellido:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Primer apellido"
                                                value={primerApellido}
                                                onChange={(e) => setprimerApellido(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group>
                                            <label htmlFor="segundoApellido"><b>Segundo Apellido:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Segundo apellido"
                                                value={segundoApellido}
                                                onChange={(e) => setsegundoApellido(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <label htmlFor="fechaRegistro"><b>Fecha de registro:</b></label>
                                        <DatePicker
                                            id="fechaRegistro"
                                            selected={fecha}
                                            onChange={(date) => setFecha(date)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Selecciona una fecha"
                                            className={`form-control `}
                                            locale={es} // Establece el local de espa�ol
                                            popperPlacement="top" // Calendario en la parte superior


                                        />
                                    </Col>

                                </Row>
                            </div>
                        ): null }
                        <div style={{ marginTop: '7vh' }}></div>

                        
                        <div className="position-relative">

                        {listaDeRegistros.length !== 0 ? (
                            <Button
                                variant="Dark"
                                style={{
                                    fontSize: '15px',
                                    position: 'absolute',
                                    zIndex: 1,
                                }}
                                type="submit"
                                size="sm"
                                onClick={() => onClickActualizarExpediente()}
                                disabled={bloquearBoton}
                            >
                                <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-2" />
                                Cargar Datos
                            </Button>
                        ) : null}




                        {pendiente ? (
                            <div>Cargando...</div>
                            ) : (

                                    /*tabla donde se muestran los datos*/

                                <Grid
                                    gridHeading={encabezadosComunes}
                                    gridData={listaDeRegistros}
                                    selectableRows={true}
                                    pending={pendiente}
                                    setFilaSeleccionada={onClickSeleccionarFila}
                                    idBuscar="idExpediente" // Actualiza esta l�nea
                                    filterColumns={filterColumns}
                                />

                        )}
                        </div>

                </div>
            </Card.Body>
        </Card>
    </>
);
}

export default BuscadorExpedientes;