import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '../../components/grid';
import { AlertDismissible } from '../../components/alerts';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import jsPDF from 'jspdf';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import { ObtenerComisiones, ObtenerComision } from '../../servicios/ServicioComisiones'
import { ObtenerSupervisores, ObtenerSupervisor } from '../../servicios/ServicioSupervisor'
import { ObtenerVendedores, ObtenerVendedor } from '../../servicios/ServicioVendedor'
import encabezadoimagen from '../../images/encabezado-instinto-viajero.png';


const Comisiones = () => {
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [key, setKey] = useState('vendedores');
    const [listaDeSupervisores, setListaDeSupervisores] = useState([]);
    const [listaDeVendedores, setListaDeVendedores] = useState([]);
    const [listaDeComisiones, setListaDeComisiones] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [nombreUsuario, setNombreUsuario] = useState('');
    const [showDownloadButton, setShowDownloadButton] = useState(false);


    const filterColumns = ['nombrePersona', 'numContrato', 'fecha'];

    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'codSupervisor', name: 'Cod. Supervisor', selector: row => row.codSupervisor, head: "Cod. Supervisor", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        { id: 'nombreSucursal', name: 'Sucursal', selector: row => row.nombreSucursal, head: "Sucursal", sortable: true },
        { id: 'fechaContratacion', name: 'F. Contratación', selector: row => new Date(row.fechaContratacion).toLocaleDateString('es-ES'), head: "F. Contratación", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true },

    ]
    const encabezadoVendedor = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'codVendedor', name: 'Cod. Vendedor', selector: row => row.codVendedor, head: "Cod. Vendedor", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        { id: 'nombreSucursal', name: 'Sucursal', selector: row => row.nombreSucursal, head: "Sucursal", sortable: true },
        { id: 'fechaContratacion', name: 'F. Contratación', selector: row => new Date(row.fechaContratacion).toLocaleDateString('es-ES'), head: "F. Contratación", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true },

    ]
    const encabezadoComisiones = [
        { id: 'idComisionUsuariosClub', name: 'idComisionUsuariosClub', selector: row => row.idComisionUsuariosClub, head: "id", omit: true },
        { id: 'idRol', name: 'idRol', selector: row => row.idRol, head: "idRol", omit: true },
        { id: 'fecha', name: 'fecha', selector: row => row.fecha, head: "fecha", omit: true },
        { id: 'idUsuarioComision', name: 'Usuario Comisionado', selector: row => row.idUsuarioComision, head: "Usuario Comisionado", omit: true },
        { id: 'montoComision', name: 'Monto Comisión', selector: row => row.montoComision, head: "Monto Comisión", sortable: true },
        //{ id: 'idClub', name: 'Id Club', selector: row => row.idClub, head: "Id Club", sortable: true },
        { id: 'nombre', name: 'Nombre Completo', selector: row => row.nombrePersona, head: "Nombre", sortable: true },
        { id: 'MonPagar', name: 'Monto', selector: row => row.montoPagar, head: "MonPagar", sortable: true },
        { id: 'numCuota', name: 'Número Cuota', selector: row => row.numCuota, head: "Número Cuota", sortable: true },
        { id: 'NumContrato', name: 'Número Contrato', selector: row => row.numContrato, head: "NumContrato", sortable: true }
    ]



    const filterColumnsVentas = ['codVendedor', 'nombre', 'primerApellido', 'segundoApellido', 'nombreSucursal'];


    useEffect(() => {
        ObtenerListadoDeComisiones();
        ObtenerListadoDeSupervisores();
        ObtenerListadoDeVendedores();
    }, []);

    useEffect(() => {
        if (listaDeComisiones.length > 0) {
            setShowDownloadButton(true);
        } else {
            setShowDownloadButton(false);
        }
        console.log('showDownloadButton:', showDownloadButton);
    }, [listaDeComisiones]);

    const ObtenerListadoDeSupervisores = async () => {
        setPendiente(true);
        setListaDeSupervisores(await ObtenerSupervisores());
        setPendiente(false);
    }
    const ObtenerListadoDeVendedores = async () => {
        setPendiente(true);
        setListaDeVendedores(await ObtenerVendedores());
        setPendiente(false);
    }
    const ObtenerListadoDeComisiones = async (idUsuario) => {
        setPendiente(true);
        //setListaDeComisiones(await ObtenerComisionesUsuario(idUsuario));
        setPendiente(false);
    }

    const ObtenerComisionesUsuario = async (idUsuario) => {
        try {
            const comisionesUsuario = await ObtenerComisiones(idUsuario);
            console.log("Respuesta de ObtenerComisiones:", comisionesUsuario);
            setListaDeComisiones(comisionesUsuario);
            return comisionesUsuario;
        } catch (error) {
            console.log("Error al obtener las comisiones del usuario: ", error);
        }
    }




    const onClickSeleccionarFila = async (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setFilaSeleccionada(fila);

        const idFilaSeleccionada = fila.id || fila.idVendedor || fila.idSupervisor || fila.idComisionUsuariosClub;
        if (idFilaSeleccionada) {
            try {
                const comisionesUsuario = await ObtenerComisionesUsuario(idFilaSeleccionada);
                setListaDeComisiones(comisionesUsuario);
                //// Guardar el nombre del usuario seleccionado en la variable nombreUsuario

                const nombre = fila.nombre && fila.primerApellido !== undefined ? fila.nombre + ' ' + fila.primerApellido : fila.nombreUsuario;
                setNombreUsuario(nombre || '');



            } catch (error) {
                console.error("Error al obtener las comisiones del usuario: ", error);
            }
        } else {
            console.error("idFilaSeleccionada es undefined");
            setListaDeComisiones([]);
            setNombreUsuario(''); // Si no hay usuario seleccionado, se borra el nombre
        }
    }



    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;


    const convertirImagenABase64 = (ruta) => {
        return new Promise((resolve, reject) => {
            fetch(ruta)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
                .catch(reject);
        });
    };


    const generatePDF = async () => {
        const doc = new jsPDF();
        const imgBase64 = await convertirImagenABase64(encabezadoimagen);

        const imgWidth = doc.internal.pageSize.width - 20;
        const imgHeight = 30;
        const imgX = 10;
        const imgY = 10;

        doc.addImage(imgBase64, 'PNG', imgX, imgY, imgWidth, imgHeight);

        doc.setFontSize(18);
        doc.setTextColor(216, 92, 44);
        doc.text('Comisiones', 90, 45);

        doc.setTextColor(0);
        doc.setFontSize(14);
        doc.text('Nombre: ' + nombreUsuario, 20, 55);

        doc.setFontSize(12);


        const columns = [
            { title: 'Monto Comisión', width: 35 },
            { title: 'Nombre', width: 65 },
            { title: 'Monto a Pagar', width: 35 },
            { title: 'Número de Contrato', width: 40 }
        ];

        let data = [];


        listaDeComisiones.forEach((row) => {
            const rowData = [
                row.montoComision ? row.montoComision.toString() : '',
                row.nombrePersona ? row.nombrePersona.toString() : '',
                row.montoPagar ? row.montoPagar.toString() : '',
                row.numContrato ? row.numContrato.toString() : ''
            ];
            data.push(rowData);
        });

        let startY = 60;
        const margin = 20;


        doc.setFont('helvetica', 'normal');
        doc.setDrawColor(0);
        doc.setTextColor(0);

        // Encabezado de la tabla, en color naranja
        let startX = margin;
        columns.forEach((column) => {
            doc.setFillColor('#d85c2c');
            doc.rect(startX, startY, column.width, 10, 'FD');
            doc.text(column.title, startX + column.width / 2, startY + 8, { align: 'center' });
            startX += column.width;
        });

        startY += 10; // Ajuste para los datos

        const pageHeight = doc.internal.pageSize.height;
        const rowHeight = 10;
        let rowsPerPage = Math.floor((pageHeight - startY - margin) / rowHeight);

        // Datos de la tabla
        doc.setTextColor(0); // Restablecer el color de texto
        data.forEach((rowData, rowIndex) => {
            if (startY > pageHeight - margin) {
                doc.addPage(); // Añadir una nueva página
                startY = margin; // Restablecer la posición Y para la nueva página


                startX = margin;
                columns.forEach((column) => {
                    doc.setFillColor('#d85c2c');
                    doc.rect(startX, startY, column.width, 10, 'FD');
                    doc.text(column.title, startX + column.width / 2, startY + 8, { align: 'center' });
                    startX += column.width;
                });

                startY += 10;
            }

            const fillColor = rowIndex % 2 === 0 ? [200, 200, 200] : [255, 255, 255];
            startX = margin;
            rowData.forEach((cell, index) => {
                const column = columns[index];
                doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);
                doc.rect(startX, startY, column.width, rowHeight, 'FD');
                doc.text(cell, startX + column.width / 2, startY + 8, { align: 'center' });
                startX += column.width;
            });
            startY += rowHeight;
        });


        doc.save(`comisiones.pdf`);
    };




    return (
        <>
            <div className="container-fluid/*">
                <h1>Listado de comisiones</h1>
                <hr />

                <br /><br />
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )}

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="vendedores" title="Vendedores">
                        {/*Listado de vendedores*/}
                        <Grid gridHeading={encabezadoVendedor} gridData={listaDeVendedores} selectableRows={true}
                            pending={pendiente}
                            setFilaSeleccionada={onClickSeleccionarFila}
                            idBuscar="idVendedor" />
                    </Tab>
                    <Tab eventKey="supervisores" title="Supervisores">
                        {/*Listado de supervisores*/}
                        <Grid gridHeading={encabezado} gridData={listaDeSupervisores} selectableRows={true}
                            pending={pendiente}
                            setFilaSeleccionada={onClickSeleccionarFila} idBuscar="idSupervisor"
                        />
                    </Tab>
                </Tabs>
                <br /><br />
               
                <div className="position-relative">

                    <h3>Comisiones del usuario {nombreUsuario}</h3>
                    <br /><br />
                    <Container className='d-Grid align-content-center'>
                        <Row className='mt-4' style={{ display: showDownloadButton ? 'block' : 'none' }}>
                            <Col md={12} className="ml-auto">
                                <Button
                                    variant="dark"
                                    style={{
                                        display: showDownloadButton ? 'inline-block' : 'none',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        border: '2px solid #d85c2c',
                                        color: '#d85c2c',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        fontSize: '15px',
                                        position: 'absolute',
                                        zIndex: 1000,
                                        left: '7%',
                                        transform: 'translateX(-30%)',
                                        bottom: '-37px',
                                        padding: '10px 20px',
                                    }}
                                    onClick={generatePDF}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                                    Descargar
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mt-4" style={{ display: showDownloadButton ? 'block' : 'none' }}>
                            <Grid
                                gridHeading={encabezadoComisiones}
                                gridData={listaDeComisiones}
                                selectableRows={false}
                                pending={pendiente}
                                idBuscar="id"
                                filterColumns={filterColumns}
                            />
                        </Row>
                    </Container>
                </div>

            </div>

            <br /><br />
        </>
    )
}

export default Comisiones;