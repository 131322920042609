import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Card, Col } from 'react-bootstrap';
import { Grid } from '../../components/grid';
import { useForm } from 'react-hook-form'
import { InputsFormsReactHook, InputSelectHookForm } from '../../components/Forms/InputsFormsReactHook'
import DocumentosClubDeViajeComponet from '../Documentos/DocumentosClubDeViaje';
import { AlertDismissible } from '../../components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { InsertarLoteriaClub, ObtenerLoteriaClub } from '../../servicios/ServicioCobro'
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { faTrashAlt, faCheckToSlot } from '@fortawesome/free-solid-svg-icons';


const FormularioLoteriaClubViaje = ({ idClub, dataAbonos, CuotasRegaladas , CuotasRestantes, show }) => {
    const [isSummit, setIsSummit] = useState(false);
    const [showFormulario, setShowFormulario] = useState(true);
    const [IdClubDeViaje, setidclubDeViaje] = useState(idClub);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    //Para Obtener Abonos
    const [listaAbonosClub, setListaAbonosClub] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [numeroPremiado, setNumeroPremiado] = useState('Número');
    const [filasEliminadas, setFilasEliminadas] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const { register, watch, handleSubmit, setValue, formState: { errors }, trigger } = useForm({
        defaultValues: {

            IdClubDeViaje: idClub,
            Numero: false,
            Serie: false,
            FechaSorteo: null,
            UsuarioCreacion: 1,

        }
    });

    const [formData, setFormData] = useState({
        IdClubDeViaje:idClub,
        Numero: false,
        Serie: false,
        FechaSorteo: null,
        UsuarioCreacion: JSON.parse(sessionStorage.getItem("data_usuario")),
    });
    //console.log('Prueba de la lista', listaAbonosClub);

    const encabezado = [
        { id: 'idClubDeViaje', name: 'idClubViaje', selector: row => row.idClubDeViaje, head: "idClubViaje", omit: true },
        //{ id: 'id', name: 'Numero', selector: row => row.id, head: "Numero", sortable: true },
        //{ id: 'monColones', name: 'Dolares', selector: row => row.monColones, cell: (row) => `$ ${row.monColones}`,   head: "M.Colones", sortable: true },
        { id: 'FechaSorteo', name: 'Fecha S.', selector: row=> new Date(row.fechaSorteo).toLocaleDateString('es-ES'), head: "Fecha Sorteo", sortable: true },
        { id: 'Numero', name: 'Número', selector: row => row.numero, cell: (row) => row.numero ? 'Si':'No', head: "Número", sortable: true },
        { id: 'Serie', name: 'Serie', selector: row => row.serie, cell: (row) => row.serie ? 'Si' : 'No', head: "Serie", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true }
       

    ]

    const [isnotDisable, setisnotDisable] = useState(true);

    useEffect(() => {
        if (idClub) {
            obtenerLoteria();

        }
    }, [idClub, show]);



    // Funcion ObtenerDatosClubViaje
    const obtenerLoteria = async (row, index) => {
        try {
            setPendiente(true);
            const abonos = await ObtenerLoteriaClub(IdClubDeViaje);


      

            setListaAbonosClub(abonos);


            // Verificar si algún abono tiene serie === true
            const seriePresente = abonos.some(abono => abono.serie === true);
            setisnotDisable(!seriePresente); 


            setPendiente(false);


        } catch (error) {
            console.log("Error al obtener los abonos:", error);

        }

    }


    const onClickAceptar = async (formData) => {
        setMensajeFormulario('');
        let respuesta = {};


        if (numeroPremiado === 'Número') {
            formData.Numero = true;
            formData.Serie = false;
        }
        else if (numeroPremiado === 'Número y Serie') {
            formData.Numero = true;
            formData.Serie = true;
            setisnotDisable(false);
        }
            
        formData.FechaSorteo = new Date(watch('FechaSorteo'));


        //respuesta = await AplicarLoteriaClub(formData);


            respuesta = await InsertarLoteriaClub(formData);

            //if (respuesta.indicador === 0) {
            //    await Promise.all([obtenerLoteria(), setShowAlert(true)]);
            //    setMensajeRespuesta(respuesta);
            //} else {
            //    setMensajeFormulario(respuesta.mensaje);
            //    setShowAlert(true);
            //}
            if (respuesta.indicador === 0) {
                setMensajeRespuesta(respuesta);

                obtenerLoteria();
                setNumeroPremiado('Número'); //Devuelve la vista a numero cuando guarda

            } else {
                setMensajeRespuesta(respuesta);
                console.log('Mensaje error: ', respuesta.mensaje)
            }

        
        setShowAlert(true);
    };

    useEffect(() => {
        if (numeroPremiado === "Número y Serie") {
            setValue('CuotasRegalia' , CuotasRestantes);
        }
        else {
            setValue('CuotasRegalia', selectedValue);
        }
    }, [numeroPremiado, CuotasRestantes]);



    function cobrosPendientesDeAprobacion() {
        return listaAbonosClub.some(abono => abono.estadoCobro == false);
    }

    const handleManualValidation = async () => {
        const fechaSorteo = watch('FechaSorteo');


    };



    const handleNumeroPremiadoChange = async () => {
        setNumeroPremiado((prevNumeroPremiado) => (prevNumeroPremiado === 'Número' ? 'Número y Serie' : 'Número'));
    };


    const [ListaDeCuotasDisponibles, setListaDeCuotasDisponibles] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");

    useEffect(() => {

        // Calcula las opciones restates basadas en CuotasRegaladas
        const maxCuotas = 10;
        const CuotasDisponibles = Array.from({ length: maxCuotas }, (_, i) => ({
            value: maxCuotas - i,
            label: maxCuotas - i,
        })).slice(CuotasRegaladas).reverse();
        setListaDeCuotasDisponibles(CuotasDisponibles);

    }, [CuotasRegaladas]);

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };


    return (
        <>

            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}


            {showFormulario && (

                <Form onSubmit={handleSubmit(onClickAceptar)}>
                    <Card.Title className="card-title mb-4">Premios de Lotería</Card.Title>

                    <div className="d-flex flex-column" style={{ width: "60%" , marginBottom: "-2%" }} >

                        {numeroPremiado === "Número" && (
                            <Row className="justify-content-around align-items-end" >
                            
                                <Col
                                    md={6}
                                    style={{ fontWeight: "bold", color: "black" }}
                                >
                                    <label id="labelCuotasRegaladas" htmlFor="switch">Cuotas Regaladas:</label>
                                </Col>

                                <Col
                                    md={6}
                                    style={{ fontWeight: "bold", color: "black" , marginLeft:"-20%" }}
                                >
                                    <label id="CuotasRegaladas">{CuotasRegaladas ?? 0}</label>
                                </Col>

                            </Row>
                        )}
                        {numeroPremiado === "Número y Serie" && (

                            <Row className="justify-content-around align-items-end" >

                                <Col
                                    md={6}
                                    style={{ fontWeight: "bold", color: "black" }}
                                >
                                    <label id="labelCuotasFaltantes" htmlFor="switch">Cuotas Faltantes:</label>
                                </Col>

                                <Col
                                    md={6}
                                    style={{ fontWeight: "bold", color: "black", marginLeft: "-20%" }}
                                >
                                    <label id="CuotasRestantes">{CuotasRestantes ?? 0}</label>
                                </Col>

                            </Row>
                        )}
                    </div>
                    <hr></hr>

                    <Row>
                        <div className="col-md-4 mt-4">
                            <label className="font-weight-bold" htmlFor="numeroPremiado">
                                Ganador Por:
                            </label>
                            <BootstrapSwitchButton
                                id={`switchNumero`}
                                onlabel="Número y Serie"
                                offlabel="Número"
                                checked={numeroPremiado === "Número y Serie"}
                                onstyle="outline-primary"
                                offstyle="outline-primary"
                                size="sm"
                                width={232}
                                style={{ height: '80px', maxHeight: '80px' }}
                                onChange={handleNumeroPremiadoChange}
                            />
                        </div>

                        {numeroPremiado !== "Número y Serie" && (
                            <InputSelectHookForm
                                className="form-control custom-select "
                                label={<strong>Cuotas de Regalía: </strong>}
                                data={ListaDeCuotasDisponibles}
                                
                                mensajeValidacion={"Seleccione una cantidad de Cuotas"}
                                register={{
                                    ...register("CuotasRegalia", { required: true}),
                                }}
                                optionValue="value"
                                optionLabel="label"
                                onChange={handleChange}
                                errors={errors?.CuotasRegalia?.type}
                                isSummit={isSummit}
                                classGroup="col-md-4 mt-4"
                            />
                        )}

                        {numeroPremiado === "Número y Serie" && (
                            <InputsFormsReactHook
                                id='txt-CuotasRegalia'
                                label='Cuotas de Regalía :'
                                type='number'
                                mensajeValidacion="Cuotas de Regalia requeridas"
                                errors={errors?.CuotasRegalia?.type}
                                isSummit={isSummit}
                                readOnly={numeroPremiado === "Número y Serie"}
                                register={{ ...register('CuotasRegalia', { required: true }) }}
                               
                                classDiv="col-md-4 mt-4"
                            />

                        )}


                        <InputsFormsReactHook
                            id='txt-FecPago'
                            label='Fecha de Sorteo:'
                            type='date'
                            placeholder='Ingrese la fecha de sorteo'
                            mensajeValidacion="Fecha de sorteo requerida"
                            errors={errors?.FechaPago?.type}
                            isSummit={isSummit}
                            register={{ ...register('FechaSorteo', { required: true }) }}
                            classDiv="col-md-4 mt-4"
                        />
                    </Row>
                    <Row className="justify-content-end">
                        <div className="mt-auto">
                            <>
                                {isnotDisable && (
                                    <Button
                                        className='card-button ml-3 mr-3'
                                        variant="primary"
                                        type="submit"
                                        onClick={handleManualValidation}
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        Agregar
                                    </Button>
                                )}
                            </>
                        </div>
                    </Row>  
                        
                    {errorMessage && (
                        <label className="text-danger">{errorMessage}</label>
                    )}

                    
                    
                </Form>
            )}



            <span>Lista de Premios Registrados </span>
            <Grid gridHeading={encabezado}
                gridData={listaAbonosClub}
                selectableRows={false}
                idBuscar=" idClub"
                pending={pendiente} />

            <br /><br />
         </>


    )
}

export default FormularioLoteriaClubViaje;