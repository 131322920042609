import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { AlertDismissible } from '../../components/alerts';
import { Grid } from '../../components/grid';
import { ConfirmModal } from '../../components/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faEye, faClock } from '@fortawesome/free-solid-svg-icons';
import { ObtenerClubDeViaje } from '../../servicios/ServicioClubDeViaje'
import { ObtenerAbonoClubDeViaje, ActualizarAprobacionCobros, ActualizarDesaprobarCobros, CierrePeriodoClubDeViajes, ValidarCierre } from '../../servicios/ServicioCobro';
import { ModalVisorDocumentos } from '../../components/fileViewer';
import { SpinnerPersonalizado } from "../../components/spinnerPersonalizado";

const CobrosClubViaje = () => {

    const [showAlert, setShowAlert] = useState(false);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [listaCobros, setListaCobros] = useState([]);
    const [cobroAprobando, setCobroAprobando] = useState({});
    const [cobroDesAprobando, setCobroDesAprobando] = useState({});
    const [listaClubes, setListaClubes] = useState([]);
    const [clubSeleccionado, setClubSeleccionado] = useState({});
    const [apruebaCobro, setApruebaCobro] = useState(false);
    const [DesapruebaCobro, setDesApruebaCobro] = useState(false);
    const [cierraPeriodo, setCierraPeriodo] = useState(false);
    const [validarCierre, setValidarCierre] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [documentoIndicador, setDocumentoIndicador] = useState(null);
    const [documentoId, setDocumentoId] = useState(null);
    const [NumeroAbono, setNumeroAbono] = useState(null);
    const filterColumns = ['identificacion', 'nombre', 'numContrato'];
    const [isLoading, setIsLoading] = useState(false);

    const encabezadoCobros = [
        { id: 'numAbono', name: 'numAbono', selector: row => row.numAbono, head: "numAbono", width: '15%', omit: true },
        { id: 'IdDocumento', name: 'IdDocumento', selector: row => row.idDocumento !== null ? row.idDocumento : null, head: "IdDocumento", omit: true },
        { id: 'descripcion', name: 'Descripción', selector: row => row.descEstCobros, head: "descripcion", width: '15%' },
        { id: 'pagado', name: 'Monto pagado', selector: row => row.monPagar, head: "pagado", width: '15%' },
        { id: 'fecha', name: 'Fecha del pago', selector: row => row.fecPago, head: "fecha", width: '20%' },
        { id: 'ganadoLoteria', name: 'Loteria', selector: row => row.ganadoLoteria === true ? "Si" : "No", head: "fecha", width: '10%' },
        {
            id: 'aprobar', name: 'Aprobar', head: 'aprobar', width: '10%',
            cell: (row) => (
                <div >
                    <Button variant="link" size="sm" onClick={() => handleAprobarCobro(row)}>
                        <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '10px' }} />
                    </Button>
                </div>
            ),
        },
        {
            id: 'Des-aprobar', name: 'Desaprobar', head: 'Eliminar', width: '12%',
            cell: (row) => (
                <div >
                    <Button variant="link" size="sm" onClick={() => handleDesAprobarCobro(row)}>
                        <FontAwesomeIcon icon={faCheck} style={{ color: 'red', marginLeft: '15px' }} />
                    </Button>
                </div>
            ),
        },
        {
            id: 'verDoc',
            name: 'Comprobante',
            head: 'verDoc',
            cell: (row) => {
                if (row.idDocumento !== null) {
                    return (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '60%',
                        }}>
                            <Button variant="link" size="sm" onClick={() => handleAbrirDocumento(row)}>
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </div>
                    );
                }
                return null;
            },
        }

    ];

    const encabezadoClubes = [
        { id: 'identificacion', name: 'Identificación del cliente', selector: row => row.identificacion, head: "identificacion" },
        {
            id: 'nombre', name: 'Nombre del Cliente', selector: row => `${row.nombre} ${row.primerApellido} ${row.segundoApellido}`,
            head: "nombre", width: '29%'
        },
        { id: 'numContrato', name: 'Número de contrato', selector: row => row.numContrato, head: "numContrato", width: '29%' },
    ];

    const obtenerClubesDeViaje = async () => {
        const clubes = await ObtenerClubDeViaje();
        if (clubes != null && clubes != undefined) {
            setListaClubes(clubes);
        }
    }




    const obtenerListadoDeCobros = async (club, mostrarMensaje) => {
        const cobros = await ObtenerAbonoClubDeViaje(club);
        const cobrosFormateados = cobros.filter(cobro => cobro.estadoCobro != true); //COBROS QUE NO HAN SIDO APROBADOS
        if (mostrarMensaje && Object.keys(cobrosFormateados).length == 0) {
            setMensajeRespuesta({
                indicador: 1,
                mensaje: 'No hay cobros para el Club de Viaje'
            });
            setShowAlert(true);
        }
        if (cobrosFormateados != null && cobrosFormateados !== undefined) {
            setListaCobros(cobrosFormateados);
        }

    }

    useEffect(() => {
        obtenerClubesDeViaje();
        validarCierrePeriodo();

    }, []);


    const validarCierrePeriodo = async () => {
        const respuesta = await ValidarCierre();

        if (respuesta.indicador == 0) {
            setValidarCierre(false);
        } else if (respuesta.indicador == 1 && respuesta.mensaje == '') {
            setValidarCierre(true);
        } else {
            setValidarCierre(false);
            setMensajeRespuesta(respuesta);
        }
    };
    const handleAbrirDocumento = async (fila) => {
        setDocumentoId(fila.idDocumento ?? null);
        setNumeroAbono(fila.numAbono);
        setDocumentoIndicador(1); // 1 = club
        setShowModal(true);
    };



    const onBuscarCobros = async () => {
        if (clubSeleccionado != null && clubSeleccionado != null) {
            await obtenerListadoDeCobros(clubSeleccionado.id, true);
        }
    }

    const handleAprobarCobro = (cobroClub) => {
        setApruebaCobro(true);
        setCobroAprobando(cobroClub);
    }
    const handleDesAprobarCobro = (cobroClub) => {
        setDesApruebaCobro(true);
        setCobroDesAprobando(cobroClub);
    }
    const onConfirmAprobarCobro = async () => {
        const usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setApruebaCobro(false);
        var cobro = {
            idClubViaje: clubSeleccionado.id,
            numAbono: cobroAprobando.numAbono,
            usuarioMod: usuario.idUsuario
        }
        const result = await ActualizarAprobacionCobros(cobro);
        setMensajeRespuesta(result);
        setShowAlert(true);
        if (result.indicador == 0) {
            await obtenerListadoDeCobros(clubSeleccionado.id, false);
        }
        await obtenerClubesDeViaje();
    }
    const onConfirmDesAprobarCobro = async () => {
        try {
            const usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
            setDesApruebaCobro(false);
            var cobro = {
                idClubViaje: clubSeleccionado.id,
                numAbono: cobroDesAprobando.numAbono,
                usuarioMod: usuario.idUsuario
            }

            const respuesta = await ActualizarDesaprobarCobros(cobro);

            setMensajeRespuesta(respuesta);
            setShowAlert(true);
            if (respuesta.indicador == 0) {
                await obtenerListadoDeCobros(clubSeleccionado.id, false);
            }

            await obtenerClubesDeViaje();

        } catch (error) {
            console.error("Error al Desaprobar abono:", error);
        }
    };

    const handleCierrePeriodo = () => {
        setCierraPeriodo(true);
    };
    
    const onCierrePeriodo = async () => {
        try {
            setIsLoading(true);
            const usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
            setCierraPeriodo(false);
            const respuesta = await CierrePeriodoClubDeViajes(usuario.idUsuario);
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
            if (respuesta.indicador == 0) {
                setValidarCierre(false);
                await obtenerListadoDeCobros(clubSeleccionado.id, false);
            }
            setIsLoading(false);
            await validarCierrePeriodo(); // Llama a la función para actualizar `validarCierre`
        } catch (error) {
            setIsLoading(false);
            console.error("Error al cerrar el periodo:", error);
        }
    };


    return <>
        <SpinnerPersonalizado show={isLoading} />

        {showAlert && (
            <AlertDismissible
                indicador={mensajeRespuesta.indicador}
                mensaje={mensajeRespuesta.mensaje}
                setShow={setShowAlert}
            />
        )}

        <div className="d-flex justify-content-end" style={{ position: 'absolute', right: '10px', top: '180px' }}>
            {validarCierre && (
                <Button
                    variant="primary"
                    onClick={() => handleCierrePeriodo(true)}
                    size="sm"
                    className="mr-1 mb-2 card-button"
                    style={{ fontSize: '14px' }}
                >
                    <FontAwesomeIcon icon={faClock} className="mr-2" />
                    Cierre de Periodo
                </Button>
            )}

            {cierraPeriodo && (
                <ConfirmModal
                    isOpen={cierraPeriodo}
                    toggle={() => setCierraPeriodo(!cierraPeriodo)}
                    message="¿Desea cerrar el periodo?"
                    onConfirm={onCierrePeriodo}
                />
            )}
        </div>

        <ModalVisorDocumentos
            show={showModal}
            handleClose={() => setShowModal(false)}
            titulo="Información del Pago"
            tamano="lg"
            idDocumento={documentoId}
            indicador={documentoIndicador}
            numAbono={NumeroAbono}
        />


        <Card className="card-container" style={{ width: '100%' }}>
            <Card.Body>
                <Row className="mt-4">
                    <Col>
                        <Card.Title className="card-title"> Aprobar Cobros de Clubes de Viaje </Card.Title>
                        <Container className='d-Grid align-content-center'>




                            <Col md={12}>
                                <div className="d-flex justify-content-center">
                                    <label><b>Clubes de Viaje</b></label>
                                </div>

                            </Col>
                            <Row className="mt-4">
                                <Col md={12} className="ml-auto">

                                    <Button variant="dark" onClick={onBuscarCobros}
                                        style={{
                                            textAlign: 'center',
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                            border: '2px solid #d85c2c',
                                            color: '#d85c2c',
                                            backgroundColor: 'white',
                                            borderRadius: '20px',
                                            fontSize: '15px',
                                            position: 'absolute',
                                            zIndex: 1000,
                                            left: '7%',
                                            transform: 'translateX(-30%)',
                                            bottom: '-50px',
                                            padding: '10px 20px',
                                        }}>
                                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                        Buscar cobros
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-2">

                                <Grid selectableRows={true}
                                    gridHeading={encabezadoClubes}
                                    gridData={listaClubes}
                                    setFilaSeleccionada={setClubSeleccionado}
                                    filterColumns={filterColumns}
                                    className="row col-12 justify-content-end"
                                    idBuscar="id"
                                />
                            </Row>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <div className="d-flex justify-content-center">
                                        <label><b>Cobros pendientes del Club</b></label>
                                    </div>

                                    <Grid selectableRows={false}
                                        gridHeading={encabezadoCobros}
                                        gridData={listaCobros}
                                        setFilaSeleccionada={setCobroAprobando}
                                    >
                                    </Grid>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Card.Body>
        </Card >
        {apruebaCobro && (
            <ConfirmModal
                isOpen={apruebaCobro}
                toggle={() => setApruebaCobro(!apruebaCobro)}
                message={`¿Desea aprobar el cobro ${cobroAprobando.numAbono} con un monto de ${cobroAprobando.monPagar} ?`}
                onConfirm={onConfirmAprobarCobro}
            />
        )
        }
        {
            DesapruebaCobro && (
                <ConfirmModal
                    isOpen={DesapruebaCobro}
                    toggle={() => setDesApruebaCobro(!DesapruebaCobro)}
                    message={`¿Desea Desaprobar el cobro ${cobroDesAprobando.numAbono} con un monto de ${cobroDesAprobando.monPagar} ?`}
                    onConfirm={onConfirmDesAprobarCobro}
                />
            )
        }
    </>
}

export default CobrosClubViaje;