import React, { useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { InputText } from '../../components/inputs';
import { BuscarPersona } from '../../components/buscarPersona';




const Formulario = ({ labelButton, data, proceso, onClickProcesarCliente, PermisoActualizar, mensaje }) => {
    const [buscarPersonas, setBuscarPersonas] = useState(false);
    const [showFormulario, setShowFormulario] = useState(true);


    //campos de form
    //const [correo, setCorreo] = useState(proceso == 2 ? data.coreoElectronico : '');
    const [identificacion, setIdentificacion] = useState(proceso == 2 ? data.identificacion : '');
    const [nombre, setNombre] = useState(proceso == 2 ? data.nombre + " " + data.primerApellido + " " + data.segundoApellido : '');

    const [idPersona, setidPersona] = useState(proceso == 2 ? data.idPersona : 0);
    const [validated, setValidated] = useState(false);

    const [lugarTrabajo, setLugarTrabajo] = useState(proceso == 2 ? data.lugarTrabajo : '');
    const [apartadoPostal, setApartadoPostal] = useState(proceso == 2 ? data.apartadoPostal : '');
    const [direccionTrabajo, setDireccionTrabajo] = useState(proceso == 2 ? data.direccionTrabajo : '');
    const [direccionCobro, setDireccionCobro] = useState(proceso == 2 ? data.direccionCobro : '');
    const [correo, setCorreo] = useState('');

    //envio de datos
    const onClickAceptar = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = {
                idPersona: idPersona,
                lugarTrabajo: lugarTrabajo,
                apartadoPostal: apartadoPostal,
                direccionTrabajo: direccionTrabajo,
                direccionCobro: direccionCobro,
                correo: correo
            }
            onClickProcesarCliente(data);
        }
        setValidated(true);
        event.preventDefault();
    }


    //eventos del form

    const onClickBuscarPersona = () => {
        setBuscarPersonas(!buscarPersonas);
        setShowFormulario(false);
    }

    const onClickCerrarBuscarPersonas = () => {
        setBuscarPersonas(false);
        setShowFormulario(true);
    }

    const onClickAceptarBuscarPersonas = (persona) => {
        setidPersona(persona.idPersona);
        setNombre(`${persona.nombre} ${persona.primerApellido} ${persona.segundoApellido}`);
        setIdentificacion(persona.identificacion);
        setCorreo(persona.correoElectronico);
        setBuscarPersonas(false);
        setShowFormulario(true);
    }

    const onChangeIdentificacion = (e) => setIdentificacion(e.target.value.replace(/[^0-9]/g, ""));
    const onChangeNombre = (e) => setNombre(e.target.value);
    const onChangeLugarTrabajo = (e) => setLugarTrabajo(e.target.value);
    const onChangeApartadoPostal = (e) => setApartadoPostal(e.target.value);
    const onChangeDireccionTrabajo = (e) => setDireccionTrabajo(e.target.value);
    const onChangeDireccionCobro = (e) => setDireccionCobro(e.target.value);


    return (
        <>
            {showFormulario && (
                <Form noValidate validated={validated} onSubmit={onClickAceptar}>
                    <Row>
                        <InputText id='txt-nombre' label='Nombre:' type='text' value={nombre}
                            onChange={onChangeNombre} mensajeValidacion="El nombre del cliente es requerido" placeholder='Nombre del cliente' className="col-md-4" onClick={onClickBuscarPersona} readOnly={proceso == 2} />

                        <InputText id='txt-identificacion' label='Identificación:' type='text' value={identificacion}
                            onChange={onChangeIdentificacion} mensajeValidacion="La identificación es requerida" className="col-md-4" readOnly />
                    </Row>
                    <Row>
                        <InputText id='txt-lugarTrabajo' label='Lugar de trabajo:' type='text' value={lugarTrabajo} maxLength={200}
                            onChange={onChangeLugarTrabajo} mensajeValidacion="El lugar de trabajo es requerido" placeholder='Lugar de trabajo' className="col-md-4" />
                        <InputText id='txt-apartadoPostal' label='Apdo. Postal:' type='text' value={apartadoPostal} maxLength={30} required={false}
                            onChange={onChangeApartadoPostal}  placeholder='Apdo. Postal' className="col-md-4" />
                        <InputText id='txt-direccionTrabajo' label='Dirección de trabajo:' type='text' value={direccionTrabajo} maxLength={200}
                            onChange={onChangeDireccionTrabajo} mensajeValidacion="Dirección de trabajo requerida" placeholder='Dirección de trabajo' className="col-md-4" />
                        <InputText id='txt-direccionCobro' label='Dirección de cobro:' type='text' value={direccionCobro} maxLength={200}
                            onChange={onChangeDireccionCobro} mensajeValidacion="Dirección de cobro requerida" placeholder='Dirección de cobro' className="col-md-4" />
                    </Row>
                    <br />
                    {mensaje !== "" ? <p className="text-info text-center">{mensaje}</p> : ""}
                    <div className='text-right'>


                        {proceso === 2 && PermisoActualizar && (
                            <Button
                                className="mr-1 mb-2 card-button"
                                variant="primary"
                                type="submit"
                                size="sm">
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                {labelButton}
                            </Button>
                        )}

                        {labelButton === "Registrar" && (
                            <Button
                                className="mr-1 mb-2 card-button"
                                variant="primary"
                                type="submit"
                                size="sm">
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                {labelButton}
                            </Button>
                        )}

                    </div>

                </Form>
            )}

            <BuscarPersona show={buscarPersonas} handleClose={onClickCerrarBuscarPersonas} handleAceptar={onClickAceptarBuscarPersonas} className='' tamano="lg">
                <h5>Personas buscador</h5>
            </BuscarPersona>
        </>
    )
}

export default Formulario;