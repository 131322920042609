import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import Formulario from './formulario';
import { FormularioModal } from '../../components/ventanaModal';
import { AgregarPersona, ActualizarPersona, InactivarPersona, ObtenerPersonas, ObtenerPersona } from '../../servicios/ServicioPersonas'
import { AlertDismissible } from '../../components/alerts';
import { ConfirmModal } from '../../components/ConfirmModal';


const Personas = () => {
    const [proceso, setProceso] = useState(1);
    const [modal, setModal] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("Registrar persona");
    const [labelButton, setLabelButton] = useState("Registrar");
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [listaDePersonas, setListaDePersonas] = useState([]); 
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [data, setData] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [textoBotonInactivar, setTextoBotonInactivar] = useState("Inactivar");

    const [textoBotonActualizar, setTextoBotonActualizar] = useState("Actualizar");

    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);


    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'identificacion', name: 'Identificación', selector: row => row.identificacion, head: "Identificación", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        { id: 'fechaNacimiento', name: 'F. Nacimiento', selector: row => new Date(row.fechaNacimiento).toLocaleDateString('es-ES'), head: "F. Nacimiento", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado" , sortable: true}
    ]

    //Se indica las columnas en las que se aplica el filtro
    const filterColumns = ['identificacion', 'nombre', 'primerApellido', 'segundoApellido'];

    const [IdentificacionInicial, setIdentificacionInicial] = useState("");

    useEffect(() => {
        ObtenerListadoDePersonas();
        ObtenerPermisosUsuario();
    }, []);

    const onClickNuevoPersona = () => {
        setProceso(1);
        setModal(!modal);
        setLabelButton("Registrar");
        setModalTitulo("Registrar persona");
    }

    const onClickActualizarPersona = async () => {
        setData(await ObtenerPersona(filaSeleccionada.idPersona));
        setIdentificacionInicial((filaSeleccionada.identificacion).toString());
        setProceso(2);
        setModal(!modal);


        if (!PermisoActualizar) {
            setModalTitulo("Información persona");
        }
        else {
            setModalTitulo("Actualizar persona");
        }

        setLabelButton("Actualizar");
    }

    const onClickInactivarPersona = async () => {
        setConfirmModalOpen(true);
    }
    const onConfirmCambioEstado = async () => {
        const respuesta = await InactivarPersona(filaSeleccionada.idPersona)
        if (respuesta.indicador === 0)
            ObtenerListadoDePersonas();
        setMensajeRespuesta(respuesta);
        setTextoBotonInactivar(textoBotonInactivar === "Activar" ? "Inactivar" : "Activar");
        setConfirmModalOpen(false);
        setShowAlert(true);
    }

    const ObtenerListadoDePersonas = async () => {
        setPendiente(true);
        setListaDePersonas(await ObtenerPersonas());
        setPendiente(false);
    }

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false);
        setPermisoEliminar(usuario.PermisoEliminar ?? false);




        //aprovecha el metodo para asignar el texto del boton dependiendo del permiso
        if (usuario.PermisoActualizar) {
            setTextoBotonActualizar("Actualizar")
        }
        else {
            setTextoBotonActualizar("Ver Información")
        }


    }


    const onClickProcesarPersona = async (data) => {
        setMensajeFormulario("");
        let respuesta = {};


        //AGREGA A LA PERSONA
        if (proceso === 1) {

            data.modificoIdentificacion = 1;
          
            data.estado = true;
            respuesta = await AgregarPersona(data);


        }
        else {

            //Si son iguales entonces quiere decir que no se modifico {2}
            if (IdentificacionInicial === data.Identificacion) {
                data.modificoIdentificacion = 2
            } else {
                data.modificoIdentificacion = 1
            }
            data.idPersona = filaSeleccionada.idPersona;
            data.estado = true;
            respuesta = await ActualizarPersona(data);
        }

        if (respuesta.indicador === 0) {
            setModal(false);
            ObtenerListadoDePersonas();
            setMensajeRespuesta(respuesta);
        } else {
            setMensajeRespuesta(respuesta);
        }
        setShowAlert(true);

    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setTextoBotonInactivar(!filaValida ? "Inactivar" : (fila.estado === "Activo" ? "Inactivar" : "Activar"));
        setFilaSeleccionada(fila);
    }

    const onClickCerrarModal = () => {
        setModal(false);
        setMensajeFormulario("");
    }

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    return (
        <>
            <div className="container-fluid">
                <h1>Mantenimiento de personas</h1>
                <hr />

                {PermisoAgregar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickNuevoPersona()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Registrar
                        </Button>{' '}
                    </>
                )}

                
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickActualizarPersona()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faPen} className="mr-2" />
                            {textoBotonActualizar}
                        </Button>{' '}

                {PermisoEliminar && (
                    <>
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            type="submit"
                            size="sm"
                            onClick={() => onClickInactivarPersona()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            {textoBotonInactivar}
                        </Button>
                    </>
                )}

                <br /><br />
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )} 
                <span>Listado de todas las personas registradas</span>
                <Grid gridHeading={encabezado} gridData={listaDePersonas} selectableRows={true} pending={pendiente}
                    setFilaSeleccionada={onClickSeleccionarFila} idBuscar="idPersona" filterColumns={filterColumns} />
                <br /><br />
            </div>
            <FormularioModal show={modal} handleClose={onClickCerrarModal} titulo={modalTitulo} className='' tamano="lg">
                <Formulario labelButton={labelButton} data={data} proceso={proceso} onClickProcesarPersona={onClickProcesarPersona} PermisoActualizar={PermisoActualizar} mensaje={mensajeFormulario} />
            </FormularioModal>
            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`¿Desea cambiar el estado de la persona a ${textoBotonInactivar === "Activar" ? "activo" : "inactivo"
                        }?`}
                    onConfirm={onConfirmCambioEstado}
                />
            )}
        </>
    )
}

export default Personas;