import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Card, Container, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputText } from "../../components/inputs";
import { BuscarPersona } from '../../components/buscarPersona';
import { ComboBox } from '../../components/combobox';
import { ObtenerRoles, ObtenerSucursales } from '../../servicios/ServicioUsuarios';
import { ObtenerPersona } from '../../servicios/ServicioPersonas';

const Formulario = ({ labelButton, data, proceso, PermisoActualizar, onClickProcesarUsuario, mensaje }) => {

    const [personaSeleccionada, setPersonaSeleccionada] = useState({});

    //campos de form
    const [correo, setCorreo] = useState(proceso === 2 ? data.coreoElectronico : '');
    const [contrasenaTemporal, setContrasenaTemporal] = useState('');
    const [identificacion, setIdentificacion] = useState(proceso === 2 ? data.identificacion : '');
    const [nombre, setNombre] = useState(proceso === 2 ? (data.nombre + ' ' + data.primerApellido + ' ' + data.segundoApellido) : '');
    const [id, setId] = useState(proceso === 2 ? data.id : '');

    const [validated, setValidated] = useState(false);

    const [listaSucursales, setListaSucursales] = useState([]);
    const [listaRoles, setListaRoles] = useState([]);

    const [idRol, setIdRol] = useState(proceso === 2 ? data.idRol : 0);
    const [idSucursal, setIdSucursal] = useState(proceso === 2 ? data.idSucursal : 0);

    const ObtenerListadoDeRoles = async () => {
        const roles = await ObtenerRoles();
        if (roles !== undefined) {
            setListaRoles(roles);
        }
    }

    const ObtenerListadoDeSucursales = async () => {
        const Sucursales = await ObtenerSucursales();
        if (Sucursales !== undefined) {
            setListaSucursales(Sucursales);
        }
    }

    const onChangeRol = (event) => {
        setIdRol(event.target.value);
    }

    const onChangeSucursal = (event) => {
        setIdSucursal(event.target.value);
    }

    useEffect(() => {
        ObtenerListadoDeSucursales();
        ObtenerListadoDeRoles();
    }, []); 

    

    useEffect(() => {
        if (Object.keys(personaSeleccionada).length > 0) {
            setIdentificacion(personaSeleccionada?.identificacion);
            setNombre(personaSeleccionada?.nombre + ' ' + personaSeleccionada?.primerApellido + ' ' + personaSeleccionada?.segundoApellido);
            setCorreo(personaSeleccionada?.correoElectronico);
            setId(personaSeleccionada?.idPersona);

        }

    }, [personaSeleccionada]);

    const [showFormulario, setShowFormulario] = useState(true);
    const [mostrarModalPersonas, setMostrarModalPersonas] = useState(false);
    const [RolAdmin, setRolAdmin] = useState(0);

    const abrirModalPersona = () => {
        setShowFormulario(false);
        setMostrarModalPersonas(true);
    }

    const cerrarModalPersonas = () => {
        setShowFormulario(true);
        setMostrarModalPersonas(false);
    }

    const onClickAceptarBuscarPersonas = async (persona) => {
        setPersonaSeleccionada(await ObtenerPersona(persona.idPersona));
        cerrarModalPersonas();
    }

    const ValidarRol = () => {
        let rol = JSON.parse(sessionStorage.getItem("data_usuario"));
        return rol ? rol.idRol : null;
    };

    useEffect(() => {
        const rol = ValidarRol();
        setRolAdmin(rol);
    }, []);


    //envio de datos
    const onClickAceptar = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = {
                coreoElectronico: correo,
                contrasenaTemporal: contrasenaTemporal,
                idPersona: id,
                idRol: idRol,
                idSucursal: idSucursal
            }
            onClickProcesarUsuario(data);
        }
        setValidated(true);
        event.preventDefault();
    }

    const onChangeContrasenha = (e) => setContrasenaTemporal(e.target.value);

    return (
        <>
            {showFormulario && (
                <Form noValidate validated={validated} onSubmit={onClickAceptar}>
                    <div className='d-flex justify-content-around'>
                        <Card className="card-container" style={{ width: '100%' }}>
                            <Card.Body>
                                {proceso === 1 ? <Button
                                    variant="primary"
                                    onClick={abrirModalPersona}
                                    className="mr-1 mb-2, card-button" >
                                    <FontAwesomeIcon icon={faSearch} className="mr-2"
                                    />
                                    Buscar Persona
                                </Button> : ""}
                                <Container className='d-Grid align-content-center'>
                                    <Row className="mt-4">
                                        <div className="col-md-5">
                                        <label htmlFor="identificacion"><b>Identificación:</b></label>
                                            <p id="identificacion">{identificacion}</p>
                                        </div>
                                        <div className="col-md-7">
                                            <label htmlFor="nombre"><b>Nombre completo:</b></label>
                                            <p id="nombre">{nombre}</p>
                                        </div>
                                    </Row>
                                    <br />
                                    <Row className="mt-4">
                                        <div className="col-md-5">
                                            <ComboBox data={listaRoles} label="Rol" controlId="sel-rol" onChange={onChangeRol} value={idRol}
                                                optionValue="idRol" optionLabel="descripcion" indicacion="Seleccione el rol" disabled={RolAdmin !== 1}/>
                                        </div>
                                        <div className="col-md-7">
                                            <label htmlFor="correo"><b>Correo electrónico:</b></label>
                                            <p id="correo">{correo}</p>
                                        </div>
                                    </Row>
                                    <br />
                                    <Row className="mt-4">

                                        <ComboBox data={listaSucursales} label="Sucursales" controlId="sel-idSucursal" onChange={onChangeSucursal} value={idSucursal}
                                            optionValue="idSucursal" optionLabel="nombreSucursal" indicacion="Seleccione la Sucursal" classGroup="col-md-5" />
                                        
                                        <Col>

                                            <InputText id='txt-contrasenha' label='Contraseña:' type='password' placeholder='Ingrese la contraseña' value={contrasenaTemporal}
                                                text='Contraseña temporal.' onChange={onChangeContrasenha} mensajeValidacion="La contraseña es requerida" required={true} />
                                            </Col>
                                    </Row>
                                    <br />
                {mensaje !== "" ? <p className="text-info text-center">{mensaje}</p> : ""}
                    <div className='text-right'>


                        {proceso === 2 && PermisoActualizar && (
                            <Button
                                className="mr-1 mb-2 card-button"
                                variant="primary"
                                type="submit"
                                size="sm">
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                {labelButton}
                            </Button>
                        )}

                        {labelButton === "Registrar" && (
                            <Button
                                className="mr-1 mb-2 card-button"
                                variant="primary"
                                type="submit"
                                size="sm">
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                {labelButton}
                            </Button>
                        )}
                    </div>
                                </Container>
                            </Card.Body>
                            </Card>

                    </div >
                </Form>
            )}
            <BuscarPersona
                show={mostrarModalPersonas}
                handleClose={cerrarModalPersonas}
                handleAceptar={onClickAceptarBuscarPersonas}
                tamano="lg">
            </BuscarPersona>
        </>
    )
}

export default Formulario;