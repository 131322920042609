import { Form } from "react-bootstrap"
import '../styles/clubDeViaje.css'
import React, { useState, useEffect } from 'react'

export const InputsFormsReactHookMask = ({
    label,
    type,
    id,
    register,
    placeholder,
    onInput,
    step = "any",
    mensajeValidacion,
    className,
    readOnly = false,
    isSummit = false,
    classDiv,
    onClick,
    applyMask = false,
    customWidth,
    errors,
    defaultValue, // Nueva prop para el valor por defecto
    externalValue // Nueva prop para el valor externo
}) => {

    const [formattedValue, setFormattedValue] = useState('');

    useEffect(() => {
        // Actualizar formattedValue cuando cambia el valor externo
        if (externalValue !== undefined) {
            const formatted = applyMask ? applyDateMask(externalValue) : externalValue;
            setFormattedValue(formatted);
        }
    }, [externalValue, applyMask]);

    useEffect(() => {
        // Actualizar formattedValue cuando se monta el componente
        if (defaultValue !== undefined && externalValue === undefined) {
            const formatted = applyMask ? applyDateMask(defaultValue) : defaultValue;
            setFormattedValue(formatted);
        }
    }, [defaultValue, applyMask, externalValue]);


    const handleChange = (event) => {
        const { value } = event.target;

        const formatted = applyMask ? applyDateMask(value) : value;
        setFormattedValue(formatted);

        if (!isValidDate(formatted)) {
            setFormattedValue('');
            if (onInput) {
                onInput('');
            }
        } 

        if (onInput) {
           onInput(formatted);}
    };

    const isValidDate = (dateString) => {
        const [month, year] = dateString.split('/');
        const regex = /^(0[1-9]|1[0-2])\/(2[3-9]|[3-9][0-9])$/;
        if (dateString.length !== 5) {
            return true;
        } else if (!regex.test(dateString)) {
            return false;
        }
        const selectedDate = new Date(`20${year}`, month - 1, 1);
        const currentDate = new Date();
        return !isNaN(selectedDate.getTime()) && selectedDate > currentDate;
    };

    //const handleBlur = (event) => {
    //    if (formattedValue.length !== 5) {         
    //        setFormattedValue('');
    //        if (onInput) {
    //            onInput('');
    //        }
    //    }
    //    else if (onInput) {
    //        externalValue("FecVenceCuenta", formattedValue);
    //        onInput(formattedValue);
    //    } 
    //    console.log("***** :" +formattedValue);
    //};

    const applyDateMask = (value) => {

        if (!value) {
            return setFormattedValue('');
        }

        const digits = value.replace(/\D/g, '');
       const formatted = digits.substring(0, 4).replace(/(\d{2})(\d{2})/, '$1/$2');
        return formatted;
    };

    const inputStyle = customWidth ? { width: customWidth } : null;

    return (
        <div className={"mb-3 " + classDiv}>
            <label htmlFor={id} className="form-label ml-1">
                <b>{label}</b>
            </label>
            <input
                id={id}
                step={step}
                style={inputStyle}
                className={`form-control ${errors !== undefined ? 'is-invalid' : ''} ${errors === undefined  && isSummit ? 'is-valid' : ''}  ` + className}
                readOnly={readOnly}
                placeholder={placeholder}
                type={type}
                value={formattedValue}
                {...register}
                onClick={onClick}
                onChange={handleChange}
            />
            <div className="invalid-feedback">
                {errors && <small>{mensajeValidacion}</small>}
                {errors === 'maxLength' && <small>No cumple con el m�ximo de caracteres</small>}
                {errors === 'minLength' && <small>No cumple con el m�nimo de caracteres</small>}
            </div>
        </div>
    );
}
