import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "EstadoDeCuenta";


export const ObtenerListaEstadosDeCuenta = async (data) => {
    const url = `${controlador}/ObtenerListaEstadosDeCuenta`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerEstadoDeCuenta = async (data) => {
    const url = `${controlador}/ObtenerEstadoDeCuenta`;
    return await ProcesarDatosApi('POST', url, data);
}


