import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col, Form, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '../../components/grid';
import { faSearch, faUpRightFromSquare, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { ObtenerClubes, ObtenerGrupos } from '../../servicios/ServicioVendedor';
import { ObtenerClubDeViajePorId} from '../../servicios/ServicioClubDeViaje';
import { ObtenerPaquetePorId } from '../../servicios/ServicioPaquete';
import { ObtenerAbonos, ObtenerAbonoClubDeViaje } from '../../servicios/ServicioCobro';
import { ObtenerCuota } from '../../servicios/ServicioCuotas';
import { ObtenerPersona } from '../../servicios/ServicioPersonas';
import { ObtenerFormasDePagoPorId } from '../../servicios/ServicioFormasDePago';
import { ObtenerComisiones, ObtenerComision ,ObtenerComisionVendedor } from '../../servicios/ServicioComisiones'


const Vendedor = (proceso = 1, data) => {

    //Obtener Usuario
    let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
    const IdUsuario = usuario.idUsuario;
    //Fin obtener Usuario


    const [listaDeClubes, setlistaDeClubes] = useState([]);
    const [mostrarTabsGrupo, setMostrarGrupo] = useState(false);
    const [listaDeGrupos, setlistaDeGrupos] = useState([]);
    const [listaDeAbonos, setListaDeAbonos] = useState([]);
    const [listaToursC, setListaToursC] = useState([]);
    const [listaToursA, setListaToursA] = useState([]);
    const [listaA, setListaA] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [pendienteG, setPendienteG] = useState(false);
    const [datosClub, setDatosClub] = useState();
    const [detallesCuota, setDetallesCuota] = useState();
    const [detallesPersona, setDetallesPersona] = useState();
    const [detalleFormaDePago, setDetalleFormaDePago] = useState();
    const [mostrarInformacionClubes, setMostrarInformacionClubes] = useState(true);
    const [mostrarCard, setMostrarCard] = useState(true);
    const [datosCargados, setDatosCargados] = useState(false);
    const [datosGrupo, setDatosGrupo] = useState();  
    const [filaSeleccionadaClub, setFilaSeleccionadaClub] = useState(null);
    const [filaSeleccionadaGrupo, setFilaSeleccionadaGrupo] = useState(null);
    const [bloquearBotonClubes, setBloquearBotonClubes] = useState(true);
    const [bloquearBotonGrupos, setBloquearBotonGrupos] = useState(true);
    const [listaDeAbonosC, setListaDeAbonosC] = useState([]);
    const [pendienteC, setPendienteC] = useState(false);
    const [listaDeComisiones, setListaDeComisiones] = useState([]);
   



    const encabezadoClubes = [
        { id: 'idExpediente', name: 'idExpediente', selector: row => row.idExpediente, head: "id", omit: true },

        { id: 'numContrato', name: 'Contrato', selector: row => row.numContrato, head: "numContrato", sortable: true },
        { id: 'identificacion', name: 'Identificación', selector: row => row.identificacion, head: "identificacion", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "nombre", sortable: true },
        { id: 'primerApellido', name: 'Primer Apellido', selector: row => row.primerApellido, head: "primerApellido", sortable: true },
        { id: 'segundoApellido', name: 'Segundo Apellido', selector: row => row.segundoApellido, head: "segundoApellido", sortable: true },
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "estado", sortable: true },
        { id: 'FechaContrato', name: 'Contrato', selector: row => row.fechaContrato, head: "FechaContrato" },
        { id: 'FecCreacion', name: 'Registro', selector: row => row.fecCreacion, head: "FecCreacion" },
        { id: 'fechaPrimerRebajo', name: 'fechaPrimerRebajo', selector: row => row.fechaPrimerRebajo, head: "fechaPrimerRebajo" }
    ]
    const filterColumns = ['numContrato', 'identificacion', 'primerApellido', 'segundoApellido', 'nombre', 'estado'];

    //-------------------------------------------------------------------------------
    

    const encabezadoGrupos = [
        { id: 'idPaquete', name: 'idPaquete', selector: row => row.idPaquete, head: "id", omit: true },

        { id: 'identificacion', name: 'Identificación', selector: row => row.identificacion, head: "identificacion", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "nombre", sortable: true },
        { id: 'primerApellido', name: 'Primer Apellido', selector: row => row.primerApellido, head: "primerApellido", sortable: true },
        { id: 'segundoApellido', name: 'Segundo Apellido', selector: row => row.segundoApellido, head: "segundoApellido", sortable: true },
        { id: 'descripcion', name: 'Descripción', selector: row => row.descripcion, head: "descripcion", sortable: true },
        { id: 'costoTotal', name: 'Costo Total', selector: row => row.costoTotal, head: "costoTotal", sortable: true },
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "estado", sortable: true },
        { id: 'fecSalida', name: 'Salida', selector: row => row.fecSalida, head: "fecSalida" },
        { id: 'fecLlegada', name: 'Llegada', selector: row => row.fecLlegada, head: "fecLlegada" },
        { id: 'FecCreacion', name: 'Registro', selector: row => row.fecCreacion, head: "FecCreacion" }
    ]
    const filterColumnsG = ['numContrato', 'identificacion', 'primerApellido', 'segundoApellido', 'nombre', 'estado'];
//--------------------------------------------------------------------------------------------------------------------

    


    const encabezado = [
        { id: 'idPaquete', name: 'id', selector: row => row.idpaquete, head: "id", omit: true },
        { id: 'numAbono', name: 'Abono', selector: row => row.numAbono, head: "Número de Abono", sortable: true },
        { id: 'monColones', name: 'M. Colones', selector: row => row.monColones, head: "Monto Colones", sortable: true },
        { id: 'fecPago', name: 'Fecha Pago', selector: row => new Date(row.fecPago).toLocaleDateString('es-ES'), head: "Fecha de Pago", sortable: true },
        { id: 'monPagar', name: 'M. Dolares', selector: row => row.monPagar, head: "Monto Dolares", sortable: true },
        { id: 'monSaldo', name: 'Saldo', selector: row => row.monSaldo, head: "Saldo Restante", sortable: true }
    ]

    
//--------------------------------------------------------------------------------------------------------------------
    



    const encabezadoTourCliente = [
        { id: 'fecha', name: 'Fecha', selector: row => formatearFecha(row.fecha), head: "fecha" },
        { id: 'monto', name: 'Monto', selector: row => row.monto, head: "monto", sortable: true },
        { id: 'descripcion', name: 'Descripción', selector: row => row.descripcion, head: "descripcion", sortable: true },
        { id: 'pais ', name: 'País', selector: row => row.pais, head: "pais", sortable: true },
    ]
//--------------------------------------------------------------------------------------------------------------------
    



    const encabezadoToursAcom = [
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "nombre", sortable: true },
        //{ id: 'primerApellido', name: 'primerApellido', selector: row => row.primerApellido, head: "primerApellido", sortable: true },
        //{ id: 'segundoApellido', name: 'segundoApellido', selector: row => row.segundoApellido, head: "segundoApellido", sortable: true },
        { id: 'pais', name: 'pais', selector: row => row.pais, head: "pais", sortable: true },
        { id: 'monto', name: 'Monto', selector: row => row.monto, head: "monto", sortable: true },
        { id: 'fecha', name: 'fecha', selector: row => formatearFecha(row.fecha), head: "fecha" },
        { id: 'descripcion', name: 'descripcion', selector: row => row.descripcion, head: "descripcion", sortable: true }
    ]
    //--------------------------------------------------------------------------------------------------------------------
    



    const encabezadoAcom = [
        { id: 'identificacion', name: 'Identificación', selector: row => row.numIdentificacionAcompanante, head: "identificacion", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nomAcompanante, head: "nombre", sortable: true },
        //{ id: 'primerApellido', name: 'Primer Apellido', selector: row => row.primerApellido, head: "primerApellido", sortable: true },
        //{ id: 'segundoApellido', name: 'Segundo Apellido', selector: row => row.segundoApellido, head: "segundoApellido", sortable: true },
        { id: 'seguro', name: 'Seguro', selector: row => row.seguro, head: "seguro", sortable: true },
        { id: 'fecha', name: 'Fecha', selector: row => formatearFecha(row.fechaCreacion), head: "fecha", sortable: true },
    ]



    const encabezadoAbonosC = [
        { id: 'idClubViaje', name: 'id', selector: row => row.idClubViaje, head: "id", omit: true },
        { id: 'numAbono', name: 'Abono', selector: row => row.numAbono, head: "Número de Abono", sortable: true },
        { id: 'monColones', name: 'M. Colones', selector: row => row.monColones, head: "Monto Colones", sortable: true },
        { id: 'fecPago', name: 'Fecha Pago', selector: row => formatearFecha(row.fecPago), head: "Fecha de Pago", sortable: true },
        { id: 'monPagar', name: 'M. Dolares', selector: row => row.monPagar, head: "Monto Dolares", sortable: true },
        { id: 'monSaldo', name: 'Saldo', selector: row => row.monSaldo, head: "Saldo Restante", sortable: true }
    ]



    const encabezadoComisiones = [
        { id: 'idComisionUsuariosClub', name: 'idComisionUsuariosClub', selector: row => row.idComisionUsuariosClub, head: "id", omit: true },
        { id: 'idRol', name: 'idRol', selector: row => row.idRol, head: "idRol", omit: true },
        { id: 'fecha', name: 'fecha', selector: row => row.fecha, head: "fecha", omit: true },
        { id: 'idUsuarioComision', name: 'Usuario Comisionado', selector: row => row.idUsuarioComision, head: "Usuarrio Comisionado", omit: true },
        { id: 'montoComision', name: 'Monto Comisión', selector: row => row.montoComision, head: "Monto Comisión", sortable: true },
        { id: 'idClub', name: 'Id Club', selector: row => row.idClub, head: "Id Club", sortable: true },
        { id: 'nombre', name: 'nombre.C', selector: row => row.nombrePersona, head: "nombre", sortable: true },
        { id: 'MonPagar', name: 'Monto', selector: row => row.montoPagar, head: "MonPagar", sortable: true },
        { id: 'numCuota', name: 'Número Cuota', selector: row => row.numCuota, head: "Número Cuota", sortable: true },
        { id: 'idPersonaClub', name: 'idPersonaClub', selector: row => row.idPersonaClub, head: "id", omit: true },
    ]  



    //useEffect(() => {
    //    if (idPaquete) {
    //        obtenerAbonos();
    //    }
    //}, [idPaquete, show]);

    const obtenerAbonos = async () => {
        try {
            setPendiente(true);
            //const abonos = await ObtenerAbonos(filaSeleccionadaGrupo.idPaquete);
            //setListaDeAbonos(Object.values(abonos));
            //setPendiente(false);
            //console.log('Datos obtenidos: ', abonos)
        } catch (error) {
            console.log("Error al obtener los abonos:", error);
            // Maneja el error de acuerdo a tus necesidades
        }
    }

//----------------------------------------------------------------------------------------------------------------------


 


    const onClickCargarClubes = async () => {
        if (filaSeleccionadaClub) {
            const idClub = filaSeleccionadaClub.idExpediente;
            const datosDelClub = await ObtenerClubDeViajePorId(idClub);



            setDatosClub(datosDelClub);
            setMostrarGrupo(false);
            setMostrarInformacionClubes(true);  
            setDatosCargados(true); 

            const detallesDeCuota = await ObtenerCuota(datosDelClub.idCuota);
            setDetallesCuota(detallesDeCuota);

            const detallesPersona = await ObtenerPersona(datosDelClub.idPersonaBeneficiario);
            setDetallesPersona(detallesPersona);


            const descFormaPago = await ObtenerFormasDePagoPorId(datosDelClub.tipoTarjeta);
            setDetalleFormaDePago(descFormaPago);
            obtenerAbonosC();

            setMostrarCard(false);

        }
    };



   

    const onClickCargarGrupos = async () => {
        if (filaSeleccionadaGrupo) {
            const idPaquete = filaSeleccionadaGrupo.idPaquete;
            const datosDelGrupo = await ObtenerPaquetePorId(idPaquete);
            setDatosGrupo(datosDelGrupo);
            setMostrarGrupo(true);
            setMostrarInformacionClubes(false);  // Muestra la información de grupos
            setDatosCargados(true); // Marcar los datos como cargados
            const acom = datosDelGrupo.acompanantes;


            setListaA(acom);
            setMostrarCard(false);

            const toursC = datosDelGrupo.toursGrupo.filter(tour => tour.tipo === 'Cliente');
            const toursA = datosDelGrupo.toursGrupo.filter(tour => tour.tipo !== 'Cliente');

            setListaToursC(toursC);
            setListaToursA(toursA);
            const descFormaPago = await ObtenerFormasDePagoPorId(datosDelGrupo.tipoPago);
            setDetalleFormaDePago(descFormaPago);
            obtenerAbonosp (); 
            ObtenerListadoDeComisiones();


        }
    };


 

    function obtenerTextoFrecuencia(idFrecuenciaPago) {
        switch (idFrecuenciaPago) {
            case 1:
                return "Semanal";
            case 2:
                return "Quincenal";
            case 3:
                return "Mensual";
            default:
                return "Frecuencia no definida";
        }
    }


    const ObtenerListadoDeClubes = async () => {
        setPendiente(true);
        setlistaDeClubes(await ObtenerClubes(IdUsuario));
        setPendiente(false);
    }
    const ObtenerListadoDeGrupos = async () => {
        setPendiente(true);
        setlistaDeGrupos(await ObtenerGrupos(IdUsuario));
        setPendiente(false);
    }

    const ObtenerListadoDeComisiones = async () => {
        try {
            setPendienteC(true);
            const comisionS = await ObtenerComisionVendedor(IdUsuario);
            setListaDeComisiones(Object.values(comisionS));
            setPendienteC(false);
        } catch (error) {
            console.log("Error al obtener las comisiones :", error);

        }

    }


  

    useEffect(() => {

        ObtenerListadoDeClubes();
        ObtenerListadoDeGrupos();
        ObtenerListadoDeComisiones(IdUsuario);

    }, []);

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

 
    const onClickSeleccionarFilaClub = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBotonClubes(!filaValida);

        if (filaValida) {
            setFilaSeleccionadaClub(fila);
            // Resto de la lógica cuando se selecciona una fila en el grid de clubes...
        } else {
            setFilaSeleccionadaClub(null);
        }
    }

    const onClickSeleccionarFilaGrupo = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBotonGrupos(!filaValida);

        if (filaValida) {
            setFilaSeleccionadaGrupo(fila);
            // Resto de la lógica cuando se selecciona una fila en el grid de grupos...
        } else {
            setFilaSeleccionadaGrupo(null);
        }
    }


    //formatear fecha de contrato y del vencimiento
    let fechaFormateadaContrato = "";
    let fechaFormateadaVencimiento = "";

    if (datosClub !== undefined) {
        const fechaOriginalContrato = new Date(datosClub.fecContrato);
        const diaC = fechaOriginalContrato.getDate().toString().padStart(2, '0');
        const mesC = (fechaOriginalContrato.getMonth() + 1).toString().padStart(2, '0');
        const añoC = fechaOriginalContrato.getFullYear();

        fechaFormateadaContrato = `${diaC}/${mesC}/${añoC}`;

        const fechaOriginalVencimiento = new Date(datosClub.fecPrimerRebajo);
        const diaV = fechaOriginalVencimiento.getDate().toString().padStart(2, '0');
        const mesV = (fechaOriginalVencimiento.getMonth() + 1).toString().padStart(2, '0');
        const añoV = fechaOriginalVencimiento.getFullYear();

        fechaFormateadaVencimiento = `${diaV}/${mesV}/${añoV}`;
    }

    let fechaFormateadaLlegada = "";

    if (datosGrupo) {

        const fechaOriginalLlegada = new Date(datosGrupo.fechaLLegada);
        const diaC = fechaOriginalLlegada.getDate().toString().padStart(2, '0');
        const mesC = (fechaOriginalLlegada.getMonth() + 1).toString().padStart(2, '0');
        const añoC = fechaOriginalLlegada.getFullYear(); fechaFormateadaLlegada = `${diaC}/${mesC}/${añoC}`;

    }

    // Función para formatear la fecha
    const formatearFecha = (fecha) => {
        const fechaOriginal = new Date(fecha);
        const dia = fechaOriginal.getDate().toString().padStart(2, '0');
        const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0');
        const año = fechaOriginal.getFullYear();
        return `${dia}/${mes}/${año}`;
    }


//FIN formatear fecha de contrato y del vencimiento


    const obtenerAbonosp = async () => {
        try {
            setPendiente(true);
            const abonos = await ObtenerAbonos(filaSeleccionadaGrupo.idPaquete);
            setListaDeAbonos(Object.values(abonos));
            setPendiente(false);
        } catch (error) {
            console.log("Error al obtener los abonos:", error);

        }
    }




    const obtenerAbonosC = async (idExpediente) => {
        try {
            setPendienteC(true);
            const abonosC = await ObtenerAbonoClubDeViaje(filaSeleccionadaClub.idExpediente);
            setListaDeAbonosC(Object.values(abonosC));
            setPendienteC(false);
        } catch (error) {
            console.log("Error al obtener los abonos:", error);

        }

    }

    return (

        <>

            {datosCargados && (
                <Button
                    className="ml-3"
                    variant="Dark"
                    style={{}}
                    type="submit"
                    size="sm"
                    onClick={() => setMostrarCard(!mostrarCard)}
                >
                    {mostrarCard ? (
                        <>
                            <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                            Ocultar Datos
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            Mostrar Datos
                        </>
                    )}
                </Button>

            )}
            {mostrarCard && (
                <Card className="card-container mt-3" style={{ width: '100%' }}>
                    <Card.Body>
                        <div className="container-fluid text-no">
                            {/* Aquí puedes agregar contenido antes de la condición */}
                        </div>
                        <Row className="mt-4">
                            {listaDeClubes.length > 0 ? (
                                <Col md={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h1 className='card-title' style={{ color: 'orangered' }}>Clubes Registrados</h1>
                                    </div>
                                    <div style={{ position: 'relative' }}>

                                        <Button

                                            variant="Dark"
                                            style={{
                                                fontSize: '15px',
                                                position: 'absolute',  // Asegura que el botón tenga posición absoluta
                                                top: '4px',              // Ajusta la posición vertical según tus necesidades
                                                left: '0px',            // Ajusta la posición horizontal según tus necesidades
                                                zIndex: 2,             // Asegura que el botón tenga un z-index mayor que el div
                                            }}
                                            type="submit"
                                            size="sm"
                                            onClick={() => onClickCargarClubes()}
                                            disabled={bloquearBotonClubes}
                                        >
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-2" />
                                            Cargar Datos</Button>

                                    </div>
                                    <Grid
                                        gridHeading={encabezadoClubes}
                                        gridData={listaDeClubes}
                                        selectableRows={true}
                                        pending={pendiente}
                                        setFilaSeleccionada={onClickSeleccionarFilaClub}
                                        idBuscar="idExpediente"
                                        filterColumns={filterColumns}
                                    />
                                </Col>
                            ) : (
                                <p>No hay registros de club de viajes disponibles.</p>
                            )}
                        </Row>
                        <hr />

                        <Row className="mt-4">
                            {listaDeGrupos.length > 0 ? (
                                <Col md={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h1 className='card-title' style={{ color: 'orangered' }} >Grupos Registrados</h1>
                                    </div>
                                    <div style={{ position: 'relative' }}>

                                        <Button

                                            variant="Dark"
                                            style={{
                                                fontSize: '15px',
                                                position: 'absolute',  // Asegura que el botón tenga posición absoluta
                                                top: '4px',              // Ajusta la posición vertical según tus necesidades
                                                left: '0px',            // Ajusta la posición horizontal según tus necesidades
                                                zIndex: 2,             // Asegura que el botón tenga un z-index mayor que el div
                                            }}
                                            type="submit"
                                            size="sm"
                                            onClick={() => onClickCargarGrupos()}
                                            disabled={bloquearBotonGrupos}
                                        >
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-2" />
                                            Cargar Datos</Button>{' '}

                                    </div>
                                    <Grid
                                        gridHeading={encabezadoGrupos}
                                        gridData={listaDeGrupos}
                                        selectableRows={true}
                                        pending={pendienteG}
                                        setFilaSeleccionada={onClickSeleccionarFilaGrupo}
                                        idBuscar="idPaquete"
                                        filterColumns={filterColumnsG}
                                    />
                                </Col>
                            ) : (
                                <p>No hay registros de grupos de viajes disponibles.</p>
                            )}
                        </Row>
                    </Card.Body>



                </Card >
            )}
            {datosCargados && (

                <Card className="card-container mt-3 mb-5" style={{ width: '100%', marginBottom: '' }}>
                    <Card.Body>
                        <div className="container-fluid text-no">
                            {/* Aquí puedes agregar contenido antes de la condición */}
                        </div>
                        <Tabs defaultActiveKey="detalles" id="vendedor-tabs">
                            <Tab eventKey="detalles" title="Detalles">
                                <Row className="mt-4 ">
                                    <Container className='d-Grid align-content-center ' style={{ maxWidth: '90%' }}>

                                        {mostrarInformacionClubes ? (
                                            <>



                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered' }} >Información General</h1>
                                                    </Col>
                                                </Row>


                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered', textAlign: 'left' }}  >Cliente</h1>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-4 ">


                                                    {datosClub && (
                                                        <>
                                                            <Col md={4} >
                                                                <label htmlFor="nombre"><b>Nombre Completo:</b></label>
                                                                <p id="nombre">{datosClub.nombre + ' ' + datosClub.primerApellido + ' ' + datosClub.segundoApellido}</p>
                                                            </Col>
                                                        </>
                                                    )}


                                                    <Col md={4} >
                                                        <label htmlFor="apellido"><b>Identificación:</b></label>
                                                        <p id="apellido">{datosClub && datosClub.identificacion}</p>
                                                    </Col>
                                                    <Col md={4} >
                                                        <label htmlFor="Telefono"><b>Teléfono:</b></label>
                                                        <p id="Telefono">{datosClub && datosClub.telefono}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={4} >
                                                        <label htmlFor="Email"><b>Email:</b></label>
                                                        <p id="Email">{datosClub && datosClub.correoElectronico}</p>
                                                    </Col>
                                                    {datosClub && (
                                                        <>
                                                            <Col md={4} >
                                                                <label htmlFor="direccion"><b>Dirección:</b></label>
                                                                <p id="direccion">{datosClub.provincia + ', ' + datosClub.canton + ', ' + datosClub.distrito + ', ' + datosClub.direccion}</p>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>

                                                <hr />



                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered', textAlign: 'left' }} >Beneficiario</h1>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    {datosClub && (
                                                        <>
                                                            <Col md={4} >
                                                                <label htmlFor="nombreB"><b>Nombre Completo:</b></label>
                                                                <p id="nombreB">
                                                                    {datosClub.nomBeneficiario
                                                                        ?? ((`${datosClub.nombreBeneficiario || ''} ${datosClub.primerApellidoBeneficiario || ''} ${datosClub.segundoApellidoBeneficiario || ''}`).trim() || '')}

                                                                </p>
                                                            </Col>
                                                        </>
                                                    )}
                                                    <Col md={4} >
                                                        <label htmlFor="vencimiento"><b>Identificación:</b></label>
                                                        <p id="vencimiento">
                                                            {datosClub.numIdentificacionBeneficiario
                                                                ?? datosClub.identificacionBeneficiario
                                                                ?? ''}
                                                        </p>
                                                    </Col>

                                                    {datosClub.nombreBeneficiario && datosClub.nomBeneficiario && (
                                                        <>
                                                            <Col md={4} >
                                                                <label htmlFor="Telefono"><b>Teléfono:</b></label>
                                                                <p id="Telefono">{detallesPersona && detallesPersona.telefono}</p>
                                                            </Col>

                                                            <Col md={4} >
                                                                <label htmlFor="email"><b>Email:</b></label>
                                                                <p id="Email">{datosClub && datosClub.correoElectronicoBeneficiario}</p>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                                <hr />
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered', textAlign: 'left' }} >Información del Contrato</h1>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">



                                                    <Col md={3}>
                                                        <label htmlFor="frecuencia"><b>Frecuencia de Pago:</b></label>
                                                        <p id="frecuencia">{obtenerTextoFrecuencia(datosClub && datosClub.idFrecuenciaPago)}</p>
                                                    </Col>
                                                    <Col md={3} >
                                                        <label htmlFor="fechaPago"><b>Fechas de Pagos:</b></label>
                                                        <p id="fechaPago">{datosClub && datosClub.fechaDePago}</p>
                                                    </Col>


                                                    <Col md={3} >
                                                        <label htmlFor="frecuencia"><b>Cuota Semanal:</b></label>
                                                        <p id="cuotaSemanal">{detallesCuota && '₡' + detallesCuota.cuotaSemanal}</p>
                                                    </Col>



                                                    <Col md={3} >
                                                        <label htmlFor="fechaPago"><b>Monto:</b></label>
                                                        <p id="Monto">{detallesCuota && '₡' + detallesCuota.monto}</p>
                                                    </Col>
                                                </Row>


                                                <Row className="mt-4">
                                                    <Col md={3} >
                                                        <label htmlFor="contrato"><b>Contrato:</b></label>
                                                        <p id="Contrato">{datosClub && datosClub.numContrato}</p>
                                                    </Col>
                                                    <Col md={3} >
                                                        <label htmlFor="prima"><b>Prima:</b></label>
                                                        <p id="Prima">{datosClub && '₡' + datosClub.monPrima}</p>
                                                    </Col>



                                                    <Col md={3} >
                                                        <label htmlFor="fechaContrato"><b>Fecha del contrato:</b></label>
                                                        <p id="fechaContrato">{fechaFormateadaContrato}</p>
                                                    </Col>

                                                    <Col md={3} >
                                                        <label htmlFor="fechaVencimiento"><b>Fecha de Primer Rebajo:</b></label>
                                                        <p id="fechaVencimiento">{fechaFormateadaVencimiento}</p>
                                                    </Col>
                                                </Row>










                                            </>
                                        ) : (


                                            <>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered' }} >Información General</h1>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered', textAlign: 'left' }}  >Cliente</h1>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4 ">

                                                    <Col md={4} >
                                                        <p id="nombre">{datosGrupo.nombre + ' ' + datosGrupo.primerApellido + ' ' + datosGrupo.segundoApellido}</p>
                                                    </Col>


                                                    <Col md={4} >
                                                        <label htmlFor="apellido"><b>Identificación:</b></label>
                                                        <p id="apellido">{datosGrupo && datosGrupo.identificacion}</p>
                                                    </Col>
                                                    <Col md={4} >
                                                        <label htmlFor="Telefono"><b>Teléfono:</b></label>
                                                        <p id="Telefono">{datosGrupo && datosGrupo.telefono}</p>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-4">
                                                    <Col md={4} >
                                                        <label htmlFor="Email"><b>Email:</b></label>
                                                        <p id="Email">{datosGrupo && datosGrupo.correoElectronico}</p>
                                                    </Col>

                                                    <Col md={4} >
                                                        <label htmlFor="direccion"><b>Dirección:</b></label>
                                                        <p id="direccion">{datosGrupo.provincia + ', ' + datosGrupo.canton + ', ' + datosGrupo.distrito + ', ' + datosGrupo.direccion}</p>
                                                    </Col>
                                                </Row>
                                                <hr />

                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered', textAlign: 'left' }} >Información del Paquete</h1>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-4">
                                                    <Col md={4} >
                                                        <label htmlFor="precioContado"><b>Precio de Contado:</b></label>
                                                        <p id="precioContado">{datosGrupo && datosGrupo.precioContado}</p>

                                                    </Col>

                                                    <Col md={4} >
                                                        <label htmlFor="precioPlazos"><b>Precio a Plazos:</b></label>
                                                        <p id="precioPlazos">{datosGrupo && datosGrupo.precioPlazos}</p>

                                                    </Col>
                                                    <Col md={4} >
                                                        <label htmlFor="fechaLLegada"><b>Fecha de Llegada:</b></label>
                                                        <p id="fechaLLegada">{fechaFormateadaLlegada}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={4} >
                                                        <label htmlFor="seguro"><b>Seguro:</b></label>
                                                        <p id="seguro">{datosGrupo && datosGrupo.seguro}</p>

                                                    </Col>

                                                    <Col md={4} >
                                                        <label htmlFor="Descuento"><b>Descuento:</b></label>
                                                        <p id="Descuento">{datosGrupo && datosGrupo.descuento}</p>

                                                    </Col>
                                                </Row>





                                            </>
                                        )}

                                    </Container>
                                </Row>
                            </Tab>


                            {mostrarTabsGrupo && (


                                <Tab eventKey="tourC" title="Tours Cliente">
                                    <Row className="mt-4">
                                        <Container className='d-Grid align-content-center'>

                                            <Row className="mt-4">
                                                <Col>
                                                    <h1 className='card-title' style={{ color: 'orangered' }} >Tours del Cliente</h1>
                                                </Col>
                                            </Row>


                                            <Grid gridHeading={encabezadoTourCliente}
                                                style={{ width: '100%' }}
                                                gridData={listaToursC}
                                                idBuscar={'idPaquete'}
                                                selectableRows={false}
                                                pending={pendiente} />
                                            {/*  */}
                                        </Container>
                                    </Row>
                                </Tab>


                            )}

                            {mostrarTabsGrupo && (

                                <Tab eventKey="tourA" title="Acompañantes">
                                    <Row className="mt-4">
                                        <Container className='d-Grid align-content-center' >

                                            <Row className="mt-4">
                                                <Col>
                                                    <h1 className='card-title' style={{ color: 'orangered' }} >Acompañantes</h1>
                                                </Col>
                                            </Row>

                                            <Grid gridHeading={encabezadoAcom}
                                                gridData={listaA}
                                                idBuscar={'idPaquete'}
                                                selectableRows={false}
                                                pending={pendiente} />
                                            {/*  */}
                                        </Container>
                                    </Row>

                                    <Row className="mt-4">
                                        <Container className='d-Grid align-content-center' >

                                            <Row className="mt-4">
                                                <Col>
                                                    <h1 className='card-title' style={{ color: 'orangered' }} >Tours de Acompañantes</h1>
                                                </Col>
                                            </Row>

                                            <Grid gridHeading={encabezadoToursAcom}
                                                gridData={listaToursA}
                                                idBuscar={'idPaquete'}
                                                selectableRows={false}
                                                pending={pendiente} />
                                            {/*  */}
                                        </Container>
                                    </Row>
                                </Tab>
                            )}



                            <Tab eventKey="cobros" title="Registro de cobros">
                                {mostrarInformacionClubes ? (
                                    <>

                                        <Row className="mt-4 ">
                                            <Container className='d-Grid align-content-center ' style={{ maxWidth: '90%' }}>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered' }} >Registro de Cobros</h1>
                                                    </Col>
                                                </Row>

                                                {listaDeAbonosC && (
                                                    <>


                                                        <Grid gridHeading={encabezadoAbonosC}
                                                            gridData={listaDeAbonosC}
                                                            idBuscar={'idClubViaje'}
                                                            selectableRows={false}
                                                            pending={pendienteC} />
                                                    </>
                                                )}

                                            </Container>
                                        </Row>

                                    </>
                                ) : (


                                    <>

                                        <Row className="mt-4 ">
                                            <Container className='d-Grid align-content-center ' style={{ maxWidth: '90%' }}>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className='card-title' style={{ color: 'orangered' }} >Registro de Pagos</h1>
                                                    </Col>
                                                </Row>

                                                <Grid gridHeading={encabezado}
                                                    gridData={listaDeAbonos}
                                                    idBuscar={'idPaquete'}
                                                    selectableRows={false}
                                                    pending={pendiente} />

                                            </Container>
                                        </Row>

                                    </>
                                )}

                            </Tab> 

                            <Tab eventKey="Comisiones" title="Comisiones">
                                <Row className="mt-4">
                                    <Col>
                                        <h1 className='card-title' style={{ color: 'orangered' }} >Listado de comisiones</h1>
                                    </Col>
                                </Row>

                              
                                <Grid gridHeading={encabezadoComisiones} gridData={listaDeComisiones} selectableRows={false} pending={pendiente} />
                                {/*<br /><br />  setFilaSeleccionada={onClickSeleccionarFila}      idBuscar="idComisionUsuariosClub"         */}
                            </Tab>


                        </Tabs>
                    </Card.Body>
                </Card>
            )}
        </>



    );
};

export default Vendedor;