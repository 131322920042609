import { ProcesarDatosApi } from "../api/ApiFetch";

const controlador = "Paquete";

export const ObtenerPaquetePorId = async (idPaquete) => {
    const url = `${controlador}/ObtenerDetallePaqueteID/${idPaquete}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerComentariosPaqueteId = async (idPaquete) => {
    const url = `${controlador}/ObtenerComentariosPaqueteID/${idPaquete}`;
    return await ProcesarDatosApi('GET', url);
}
export const ObtenerAcompanantesPaqueteId = async (idPaquete) => {
    const url = `${controlador}/ObtenerAcompanantesPaqueteID/${idPaquete}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerAcompanantesPaqueteViajeId = async (idPaquete) => {
    const url = `${controlador}/ObtenerAcompanantesPaqueteViajeID/${idPaquete}`;
    return await ProcesarDatosApi('GET', url);
}

export const AgregarComentarioPaquete = async (data) => {
    const url = `${controlador}/insertarComentario`;
    return await ProcesarDatosApi('POST', url, data);
}

//ActualizarComentarioPaquete
export const ActualizarComentarioPaquete = async (data) => {
    const url = `${controlador}/ActualizarComentarioPaquete`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarPaquete = async (id) => {
    const url = `${controlador}/EliminarPaquete?idPaquete=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const AgregarPaquete = async (data) => {
    const url = `${controlador}/InsertarPaquete`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActualizarPaquete = async (data) => {
    const url = `${controlador}/actualizarPaquete`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarAcompanante = async (data) => {
    const url = `${controlador}/inactivarAcompanante`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarTour = async (data) => {
    const url = `${controlador}/inactivarTour`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarHabitacion = async (data) => {
    const url = `${controlador}/inactivarHabitacion`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InsertarAcompanante = async (data) => {
    const url = `${controlador}/insertarAcompanante`;
    return await ProcesarDatosApi('POST', url, data);
}

export const InsertarTour = async (data) => {
    const url = `${controlador}/insertarTour`;
    return await ProcesarDatosApi('POST', url, data);
}

export const InsertarHabitacion = async (data) => {
    const url = `${controlador}/insertarHabitacion`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActualizarAcompanante = async (data) => {
    const url = `${controlador}/actualizarAcompanante`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ObtenerDetallePaquetesPorFecha = async (fechaInicio, fechaFin) => {
    const url = `${controlador}/ObtenerDetallePaquetesPorFecha/${fechaInicio}/${fechaFin}`;
    return await ProcesarDatosApi('GET', url);
}

export const ActualizarEstadoPaquete = async (data) => {
    const url = `${controlador}/ActualizarEstadoPaquete`;
    return await ProcesarDatosApi('PUT', url, data);
}