import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Grid } from '../../components/grid';
import { ObtenerEstadoDeCuenta } from "../../servicios/ServicioEstadoDeCuenta";
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalExportatPDF = ({ show, handleClose, datos, handleExport }) => {

    const [listaDeRegistros, setListaDeRegistros] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (show && datos) {
                const data = {
                    idClubViajePaquete: datos.idClubViajePaquete,
                    identificacion: datos.identificacion,
                    numContrato: datos.numContrato,
                    opcionSeleccionada: datos.opcionSeleccionada
                };

                try {
                    const resultadosObtenidos = await ObtenerEstadoDeCuenta(data);
                    setListaDeRegistros(resultadosObtenidos);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [show, datos]);

    const encabezadosComunes = [
        { id: 'cuota', name: 'Cuota', selector: row => row.cuota, head: "Cuota", sortable: true },
        {
            id: 'fechaPago',
            name: 'Fecha de Pago',
            selector: row => (
                row.fechaPago
            ),
            head: "FechaPago",
            sortable: true
        },
        { id: 'monPagar', name: 'Monto a Pagar', selector: row => row.monPagar, head: "MonPagar", sortable: true },
        { id: 'monSaldo', name: 'Monto de Saldo', selector: row => row.monSaldo, head: "MonSaldo" },
    ];

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Estado de cuenta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Grid
                        gridHeading={encabezadosComunes}
                        gridData={listaDeRegistros}
                        selectableRows={false}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleExport(listaDeRegistros, datos)} style={{
                    textAlign: 'center',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    border: '2px solid #d85c2c',
                    color: '#d85c2c',
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    fontSize: '15px',
                    zIndex: 1000,
                    bottom: '-37px',
                    padding: '10px 20px',
                }}>
                    <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                    Exportar a PDF
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalExportatPDF;