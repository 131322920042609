import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { InputsFormsReactHook } from '../../components/Forms/InputsFormsReactHook';
import { AlertDismissible } from '../../components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { GuardarDatosCierreClub } from "../../servicios/ServicioClubDeViaje";

const FormularioCompletarClubViaje = ({ idClub, destino, montoReserva, montoCobradoAlSocio, show, idUsuario, actualizarDatosCierreClub }) => {
    const [isSummit, setIsSummit] = useState(false);
    const [showFormulario, setShowFormulario] = useState(true);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { register, handleSubmit, formState: { errors }, trigger } = useForm({
        defaultValues: {
            IdClubViaje: idClub,
            Destino: destino,
            MontoReserva: montoReserva,
            MontoCobradoAlSocio: montoCobradoAlSocio,
        }
    });

    const onClickAceptar = async (formData) => {
        // Utiliza los valores del formulario en lugar de los parámetros de entrada
        var datosCierreClub = {
            IdClub: formData.IdClubViaje,
            UsuarioModificacion: idUsuario,
            Destino: formData.Destino,
            MontoReserva: formData.MontoReserva,
            MontoCobradoAlSocio: formData.MontoCobradoAlSocio
        };

        let respuesta = {};
        respuesta = await GuardarDatosCierreClub(datosCierreClub);

        if (respuesta.indicador == 0) {



            setMensajeRespuesta(respuesta);

            actualizarDatosCierreClub({
                idClub: formData.IdClubViaje,
                destino: formData.Destino,
                montoReserva: formData.MontoReserva,
                montoCobradoAlSocio: formData.MontoCobradoAlSocio
            });
        } else {
            setMensajeFormulario(respuesta.mensaje);
            console.log('Mensaje error: ', respuesta.mensaje);
        }
        setShowAlert(true);
    };

    const validarNumeroNoNegativo = (value, setErrorMessage) => {
        const isValid = parseFloat(value) >= 0;

        if (!isValid) {
            setErrorMessage("No se permite ingresar números negativos");

            // Limpiar el mensaje de error después de tres segundos (2s)
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
        } else {
            setErrorMessage("");
        }

        return isValid;
    };

    const handleManualValidation = async () => {
        const isValid = await trigger();
        if (!isValid) {
            setIsSummit(true);
        }
    };

    return (
        <>
            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}

            {showFormulario && (
                <Form onSubmit={handleSubmit(onClickAceptar)}>
                    <Card.Title className="card-title mb-4">Finalizar Club de Viaje</Card.Title>
                    <Row>
                        <InputsFormsReactHook
                            id='txt-MontoReserva'
                            name='MontoReserva'
                            label='Monto Reserva:'
                            type='number'
                            placeholder='Ingrese el monto'
                            min={0.00}
                            max={99999999.99}
                            mensajeValidacion="Monto requerido"
                            errors={errors?.MontoReserva?.type}
                            isSummit={isSummit}
                            register={{
                                ...register('MontoReserva', {
                                    required: true,
                                    validate: (value) => validarNumeroNoNegativo(value, setErrorMessage),
                                }),
                            }}
                            classDiv="col-md-6 mt-6"
                        />
                        <InputsFormsReactHook
                            id='txt-MontoCobradoAlSocio'
                            label='Monto Cobrado al Socio:'
                            type='number'
                            placeholder='Ingrese monto'
                            min={0.00}
                            max={99999999.99}
                            mensajeValidacion="Monto requerido"
                            errors={errors?.MontoCobradoAlSocio?.type}
                            isSummit={isSummit}
                            register={{
                                ...register('MontoCobradoAlSocio', {
                                    validate: (value) => validarNumeroNoNegativo(value, setErrorMessage),
                                }),
                            }}
                            classDiv="col-md-6 mt-6"
                        />

                        <InputsFormsReactHook
                            classDiv="col-md-12"
                            label="Destino:"
                            placeholder="Destino"
                            mensajeValidacion="El Destino es requerido"
                            errors={errors?.Destino?.type}
                            isSummit={isSummit}
                            register={{ ...register("Destino", { required: true }) }}
                        />
                    </Row>
                    {errorMessage && (
                        <label className="text-danger">{errorMessage}</label>
                    )}

                    <br />
                    <div className="d-flex flex-column">
                        <Row className="justify-content-end">
                            <div className="mt-auto">
                                <Button
                                    className='card-button ml-3 mr-3'
                                    variant="primary"
                                    type="submit"
                                    onClick={handleManualValidation}
                                    size="sm"
                                >
                                    Guardar
                                </Button>
                            </div>
                        </Row>
                    </div>
                </Form>
            )}
        </>
    );
};

export default FormularioCompletarClubViaje;
