import React, { useEffect, useState } from "react";
import { Button, Card, Container, Row, Col, Form } from "react-bootstrap";
import ModalComentarios from "../Comentarios/ModalComentarios";
import { TexAreaFormsReactHook } from "../Forms/InputsFormsReactHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "../grid";
import { ActualizarComentarioClubDeViaje } from "../../servicios/ServicioClubDeViaje";


function CardBitacoraComentarios({
    errorsComentario,
    registerComentario,
    isSummit,
    handleSubmitComentario,
    handleSubmitComentarios,
    handleManualValidationComentarios,
    listaDeComentarios,
    pendienteComentarios,
    handleResponse, // Nueva propiedad
    setMensajeRespuesta,
    PermisoComentarioC,
    setShowAlert

}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);
    const [idUsuario, setIdUsuario] = useState(null); 
    const [respuesta, setRespuesta] = useState(false);
    const mensajeComentarioError = {
        indicador: '1', mensaje: 'Para actualizar un comentario debe guardar el Club de Viaje'
    };

    const mensajeComentarioError2 = {
        indicador: '1', mensaje: 'No tienes permiso para editar comentarios'
    };


    useEffect(() => {
        ObtenerIdUsuario();
    }, []);

    const ObtenerIdUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        if (usuario) {
            setIdUsuario(String(usuario.idUsuario));
        }
    };

    //evento de clic en el comentario
    const handleCommentClick = (comment) => {
        // Verificar que idUsuario esté inicializado antes de usarlo


        console.log(comment);
        if (!PermisoComentarioC) {
            setMensajeRespuesta(mensajeComentarioError2);
            setShowAlert(true);
        }
        else {

            if (comment.idComentarioClubDeViaje) {

                if (idUsuario) {
                    setSelectedComment({
                        ...comment,
                        idComentarioClubDeViaje: comment.idComentarioClubDeViaje,
                        idUsuario: idUsuario,
                        IndicadorPlan: 1,
                    });
                    setShowModal(true);
                }
            }
            else {
                setMensajeRespuesta(mensajeComentarioError);
                setShowAlert(true);

            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedComment(null);
    };

    const handleSaveComment = async () => {
        try {

            console.log("123");



            const response = await ActualizarComentarioClubDeViaje(selectedComment);


            console.log(response);

            // Verifica si el indicador es 0 y maneja la respuesta
            if (response.indicador === 0) {
                handleResponse(response); // Llama a la función manejadora con la respuesta
            }

            setRespuesta(response);
            handleModalClose();
            return

        } catch (error) {
            console.error('Error al actualizar el comentario:', error);
            // Aquí maneja el error, por ejemplo, mostrando un mensaje al usuario
        }
    };

    const encabezadoComentarios = [
        {
            id: "Comentario",
            name: "Descripción Comentario",
            selector: (row) => (
                <div onClick={() => handleCommentClick(row)}>
                    {row.Comentario}
                </div>
            ),
            head: "Comentario",
            sortable: true,
        },
        {
            id: "FechaCreacion",
            name: "Fecha Creación",
            selector: (row) => row.FechaCreacion,
            head: "Fecha Creación",
            width: '150px',
        },
    ];

    return (
        <>

            <Card className="card-container" style={{ width: "45%" }}>
                <Card.Body>
                    <Card.Title className="card-title">Bitácora de Comentarios</Card.Title>
                    <Container className="d-Grid align-content-center">
                        <Form onSubmit={handleSubmitComentario(handleSubmitComentarios)}>

                            {PermisoComentarioC && (
                                <>
                                    <TexAreaFormsReactHook
                                        type={""}
                                        rows={3}
                                        id="txt-Comentarios"
                                        label={<strong>Comentarios: </strong>}
                                        placeholder="Escriba su comentario"
                                        errors={errorsComentario?.Comentario?.type}
                                        mensajeValidacion=""
                                        isSummit={isSummit}
                                        register={{
                                            ...registerComentario("Comentario", {
                                                required: true,
                                                maxLength: 1000,
                                            }),
                                        }}
                                    />

                                    <Button
                                        className="mr-1  card-button"
                                        variant="primary"
                                        type="submit"
                                        style={{ height: "37px", alignSelf: "flex-end" }}
                                        onClick={handleManualValidationComentarios}
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        Agregar Comentario
                                    </Button>
                                </>
                            )}



                        </Form>
                        <Row className="mt-4">
                            <Col md={12}>
                                <span>Listado de Comentarios</span>
                                <Grid
                                    gridHeading={encabezadoComentarios}
                                    gridData={listaDeComentarios}
                                    selectableRows={false}
                                    pending={pendienteComentarios}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            <ModalComentarios
                show={showModal}
                handleClose={handleModalClose}
                comment={selectedComment} // Pasar el comentario seleccionado que ahora incluye idUsuario
                setComment={setSelectedComment} // Asegúrate de que `setComment` actualice `selectedComment`
                handleSave={handleSaveComment}
            />
        </>
    );
}

export default CardBitacoraComentarios;