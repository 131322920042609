import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '../../components/grid';
import { ObtenerClubesCliente, ObtenerGruposCliente } from '../../servicios/ServicioClientes';
import { Button, Card, Container, Row, Col, Tab, Tabs, Modal } from 'react-bootstrap';
import { ObtenerClubDeViajePorId } from '../../servicios/ServicioClubDeViaje';
import { ObtenerPaquetePorId } from '../../servicios/ServicioPaquete';
import { ObtenerCuota } from '../../servicios/ServicioCuotas';
import { ObtenerFormasDePagoPorId } from '../../servicios/ServicioFormasDePago';
import { faUpRightFromSquare, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ObtenerAbonos, ObtenerAbonoClubDeViaje } from '../../servicios/ServicioCobro';
import imagen from '../../images/paquete.jpg';

const Cliente = () => {
    const [mostrarInformacionClubes, setMostrarInformacionClubes] = useState(true);
    const [listaDeClubes, setlistaDeClubes] = useState([]);
    const [listaDeGrupos, setlistaDeGrupos] = useState([]);
    const [listaDeAbonos, setListaDeAbonos] = useState([]);
    const [listaToursC, setListaToursC] = useState([]);
    const [listaToursA, setListaToursA] = useState([]);
    const [listaA, setListaA] = useState([]);
    const [listaDeAbonosC, setListaDeAbonosC] = useState([]);
    const [detalleFormaDePago, setDetalleFormaDePago] = useState();
    const [datosClub, setDatosClub] = useState();
    const [detallesCuota, setDetallesCuota] = useState();
    const [datosGrupo, setDatosGrupo] = useState();
    const [filaSeleccionadaClub, setFilaSeleccionadaClub] = useState(null);
    const [filaSeleccionadaGrupo, setFilaSeleccionadaGrupo] = useState(null);
    const [bloquearBotonClubes, setBloquearBotonClubes] = useState(true);
    const [bloquearBotonGrupos, setBloquearBotonGrupos] = useState(true);
    const [mostrarCard, setMostrarCard] = useState(true);
    const [datosCargados, setDatosCargados] = useState(false);
    const [mostrarGrupo, setMostrarGrupo] = useState(false);
    const [pendienteC, setPendienteC] = useState(false);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [mostrarTabTourCliente, setMostrarTabTourCliente] = useState(false);
    const [mostrarTabAcompanantes, setMostrarTabAcompanantes] = useState(false);
    const [mostrarTabCobros, setMostrarTabCobros] = useState(false);
    const [mostrarModal, setMostrarModal] = useState(false);
    const [mostrarTabPagos, setMostrarTabPagos] = useState(true);

    // Obtener Usuario
    let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
    const IdUsuario = usuario.idUsuario;

    const encabezadoClubes = [

        { id: 'numContrato', name: 'Numero Contrato', selector: row => row.numContrato, head: "Numero Contrato", sortable: true },
        {
            id: 'fecPrimerRebajo',
            name: 'Fecha Primer Rebajo',
            selector: row => row.fecPrimerRebajo,
            head: "FecPrimerRebajo",
            sortable: true
        },
        {
            id: 'fecContrato',
            name: 'Fecha Contrato',
            selector: row => row.fecContrato,
            head: "Fecha Contrato",
            sortable: true
        },
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "estado", sortable: true },
        { id: 'idExpediente', name: 'idExpediente', selector: row => row.idExpediente, head: "idExpediente", omit: true },
    ];

    const filterColumnsC = ['numContrato'];

    const encabezadoGrupos = [
        { id: 'descripcion', name: 'Descripcion', selector: row => row.descripcion, head: "descripcion", sortable: true },
        { id: 'fecSalida', name: 'Fecha Salida', selector: row => row.fecSalida, head: "FechaSalida", sortable: true },
        { id: 'fechaLLegada', name: 'Fecha LLegada', selector: row => row.fecLlegada, head: "FechaLLegada", sortable: true },
        { id: 'costoTotal', name: 'Costo Total ', selector: row => row.costoTotal, head: "costoTotal", sortable: true },
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "estado", sortable: true },
        { id: 'idPaquete', name: 'IdPaquete', selector: row => row.idPaquete, head: "idPaquete", omit: true },
      
    ];

    const filterColumns = ['descripcion', 'costoTotal', 'estado'];

    const encabezado = [
        { id: 'idPaquete', name: 'id', selector: row => row.idpaquete, head: "id", omit: true },
        { id: 'numAbono', name: 'Número de Abono', selector: row => row.numAbono, head: "Número de Abono", sortable: true },
        { id: 'fecPago', name: 'Fecha Pago', selector: row => new Date(row.fecPago).toLocaleDateString('es-ES'), head: "Fecha de Pago", sortable: true },
        { id: 'monPagar', name: 'Monto', selector: row => row.monPagar, head: "Monto Dolares", sortable: true },
        { id: 'monSaldo', name: 'Saldo', selector: row => row.monSaldo, head: "Saldo Restante", sortable: true }
    ];

  

    const encabezadoTourCliente = [
        { id: 'fecha', name: 'Fecha', selector: row => formatearFecha(row.fecha), head: "fecha" },
        { id: 'monto', name: 'Monto', selector: row => row.monto, head: "monto", sortable: true },
        { id: 'descripcion', name: 'Descripción', selector: row => row.descripcion, head: "descripcion", sortable: true },
        { id: 'pais ', name: 'País', selector: row => row.pais, head: "pais", sortable: true },
    ];

    const encabezadoTourAcompanante = [
        { id: 'nombre ', name: 'Nombre', selector: row => row.nombre, head: "nombre", sortable: true },
        { id: 'pais ', name: 'País', selector: row => row.pais, head: "pais", sortable: true },
        { id: 'monto', name: 'Monto', selector: row => row.monto, head: "monto", sortable: true },
        { id: 'fecha', name: 'Fecha', selector: row => formatearFecha(row.fecha), head: "fecha" },
        { id: 'descripcion', name: 'Descripción', selector: row => row.descripcion, head: "descripcion", sortable: true },
    ];

    const encabezadoAcom = [
        { id: 'identificacion', name: 'Identificación', selector: row => row.numIdentificacionAcompanante, head: "identificacion", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nomAcompanante, head: "nombre", sortable: true },
        { id: 'seguro', name: 'Seguro', selector: row => row.seguro, head: "seguro", sortable: true },
        { id: 'fecha', name: 'Fecha', selector: row => formatearFecha(row.fechaCreacion), head: "fecha", sortable: true },
    ];

    const encabezadoAbonosC = [
        { id: 'idClubViaje', name: 'id', selector: row => row.idClubViaje, head: "id", omit: true },
        { id: 'numAbono', name: 'Número de Abono', selector: row => row.numAbono, head: "Número de Abono", sortable: true },
        // { id: 'monColones', name: 'M. Colones', selector: row => row.monColones, head: "Monto Colones", sortable: true },
        { id: 'fecPago', name: 'Fecha Pago', selector: row => formatearFecha(row.fecPago), head: "Fecha de Pago", sortable: true },
        { id: 'monPagar', name: 'Monto', selector: row => row.monPagar, head: "Monto Dolares", sortable: true },
        { id: 'monSaldo', name: 'Saldo', selector: row => row.monSaldo, head: "Saldo Restante", sortable: true }
    ];

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);

        if (filaValida) {
            setFilaSeleccionada(fila);
            const { idExpediente, tipo } = fila;
        } else {
            setFilaSeleccionada(null);
        }
    };
    
    const ObtenerListadoDeClubes = async () => {
        setPendiente(true);
        try {
            const clubes = await ObtenerClubesCliente(IdUsuario);
            if (Array.isArray(clubes)) {
                // Formatear fechas antes de setear los datos
                const clubesFormateados = clubes.map(club => ({
                    ...club,
                    fecContrato: formatearFecha(club.fecContrato),
                    fecPrimerRebajo: formatearFecha(club.fecPrimerRebajo),
                }));
                setlistaDeClubes(clubesFormateados);
            } else {
                console.error("Error: El resultado de ObtenerClubesCliente no es un array", clubes);
            }
        } catch (error) {
            console.error("Error al obtener clubes:", error);
        }
        setPendiente(false);
    };

    const ObtenerListadoDeGrupos = async () => {
        setPendiente(true);
        try {
            const grupos = await ObtenerGruposCliente(IdUsuario);
            if (Array.isArray(grupos)) {
                setlistaDeGrupos(grupos);
            } else {
                console.error("Error: El resultado de ObtenerGruposCliente no es un array", grupos);
            }
        } catch (error) {
            console.error("Error al obtener grupos:", error);
        }
        setPendiente(false);
    };

    const formatearFecha = (fecha) => {
        const fechaOriginal = new Date(fecha);
        if (isNaN(fechaOriginal.getTime())) {
            return "";
        }
        const dia = fechaOriginal.getDate().toString().padStart(2, '0');
        const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0');
        const año = fechaOriginal.getFullYear();
        return `${dia}/${mes}/${año}`;
    };

    const obtenerDescripcionTipoPago = (tipoPago) => {
        switch (tipoPago) {
            case 2:
                return "Contado";
            case 3:
                return "Plazos";
            default:
                return "Desconocido";
        }
    };

    useEffect(() => {
        ObtenerListadoDeClubes();
        ObtenerListadoDeGrupos();
    }, []);



    const [CuotasAprobadas, SetCuotasAprobadas] = useState(0);
    const [FechaDePago, SetFechaDePago] = useState("");
    
    const onClickCargarClubes = async () => {
        if (filaSeleccionadaClub) {
            const idClub = filaSeleccionadaClub.idExpediente;
            const datosDelClub = await ObtenerClubDeViajePorId(idClub);

            setDatosClub(datosDelClub);
            setMostrarInformacionClubes(true);
            setMostrarGrupo(false);
            setDatosCargados(true);
            setMostrarTabCobros(true);
            setMostrarTabTourCliente(false);
            setMostrarTabAcompanantes(false);
            const detallesDeCuota = await ObtenerCuota(datosDelClub.idCuota);
            setDetallesCuota(detallesDeCuota);
            SetCuotasAprobadas(datosDelClub.cuotasAprobadas);

            const fechas = datosDelClub.fechaDePago.split(",");

            if (datosDelClub.idFrecuenciaPago === 2) {
                SetFechaDePago("Fecha1 : " + fechas[0] + " / " + " Fecha2 : " + fechas[1]);
            }
            else {
                SetFechaDePago(datosDelClub.fechaDePago);
            }




            setMostrarCard(false);
            obtenerAbonosC();
        }
    };
    const [CostoTotal, SetCostoTotal] = useState(0);

    const onClickCargarGrupos = async () => {
        if (filaSeleccionadaGrupo) {
            try {
                const idPaquete = filaSeleccionadaGrupo.idPaquete;
              
                const datosDelGrupo = await ObtenerPaquetePorId(idPaquete);
                
                if (!datosDelGrupo) {
                    console.error('No se encontraron datos para el grupo con id:', idPaquete);
                    return;
                }
               
                setDatosGrupo(datosDelGrupo);
                setDatosGrupo(datosDelGrupo);
                setMostrarGrupo(true);
                setMostrarInformacionClubes(false);
                setDatosCargados(true);
                setMostrarTabCobros(false);
                setMostrarTabTourCliente(true);
                setMostrarTabAcompanantes(true);
                setMostrarTabPagos(true);
                setMostrarModal(true);
                SetCostoTotal(datosDelGrupo.costoTotal);
               
                const acom = datosDelGrupo.acompanantes || [];
                setListaA(acom);

                const toursC = (datosDelGrupo.toursGrupo || []).filter(tour => tour.tipo === 'Cliente');
                const toursA = (datosDelGrupo.toursGrupo || []).filter(tour => tour.tipo !== 'Cliente');
               
                setListaToursC(toursC);
                setListaToursA(toursA);

                const descFormaPago = await ObtenerFormasDePagoPorId(datosDelGrupo.tipoPago);
                setDetalleFormaDePago(descFormaPago);

                setMostrarCard(false);
                obtenerAbonos();
            } catch (error) {
                console.error('Error al cargar los datos del grupo:', error);
            }
        }
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    const onClickSeleccionarFilaClub = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBotonClubes(!filaValida);

        if (filaValida) {
            setFilaSeleccionadaClub(fila);
        } else {
            setFilaSeleccionadaClub(null);
        }
    };



    const onClickSeleccionarFilaGrupo = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBotonGrupos(!filaValida);

        if (filaValida) {
            setFilaSeleccionadaGrupo(fila);
        } else {
            setFilaSeleccionadaGrupo(null);
        }
    };

    let fechaFormateadaContrato = "";
    let fechaFormateadaVencimiento = "";

    if (datosClub) {
        fechaFormateadaContrato = formatearFecha(datosClub.FecContrato);
        fechaFormateadaVencimiento = formatearFecha(datosClub.FecPrimerRebajo);
    }

    let fechaFormateadaLlegada = "";

    if (datosGrupo) {
        fechaFormateadaLlegada = formatearFecha(datosGrupo.fechaLLegada);
    }

    function obtenerTextoFrecuencia(idFrecuenciaPago) {
        switch (idFrecuenciaPago) {
            case 1:
                return "Semanal";
            case 2:
                return "Quincenal";
            case 3:
                return "Mensual";
            default:
                return "Frecuencia no definida";
        }
    }

    const obtenerAbonos = async () => {
        try {
            setPendiente(true);
            const abonos = await ObtenerAbonos(filaSeleccionadaGrupo.idPaquete);

            const abonosFiltrados = abonos.filter((abono) => abono.descEstCobros === "Aprobado" );

            setListaDeAbonos(Object.values(abonosFiltrados));
            setPendiente(false);
        } catch (error) {
            setPendiente(false);
        }
    };

    const obtenerAbonosC = async () => {
        try {
            setPendienteC(true);
            const abonosC = await ObtenerAbonoClubDeViaje(filaSeleccionadaClub.idExpediente);
            const abonosFiltrados = abonosC.filter((abono) => abono.descEstCobros === "Aprobado");
            
            setListaDeAbonosC(Object.values(abonosFiltrados));
            setPendienteC(false);
        } catch (error) {
        }
    };

    return (
        <>
            {datosCargados && (
                <Button
                    className="ml-3"
                    variant="Dark"
                    type="submit"
                    size="sm"
                    onClick={() => setMostrarCard(!mostrarCard)}
                >
                    {mostrarCard ? (
                        <>
                            <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                            Ocultar Datos
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            Mostrar Datos
                        </>
                    )}
                </Button>
            )}

            {mostrarCard && (
                <Card className="card-container mt-3" style={{ width: '100%' }}>
                    <Card.Body>
                        <div className="container-fluid text-no"></div>
                        <Row className="mt-4">
                            {listaDeClubes.length > 0 ? (
                                <Col md={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h1 className="card-title" style={{ color: 'orangered' }}>Clubes Registrados</h1>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <Button
                                            variant="Dark"
                                            style={{
                                                fontSize: '15px',
                                                position: 'absolute',
                                                top: '4px',
                                                left: '0px',
                                                zIndex: 2,
                                            }}
                                            type="submit"
                                            size="sm"
                                            onClick={onClickCargarClubes}
                                            disabled={bloquearBotonClubes}
                                        >
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-2" />
                                            Cargar Datos
                                        </Button>
                                    </div>
                                    <Grid
                                        gridHeading={encabezadoClubes}
                                        gridData={listaDeClubes}
                                        selectableRows={true}
                                        pending={pendiente}
                                        setFilaSeleccionada={onClickSeleccionarFilaClub}
                                        idBuscar="id"
                                        filterColumns={filterColumnsC}
                                    />
                                </Col>
                            ) : (
                                <Col md={12} style={{ textAlign: 'center' }}>
                                    <h1 className="card-title" style={{ color: 'orangered' }}>Clubes Registrados</h1>
                                    <h5>No hay datos para mostrar</h5>
                                </Col>
                            )}
                        </Row>
                        <Row className="mt-4">
                            {listaDeGrupos.length > 0 ? (
                                <Col md={12}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h1 className="card-title" style={{ color: 'orangered' }}>Grupos Registrados</h1>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <Button
                                            variant="Dark"
                                            style={{
                                                fontSize: '15px',
                                                position: 'absolute',
                                                top: '4px',
                                                left: '0px',
                                                zIndex: 2,
                                            }}
                                            type="submit"
                                            size="sm"
                                            onClick={onClickCargarGrupos}
                                            disabled={bloquearBotonGrupos}
                                        >
                                            <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-2" />
                                            Cargar Datos
                                        </Button>
                                    </div>
                                    <Grid
                                        gridHeading={encabezadoGrupos}
                                        gridData={listaDeGrupos}
                                        selectableRows={true}
                                        pending={pendiente}
                                        setFilaSeleccionada={onClickSeleccionarFilaGrupo}
                                        idBuscar="idPaquete"
                                        filterColumns={filterColumns}
                                    />
                                </Col>
                            ) : (
                                <Col md={12} style={{ textAlign: 'center' }}>
                                    <h1 className="card-title" style={{ color: 'orangered' }}>Grupos Registrados</h1>
                                    <h5>No hay datos para mostrar</h5>
                                </Col>
                            )}
                        </Row>
                    </Card.Body>
                </Card>
            )}

            {datosCargados && (
                <Card className="card-container mt-3" style={{ width: '100%' }}>
                    <Card.Body>
                        <div className="container-fluid text-no"></div>
                        <Tabs defaultActiveKey="general" id="cliente-tabs">
                            <Tab eventKey="general" title="General">
                                <Row className="mt-4">
                                    <Container className="d-Grid align-content-center">
                                        <Row className="mt-4">
                                            <Col>
                                                {/*<h1 className="card-title" style={{ color: 'orangered' }}>Información General</h1>*/}
                                            </Col>
                                        </Row>
                                        {mostrarInformacionClubes ? (
                                            <>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className="card-title" style={{ color: 'orangered', textAlign: 'left' }}>Beneficiario</h1>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    {datosClub && (
                                                        <>
                                                            <Col md={4}>
                                                                <label htmlFor="nombre"><b>Nombre Completo:</b></label>
                                                                <p id="nombre">{`${datosClub.nombreBeneficiario ?? ""} ${datosClub.primerApellidoBeneficiario ?? ""} ${datosClub.segundoApellidoBeneficiario ?? ""}`}</p>
                                                            </Col>
                                                        </>
                                                    )}
                                                    <Col md={4}>
                                                        <label htmlFor="cedula"><b>Cédula:</b></label>
                                                        <p id="cedula">{datosClub && datosClub.identificacionBeneficiario}</p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <label htmlFor="email"><b>Email:</b></label>
                                                        <p id="email">{datosClub && datosClub.correoElectronicoBeneficiario}</p>
                                                    </Col>
                                                   
                                                </Row>
                                                <hr />
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className="card-title" style={{ color: 'orangered', textAlign: 'left' }}>Información de la Cuenta</h1>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={3}>
                                                        <label htmlFor="tarjeta"><b>Número de Tarjeta:</b></label>
                                                        <p id="numTarjeta">{datosClub && datosClub.numCuenta}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="emisor"><b>Emisor:</b></label>
                                                        <p id="emisor">{datosClub && datosClub.banco}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="frecuencia"><b>Frecuencia de Pago:</b></label>
                                                        <p id="frecuencia">{obtenerTextoFrecuencia(datosClub && datosClub.idFrecuenciaPago)}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="fechaPago"><b>Fecha de Pago:</b></label>
                                                        <p id="fecPago">{datosClub && FechaDePago}</p>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className="card-title" style={{ color: 'orangered', textAlign: 'left' }}>Información del Contrato</h1>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={3}>
                                                        <label htmlFor="frecuencia"><b>Cuota Semanal:</b></label>
                                                        <p id="cuotaSemanal">{detallesCuota && `₡${detallesCuota.cuotaSemanal}`}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="Monto"><b>Monto:</b></label>
                                                        <p id="Monto">{detallesCuota && `₡${detallesCuota.monto}`}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="contrato"><b>Contrato:</b></label>
                                                        <p id="Contrato">{datosClub && datosClub.numContrato}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="cuotasAprobadas"><b>Cuotas Aprobadas:</b></label>
                                                        <p id="CuotasAprobadas">{CuotasAprobadas}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={3}>
                                                        <label htmlFor="Saldo"><b>Saldo:</b></label>
                                                        <p id="Saldo">{datosClub && `₡${datosClub.saldo}`}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="fechaContrato"><b>Fecha del contrato:</b></label>
                                                        <p id="fechaContrato">{datosClub && formatearFecha(datosClub.fecContrato)}</p>
                                                    </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="fechaVencimiento"><b>Fecha de Primer Rebajo:</b></label>
                                                        <p id="fechaVencimiento">{datosClub && formatearFecha(datosClub.fecPrimerRebajo)}</p>
                                                    </Col>
                                                
                                                </Row>
                                            </>
                                        ) : (
                                                <>
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <h1 className="card-title" style={{ color: 'orangered' }}>Información del Cliente</h1>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Col md={4}>
                                                            <label htmlFor="nombre"><b>Nombre:</b></label>
                                                            <p id="nombre">{datosGrupo.nombre}</p>
                                                        </Col>
                                                        <Col md={4}>
                                                            <label htmlFor="primerApellido"><b>Primer Apellido:</b></label>
                                                            <p id="primerApellido">{datosGrupo.primerApellido}</p>
                                                        </Col>
                                                        <Col md={4}>
                                                            <label htmlFor="segundoApellido"><b>Segundo Apellido:</b></label>
                                                            <p id="segundoApellido">{datosGrupo.segundoApellido}</p>
                                                        </Col>
                                                       
                                                    </Row>
                                                    <Row className="mt-4"></Row>
                                                    <Row className="mt-4">
                                                        <Col md={4}>
                                                            <label htmlFor="identificacion"><b>Identificacion:</b></label>
                                                            <p id="identificacion">{datosGrupo.identificacion}</p>
                                                        </Col>
                                                        <Col md={4}>
                                                            <label htmlFor="email"><b>Email:</b></label>
                                                            <p id="email">{datosGrupo.correoElectronico}</p>
                                                        </Col>
                                                        <Col md={4}>
                                                            <label htmlFor="numero"><b>Numero Telefono:</b></label>
                                                            <p id="numero">{datosGrupo.telefono}</p>
                                                        </Col>
                                                      
                                                       

                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Col md={4}>
                                                            <label htmlFor="direccion"><b>Direccion:</b></label>
                                                            <p id="direccion">{datosGrupo.direccion}</p>
                                                        </Col>


                                                    </Row>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className="card-title" style={{ color: 'orangered' }}>Información del Paquete</h1>
                                                    </Col>
                                                </Row>
                                                    <Row className="mt-4">
                                                        <Col md={3}>
                                                            <label htmlFor="descripcion"><b>Destino:</b></label>
                                                            <p id="descripcion">{datosGrupo.descripcion}</p>
                                                        </Col>
                                                      <Col md={3}>
                                                        <label htmlFor="fecSalida"><b>Fecha Salida:</b></label>
                                                         <p id="fecSalida">{formatearFecha(datosGrupo.fechaSalida)}</p>
                                                      </Col>
                                                    <Col md={3}>
                                                        <label htmlFor="fecLlegada"><b>Fecha Llegada:</b></label>
                                                        <p id="fecLlegada">{formatearFecha(datosGrupo.fechaLLegada)}</p>
                                                    </Col>
                                                       
                                                        <Col md={3}>
                                                            <label htmlFor="costoTotal"><b>Monto Total:</b></label>
                                                            <p id="CostoTotal">{datosGrupo.costoTotal}</p>
                                                        </Col>
                                                   
                                                </Row>
                                                <Row className="mt-4"></Row>
                                                <Row className="mt-4">
                                                    <Col md={3}>
                                                        <label htmlFor="seguro"><b>Seguro:</b></label>
                                                            <p id="seguro">{datosGrupo && `₡${datosGrupo.seguro}`}</p>
                                                    </Col>      
                                                       <Col md={3}>
                                                          <label htmlFor="cuenta"><b>Descuentos:</b></label>
                                                            <p id="descuentos">{datosGrupo && `₡${datosGrupo.descuento}`}</p>
                                                     </Col>      
                                               
                                                    <Col md={3}>
                                                         <label htmlFor="tipoPago"><b>Tipo de Pago:</b></label>
                                                           <p id="tipoPago">{obtenerDescripcionTipoPago(datosGrupo.tipoPago)}</p>
                                                     </Col>
                                                        <Col md={3}>
                                                            <label htmlFor="monsaldo"><b>Saldo:</b></label>
                                                            <p id="monSaldo">{datosGrupo.saldo}</p>
                                                        </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Container>
                                </Row>
                            </Tab>
                            {mostrarTabTourCliente && (
                                <Tab eventKey="tourC" title="Tour Cliente">
                                    {mostrarGrupo ? (
                                        <Row className="mt-4">
                                            <Container className="d-Grid align-content-center">
                                                <Row className="mt-4">
                                                    <Col>
                                                        <h1 className="card-title" style={{ color: 'orangered' }}>Información del Tour Cliente</h1>
                                                    </Col>
                                                </Row>
                                                {listaToursC.length > 0 ? (
                                                    <Grid
                                                        gridHeading={encabezadoTourCliente}
                                                        gridData={listaToursC}
                                                        idBuscar="idPaquete"
                                                        selectableRows={false}
                                                        pending={pendiente}
                                                    />
                                                ) : (
                                                    <Col md={12} style={{ textAlign: 'center' }}>
                                                        <h5>No hay datos para mostrar</h5>
                                                    </Col>
                                                )}
                                            </Container>
                                        </Row>
                                    ) : (
                                        <Col md={12} style={{ textAlign: 'center' }}>
                                            <h1 className="card-title" style={{ color: 'orangered' }}>Información del Tour Cliente</h1>
                                            <h5>No hay datos para mostrar</h5>
                                        </Col>
                                    )}
                                </Tab>
                            )}
                            {mostrarTabAcompanantes && (
                                <Tab eventKey="tourA" title="Acompañantes">
                                    {mostrarGrupo ? (
                                        <>
                                            <Row className="mt-4">
                                                <Container className="d-Grid align-content-center">
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <h1 className="card-title" style={{ color: 'orangered' }}>Acompañantes</h1>
                                                        </Col>
                                                    </Row>
                                                    {listaA.length > 0 ? (
                                                        <Grid
                                                            gridHeading={encabezadoAcom}
                                                            gridData={listaA}
                                                            idBuscar="idPaquete"
                                                            selectableRows={false}
                                                            pending={pendiente}
                                                        />
                                                    ) : (
                                                        <Col md={12} style={{ textAlign: 'center' }}>
                                                            <h5>No hay datos para mostrar</h5>
                                                        </Col>
                                                    )}
                                                </Container>
                                            </Row>
                                            <Row className="mt-4">
                                                <Container className="d-Grid align-content-center">
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <h1 className="card-title" style={{ color: 'orangered' }}>Tours de Acompañantes</h1>
                                                        </Col>
                                                    </Row>
                                                    {listaToursA.length > 0 ? (
                                                        <Grid
                                                            gridHeading={encabezadoTourAcompanante}
                                                            gridData={listaToursA}
                                                            idBuscar="idPaquete"
                                                            selectableRows={false}
                                                            pending={pendiente}
                                                        />
                                                    ) : (
                                                        <Col md={12} style={{ textAlign: 'center' }}>
                                                            <h5>No hay datos para mostrar</h5>
                                                        </Col>
                                                    )}
                                                </Container>
                                            </Row>
                                        </>
                                    ) : (
                                        <Col md={12} style={{ textAlign: 'center' }}>
                                            <h1 className="card-title" style={{ color: 'orangered' }}>Acompañantes</h1>
                                            <h5>No hay datos para mostrar</h5>
                                        </Col>
                                    )}
                                </Tab>
                            )}
                            {mostrarTabPagos && mostrarGrupo && (
                                <Tab eventKey="pagos" title="Registro de pagos">
                                    <Row className="mt-4">
                                        <Col>
                                            <h1 className="card-title" style={{ color: 'orangered' }}>Registro de Pagos</h1>
                                        </Col>
                                    </Row>
                                    {listaDeAbonos.length > 0 ? (
                                        <Grid
                                            gridHeading={encabezado}
                                            gridData={listaDeAbonos}
                                            idBuscar="idPaquete"
                                            selectableRows={false}
                                            pending={pendiente}
                                        />
                                    ) : (
                                        <Col md={12} style={{ textAlign: 'center' }}>
                                            <h5>No hay datos para mostrar</h5>
                                        </Col>
                                    )}
                                </Tab>
                            )}

                            {mostrarTabCobros && (
                                <Tab eventKey="cobros" title="Registro de cobros">
                                    {mostrarInformacionClubes ? (
                                        <>
                                            <Row className="mt-4">
                                                <Container className="d-Grid align-content-center" style={{ maxWidth: '90%' }}>
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <h1 className="card-title" style={{ color: 'orangered' }}>Registro de Cobros</h1>
                                                        </Col>
                                                    </Row>
                                                    {listaDeAbonosC.length > 0 ? (
                                                        <Grid
                                                            gridHeading={encabezadoAbonosC}
                                                            gridData={listaDeAbonosC}
                                                            idBuscar="idClubViaje"
                                                            selectableRows={false}
                                                            pending={pendienteC}
                                                        />
                                                    ) : (
                                                        <Col md={12} style={{ textAlign: 'center' }}>
                                                            <h5>No hay datos para mostrar</h5>
                                                        </Col>
                                                    )}
                                                </Container>
                                            </Row>
                                        </>
                                    ) : (
                                        <>

                                        </>
                                    )}
                                </Tab>
                            )}
                        </Tabs>
                    </Card.Body>
                </Card>
            )}

            <Modal show={mostrarModal} onHide={() => setMostrarModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Bienvenido a tu viaje {listaToursC.length > 0 ? listaToursC[0].pais : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center" style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
                        <img src={imagen} style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setMostrarModal(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Cliente;