import React from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';


export const FormularioModal = ({show, handleClose, titulo, className, children, tamano}) => {  

    return(
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName={className}
                aria-labelledby="contained-modal-title-vcenter" centered size={tamano } >
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}                   
                </Modal.Body>
            </Modal>
        </>
    )
}

export const MensajeModal = ({show, handleClose, titulo, mensaje, handleAceptar}) => {
    return(
        <>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title className='h5'>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mensaje}                    
                    <div className='text-center'>
                        <br/>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="outline-secondary" 
                            size="sm"
                            onClick={handleClose}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            Cancelar
                        </Button>{' '}
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="outline-primary" 
                            size="sm"
                            onClick={() => handleAceptar()}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Aceptar
                        </Button>   
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export const MensajeModalAceptar = ({show, handleClose, titulo, mensaje}) => {
    return(
        <>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} 
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title className='h5'>{titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {mensaje}                    
                    <div className='text-center'>
                        <br/>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="outline-primary" 
                            size="sm"
                            onClick={() => handleClose()}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Aceptar
                        </Button>   
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
