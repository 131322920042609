import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ValidarCorreoUsuario, ObtenerUsuarioPorRolYCorreo, ActualizarContrasenhaTemporal } from "../../servicios/ServicioAutenticacion";
import "../../styles/login.css";

const ModalRecuperarContrasena = ({ show, handleClose, handleResponse }) => {
    const [correo, setCorreo] = useState("");
    const [validated, setValidated] = useState(false);
    const [roles, setRoles] = useState([]);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isCorreoReadOnly, setCorreoReadOnly] = useState(false);
    const [isEnviarVisible, setEnviarVisible] = useState(false);
    const [isValidarVisible, setValidarVisible] = useState(true);
    const [selectedIdRol, setSelectedIdRol] = useState("");

    const [errors, setErrors] = useState({});

    const handleRolSelect = (e) => {
        setSelectedIdRol(e.target.value);
    };

    const handleCorreoChange = (e) => setCorreo(e.target.value);

    const handleValidar = async (event) => {
        event.preventDefault();

        const newErrors = {};
        if (!correo) {
            newErrors.correo = "Por favor ingrese un correo electrónico.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const respuesta = await ValidarCorreoUsuario(correo);

            if (respuesta.length > 0) {
                setRoles(respuesta);
                setDropdownVisible(true);
                setCorreoReadOnly(true);
                setEnviarVisible(true);
                setValidarVisible(false);
                setErrors('');
            } else {
                setRoles([]);
                setDropdownVisible(false);
                setEnviarVisible(false);
                setCorreoReadOnly(false);
                setValidarVisible(true);
                setErrors({ correo: "Correo no encontrado." });
            }
        } catch (error) {
            console.error("Error al validar correo:", error);
            setErrors({ correo: "Error al validar el correo. Inténtelo de nuevo." });
        }
    };

    const handleLimpiar = () => {
        setRoles([]);
        setCorreo('');
        setErrors('');
        setDropdownVisible(false);
        setCorreoReadOnly(false);
        setEnviarVisible(false);
        setSelectedIdRol("");
        setValidarVisible(true);
    };

    const generarContrasena = (longitud = 8) => {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let contrasena = '';
        for (let i = 0; i < longitud; i++) {
            const indice = Math.floor(Math.random() * caracteres.length);
            contrasena += caracteres[indice];
        }
        return contrasena;
    };

    const handleEnviarContrasena = async () => {
        const newErrors = {};
        if (!selectedIdRol) {
            newErrors.rol = "Debe seleccionar un rol.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {

            const datosUsuario = {
                idRol: selectedIdRol,
                correo: correo,
            };

            const respuestaDatos = await ObtenerUsuarioPorRolYCorreo(datosUsuario)

            const contrasena = generarContrasena();

            const datosCambioContrasena = {
                idUsuario: respuestaDatos.idUsuario,
                contrasenaTemporal: contrasena,
                correo: correo
            };

            const respuesta = await ActualizarContrasenhaTemporal(datosCambioContrasena);

            respuesta.mensaje = 'Contraseña Recuperada Exitosamente'
            // Verifica si el indicador es 0 y maneja la respuesta
            if (respuesta.indicador === 0) {
                handleResponse(respuesta); // Llama a la función manejadora con la respuesta
            }

            handleLimpiar();
            handleClose();


        } catch (error) {
            console.error("Error al enviar la contraseña:", error);
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-custom-center modal-dialog-centered"
        >
            <Modal.Header closeButton>
                <Modal.Title>Recuperar Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleValidar}>
                    <Form.Group controlId="formBasicEmail" style={{ marginBottom: '1rem' }}>
                        <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Ingrese su correo electrónico"
                            value={correo}
                            onChange={handleCorreoChange}
                            readOnly={isCorreoReadOnly}
                            isInvalid={!!errors.correo}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.correo}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {isDropdownVisible && (
                        <Form.Group controlId="formBasicRole" style={{ marginBottom: '1rem' }}>
                            <Form.Label>Seleccionar Rol</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedIdRol}
                                onChange={handleRolSelect}
                                isInvalid={!!errors.rol}
                            >
                                <option value="">Seleccionar Rol</option>
                                {roles.map((role) => (
                                    <option key={role.idRol} value={role.idRol}>
                                        {role.descripcion}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.rol}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    <div className="modal-footer-custom">
                        <Button variant="secondary" onClick={() => { handleClose(); handleLimpiar(); }}>
                            Cancelar
                        </Button>
                        {isValidarVisible && (
                        <Button variant="primary" type="submit">
                            Validar
                            </Button>
                        )}
                        {isEnviarVisible && (
                            <Button variant="success" onClick={handleEnviarContrasena}>
                                Enviar Contraseña
                            </Button>
                        )}
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRecuperarContrasena;
