import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "ClubDeViaje";

export const ObtenerClubDeViaje = async() => {
    const url = `${controlador}/ObtenerClubDeViaje`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerClubDeViajePorId = async (idClub) => {
    const url = `${controlador}/ObtenerDetalleClubDeViajeID/${idClub}`;
    return await ProcesarDatosApi('GET', url);
}
export const ObtenerComentariosClubDeViajePorId = async (idClub) => {
    const url = `${controlador}/ObtenerComentariosClubDeViajeID/${idClub}`;
    return await ProcesarDatosApi('GET', url);
}

export const InactivarClubDeViaje = async (id) => {
    const url = `${controlador}/EliminarClubDeViaje?idClubDeViaje=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const AgregarComentario = async (data) => {
    const url = `${controlador}/insertarComentario`;
    return await ProcesarDatosApi('POST', url, data);
}

export const AgregarClubDeViaje = async (data) => {
    const url = `${controlador}/insertarClubDeViaje`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActualizarClubDeViaje = async (data) => {
    const url = `${controlador}/actualizarClubDeViaje`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ObtenerTiposDeCuentas = async() => {
    const url = `${controlador}/ObtenerTiposDeCuentas`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerTiposDeTarjetas = async() => {
    const url = `${controlador}/ObtenerTiposDeTarjetas`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerFrecuenciasDePago = async() => {
    const url = `${controlador}/ObtenerFrecuenciasDePago`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerTiposDeCuotas = async () => {
    const url = `${controlador}/ObtenerTiposDeCuotas`;
    return await ProcesarDatosApi('GET', url);
}

export const ActualizarEstadoCuota = async (data) => {
    const url = `${controlador}/modificarEstadoCuota`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ObtenerEstadoCuotasClub = async (idClub) => {
    const url = `${controlador}/ObtenerEstadoCuotaClub/${idClub}`;
    return await ProcesarDatosApi('GET', url);
}

export const ActivarRegaliaMontania = async (data) => {
    const url = `${controlador}/activarRegaliaMontania`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActivarRegaliaPlaya = async (data) => {
    const url = `${controlador}/activarRegaliaPlaya`;
    return await ProcesarDatosApi('POST', url, data);
}

export const GuardarDatosCierreClub = async (data) => {
    const url = `${controlador}/guardarDatosCierreClub`;
    return await ProcesarDatosApi('POST', url, data);
}

/*nuevo servicio para editar comentarios*/

export const ActualizarComentarioClubDeViaje = async (data) => {
    let url;
    if (data.IndicadorPlan === 1) {
        url = `${controlador}/ActualizarComentarioClubDeViaje`;
    } else if (data.IndicadorPlan === 2) {
        url = `Paquete/ActualizarComentarioPaquete`;
    } else {
        throw new Error("Tipo de comentario no v�lido");
    }

    return await ProcesarDatosApi('PUT', url, data);
}