import React, { useState } from "react"
import { Button, Form } from "react-bootstrap";
import { InputText } from "../../components/inputs";
import ModalRecuperarContrasena from "../login/ModalRecuperarContrasena";
import { AlertDismissible } from "../../components/alerts";
import "../../styles/login.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const FormLogin = ({ IniciarSesion, mensaje }) => {
    const [usuario, setUsuario] = useState("");
    const [contrasenha, setContrasenha] = useState("");
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onChangeUsuario = (e) => setUsuario(e.target.value);
    const onChangeContrasenha = (e) => setContrasenha(e.target.value);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [showAlert, setShowAlert] = useState(false);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});


    const onClickAceptar = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = {
                usuario: usuario,
                contrasena: contrasenha
            }
            IniciarSesion(data);
        }
        setValidated(true);
        event.preventDefault();
    }

    const handleResponse = (handleResponse) => {

        if (handleResponse.indicador == 0) {

            setMensajeRespuesta(handleResponse.mensaje);
            if (handleResponse.indicador == 0) {
                setMensajeRespuesta(handleResponse);

            } else {
                setMensajeRespuesta("");
            }

            setShowAlert(true);
        }
    }

    return (
        <>
            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <Form noValidate validated={validated} onSubmit={onClickAceptar}>
                <h2>Iniciar sesión</h2>
                <InputText id='txt-usuario' label='Usuario:' type='text' placeholder='Ingrese su usuario' value={usuario}
                    text='Nunca comparta los datos de su usuario' onChange={onChangeUsuario} mensajeValidacion="El usuario es requerido" />
                <InputText id='txt-contrasenha' label='Contraseña:' type='password' placeholder='' value={contrasenha}
                    text='' onChange={onChangeContrasenha} mensajeValidacion="La contraseña es requerida" />
                <div className="text-center">{mensaje !== '' ? <span> <br /> {mensaje}</span> : ''}</div>
                <div className="text-center">
                    <a href="#" id="link-recuperar" onClick={handleShowModal}>Recuperar Contraseña</a>
                </div>
                <Button type="submit" className="btn" id="btn-ingresar">Ingresar </Button>

                <div className="text-center">{mensaje !== '' ? <span> <br /> {mensaje}</span> : ''}</div>

            </Form>
            <ModalRecuperarContrasena show={showModal} handleClose={handleCloseModal} handleResponse={handleResponse} />
        </>
    )
}

export default FormLogin;