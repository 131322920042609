import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import CobrosClubViaje from './CobrosClubViaje';
import CobrosPaquete from './CobrosPaquete';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const AprobarCobros = () => {

    const [forceRerender, setForceRerender] = useState(false);
    const [isCobrosClubViaje, setIsCobrosClubViaje] = useState(true);

    useEffect(() => {
        setForceRerender(prev => !prev);

    }, []);


    return <>
        <Col md={6} className="d-flex flex-column" style={{ padding: "0 30px", marginBottom:"10px" }}>
            <label htmlFor="switch">
                <b>Tipo de cobro</b>
            </label>
            <BootstrapSwitchButton key={forceRerender}
                id={`switchTipoCobro`}
                onlabel="Club de viajes"
                offlabel="Paquetes"
                checked={isCobrosClubViaje}
                onChange={() => setIsCobrosClubViaje(!isCobrosClubViaje)}
                onstyle="outline-primary"
                offstyle="outline-primary"
                width={200}
            />
        </Col> 
        {isCobrosClubViaje && <CobrosClubViaje />}
        {!isCobrosClubViaje && <CobrosPaquete />}
       
    </>
}

export default AprobarCobros;