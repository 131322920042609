import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "../../styles/clubDeViaje.css";
import {
    InputSelectHookForm,
    InputsFormsReactHook,
    CheckBoxFormsReactHook,
} from "../Forms/InputsFormsReactHook";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { InputsFormsReactHookMask } from "..//MaskVencimiento";
import { InputsFormsReactHookMaskCard } from "..//MaskTarjeta";
import {
    ObtenerFrecuenciasDePago,
    ObtenerTiposDeCuentas,
    ObtenerTiposDeTarjetas,
} from "../../servicios/ServicioClubDeViaje";
import { ObtenerSupervisores } from '../../servicios/ServicioSupervisor';
import { ObtenerVendedores } from '../../servicios/ServicioVendedor';
import "react-datepicker/dist/react-datepicker.css";

function CardInformacionCuenta({
    errors,
    isSummitInfo,
    valoresCuenta,
    listaDias,
    register,
    isSummit,
    setMostrarMensual,
    setMostrarQuincenal,
    setMostrarSemanal,
    mostrarMensual,
    mostrarQuincenal,
    mostrarSemanal,
    setSelectedOption,
    selectedOption,
    setPagoMensual,
    setPagoQuincenal,
    setPagoQuincenal2,
    pagoMensual,
    pagoQuincenal,
    pagoQuincenal2,
    idVendedor,
    idSupervisor,
    setIdVendedor,
    setIdSupervisor,
    setFecPrimerRebajo,
    fecPrimerRebajo,
    TipoDeCobro,
    setTipoDeCobro,
    PagoAutomatico,
    setPagoAutomatico
}) {
    
    const [listaTiposDeCuenta, setListaTiposDeCuenta] = useState([]);
    const [listaTiposDeTarjeta, setListaTiposDeTarjeta] = useState([]);
    const [listaFrecuenciaDePago, setListaFrecuenciaDePago] = useState([]);
    const [TipoCuenta, setTipoCuenta] = useState();
    const [TipoTarjeta, setTipoTarjeta] = useState();
    const [frecuenciaDePago, setfrecuenciaDePago] = useState();
    const [listaSupervisores, setListaSupervisores] = useState([]);
    const [listaVendedores, setListaVendedores] = useState([]);

    ///*pruebas*/

    const [CargaVendedores, setCargaVendedores] = useState(true);
    const [CargaSupervisores, setCargaSupervisores] = useState(true);

    useEffect(() => {
        ObtenerListadoDatosSelects();
    },[]);

    const ObtenerListadoDatosSelects = async () => {
        try {
            const tiposCuentas = await ObtenerTiposDeCuentas();


            if (tiposCuentas !== undefined) {
                setListaTiposDeCuenta(tiposCuentas);
            }

            const tiposTarjeta = await ObtenerTiposDeTarjetas();
            if (tiposTarjeta !== undefined) {
                setListaTiposDeTarjeta(tiposTarjeta);
            }
            const frecuenciaDePago = await ObtenerFrecuenciasDePago();
            if (frecuenciaDePago !== undefined) {
                setListaFrecuenciaDePago(frecuenciaDePago);
            }

            
        } catch (error) {
            console.log(error)
        }
       
    };

   /* codigo nuevo para obtener vendedores*/

    useEffect(() => {
        obtenerVendedores();
    }, []);

    const obtenerVendedores = async () => {
        try {
            const vendedores = await ObtenerVendedores();
            if (vendedores !== undefined) {
                var vendedoresFormateados = vendedores.map(
                    vendedor => ({ idVendedor: vendedor.idVendedor, nombre: vendedor.nombre + ' ' + vendedor.primerApellido })
                );
                setListaVendedores(vendedoresFormateados);
                setCargaVendedores(false);
            }
        } catch (error) {
            console.log(error);
            setCargaVendedores(false);
        }
    };


    useEffect(() => {
        obtenerSupervisores();
    }, []);

    const obtenerSupervisores = async () => {
        try {
            const supervisores = await ObtenerSupervisores();
            if (supervisores !== undefined) {
                var supervisoresFormateados = supervisores.map(
                    supervisor => (
                        { idSupervisor: supervisor.idSupervisor, nombre: supervisor.nombre + ' ' + supervisor.primerApellido })
                );
                setListaSupervisores(supervisoresFormateados);
                setCargaSupervisores(false);
            }
        } catch (error) {
            console.log(error);
            setCargaSupervisores(false);
        }
    };





    
    const onChangeTipoCuenta = async (event) => {
        setTipoCuenta(event.target.value);
        if (event.target.value != "") {
            if (errors?.IdTipoCuenta) {
                errors.IdTipoCuenta.type = undefined;
            }
        }
    };

    const onChangeTipoTajeta = async (event) => {
        setTipoTarjeta(event.target.value);
        if (event.target.value != "") {
            if (errors?.IdTipoTarjeta) {
                errors.IdTipoTarjeta.type = undefined;
            }
        }
    };

    const onChangeFrecuenciaDePago = async (event) => {
        const value = event.target.value;

        if (value === "2" || value === "3") {
            setFecPrimerRebajo(null);
        }
        setfrecuenciaDePago(value);
        setMostrarSemanal(value === "1");
        setMostrarQuincenal(value === "2");
        setMostrarMensual(value === "3");

        if (value !== "" && errors?.IdFrecuenciaPago) {
            errors.IdFrecuenciaPago.type = undefined;
        }
    };

    const onChangeSupervisor = (e) => {
        setIdSupervisor(Number(e.target.value));
        if (e.target.value != "") {
            if (errors?.IdSupervisor) {
                errors.IdSupervisor.type = undefined;
            }
        }
    };

    const onChangeVendedor = (e) => {
        setIdVendedor(Number(e.target.value));
        if (e.target.value != "") {
            if (errors?.IdVendedor) {
                errors.IdVendedor.type = undefined;
            }
        }
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    ///////////////////////////

    const onChangePagoMensual = async (event) => {
        setPagoMensual(event.target.value);
        setFecPrimerRebajo(null);

        if (event.target.value != "") {
            if (errors?.pagoMensual) {
                errors.pagoMensual.type = undefined;
            }
        }
    };
    const onChangePagoQuincenal = async (event) => {
        setPagoQuincenal(event.target.value);

        setFecPrimerRebajo(null);
        if (event.target.value != "") {
            if (errors?.pagoQuincenal) {
                errors.pagoQuincenal.type = undefined;
            }
        }
    };
    const onChangePagoQuincenal2 = async (event) => {
        setPagoQuincenal2(event.target.value);
        setFecPrimerRebajo(null);
        if (event.target.value != "") {
            if (errors?.PagoQuincenal2) {
                errors.PagoQuincenal2.type = undefined;
            }
        }
    };

    const validateFecVenceCuenta = (value) => {
        return value.length === 5 || 'required';
    };


    const [forceRerender, setForceRerender] = useState(false);
    


    const handleTipodePago = () => {
        setPagoAutomatico(prevState => !prevState);
        setTipoDeCobro(prevState => prevState === 2 ? 1 : 2);

    };


    useEffect(() => {
        setForceRerender(prev => !prev);
        setPagoAutomatico(prevState => !prevState);
    }, []);

    return (
        <Card className="card-container" style={{ width: "45%" }}>
            <Card.Body>

                <Card.Title className="card-title mb-5">
                    Información de la Cuenta
                </Card.Title>
                <Container className="d-Grid align-content-center">

                    <Card className="card-container"
                        style={{
                            width: "auto" ,
                            height: "auto",
                            padding: "0",
                            marginBottom: "2%",

                    }}>
                        <Row className="align-items-center">
                            <Col style={{alignItems: "center", marginBottom: 0 }}>
                                <Card.Title style={{ fontSize: "18px" }} >
                                    Tipo de Cobro
                                </Card.Title>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Card.Body style={{ marginBottom: 0, alignItems: "center" }}>
                                    <BootstrapSwitchButton
                                        key={forceRerender}
                                        id={`switchTipoPago`}
                                        onlabel={<span style={{ whiteSpace: 'nowrap' }}>Pago Automático</span>}
                                        offlabel="Depósito"
                                        checked={(TipoDeCobro === 1)? true : false}
                                        onChange={() => handleTipodePago()}
                                        onstyle="outline-primary"
                                        offstyle="outline-secondary"
                                        width={170}
                                    />
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>    
                    {TipoDeCobro === 1 && (
                        <>

                        <Row >
                            <Col className="md-8">
                                <InputsFormsReactHookMaskCard
                                    classDiv={"col-md-12"}
                                    type={"text"}
                                    step={"1"}
                                    label="Número de Tarjeta:"
                                    placeholder="9999-9999-9999-9999"
                                    id="numeroTarjetaInput"
                                    mensajeValidacion="El Número de Tarjeta es requerido"
                                    errors={errors?.NumCuenta?.type}
                                    isSummit={isSummitInfo}
                                    applyMask={true}
                                        externalValue={
                                            valoresCuenta.numCuenta === "" ? null : valoresCuenta.numCuenta
                                        }
                                    register={{
                                        ...register("NumCuenta", {
                                            required: (TipoDeCobro === 1) ? true : false,
                                            maxLength: 19,
                                            minLength: 10,
                                        }),
                                    }}
                                />
                            </Col>
                            <Col className="md-4">
                                <InputsFormsReactHookMask
                                    classDiv={"col-md-12"}
                                    label="Vencimiento:"
                                    type="text"
                                    placeholder="MM/YY"
                                    id="vencimientoInput"
                                    mensajeValidacion="El Vencimiento es requerido"
                                    errors={errors?.FecVenceCuenta?.type}
                                    isSummit={isSummitInfo}
                                    applyMask={true}
                                    customWidth="120px"
                                        externalValue={
                                            valoresCuenta.fecVenceCuenta === "" ? null : valoresCuenta.fecVenceCuenta
                                        }
                                    register={{
                                        ...register("FecVenceCuenta", {
                                            required: (TipoDeCobro === 1) ? true : false,
                                            validate: validateFecVenceCuenta,
                                        }),
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col md={6}>
                                <InputsFormsReactHook
                                    classDiv={"col-md-12"}
                                    label="Emisor:"
                                    placeholder="Emisor"
                                    mensajeValidacion="El Emisor es requerido"
                                    errors={errors?.Emisor?.type}
                                    isSummit={isSummitInfo}
                                    externalValue={
                                        valoresCuenta.Emisor === "" ? null : valoresCuenta.Emisor
                                    }
                                        register={{ ...register("Emisor", { required: (TipoDeCobro === 1) ? true : false }) }}
                                />
                            </Col>
                            <Col md={6}>
                                <InputSelectHookForm
                                    className="form-control custom-select"
                                    controlId="sel-cuenta"
                                    label={<strong>Tipo Cuenta: </strong>}
                                    data={listaTiposDeCuenta}
                                    onChange={onChangeTipoCuenta}
                                    value={TipoCuenta}
                                    mensajeValidacion={"Seleccione un Tipo Cuenta"}
                                    register={{
                                        ...register("IdTipoCuenta", { required: (TipoDeCobro === 1) ? true : false }),
                                    }}
                                    optionValue="id"
                                    errors={errors?.IdTipoCuenta?.type}
                                    isSummit={isSummitInfo}
                                    optionLabel="dscTipoCuenta"
                                    classGroup="col-md-12"
                                />
                            </Col>
                        </Row>
                        </>
                    )}




                    <Row className="mt-4">

                        {TipoDeCobro === 1 && (

                            <Col md={6}>
                                <InputSelectHookForm
                                    className="form-control custom-select "
                                    label={<strong>Tipo Tarjeta: </strong>}
                                    data={listaTiposDeTarjeta}
                                    onChange={onChangeTipoTajeta}
                                    value={TipoTarjeta}
                                    mensajeValidacion={"Seleccione un Tipo Tarjeta"}
                                    register={{
                                        ...register("IdTipoTarjeta", { required: (TipoDeCobro === 1) ? true : false }),
                                    }}
                                    optionValue="id"
                                    errors={errors?.IdTipoTarjeta?.type}
                                    isSummit={isSummit}
                                    optionLabel="dscTipoTarjeta"
                                    classGroup="col-md-12"
                                />
                            </Col>
                        )}

                        <Col md={(TipoDeCobro === 1)? 6 : 8}>
                            <InputSelectHookForm
                                className="form-control custom-select"
                                controlId="sel-FrecuenciaDePAgo"
                                label={<strong>Frecuencia de Pago: </strong>}
                                data={listaFrecuenciaDePago}
                                onChange={onChangeFrecuenciaDePago}
                                value={frecuenciaDePago}
                                mensajeValidacion={"Seleccione un Frecuencia de Pago"}
                                register={{
                                    ...register("IdFrecuenciaPago", { required: true }),
                                }}
                                optionValue="id"
                                errors={errors?.IdFrecuenciaPago?.type}
                                isSummit={isSummit}
                                optionLabel="dscFrecuenciasDePago"
                                classGroup="col-md-12"
                            />
                        </Col>

                        {mostrarSemanal && (
                            <Col md={12} className="ml-3 mt-4">
                                <CheckBoxFormsReactHook
                                    label="Días de la semana"
                                    mensajeValidacion="Debe seleccionar un día"
                                    options={[
                                        "Lunes",
                                        "Martes",
                                        "Miércoles",
                                        "Jueves",
                                        "Viernes",
                                        "Sábado",
                                        "Domingo",
                                    ]}
                                    register={{
                                        ...register("PagoSemanal", { required: true }),
                                    }}
                                    selectedOption={selectedOption}
                                    errors={errors?.PagoSemanal?.type}
                                    onChange={handleOptionChange}
                                />
                            </Col>
                        )}

                        {mostrarMensual && (
                            <Col md={12} className=" mt-4">
                                <strong className="ml-3 ">Pago mensual:</strong>
                                <InputSelectHookForm
                                    className="form-control custom-select"
                                    controlId="sel-PagoMensual"
                                    data={listaDias}
                                    onChange={onChangePagoMensual}
                                    value={pagoMensual}
                                    mensajeValidacion={"Seleccione un Día para el Pago Mensual"}
                                    placeholder={"--"}
                                    register={{
                                        ...register("PagoMensual", { required: true }),
                                    }}
                                    optionValue="id"
                                    customWidth="80px"
                                    errors={errors?.PagoMensual?.type}
                                    isSummit={isSummit}
                                    optionLabel="Dias"
                                    classGroup="col-md-12 "
                                />
                            </Col>
                        )}

                        {mostrarQuincenal && (
                            <Col md={12} className=" mt-4">
                                <strong className="ml-3 ">Pagos quincenales:</strong>
                                <div
                                    className="form-group-container mt-2"
                                    style={{ width: "22.2rem" }}
                                >
                                    <p className="ml-3 mb-0 d-flex align-items-center">
                                        Fecha 1:
                                    </p>
                                    <div className="form-group ">
                                        <InputSelectHookForm
                                            className="form-control custom-select"
                                            controlId="sel-PagoQuincenal"
                                            data={listaDias}
                                            onChange={onChangePagoQuincenal}
                                            value={pagoQuincenal}
                                            mensajeValidacion={"Requerido"}
                                            placeholder={"--"}
                                            register={{
                                                ...register("PagoQuincenal", { required: true }),
                                            }}
                                            optionValue="id"
                                            customWidth="65px"
                                            errors={errors?.PagoQuincenal?.type}
                                            isSummit={isSummit}
                                            optionLabel="Dias"
                                            classGroup="col-md-12"
                                        />
                                    </div>
                                    <p className="ml-3 mb-0 d-flex align-items-center">
                                        Fecha 2:
                                    </p>
                                    <div className="form-group">
                                        <InputSelectHookForm
                                            className="form-control custom-select"
                                            controlId="sel-PagoQuincenal2"
                                            data={listaDias}
                                            onChange={onChangePagoQuincenal2}
                                            value={pagoQuincenal2}
                                            mensajeValidacion={"Requerido"}
                                            placeholder={"--"}
                                            register={{
                                                ...register("PagoQuincenal2", { required: true }),
                                            }}
                                            optionValue="id"
                                            customWidth="65px"
                                            errors={errors?.PagoQuincenal?.type}
                                            isSummit={isSummit}
                                            optionLabel="Dias"
                                            classGroup="col-md-12"
                                        />
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </Card.Body>

            <Card.Body>
                <Card.Title className="card-title mb-5">
                    Información de la Venta 
                </Card.Title>
                <Container className="d-Grid align-content-center">
                    <Row className="mt-4">
                        <Col md={6}>
                            <InputSelectHookForm
                                className="form-control custom-select"
                                controlId="sel-vendedor"
                                label={<strong>Vendedor: </strong>}
                                data={listaVendedores}
                                onChange={onChangeVendedor}
                                value={idVendedor}
                                mensajeValidacion={"Seleccione un vendedor"}
                                register={{
                                    ...register("IdVendedor", { required: true }),
                                }}
                                optionValue="idVendedor"
                                errors={errors?.IdVendedor?.type}
                                isSummit={isSummitInfo}
                                optionLabel="nombre"
                                classGroup="col-md-12"
                            />
                        </Col>
                        <Col md={6}>
                            <InputSelectHookForm
                                className="form-control custom-select"
                                controlId="sel-supervisor"
                                label={<strong>Supervisor: </strong>}
                                data={listaSupervisores}
                                onChange={onChangeSupervisor}
                                value={idSupervisor}
                                mensajeValidacion={"Seleccione un supervisor"}
                                register={{
                                    ...register("IdSupervisor", { required: true }),
                                }}
                                optionValue="idSupervisor"
                                errors={errors?.IdSupervisor?.type}
                                isSummit={isSummitInfo}
                                optionLabel="nombre"
                                classGroup="col-md-12"
                            />
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>

    );

}

export default CardInformacionCuenta