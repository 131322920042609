import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../styles/clubDeViaje.css";
import "react-datepicker/dist/react-datepicker.css";


function CardInformacionCliente({
    onClickBuscarPersona,
    personaSeleccionada,
    InputsFormsReactHook,
    errors,
    isSummitInfo,
    register,
    NomBeneficiario,
    NumIdentificacionBeneficiario,
    IdClubDeViaje 
}) {


    return (
        <Card className="card-container" style={{ width: "53%" }}>
            <Card.Body>

                { IdClubDeViaje === 0 && (
                    <Button
                        variant="primary"
                        onClick={onClickBuscarPersona}
                        className="mr-1 mb-2, card-button"
                    >
                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                        Buscar Cliente
                    </Button>
                )}


                <Container className="d-Grid align-content-center">
                    <Row className="mt-4">
                        <Col>
                            <h1 className="card-title">Información del Cliente</h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={4}>
                            <label htmlFor="nombre">
                                <b>Nombre:</b>
                            </label>
                            <p id="nombre">{personaSeleccionada.nombre}</p>
                        </Col>
                        <Col md={4}>
                            <label htmlFor="apellido">
                                <b>Primer Apellido:</b>
                            </label>
                            <p id="apellido">{personaSeleccionada.primerApellido}</p>
                        </Col>
                        <Col md={4}>
                            <label htmlFor="apellido">
                                <b>Segundo Apellido:</b>
                            </label>
                            <p id="apellido">{personaSeleccionada.segundoApellido}</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4}>
                            <label htmlFor="apellido">
                                <b>Identificación:</b>
                            </label>
                            <p id="apellido">{personaSeleccionada.identificacion}</p>
                        </Col>
                        <Col md={4}>
                            <label htmlFor="Telefono">
                                <b>Teléfono:</b>
                            </label>
                            <p id="Telefono">{personaSeleccionada.telefono}</p>
                        </Col>
                        <Col md={4}>
                            <label htmlFor="Email">
                                <b>Email:</b>
                            </label>
                            <p id="Email">{personaSeleccionada.correoElectronico}</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <label htmlFor="direccion">
                                <b>Dirección:</b>
                            </label>
                            <p id="direccion">
                                {personaSeleccionada.idPersona != null
                                    ? `${personaSeleccionada.provincia}, ${personaSeleccionada.canton}, ${personaSeleccionada.distrito}, ${personaSeleccionada.direccion}`
                                    : ""}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>

            <Card.Body>

                <Card.Title className="card-title">Información Beneficiario</Card.Title>
                <Container className="d-Grid align-content-center">
                    <Row className="d-flex justify-content-end mt-3">
                        <Col md={3}></Col>
                    </Row>
                    <Row >
                        <Col>
                            <InputsFormsReactHook
                                classDiv={"col-md-12"}
                                label="Nombre Completo:"
                                mensajeValidacion="El nombre es requerido"
                                errors={errors?.NombreBeneficiario?.type}
                                externalValue={
                                    NomBeneficiario === "" ? null : NomBeneficiario
                                }
                                register={{ ...register("NomBeneficiario", { required: false }) }}
                            />
                        </Col>
                        <Col>
                            <InputsFormsReactHook
                                classDiv={"col-md-12"}
                                label="Identificación:"
                                placeholder=""
                                mensajeValidacion="la identificacion es requerida"
                                errors={errors?.IdentificacionBeneficiario?.type}
                                isSummit={isSummitInfo}
                                externalValue={
                                    NumIdentificacionBeneficiario === "" ? null : NumIdentificacionBeneficiario
                                }
                                register={{ ...register("NumIdentificacionBeneficiario", { required: false }) }}
                            />
                        </Col>

                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
}

export default CardInformacionCliente