import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ModalComentarios = ({ show, handleClose, comment, setComment, handleSave }) => {
    const [error, setError] = useState("");

    const comentario = comment ? comment.Comentario : "";

    const handleTextAreaChange = (e) => {
        const updatedComment = {
            ...comment,
            Comentario: e.target.value
        };
        setComment(updatedComment);
    };

    const handleSaveClick = () => {
        if (!comment || !comment.Comentario || comment.Comentario.trim() === "") {
            setError("El comentario no puede estar vacío");
            return;
        }
        setError("");
        handleSave();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Comentario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Comentario</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={comentario}
                        onChange={handleTextAreaChange}
                        isInvalid={!!error}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSaveClick}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalComentarios;