import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "supervisor";
const controladorSucursal = "Sucursal";
const controladorPersona = "persona";

export const AgregarSupervisor = async (data) => {
    const url = `${controlador}/insertarsupervisor`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ActualizarSupervisor = async (data) => {
    const url = `${controlador}/actualizarsupervisor`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const InactivarSupervisor = async (id) => {
    const url = `${controlador}/eliminarsupervisor?idSupervisor=${id}`;
    return await ProcesarDatosApi('DELETE', url);
}

export const ObtenerSupervisores = async () => {
    const url = `${controlador}/ObtenerSupervisores`;
    return await ProcesarDatosApi('GET', url);
}


export const ObtenerSupervisor = async (id) => {
    const url = `${controlador}/ObtenerDetalleSupervisor/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerSucursales = async () => {
    const url = `${controladorSucursal}/ObtenerSucursales`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerPersonas = async () => {
    const url = `${controladorPersona}/ObtenerPersonas`;
    return await ProcesarDatosApi('GET', url);
}  


export const ObtenerClubesSupervisor = async (IdUsuario) => {
    const url = `${controlador}/ObtenerClubesSupervisor/${IdUsuario}`;
    return await ProcesarDatosApi('GET', url);
}   

export const ObtenerGruposSupervisor = async (IdUsuario) => {
    const url = `${controlador}/ObtenerGruposSupervisor/${IdUsuario}`;
    return await ProcesarDatosApi('GET', url);
} 