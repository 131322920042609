import React, { useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { Grid } from '../../components/grid';
import { ObtenerAcompanantesPaqueteViajeId } from '../../servicios/ServicioPaquete'; // Asegúrate de poner la ruta correcta
import Select from 'react-select';
import { ObtenerPaquetesDeViajes } from '../../servicios/ServicioPaquetesDeViajes';
import { useEffect } from "react";
import { AlertDismissible } from "../../components/alerts";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import encabezadoimagen from '../../images/encabezado-instinto-viajero.png';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx';

const Expedientes = () => {
    const [Primerfecha, setPrimerfecha] = useState("");
    const [Segundafecha, setSegundafecha] = useState("");
    const [listaDePaquetes, setListaDePaquetes] = useState([]);
    const [listaDePaquetesViaje, setListaDePaquetesViaje] = useState([]);
    const [listaDePaquetesViajeCombo, setListaDePaquetesViajeCombo] = useState([]);
    const [descripcionPaquete, setDescripcionPaquete] = useState();
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [mostrarClientesAcompanantes, setMostrarClientesAcompanantes] = useState(null);
    const [mostrarListaDePaquetes, setmostrarListaDePaquetes] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});

    useEffect(() => {
        cargaInicial();
    }, []);

    const cargaInicial = async () => {
        let paquetes = (await ObtenerPaquetesDeViajes());
        paquetes = paquetes.filter(p => p.estado === "Activo")
        setListaDePaquetesViaje(paquetes);
        setListaDePaquetesViajeCombo([{ value: null, label: "Seleccione una opción" }, ...paquetes.map(p => ({ label: p.descripcion, value: p.id }))]);
    }


    //Informacion general del paquete
    const encabezadoInfogeneral = [
        { id: 'IdPaqueteDeViaje', name: 'ID P.Viaje', selector: row => row.id, head: "Paquete de Viaje", sortable: true, omit: true },
        {
            id: 'Descripcion', name: 'Destino', selector: row => row.descripcion, head: "Destino", sortable: true, width: '30%'
        },
        { id: 'FechaSalida', name: 'F. Salida', selector: row => new Date(row.fechaSalida).toLocaleDateString('es-ES'), head: "FecSalida", sortable: true },
        { id: 'FechaLlegada', name: 'F. Llegada', selector: row => new Date(row.fechaLLegada).toLocaleDateString('es-ES'), head: "FecLlegada", sortable: true },
        { id: 'CantidadCampos', name: 'Pasajeros', selector: row => row.cantidadCampos, head: "Campos", sortable: true },
        { id: 'CantidadGuias', name: 'Guías', selector: row => row.cantidadGuias, head: "C.Guias", sortable: true },
        { id: 'CantidadCamposDisponibles', name: 'Disponibles', selector: row => row.cantidadCamposDisponibles, head: "Disponibles" },
    ];

    //Tabla 
    const encabezadoClientesyacompañantes = [
        { id: 'TipoPersona', name: 'Tipo de Persona', selector: row => row.rol, head: "Tipo", sortable: true, width: '7%' },
        { id: 'Identificacion', name: 'Identificación', selector: row => row.rol === 'Cliente' ? row.identificacion : row.numIdentificacionAcompanante, head: "Identificación", sortable: true, width: '6%' },
        { id: 'TipoIdentificacion', name: 'Tipo Identificación', selector: row => row.rol === 'Cliente' ? row.tipoIdentificacion : row.tipoIdentificacionAcompanante, head: "Tipo de identificación", sortable: true, width: '6%' },
        {
            id: 'NombreCompleto',
            name: 'Nombre completo',
            selector: row => {
                if (row.rol === 'Cliente') {
                    const nombre = row.nombre.trim();
                    const primerApellido = row.primerApellido.trim();
                    const segundoApellido = row.segundoApellido.trim();

                    
                    let nombreCompleto = nombre;
                    if (!nombreCompleto.includes(primerApellido)) {
                        nombreCompleto += ` ${primerApellido}`;
                    }
                    if (!nombreCompleto.includes(segundoApellido)) {
                        nombreCompleto += ` ${segundoApellido}`;
                    }

                    return nombreCompleto.trim();
                } else {
                    return row.nomAcompanante;
                }
            },
            head: "Nombre completo",
            sortable: true,
            width: '18%'
        },
        { id: 'Telefono', name: 'Tel. cliente', selector: row => row.telefono, head: "Telefono", sortable: true, width: "6%" },
        { id: 'Correo', name: 'Correo', selector: row => row.correo, head: "Correo", sortable: true, width: "8%" },
        { id: 'Profesion', name: 'Profesión', selector: row => row.profesion, head: "Profesión", sortable: true, width: "6%" },
        { id: 'Nacionalidad', name: 'Nacionalidad', selector: row => row.nacionalidad, head: "Nacionalidad", sortable: true, width: "7%" },
        { id: 'Provincia', name: 'Provincia', selector: row => row.provincia, head: "Provincia", sortable: true, width: "7%" },
        { id: 'Canton', name: 'Cantón', selector: row => row.canton, head: "Cantón", sortable: true, width: "7%" },
        { id: 'Distrito', name: 'Distrito', selector: row => row.distrito, head: "Distrito", sortable: true, width: "7%" },
        { id: 'DireccionHabitacion', name: 'Dirección habitación', selector: row => row.direccionHabitacion, head: "Dirección Habitación", sortable: true, width: "9%" },
        { id: 'FechaNacimiento', name: 'Fecha Nacimiento', selector: row => row.fechaNacimiento !== '' ? new Date(row.fechaNacimiento).toLocaleDateString('es-ES') : '', head: "Fecha de Nacimiento", sortable: true, width: "7%" },
    ];

    const OnChangePrimerfecha = (event) => {
        setPrimerfecha(event.target.value);
    };

    const OnchangeSegundafecha = (event) => {
        setSegundafecha(event.target.value);
    };

    const OnchangeDescripcionPaquete = (event) => {
        setDescripcionPaquete(event);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setFilaSeleccionada(null);
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    const onClickSeleccionarFila = async (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        if (fila != null && filaValida) {
            
            const acompanantes = await ObtenerAcompanantesPaqueteViajeId(fila.id);
            let acompanantesOrdenados = []

            if (acompanantes && acompanantes.length > 0) {
                let i = 0;
                while (i < acompanantes.length) {
                    const registro = acompanantes[i];
                    const clienteAgregado = acompanantesOrdenados.some(a => a.idPersona === registro.idPersona);
                    if (!clienteAgregado) {
                        acompanantesOrdenados.push({ ...registro, rol: "Cliente", nombre: registro.nombre + " " + registro.primerApellido + " " + registro.segundoApellido, numIdentificacionAcompanante: "", nomAcompanante: "" })
                        if (registro.idAcompanantePaquete) {
                            acompanantesOrdenados.push({ ...registro, rol: "Acompañante", identificacion: "", primerApellido: "", segundoApellido: "", telefono: "", nombre: "", tipoIdentificacion: "", profesion: "", nacionalidad: "", correo: "", provincia: "", canton: "", distrito: "", direccionHabitacion: "", fechaNacimiento: "" })
                        }
                    } else {
                        acompanantesOrdenados.push({ ...registro, rol: "Acompañante", identificacion: "", primerApellido: "", segundoApellido: "", telefono: "", nombre: "", tipoIdentificacion: "", profesion: "", nacionalidad: "", correo: "", provincia: "", canton: "", distrito: "", direccionHabitacion: "", fechaNacimiento: "" })
                    }
                    i++;
                }
            }

            setMostrarClientesAcompanantes(acompanantesOrdenados);
            setShowModal(true);
        }
        
        if (filaValida) {
            setFilaSeleccionada(fila);
        } else {
            setFilaSeleccionada(null);
        }
    }

    const convertirImagenABase64 = (ruta) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', ruta);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };


    const generarArchivoExcel = () => {
        const listaFormateadaXLSX = mostrarClientesAcompanantes.map(
            item => {
                let nombreCompleto = item.nombre.trim();

                if (!nombreCompleto.includes(item.primerApellido.trim())) {
                    nombreCompleto += ` ${item.primerApellido.trim()}`;
                }
                if (!nombreCompleto.includes(item.segundoApellido.trim())) {
                    nombreCompleto += ` ${item.segundoApellido.trim()}`;
                }

                return {
                    "Tipo de Persona": item.rol,
                    "Identificación": item.rol === 'Cliente' ? item.identificacion : item.numIdentificacionAcompanante,
                    "Tipo de identificación": item.rol === 'Cliente' ? item.tipoIdentificacion : item.tipoIdentificacionAcompanante,
                    "Nombre completo": item.rol === 'Cliente' ? nombreCompleto.trim() : item.nomAcompanante,
                    "Teléfono": item.telefono,
                    "Correo": item.correo,
                    "Profesión": item.profesion,
                    "Nacionalidad": item.nacionalidad,
                    "Provincia": item.provincia,
                    "Cantón": item.canton,
                    "Distrito": item.distrito,
                    "Dirección habitación": item.direccionHabitacion,
                    "Fecha de nacimiento": item.fechaNacimiento !== "" ? new Date(item.fechaNacimiento).toLocaleDateString('es-ES') : "",
                };
            }
        );


        const hoja = XLSX.utils.aoa_to_sheet([
            ["Paquete: " + filaSeleccionada.descripcion],
            ['Fecha de salida: ' + new Date(filaSeleccionada.fechaSalida).toLocaleDateString('es-ES')],
            ['Fecha de llegada: ' + new Date(filaSeleccionada.fechaLLegada).toLocaleDateString('es-ES')],
            []
        ]);

        XLSX.utils.sheet_add_json(hoja, listaFormateadaXLSX, { origin: 'A5' });



        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, hoja, "paqueteDeViaje");
        XLSX.writeFile(wb, `${descripcionPaquete.label}_${new Date().toLocaleDateString('es-ES')}.xlsx`);
    };


    const generatePDF = async () => {
        const doc = new jsPDF();

        const imgBase64 = await convertirImagenABase64(encabezadoimagen);

        const imgWidth = doc.internal.pageSize.width - 20;
        const imgHeight = 30;
        const imgX = 10;
        const imgY = 10;

        doc.setFontSize(18);
        doc.setTextColor(216, 92, 44);
        doc.text('Reporte Paquetes de Viaje', 75, 45);

        doc.addImage(imgBase64, 'PNG', imgX, imgY, imgWidth, imgHeight);


        doc.setTextColor(0);
        doc.setFontSize(14);
        doc.text("Destino: " + filaSeleccionada.descripcion, 14, 60);
        doc.text("Fecha de salida: " + new Date(filaSeleccionada.fechaSalida).toLocaleDateString('es-ES'), 14, 70);
        doc.text("Fecha de llegada: " + new Date(filaSeleccionada.fechaLLegada).toLocaleDateString('es-ES'), 14, 80);

        doc.setFont('helvetica', 'normal');
        doc.setDrawColor(0);
        doc.setTextColor(0);

        autoTable(doc, {
            startY: 85,
            startX: 14,

            columns: [
                { key: "rol", header: "Tipo de Persona" },
                { key: "identificacion", header: "Identificación" },
                { key: "tipoIdentificacion", header: "Tipo Identificación" },
                { key: "nombreCompleto", header: "Nombre Completo" },
                { key: "telefono", header: "Tel. Cliente" },
                /*
                { key: "profesion", header: "Profesión" },
                { key: "provincia", header: "Provincia" },
                { key: "canton", header: "Cantón" },
                { key: "distrito", header: "Distrito" },
                { key: "direccionHabitacion", header: "Dirección Habitación" },
                { key: "fechaNacimiento", header: "Fecha de Nacimiento" },
                */

            ],
            headStyles: { fillColor: "#d85c2c", textColor: "#000000" },
            bodyStyles: { textColor: "#000000" },
            body: mostrarClientesAcompanantes.map(row => {
                let nombreCompleto = row.nombre.trim();

                if (!nombreCompleto.includes(row.primerApellido.trim())) {
                    nombreCompleto += ` ${row.primerApellido.trim()}`;
                }
                if (!nombreCompleto.includes(row.segundoApellido.trim())) {
                    nombreCompleto += ` ${row.segundoApellido.trim()}`;
                }

                return {
                    rol: row.rol,
                    identificacion: row.rol === 'Cliente' ? row.identificacion : row.numIdentificacionAcompanante,
                    tipoIdentificacion: row.rol === 'Cliente' ? row.tipoIdentificacion : row.tipoIdentificacionAcompanante,
                    nombreCompleto: row.rol === 'Cliente' ? nombreCompleto.trim() : row.nomAcompanante,
                    telefono: row.telefono || '',
                };
            }),
            styles: {
                lineColor: "#000000",
                lineWidth: 0.1,
            },
            didParseCell: function (data) {
                if (data.section === 'body') {
                    const rowIndex = data.row.index;
                    data.cell.styles.fillColor = rowIndex % 2 === 0 ? [200, 200, 200] : [255, 255, 255];
                }
            },
        });

        doc.save(`${descripcionPaquete.label}_${new Date().toLocaleDateString('es-ES')}.pdf`);
    };



    const buscarDetallesPaquetes = async () => {
        try {
            let validacion = 1;
            let paquetes = [];
            //debugger;
            if (Primerfecha !== "" && Segundafecha !== "" && descripcionPaquete && descripcionPaquete.value) {
                paquetes = listaDePaquetesViaje.filter(p => new Date(p.fechaSalida) >= new Date(Primerfecha) && new Date(p.fechaSalida) <= new Date(Segundafecha) && p.descripcion === descripcionPaquete.label)

            } else if (Primerfecha !== "" && Segundafecha !== "") {

                paquetes = listaDePaquetesViaje.filter(p => new Date(p.fechaSalida) >= new Date(Primerfecha) && new Date(p.fechaSalida) <= new Date(Segundafecha))
            } else if (descripcionPaquete && descripcionPaquete.value) {
                paquetes = listaDePaquetesViaje.filter(p => p.descripcion === descripcionPaquete.label)
            } else {
                validacion = 0;
                setMensajeRespuesta({ indicador: 3, mensaje: "Debe filtrar por rangos y/o descripción" });
                setShowAlert(true);
            }
            if (validacion === 1) {
                setmostrarListaDePaquetes(true);
                setListaDePaquetes(Object.values(paquetes));
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    };

    const handleBuscarClick = () => {
        buscarDetallesPaquetes();
    };


    return (
        <>
            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <div className="container">
                <Form>

                    <h1 className='card-title' style={{ color: 'orangered', textAlign: 'center', marginBottom: '40px' }}>
                        Reporte de Paquetes de Viaje
                    </h1>
                    <h2 className='card-title' style={{ color: 'orangered', fontSize: '1.2rem', marginBottom: '20px', textAlign: 'left' }}>
                        Rango de Fechas de Salidas
                    </h2>
                    <Form.Label>Seleccione rango de fechas y/o descripción</Form.Label>
                    <Row>
                        <Col>
                            <Form.Group controlId="Primerfecha">
                                <Form.Label style={{ fontWeight: 'bold' }}>Fecha desde</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={Primerfecha}
                                    onChange={OnChangePrimerfecha}
                                    style={{ marginBottom: '40px' }}
                                />
                            </Form.Group>

                        </Col>
                        <Col>
                            <Form.Group controlId="Segundafecha">
                                <Form.Label style={{ fontWeight: 'bold' }}>Fecha hasta</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={Segundafecha}
                                    onChange={OnchangeSegundafecha}
                                    style={{ marginBottom: '40px' }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Descripción</Form.Label>
                                    <Select value={descripcionPaquete} onChange={OnchangeDescripcionPaquete} options={listaDePaquetesViajeCombo} />
                                    <Button variant="primary" onClick={handleBuscarClick} style={{ marginTop: '30px', marginBottom: '40px' }}>
                                        Buscar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Col>
                    </Row>
                    {mostrarListaDePaquetes && (
                        <>
                            {listaDePaquetes && (
                                <>
                                    <h2 className='card-title' style={{ color: 'orangered', fontSize: '1.2rem', marginBottom: '20px', textAlign: 'left' }}>
                                        Grupos de viaje
                                    </h2>
                                    <Grid
                                        gridHeading={encabezadoInfogeneral}
                                        gridData={listaDePaquetes}
                                        setFilaSeleccionada={onClickSeleccionarFila}
                                        selectableRows={false}
                                        idBuscar={'id'}
                                        rowModal={true}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Form>
            </div>
            <Modal show={showModal} onHide={handleModalClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Clientes y acompañantes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Grid
                            gridHeading={encabezadoClientesyacompañantes}
                            gridData={mostrarClientesAcompanantes || []}
                            idBuscar={'idPaquete'}
                            selectableRows={false}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose} style={{
                        textAlign: 'center',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        borderRadius: '20px',
                        fontSize: '15px',
                        zIndex: 1000,
                        padding: '10px 20px',
                    }}>
                        Cancelar
                    </Button>
                    <Button
                        variant="dark"
                        style={{
                            textAlign: 'center',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            border: '2px solid #d85c2c',
                            color: '#d85c2c',
                            backgroundColor: 'white',
                            borderRadius: '20px',
                            fontSize: '15px',
                            zIndex: 1000,
                            bottom: '-37px',
                            padding: '10px 20px',
                        }}
                        onClick={generatePDF}
                    >
                        <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                        Imprimir
                    </Button>
                    <Button
                        variant="dark"
                        style={{
                            textAlign: 'center',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            border: '2px solid #d85c2c',
                            color: '#d85c2c',
                            backgroundColor: 'white',
                            borderRadius: '20px',
                            fontSize: '15px',
                            zIndex: 1000,
                            bottom: '-37px',
                            padding: '10px 20px',
                        }}
                        onClick={generarArchivoExcel}
                    >
                        <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                        Descargar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Expedientes;

