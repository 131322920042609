import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Row, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form'
import { InputsFormsReactHook } from '../../components/Forms/InputsFormsReactHook';
import DocumentosPaqueteDeViajeComponet from '../Documentos/DocumentosPaqueteDeViaje';
import { faPlus, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InsertarAbonoPaquete, ObtenerAbonos, EliminarAbonoPaqueteDeViaje } from '../../servicios/ServicioCobro';
import { EliminarDocumento } from '../../servicios/ServicioDocumentos';

import { ModalAgregarComprobante } from '../../components/ModalAgregarComprobante';
import { ConfirmModal } from "../../components/ConfirmModal";
import { ModalVisorDocumentos } from '../../components/fileViewer';
import { AlertDismissible } from '../../components/alerts';
import { Grid } from '../../components/grid';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const FormularioAbono = ({ idPaquete, data, show, PermisoEliminar, PermisoDocumentoP, setActualizarSaldo }) => {

    const [isSummit, setIsSummit] = useState(false);
    const [showFormulario] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalDoc, setShowModalDoc] = useState(false);
    const [EliminarDoc, setEliminarDoc] = useState(false);
    const [documentoIndicador, setDocumentoIndicador] = useState(null);
    const [documentoId, setDocumentoId] = useState(null);
    const [NumeroAbono, setNumeroAbono] = useState(null);

    const [EliminarAbonoConfirm, setEliminarAbonoConfirm] = useState(false);



    const [IdPaqueteDeViaje, setIdPaqueteDeViaje] = useState(idPaquete); 
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});

    const fileInputRef = useRef(null);

    const [showAlert, setShowAlert] = useState(false);
    const [listaDeAbonos, setListaDeAbonos] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [tipoMoneda, setTipoMoneda] = useState('dolares');
    const isColones = tipoMoneda === 'colones';
    const [errorMessage, setErrorMessage] = useState("");


    const mensajeEliminarError = {
        indicador: '1', mensaje: 'No tienes permiso para Eliminar'
    };

    const mensajeAgregarDOCError = {
        indicador: '1', mensaje: 'No tienes permiso para Agregar Documentos'
    };


    const {register,handleSubmit,setValue,formState: { errors },trigger} = useForm({
        defaultValues: {
            Idpaquete: idPaquete,
            MonColones: data?.MonColones,
            TipoCambio: data?.TipoCambio,
            FecPago: data?.FecPago? data.FecPago.replace('T00:00:00', '') : '',
          
        }
    });


    //Carga de datos
    const encabezado = [
        { id: 'idPaquete', name: 'id', selector: row => row.idpaquete, head: "id", omit: true },
        { id: 'numAbono', name: 'numAbono', selector: row => row.numAbono, head: "numAbono", omit: true },
        { id: 'IdDocumento', name: 'IdDocumento', selector: row => row.idDocumento !== null ? row.idDocumento : null, head: "IdDocumento", omit: true },
        { id: 'fecPago', name: 'Fecha Pago', selector: row => new Date(row.fecPago).toLocaleDateString('es-ES'), head: "Fecha de Pago", sortable: true },
        { id: 'monColones', name: 'Colones', selector: row => row.monColones, cell: (row) => `₡ ${row.monColones}`, head: "M.Colones", sortable: true, width: '15%' },
        { id: 'monPagar', name: 'Dolares', selector: row => row.monPagar, cell: (row) => `$ ${row.monPagar}`, head: "M.Dolares", sortable: true, width: '15%' },
        {
            id: 'estado',
            name: 'Estado',
            selector: row => row.idEstado, 
            omit: true, 
        },
        {
            id: 'estadoDescripcion',
            name: 'Estado',
            selector: row => row.descEstCobros,
            head: 'estadoDescripcion',
            sortable: true,
        },
        {
            id: 'verDoc',
            name: 'Comprobante',
            head: 'verDoc',
            cell: (row) => {
                const icon = row.idDocumento !== null ? faEye : faPlus;
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '75%',
                    }}>
                        <Button
                            variant="link"
                            size="sm"
                            onClick={() => {
                                if (icon === faPlus) {
                                    handleAgregarDocumento(row);
                                } else {
                                    handleAbrirDocumento(row);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={icon} />
                        </Button>
                        {icon === faEye && (
                            <Button
                                variant="link"
                                size="sm"
                                style={{ color: 'red' }}
                                onClick={() => EliminarDOC(row)}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        )}
                    </div>
                );
            },
        },
        {
            id: 'Eliminar',
            name: 'Eliminar',
            head: 'Eliminar',
            width: '13%',
            cell: (row) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '75%',
                    }}>
                        <Button
                            variant="link"
                            size="sm"
                            style={{ color: 'red' }}
                            onClick={() => EliminarAbonoPaquete(row)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      
                    </div>
                );
            },
        },

    ]
    useEffect(() => {
        if (idPaquete) {
            obtenerAbonos();
        }
    }, [idPaquete, show, showModalDoc]);

    const obtenerAbonos = async () => {
        try {
            setPendiente(true);
            const abonos = await ObtenerAbonos(idPaquete);
            const abonosArray = Object.values(abonos);
            setListaDeAbonos(abonosArray);
            setPendiente(false);
        } catch (error) {
            console.log("Error al obtener los abonos:", error);
            // Handle the error according to your needs
        }
    };

    const onClickAceptar = async (formData) => {
        const nuevoValorIdPaquete = formData.Idpaquete;


        setIdPaqueteDeViaje(idPaquete);
        setMensajeFormulario('');
        let respuesta = {};
        if (tipoMoneda === 'dolares') {
            // Asigna 0 a los campos que no están contenidos cuando la moneda es dólares
            formData.MonColones = 0;
            formData.TipoCambio = 0;
        }
        else{
            formData.MonPagar = 0;
        }
            respuesta = await InsertarAbonoPaquete(formData);




        if (respuesta.indicador == 0) {

                const mensajeCompleto = respuesta.mensaje;
                const mensajeEnPartes = mensajeCompleto.split(" ");
                const numAbono = parseInt(mensajeEnPartes.pop(), 10);
                const mensajeSinNumero = mensajeEnPartes.join(" ");

                setMensajeRespuesta({
                    indicador: respuesta.indicador,
                    mensaje: mensajeSinNumero
                });
            //obtenerAbonos();

            // Si el abono fue exitoso y hay un archivo, insertar el documento
            if (formData.file && formData.file[0]) {
                const newFormData = new FormData();


                newFormData.append('file', formData.file[0]);
                newFormData.append('idPaquete', JSON.stringify(idPaquete));
                newFormData.append('esDeAbono', true); //Insertar el documento indicando que es de un Abono
                newFormData.append('numAbono', numAbono);
                const response = await fetch(`/Documentos/InsertarDocumentosGrupos/${idPaquete}`, {
                    method: 'POST',
                    body: newFormData,
                });
            }
            await Promise.all([obtenerAbonos()]); 
                setValue('MonColones', ''); // Reinicia el campo MonColones
                setValue('TipoCambio', ''); // Reinicia el campo TipoCambio
                setValue('FecPago', ''); // Reinicia el campo FecPago
                setValue('MonPagar', ''); // Reinicia el campo MonPagar
                fileInputRef.current.value = '';
                setTipoMoneda('dolares'); //Devuelve la vista a dolares cuando guarda
            } else {
                setMensajeFormulario(respuesta.mensaje);
            }
        
        setShowAlert(true);
    };

    const handleAbrirDocumento = async (fila) => {
        setDocumentoId(fila.idDocumento ?? null);
        setNumeroAbono(fila.numAbono);
        setDocumentoIndicador(2); // 4 = Obtiene un documento de paquete de viajes Pero para la parte de Abonos
        setShowModal(true);
    };


    const EliminarDOC = async (fila) => {

        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        } else {
            setDocumentoId(fila.idDocumento ?? null);
            //abre el modal
            setEliminarDoc(true);
            setNumeroAbono(fila.numAbono);
        }

    };


    const EliminarAbonoPaquete = async (fila) => {

        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {

            //abre el modal
            setEliminarAbonoConfirm(true);
            setNumeroAbono(fila.numAbono);
        }

    };

    const handleClose = () => {
        setShowModalDoc(false);

        setShowModal(false);

        setDocumentoId(null);
        //obtenerAbonos();
    };

    const handleAgregarDocumento = (row) => {

        if (!PermisoDocumentoP) {
            setMensajeRespuesta(mensajeAgregarDOCError);
            setShowAlert(true);
        }
        else {
            setShowModalDoc(true);
            setNumeroAbono(row.numAbono)
        }
    };



    function cobrosPendientesDeAprobacion() {
        return listaDeAbonos.some(abono => abono.estadoCobro == false);
    }
   
    const handleManualValidation = async () => {
        const isValid = await trigger();
        if (!isValid) {
            setIsSummit(true)
        }
    };
   

    const onClickTipoCambio = (isChecked) => {
        const value = isChecked ? 'dolares' : 'colones'; 
        setTipoMoneda(value);
    };

    //Eliminar Abonos
    const onClickEliminar = async () => {
        try {
            const respuesta = await EliminarAbonoPaqueteDeViaje(idPaquete, NumeroAbono);

            setEliminarAbonoConfirm(false);
            if (respuesta.indicador === 0) {
                await Promise.all([obtenerAbonos(), setShowAlert(true)]);
                setMensajeRespuesta(respuesta);
            } else {
                setMensajeFormulario(respuesta.mensaje);
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error al eliminar abono:", error);
        }
        finally {
            setActualizarSaldo(prevState => !prevState);
        }
    };

    const onConfirmEliminarDocumento = async () => {

        //indicador 1 -club  2 -paquete
        const respuesta = await EliminarDocumento(2, documentoId)
        setMensajeRespuesta(respuesta);
        setShowAlert(true);
        setEliminarDoc(false);
        obtenerAbonos();
    }



    const validarNumeroNoNegativo = (value, setErrorMessage) => {
        const isValid = parseFloat(value) >= 0;

        if (!isValid) {
            setErrorMessage("No se permite ingresar números negativos");

            // Limpiar el mensaje de error después de tres segundos (3000 milisegundos)
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        } else {
            setErrorMessage("");
        }

        return isValid;
    };
    return (
        <>
            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}

            <ConfirmModal
                isOpen={EliminarAbonoConfirm}
                toggle={() => setEliminarAbonoConfirm(false)}
                message={`¿Desea eliminar este Abono? `}
                onConfirm={onClickEliminar}
            />

            <ConfirmModal
                isOpen={EliminarDoc}
                toggle={() => setEliminarDoc(false)}
                message={`¿Desea eliminar este Documento? `}
                onConfirm={onConfirmEliminarDocumento}
            />


            <ModalAgregarComprobante
                show={showModalDoc}
                handleClose={handleClose}
                handleAgregarDocumento={handleAgregarDocumento}
                numAbono={NumeroAbono}
                id_Viaje={idPaquete}
                obtenerAbonos={obtenerAbonos}
                indicador={2} // 2 = Paquete
                setMensajeRespuesta={setMensajeRespuesta}
                setShowAlert={setShowAlert}
            />

            <ModalVisorDocumentos
                show={showModal}
                handleClose={() => setShowModal(false)}
                titulo="Información del Pago"
                tamano="lg"
                idDocumento={documentoId}
                indicador={documentoIndicador}
            />


            {showFormulario && (
                <Form onSubmit={handleSubmit(onClickAceptar)}>
                    <Card.Title className="card-title mb-4">Información de Abonos</Card.Title>
                    <Row>
                        <div className="col-md-4 mt-3">
                        <label className="font-weight-bold" htmlFor="tipoMoneda">
                            Tipo de Moneda:
                        </label>
                       
                            <BootstrapSwitchButton
                                id="tipoMoneda"
                                onlabel="Dolares"
                                offlabel="Colones"
                                checked={tipoMoneda === 'dolares'}
                                onChange={onClickTipoCambio}
                                onstyle="outline-primary"
                                offstyle="outline-primary"
                                size="sm"
                                width={232}
                                style={{ height: '80px', maxHeight: '80px' }}
                                
                            />
                        
                    </div>

                   

                    {tipoMoneda === 'colones' && (
                            <>
                                
                                
                            
                                <InputsFormsReactHook
                                    id="txt-MonColones"
                                    label="Monto Colones:"
                                    type="number"
                                    placeholder="Ingrese el monto Colones"
                                    min={0.0}
                                    max={99999999.99}
                                    mensajeValidacion="Monto Colones requerido"
                                    errors={errors?.MonColones?.type}
                                    isSummit={isSummit}
                                    register={{ ...register('MonColones', { required: false, validate: (value) => validarNumeroNoNegativo(value, setErrorMessage), }) }}
                                    classDiv="col-md-4 mt-3"
                                />
                                     

                               
                                <InputsFormsReactHook
                                    id="txt-TipoCambio"
                                    label="Tipo de Cambio:"
                                    type="number"
                                    placeholder="Ingrese el Tipo de Cambio"
                                    min={0.0}
                                    max={99999999.99}
                                    mensajeValidacion="Tipo Cambio requerido"
                                    errors={errors?.TipoCambio?.type}
                                    isSummit={isSummit}
                                    defaultValue={0}
                                    register={{ ...register('TipoCambio', { required: false, validate: (value) => validarNumeroNoNegativo(value, setErrorMessage), }) }}
                                    classDiv="col-md-4 mt-3"
                                />
                            </>

                            )}

                        {tipoMoneda === 'dolares' &&(
                            <>
                       
                                <InputsFormsReactHook
                                    id="txt-MonPagar"
                                    label="Monto a pagar:"
                                    type="number"
                                    placeholder="Ingrese el monto a Pagar"
                                    min={0.00}
                                    max={99999999.99}
                                    mensajeValidacion="Monto a Pagar requerido"
                                    errors={errors?.MonPagar?.type}
                                    isSummit={isSummit}
                                    readOnly={isColones}
                                    defaultValue={isColones ? 0 : undefined}
                                    register={{
                                        ...register('MonPagar', {
                                            required: tipoMoneda === 'dolares',
                                            validate: {
                                                noNegativo: (value) => {
                                                    if (value < 0) {
                                                        setErrorMessage('No se permite ingresar números negativos');
                                                        setTimeout(() => {
                                                            setErrorMessage("");
                                                        }, 3000);
                                                        return false;
                                                    }
                                                    return true;
                                                },
                                            },
                                        }),
                                    }}
                                    classDiv="col-md-4 mt-3"
                                />
                            </>
                        )}


                        {tipoMoneda !== "colones" && (
                            <>
                                <InputsFormsReactHook
                                        id="txt-FecPago"
                                        label="Fecha de Pago:"
                                        type="date"
                                        placeholder="Ingrese la fecha de pago"
                                        mensajeValidacion="Fecha Pago requerido"
                                        errors={errors?.FecPago?.type}
                                        isSummit={isSummit}
                                        register={{ ...register('FecPago', { required: true }) }}
                                        classDiv="col-md-4 mt-3"
                                />      
                            </>
                        )}

                        {tipoMoneda === "colones" && (
                            <>

                                <div style={{
                                    display: "flex", alignItems: "center", justifyContent: "space-around"
                                }}>

                                    <InputsFormsReactHook
                                        id="txt-FecPago"
                                        label="Fecha de Pago:"
                                        type="date"
                                        placeholder="Ingrese la fecha de pago"
                                        mensajeValidacion="Fecha Pago requerido"
                                        errors={errors?.FecPago?.type}
                                        isSummit={isSummit}
                                        register={{ ...register('FecPago', { required: true }) }}
                                        classDiv="col-md-6 mt-3"
                                    />


                                    <div style={{ width: '100%' }}>
                                        <label htmlFor="fileInput" className="form-label" style={{ fontWeight: 'bold' }}>
                                            Comprobante:
                                        </label>
                                        <Form.Control
                                            id="fileInput"
                                            type="file"
                                            style={{
                                                height: 'calc(1.5em + .75rem + 2px)',
                                                width: '168%'
                                            }}
                                            {...register('file')}
                                            ref={e => {
                                                register('file').ref(e);
                                                fileInputRef.current = e;
                                            }}
                                        />
                                    </div>

                                </div>
                            </>
                        )}
                       
                    </Row>

                        <Row style={{
                            display: "flex", alignItems: "center", justifyContent: "center",
                            padding: "0 130px"
                        }} >

                            {tipoMoneda === "dolares" && (
                                <>
                                    <div style={{ width: '100%' }}>
                                        <label htmlFor="fileInput" className="form-label" style={{ fontWeight: 'bold' }}>
                                            Comprobante:
                                        </label>
                                        <Form.Control
                                            type="file"
                                            {...register('file')}
                                            ref={e => {
                                                register('file').ref(e);
                                                fileInputRef.current = e;
                                            }}
                                        />
                                    </div>
                                    <br></br>
                                </>
                            )}

                    </Row>
                        
                    {errorMessage && (
                        <label className="text-danger">{errorMessage}</label>
                    )}
                    <div className="d-flex flex-column">
                        <Row className="justify-content-end">
                            <div className="mt-auto">
                                <Button
                                    className='card-button ml-3 mr-3'
                                    variant="primary"
                                    type="submit"
                                    onClick={handleManualValidation}
                                    size="sm"
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Agregar
                                </Button>
                            </div>
                        </Row>
                    </div>
                     
                        <br />
                               
                   
                
                </Form>
            )}


            <div className="container-fluid">
                <span>Lista de Abonos Registrados</span>
                <Grid
                    gridHeading={encabezado}
                    gridData={listaDeAbonos}
                    idBuscar={'idPaquete'}
                    selectableRows={false}
                    pending={pendiente}
                />
                <br />
                <br />
            </div>
        </>
    )
}

export default FormularioAbono;