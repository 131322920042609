import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "login";

export const AutenticarUsuario = async (data) => {
    const url = `${controlador}/iniciarsesionusuario?usuario=${data.usuario}&contrasena=${data.contrasena}`;
    return await ProcesarDatosApi('GET', url);
}

export const ValidarCorreoUsuario = async (data) => {
    const url = `${controlador}/ValidarCorreoUsuario?correo=${data}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerUsuarioPorRolYCorreo = async (data) => {
    const url = `${controlador}/ObtenerUsuarioPorRolYCorreo?idrol=${data.idRol}&correo=${data.correo}`;
    return await ProcesarDatosApi('GET', url);
}

export const ActualizarContrasenhaTemporal = async (data) => {
    const url = `${controlador}/actualizarcontrasenhatemporal`;
    return await ProcesarDatosApi('PUT', url, data);
}