import React, { useEffect, useState } from 'react';
import { Grid } from '../../components/grid';
import { AlertDismissible } from '../../components/alerts';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ObtenerComisiones, ObtenerComision } from '../../servicios/ServicioComisiones';
import { ObtenerSupervisores, ObtenerSupervisor } from '../../servicios/ServicioSupervisor';
import { ObtenerVendedores, ObtenerVendedor } from '../../servicios/ServicioVendedor';
import jsPDF from 'jspdf';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import encabezadoimagen from '../../images/encabezado-instinto-viajero.png';
import Select from 'react-select';
import { SpinnerPersonalizado } from "../../components/spinnerPersonalizado";
import { notoSansBase64 } from '../../fonts/notoSansBase64'; 

const Comisiones = () => {
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [key, setKey] = useState('vendedores');
    const [listaDeSupervisores, setListaDeSupervisores] = useState([]);
    const [listaDeVendedores, setListaDeVendedores] = useState([]);
    const [listaDeComisiones, setListaDeComisiones] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [comisionesUsuarioSeleccionado, setComisionesUsuarioSeleccionado] = useState([]);
    const [nombreUsuario, setNombreUsuario] = useState('');
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [vendedorSeleccionado, setVendedorSeleccionado] = useState();
    const [supervisorSeleccionado, setSupervisorSeleccionado] = useState();
    const [listaDeVendedoresCombo, setListaDeVendedoresCombo] = useState([]);
    const [listaDeSupervisoresCombo, setListaDeSupervisoresCombo] = useState([]);
    const [Primerfecha, setPrimerfecha] = useState("");
    const [Segundafecha, setSegundafecha] = useState("");
    const [Primerfecha2, setPrimerfecha2] = useState("");
    const [Segundafecha2, setSegundafecha2] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const filterColumns = ['nombrePersona', 'numContrato', 'fecha'];

    const encabezadoComisiones = [
        { id: 'idComisionUsuariosClub', name: 'idComisionUsuariosClub', selector: row => row.idComisionUsuariosClub, head: "id", omit: true },
        { id: 'idRol', name: 'idRol', selector: row => row.idRol, head: "idRol", omit: true },
        { id: 'fecha', name: 'Fecha', selector: row => new Date(row.fecha).toLocaleDateString('es-ES'), head: "Fecha", sortable: true },
        { id: 'montoComision', name: 'Monto Comisión', selector: row => row.montoComision, head: "Monto Comisión", sortable: true },
        // { id: 'idClub', name: 'Id Club', selector: row => row.idClub, head: "Id Club", sortable: true },
        { id: 'nombre', name: 'Nombre.C', selector: row => row.nombrePersona, head: "nombre", sortable: true },
        //{ id: 'MonPagar', name: 'Monto', selector: row => row.montoPagar, head: "MonPagar", sortable: true },
        { id: 'numCuota', name: 'Número Cuota', selector: row => row.numCuota, head: "Número Cuota", sortable: true },
        { id: 'NumContrato', name: 'Número Contrato', selector: row => row.numContrato, head: "NumContrato", sortable: true },
        //{ id: 'Usuario', name: 'Usuario', selector: row => row.nomComisionVendedor || row.nomComisionSupervisor, head: "Usuario", sortable: true }
    ];

    useEffect(() => {
        ObtenerListadoDeComisiones();
        ObtenerListadoDeSupervisores();
        ObtenerListadoDeVendedores();
    }, []);

    useEffect(() => {
        if (listaDeComisiones.length > 0) {
            setShowDownloadButton(true);
        } else {
            setShowDownloadButton(false);
        }
    }, [listaDeComisiones]);

    const ObtenerListadoDeComisiones = async (idUsuario) => {
        setPendiente(true);
        //setListaDeComisiones(await ObtenerComisionesUsuario(idUsuario));
        setPendiente(false);
    };
    const ObtenerListadoDeSupervisores = async () => {
        setPendiente(true);
        const supervisores = await ObtenerSupervisores();
        setListaDeSupervisores(supervisores);
        setListaDeSupervisoresCombo([{ value: null, label: "Seleccione una opción" }, ...supervisores.map(p => ({ label: p.nombre + " " + p.primerApellido + " " + p.segundoApellido, value: p.idSupervisor }))]);
        setPendiente(false);
    };

    const ObtenerListadoDeVendedores = async () => {
        setPendiente(true);
        const vendedores = await ObtenerVendedores();
        setListaDeVendedores(vendedores);
        setListaDeVendedoresCombo([{ value: null, label: "Seleccione una opción" }, ...vendedores.map(p => ({ label: p.nombre + " " + p.primerApellido + " " + p.segundoApellido, value: p.idVendedor }))]);
        setPendiente(false);
    };

    const ObtenerComisionesUsuario = async (idUsuario, rol) => {

        try {
            setIsLoading(true);

            let comisionesUsuario = await ObtenerComisiones(idUsuario, Primerfecha, Segundafecha, rol);

            if (rol === 2) {
                comisionesUsuario = comisionesUsuario.map(c => ({ ...c, nomComisionSupe3rvisor: null }))
            } else if (rol === 3) {
                comisionesUsuario = comisionesUsuario.map(c => ({ ...c, nomComisionVendedor: null }))
            }
            setIsLoading(false);
            return comisionesUsuario; // Retornar las comisiones
        } catch (error) {
            console.log("Error al obtener las comisiones del usuario: ", error);
            setIsLoading(false);
            return [];
        }
    };

    const OnchangeVendedorSeleccionado = async (event) => {
        setVendedorSeleccionado(event);
    };

    const OnchangeSupervisorSeleccionado = (event) => {
        setSupervisorSeleccionado(event);
    };

    const OnChangePrimerfecha = (event) => {
        setPrimerfecha(event.target.value);
    };

    const OnchangeSegundafecha = (event) => {
        setSegundafecha(event.target.value);
    };

    const OnChangePrimerfecha2 = (event) => {
        setPrimerfecha2(event.target.value);
    };

    const OnchangeSegundafecha2 = (event) => {
        setSegundafecha2(event.target.value);
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length !== 0;

    const convertirImagenABase64 = (ruta) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', ruta);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };

    const generatePDF = async () => {
        const doc = new jsPDF();
        doc.addFileToVFS('NotoSans-Regular.ttf', notoSansBase64);
        doc.addFont('NotoSans-Regular.ttf', 'NotoSans', 'normal');

        const imgBase64 = await convertirImagenABase64(encabezadoimagen);

        const imgWidth = doc.internal.pageSize.width - 20;
        const imgHeight = 30;
        const imgX = 10;
        const imgY = 10;

        // Calcular el total de comisiones
        const totalComisiones = listaDeComisiones.reduce((total, row) => {
            return total + (row.montoComision || 0); // Sumar solo si montoComision tiene un valor
        }, 0);

        // Formatear el total directamente en el return
        const montoFormateado = new Intl.NumberFormat({
            style: 'currency',
            currency: 'CRC',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        }).format(totalComisiones);

        doc.addImage(imgBase64, 'PNG', imgX, imgY, imgWidth, imgHeight);

        doc.setFontSize(18);
        doc.setTextColor(216, 92, 44);
        doc.text('Comisiones', 90, 45);

        doc.setTextColor(0);
        doc.setFontSize(14);
        doc.setFont('NotoSans');
        doc.text(nombreUsuario, 20, 55);
        doc.text(
            "Total de comisiones: ₡" + montoFormateado.trim(),
            20,
            65
        );


        doc.setFontSize(12);

        const columns = [
            { title: 'Monto Comisión', width: 40 },
            { title: 'Nombre', width: 90 },
            //{ title: 'Monto a Pagar', width: 35 },
            { title: 'Número de Contrato', width: 40 }
        ];



        let data = [];

        listaDeComisiones.forEach((row) => {
            const rowData = [
                row.montoComision ? row.montoComision.toString() : '',
                row.nombrePersona ? row.nombrePersona.toString() : '',
                //row.montoPagar ? row.montoPagar.toString() : '',
                row.numContrato ? row.numContrato.toString() : ''
            ];
            data.push(rowData);
        });

        let startY = 70;
        const margin = 20;


        doc.setFont('helvetica', 'normal');
        doc.setDrawColor(0);
        doc.setTextColor(0);

        // Encabezado de la tabla, en color naranja
        let startX = margin;
        columns.forEach((column) => {
            doc.setFillColor('#d85c2c');
            doc.rect(startX, startY, column.width, 10, 'FD');
            doc.text(column.title, startX + column.width / 2, startY + 8, { align: 'center' });
            startX += column.width;
        });

        startY += 10; // Ajuste para los datos

        const pageHeight = doc.internal.pageSize.height;
        const rowHeight = 10;
        let rowsPerPage = Math.floor((pageHeight - startY - margin) / rowHeight);

        // Datos de la tabla
        doc.setTextColor(0); // Restablecer el color de texto
        data.forEach((rowData, rowIndex) => {
            if (startY > pageHeight - margin) {
                doc.addPage(); // Añadir una nueva página
                startY = margin; // Restablecer la posición Y para la nueva página


                startX = margin;
                columns.forEach((column) => {
                    doc.setFillColor('#d85c2c');
                    doc.rect(startX, startY, column.width, 40, 'FD');
                    doc.text(column.title, startX + column.width / 2, startY + 8, { align: 'center' });
                    startX += column.width;
                });

                startY += 10;
            }

            const fillColor = rowIndex % 2 === 0 ? [200, 200, 200] : [255, 255, 255];
            startX = margin;
            rowData.forEach((cell, index) => {
                const column = columns[index];
                doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);
                doc.rect(startX, startY, column.width, rowHeight, 'FD');
                doc.text(cell, startX + column.width / 2, startY + 8, { align: 'center' });
                startX += column.width;
            });
            startY += rowHeight;
        });


        doc.save(`${nombreUsuario}_${new Date().toLocaleDateString('es-ES')}.pdf`);
    };

    const handleBuscarClickComisionesVendedor = async () => {

        if (!Primerfecha || !Segundafecha || !vendedorSeleccionado?.value) {
            setMensajeRespuesta({ indicador: 3, mensaje: "Debe filtrar por rangos de fechas y vendedor" });
            setShowAlert(true);
        } else {
            const vendSeleccionado = listaDeVendedores.find(v => v.idVendedor === vendedorSeleccionado?.value);
            let idFilaSeleccionada = null;

            if (vendSeleccionado) {
                idFilaSeleccionada = vendSeleccionado.id || vendSeleccionado.idVendedor || vendSeleccionado.idSupervisor || vendSeleccionado.idComisionUsuariosClub;
            }

            try {
                let comisionesUsuario = [];
                if (Primerfecha && Segundafecha && idFilaSeleccionada) {
                    comisionesUsuario = await ObtenerComisionesUsuario(idFilaSeleccionada, 2);
                } else {
                    setMensajeRespuesta({ indicador: 3, mensaje: "Seleccione un rango de fechas correcto y un vendedor válido" });
                    setShowAlert(true);
                    return;
                }

                if (Array.isArray(comisionesUsuario)) {
                    setComisionesUsuarioSeleccionado(comisionesUsuario);
                    setListaDeComisiones(comisionesUsuario);
                }

                if (vendSeleccionado) {
                    const nombre = vendedorSeleccionado.label;
                    setNombreUsuario("Comisiones del usuario: " + nombre);
                }
            } catch (error) {
                console.error("Error al obtener las comisiones del usuario: ", error.mensaje);
            }
        }

    };

    const handleBuscarClickComisionesSupervisor = async () => {

        try {
            if (!Primerfecha || !Segundafecha || !supervisorSeleccionado?.value) {
                setMensajeRespuesta({ indicador: 3, mensaje: "Debe filtrar por rangos de fechas y supervisor" });
                setShowAlert(true);
            } else {
                const superSeleccionado = listaDeSupervisores.find(v => v.idSupervisor === supervisorSeleccionado?.value);
                let idFilaSeleccionada = null;

                if (superSeleccionado) {
                    idFilaSeleccionada = superSeleccionado.id || superSeleccionado.idSupervisor || superSeleccionado.idComisionUsuariosClub;
                }

                try {
                    let comisionesUsuario = [];
                    if (Primerfecha && Segundafecha && idFilaSeleccionada) {
                        comisionesUsuario = await ObtenerComisionesUsuario(idFilaSeleccionada, 3);
                    } else {
                        setMensajeRespuesta({ indicador: 3, mensaje: "Seleccione un rango de fechas correcto y un supervisor válido" });
                        setShowAlert(true);
                        return;
                    }

                    if (Array.isArray(comisionesUsuario)) {
                        setComisionesUsuarioSeleccionado(comisionesUsuario);
                        setListaDeComisiones(comisionesUsuario);
                    }

                    if (superSeleccionado) {
                        const nombre = supervisorSeleccionado.label;
                        setNombreUsuario("Comisiones del usuario: " + nombre);
                    } else {
                        setNombreUsuario("Comisiones encontradas: ");
                    }
                } catch (error) {
                    console.error("Error al obtener las comisiones del usuario: ", error.message);
                }
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }


    };

    return (
        <>
            <SpinnerPersonalizado show={isLoading} />
            <div className="container-fluid">
                <h1 className='card-title' style={{ color: 'orangered', textAlign: 'center', marginBottom: '20px' }}>Listado de comisiones</h1>
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )}

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="vendedores" title="Vendedores">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>Vendedor</Form.Label>
                                        <Select value={vendedorSeleccionado} onChange={OnchangeVendedorSeleccionado} options={listaDeVendedoresCombo} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="Primerfecha">
                                        <Form.Label style={{ fontWeight: 'bold' }}>Fecha desde</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={Primerfecha}
                                            onChange={OnChangePrimerfecha}
                                            style={{ marginBottom: '40px' }}
                                        />
                                    </Form.Group>

                                </Col>
                                <Col>
                                    <Form.Group controlId="Segundafecha">
                                        <Form.Label style={{ fontWeight: 'bold' }}>Fecha hasta</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={Segundafecha}
                                            onChange={OnchangeSegundafecha}
                                            style={{ marginBottom: '40px' }}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleBuscarClickComisionesVendedor}>
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>


                    </Tab>
                    <Tab eventKey="supervisores" title="Supervisores">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>Supervisor</Form.Label>
                                        <Select value={supervisorSeleccionado} onChange={OnchangeSupervisorSeleccionado} options={listaDeSupervisoresCombo} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="Primerfecha">
                                        <Form.Label style={{ fontWeight: 'bold' }}>Fecha desde</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={Primerfecha}
                                            onChange={OnChangePrimerfecha}
                                            style={{ marginBottom: '40px' }}
                                        />
                                    </Form.Group>

                                </Col>
                                <Col>
                                    <Form.Group controlId="Segundafecha">
                                        <Form.Label style={{ fontWeight: 'bold' }}>Fecha hasta</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={Segundafecha}
                                            onChange={OnchangeSegundafecha}
                                            style={{ marginBottom: '40px' }}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleBuscarClickComisionesSupervisor}>
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                </Tabs>
                <br /><br />
                <div className="position-relative">

                    <h3>{nombreUsuario}</h3>
                    <br /><br />
                    <Container className='d-Grid align-content-center'>
                        <Row className='mt-4' style={{ display: showDownloadButton ? 'block' : 'none' }}>
                            <Col md={12} className="ml-auto">
                                <Button
                                    variant="dark"
                                    style={{
                                        display: showDownloadButton ? 'inline-block' : 'none',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        border: '2px solid #d85c2c',
                                        color: '#d85c2c',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        fontSize: '15px',
                                        position: 'absolute',
                                        zIndex: 1000,
                                        left: '7%',
                                        transform: 'translateX(-30%)',
                                        bottom: '-37px',
                                        padding: '10px 20px',
                                    }}
                                    onClick={generatePDF}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                                    Descargar
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mt-2" style={{ display: showDownloadButton ? 'block' : 'none' }}>
                            <Grid
                                gridHeading={encabezadoComisiones}
                                gridData={listaDeComisiones}
                                selectableRows={false}
                                pending={pendiente}
                                idBuscar="id"
                                filterColumns={filterColumns}
                            />
                        </Row>
                    </Container>
                </div>

            </div>


        </>
    )
}

export default Comisiones;