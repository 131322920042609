import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import "../../styles/clubDeViaje.css";
import { InputsFormsReactHook} from "../Forms/InputsFormsReactHook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // Importa el local de español


function CardInformacionContrato({
    errors,
    isSummitInfo,
    register,
    FecContrato,
    handleDateContratoChange,
    FechaContratoValida,
    FecPrimerRebajo,
    handleDateChange,
    FechaValida,
    handleCambioCuota,
    accionCuota,
    IdClubDeViaje,
    saldo,
    CuotasAprobadas,
    handlePrimaChange,
    prima,
    errorFechaPrimerPago,
    ultimoAbonoAprobado,
    idEstadoRegaliaMontania,
    handleAplicarRegaliaMontania,
    idEstadoRegaliaPlaya,
    handleAplicarRegaliaPlaya, 
    MonCuotasRegalia,
    CuotasRegaladas,
    MontoAhorrado,
    Destino
}) {
    const [forceRerender, setForceRerender] = useState(false);

    useEffect(() => {
       setForceRerender(prev => !prev);

    }, []);

    return (
        <Card className="card-container mb-5" style={{ width: "53%" }}>
            <Card.Body>
                <Container className="d-Grid align-content-center">
                    <Row className="mt-4">
                        <Col>
                            <h1 className="card-title">Información del Contrato</h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md={4}>
                            <InputsFormsReactHook
                                classDiv={"col-md-12"}
                                label="Número de contrato:"
                                type={"text"}
                                step={"1"}
                                placeholder="Contrato"
                                mensajeValidacion={"El Número de contrato es requerido"}
                                errors={errors?.NumContrato?.type}
                                isSummit={isSummitInfo}
                                applyMask={true}
                                register={{
                                    ...register("NumContrato", {
                                        required: true,                                      

                                    }),

                                }}

                            />
                        </Col>
                        <Col
                            md={4}
                            className="d-flex flex-column"
                            style={{ padding: "0 30px" }}
                        >
                            <label htmlFor="fechaContrato">
                                <b>Fecha de contrato:</b>
                            </label>
                            <DatePicker
                                id="fechaContrato"
                                selected={FecContrato}
                                onChange={handleDateContratoChange}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecciona una fecha"
                                className={`form-control ${FechaContratoValida ? "is-invalid" : ""
                                    }`}
                                locale={es} // Establece el local de español
                            />
                            {FechaContratoValida && (
                                <div className="invalid-feedbackCheckBox">
                                    La fecha de contrato es requerida
                                </div>
                            )}
                        </Col>

                        <Col
                            md={4}
                            className="d-flex flex-column"
                        >
                            <label htmlFor="fechaVencimiento">
                                <b>Fecha del primer rebajo:</b>
                            </label>
                            <DatePicker
                                id="fecPrimerRebajo"
                                selected={FecPrimerRebajo}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecciona una fecha"
                                className={`form-control ${FechaValida ? "is-invalid" : ""}`}
                                locale={es} // Establece el local de español
                            />
                            {FechaValida && (
                                <div className="invalid-feedbackCheckBox">
                                    {errorFechaPrimerPago}
                                </div>
                            )}
                        </Col>
             
                    </Row>


                    {IdClubDeViaje !== 0 && IdClubDeViaje != null ? (

                        <Row className="mt-4">
                                    
                            {CuotasAprobadas === 0 && (
                                <>
                                    <Col
                                        md={6}
                                        className="d-flex flex-column"
                                        style={{ padding: "0 30px" }}
                                    >
                                        <label id="div_switch" htmlFor="switch"><b>Cuotas Aprobadas:</b></label>
                                        <p id="Saldo">{CuotasAprobadas}</p>
                                    </Col>
                                    <br /><br /><br /><br />

                                    <Col
                                        md={4}
                                        className="d-flex flex-column"
                                        style={{ padding: "0 30px" }}
                                    >
                                        <label id="div_switch" htmlFor="switch"><b>Cuotas de Regalía:</b></label>
                                        <p id="Saldo">{CuotasRegaladas ?? 0}</p>
                                    </Col>
                        
                                </>
                            )}


                            {CuotasAprobadas > 0 &&(
                                <>

                                    <Col
                                        md={4}
                                        className="d-flex flex-column"
                                        style={{ padding: "0 30px" }}
                                    >
                                        <label id="div_switch" htmlFor="switch"><b>Cuotas Aprobadas:</b></label>
                                        <p id="Saldo">{CuotasAprobadas}</p>
                                    </Col>
                                    <Col
                                        md={4}
                                        className="d-flex flex-column"
                                        style={{ padding: "0 30px" }}
                                    >
                                        <label id="div_switch" htmlFor="switch"><b>Cuotas de Regalía:</b></label>
                                        <p id="Saldo">{CuotasRegaladas ?? 0}</p>
                                    </Col>
                                    <Col
                                        md={4}
                                        className="d-flex flex-column"
                                        style={{ padding: "0 30px" }}
                                    >
                                        <label id="div_switch" htmlFor="switch"><b>Cuotas:</b></label>
                                        <BootstrapSwitchButton
                                            id={`switch`} onlabel="Reanudar"
                                            offlabel="Detener" checked={accionCuota}
                                            onChange={() => handleCambioCuota()}
                                            onstyle="outline-primary" offstyle="outline-secondary"
                                            width={120}
                                        />
                                    </Col>
                                </>

                            )}
                            <br /><br /><br /><br />
                        </Row>
                    ) : null}

                    <Row className="mt-4">

                        
                        <Col
                            md={4}
                            className="d-flex flex-column"
                            style={{ padding: "0 30px" }}
                        >
                            <label id="div_switch" htmlFor="switch"><b>Monto Aprobado:</b></label>
                            <p id="Saldo">{MontoAhorrado ?? 100 }</p>
                        </Col>
                        <br /><br /><br /><br />

                        <Col
                            md={4}
                            className="d-flex flex-column"
                            style={{ padding: "0 30px" }}
                        >
                            <label id="div_switch" htmlFor="switch"><b>Monto de Regalía:</b></label>
                            <p id="Saldo">{MonCuotasRegalia ?? 0}</p>
                        </Col>

                        <Col
                            md={4}
                            className="d-flex flex-column"
                            style={{ padding: "0 30px" }}
                        >
                            <label id="div_switch" htmlFor="switch"><b>Saldo:</b></label>
                            <p id="Saldo">{saldo - MonCuotasRegalia === 0 ? 0 : saldo}</p>
                        </Col>
                            {/*<p id="Saldo">{saldo - MonCuotasRegalia <= 0 ? 0 : saldo - MonCuotasRegalia}</p>*/}

                    </Row>






                    <Row className="mt-4">
                        {CuotasAprobadas >= 15 && CuotasAprobadas < 30 && (     
                            <>
                                <Col md={6} className="d-flex flex-column" style={{ padding: "0 30px" }}>
                                    <label htmlFor="switch">
                                        <b>Regalía Montaña:</b>
                                    </label>
                                    <BootstrapSwitchButton key={forceRerender} 
                                        id={`switchMontania`}
                                        onlabel="Utilizado"
                                        offlabel="Activar"
                                        checked={(idEstadoRegaliaMontania === 3) ? true : false}
                                        onChange={() => handleAplicarRegaliaMontania()}
                                        onstyle="outline-primary"
                                        offstyle="outline-secondary"
                                        width={120}
                                        disabled={idEstadoRegaliaMontania === 3}         
                                    />
                                </Col>    

                            </>
                      
                        )}
                            
               



                        {CuotasAprobadas >= 30 && (
                            <>
                                <Col md={4} className="d-flex flex-column" style={{ padding: "0 30px" }}>
                                    <label htmlFor="switch">
                                        <b>Regalía Montaña:</b>
                                    </label>
                                    <BootstrapSwitchButton key={forceRerender} 
                                        id={`switchMontania`}
                                        onlabel="Utilizado"
                                        offlabel="Activar"
                                        checked={(idEstadoRegaliaMontania === 3) ? true : false}
                                        onChange={() => handleAplicarRegaliaMontania()}
                                        onstyle="outline-primary"
                                        offstyle="outline-secondary"
                                        width={120}
                                        disabled={idEstadoRegaliaMontania === 3}         
                                    />
                                </Col>    
                                <Col md={4} className="d-flex flex-column" style={{ padding: "0 30px" }}>
                                    <label htmlFor="switch">
                                        <b>Regalía Playa:</b>
                                    </label>
                                    <BootstrapSwitchButton key={forceRerender} 
                                        id={`switchPlaya`}
                                        onlabel="Utilizado"
                                        offlabel="Activar"
                                        checked={(idEstadoRegaliaPlaya === 3) ? true : false}
                                        onChange={() => handleAplicarRegaliaPlaya()}
                                        onstyle="outline-primary"
                                        offstyle="outline-secondary"
                                        width={120}
                                        disabled={idEstadoRegaliaPlaya === 3}
                                    />
                                </Col> 


                                {Destino && (
                                    <>

                                        <Col md={4} className="d-flex flex-column" style={{ padding: "0 30px" }}>
                                            <label id="div_switch" htmlFor="switch"><b>Grupo de Viaje:</b></label>
                                            <p id="Saldo">{Destino ?? ""}</p>
                                        </Col> 
                                     
                                
                                    </>
                                )}



                            </>
                        )}
                    </Row>

                    
                </Container>
            </Card.Body>
        </Card>
    );
}
export default CardInformacionContrato