import React from 'react';
//import paris from '../../images/paris, francia-crop-u13942.jpg';
//import mexico from '../../images/teotihuacan-crop-u14787.jpg';
//import peru from '../../images/peru.jpg';
//import mexicoAlt from '../../images/mexico.jpg';

import Barcelona from '../../images/Barcelona.jpg'; 
import Cartagena from '../../images/Cartagena.jpg';
import Mexico from '../../images/Chichen itza.jpg';
import Cusco from '../../images/Cusco.jpg';
import logo from '../../images/logo.webp';
import '../../styles/Home.css'

const Home = () => {
    return (

        <div className="lightbox" style={{ alignItems: "flex-end" }}>

            <div className="barra-azul">
                <h2 className="titulo">Bienvenido a Instinto Viajero</h2>
                <img src={logo} alt="" className="img-fluid" style={{ width: "150px" }}></img>
            </div>


            <hr></hr>
            <br></br> <br></br>
            <div className="row">
                <div className="col-3">
                    <div className="image-container">
                        <img
                            src={Barcelona}
                            alt="Barcelona Espa�a"
                            className="image w-100 shadow-1-strong rounded"
                        />
                        <div className="overlay">Barcelona</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="image-container">
                        <img
                            src={Mexico}
                            alt="Chichen itza Mexico"
                            className="image w-100 shadow-1-strong rounded"
                        />
                        <div className="overlay">Chichen itza</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="image-container">
                        <img
                            src={Cartagena}
                            alt="Cartagena Colombia"
                            className="image w-100 shadow-1-strong rounded"
                        />
                        <div className="overlay">Cartagena</div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="image-container">
                        <img
                            src={Cusco}
                            alt="Cusco Peru"
                            className="image w-100 shadow-1-strong rounded"
                        />
                        <div className="overlay">Cusco</div>
                    </div>
                </div>
            </div>

        </div>
        /////////////////////////////////////////////////////////////////////////////
        //<div className="lightbox">
        //    <div className="row">
        //        <div className="col-md-6">
        //            <img
        //                src={paris}
        //                alt="Paris"
        //                className="w-100 mb-2 mb-md-4 shadow-1-strong rounded"
        //            />
        //            <img
        //                src={mexico}
        //                alt="Coconut with Strawberries"
        //                className="w-100 shadow-1-strong rounded"
        //            />
        //        </div>
        //        <div className="col-md-6">
        //            <img
        //                src={peru}
        //                alt="Per�"
        //                className="w-100 mb-2 mb-md-4 shadow-1-strong rounded"
        //            />
        //            <img
        //                src={mexicoAlt}
        //                alt="Colombia"
        //                className="w-100 shadow-1-strong rounded"
        //            />
        //        </div>
        //    </div>
        //</div>
    )
}

export default Home;