import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../components/grid';
import { AgregarPersona, ObtenerPersonas } from '../servicios/ServicioPersonas'
import Formulario from '../views/personas/formulario';
import { FormularioModal } from './ventanaModal';
import { AlertDismissible } from '../components/alerts';

export const BuscarPersona = ({ show, handleClose, className, tamano, handleAceptar }) => {
    const [proceso, setProceso] = useState(1);
    const [listaDePersonas, setListaDePersonas] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [data, setData] = useState({});
    //const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
   
    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'identificacion', name: 'Identificación', selector: row => row.identificacion, head: "Identificación", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        //{ id: 'fechaNacimiento', name: 'FechaNacimiento', selector: row => new Date(row.fechaNacimiento).toLocaleDateString('es-ES'), head: "F. Nacimiento", sortable: true },
        //{ id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true }
    ]

    //Se indica las columnas para aplicar filtro
    const filterColumns = ['identificacion', 'nombre', 'primerApellido', 'segundoApellido', 'fechaNacimiento'];

    useEffect(() => {
        ObtenerListadoDePersonas();
    }, []);


    const ObtenerListadoDePersonas = async () => {
        setPendiente(true);
        const ListPerson = await ObtenerPersonas();

        const result = () => {
            return ListPerson.filter(Items => Items.estado == 'Activo');
        };
        setListaDePersonas(result)

        setPendiente(false);
    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setFilaSeleccionada(fila);
    }

    const handleAceptarClick = () => {
        if (typeof handleAceptar === 'function') {
            handleAceptar(filaSeleccionada);
        }
    };
    const handleMostrarFormulario = () => {
        setMostrarFormulario(!mostrarFormulario);
    };

    const onClickProcesarPersona = async (data) => {
        setMensajeFormulario("");
        let respuesta = {};
        if (proceso === 1) {
            data.estado = true;
            respuesta = await AgregarPersona(data);
        }
        if (respuesta.indicador === 0) {
            setMostrarFormulario(false);
            ObtenerListadoDePersonas();
            setMensajeRespuesta(respuesta); 
        } else {
            setMensajeFormulario(respuesta.mensaje);
        }
        setShowAlert(true);

    }

    const onClickCerrarModal = () => {
        setMostrarFormulario(!mostrarFormulario);
    }

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;
    return (
        <>
            <Modal show={show && !mostrarFormulario} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName={className}
                aria-labelledby="contained-modal-title-vcenter" centered size={tamano} >
                
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{"Personas registradas"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button
                        className="mr-1 mb-2 card-button"
                        variant="primary"
                        size="sm"
                        onClick={handleMostrarFormulario}>
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Registrar
                    </Button>
                    <Grid gridHeading={encabezado} gridData={listaDePersonas} selectableRows={true} pending={pendiente}
                        setFilaSeleccionada={onClickSeleccionarFila} idBuscar="idPersona" filterColumns={filterColumns} />


                    <div className='text-right'>
                        <br />
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            size="sm"
                            disabled={bloquearBoton}
                            onClick={handleAceptarClick}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Aceptar
                        </Button>{' '} 
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            size="sm"
                            onClick={handleClose}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            Cancelar
                        </Button>{' '}                 
                    </div>
                    
                </Modal.Body>
            </Modal>
            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            

            <FormularioModal show={mostrarFormulario} handleClose={onClickCerrarModal} titulo={"Registrar Personas"} className='' tamano="lg">
                <Formulario labelButton={"Registrar"} data={data} proceso={proceso} onClickProcesarPersona={onClickProcesarPersona} mensaje={mensajeFormulario} />
            </FormularioModal>
            
        </>

    )
}