import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ModalAgregarHabitacion = ({ show, handleClose, handleAceptar }) => {
    const [TipoDeHabitacion, setTipoDeHabitacion] = useState('');
    const [MontoExtra, setMontoExtra] = useState(0);
    const [TipoDeHabitacionError, setTipoDeHabitacionError] = useState('');
    const [MontoExtraError, setMontoExtraError] = useState('');

    const onSave = () => {
        let valid = true;

        if (!TipoDeHabitacion) {
            setTipoDeHabitacionError('El tipo de Habitación es requerido');
            valid = false;
        } else {
            setTipoDeHabitacionError('');
        }

        if (MontoExtra === '' || MontoExtra < 0) {
            if (MontoExtra < 0) {
                setMontoExtraError('El monto no puede ser menor a 0.');
            } else {
                setMontoExtra(0);
            }
            valid = false;
        } else {
            setMontoExtraError('');
        }

        if (valid) {
            handleAceptar({ TipoDeHabitacion, MontoExtra });
            setTipoDeHabitacion('');
            setMontoExtra(0);
        }
    };

    const onClickCerrarModal = () => {
        handleClose();
        setTipoDeHabitacion('');
        setMontoExtra(0);
        setTipoDeHabitacionError('');
        setMontoExtraError('');
    };

    return (
        <Modal show={show} onHide={onClickCerrarModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Habitación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Tipo de Habitación</Form.Label>
                    <Form.Control
                        type="text"
                        value={TipoDeHabitacion}
                        onChange={(e) => setTipoDeHabitacion(e.target.value)}
                        isInvalid={!!TipoDeHabitacionError}
                    />
                    <Form.Control.Feedback type="invalid">
                        {TipoDeHabitacionError}
                    </Form.Control.Feedback>
                </Form.Group>
                <br></br>
                <Form.Group>
                    <Form.Label>Monto Extra($)</Form.Label>
                    <Form.Control
                        type="number"
                        value={MontoExtra}
                        onChange={(e) => setMontoExtra(e.target.value)}
                        isInvalid={!!MontoExtraError}
                        min={0}  
                        max={999999999}
                    />
                    <Form.Control.Feedback type="invalid">
                        {MontoExtraError}
                    </Form.Control.Feedback>
                </Form.Group>
            <br></br>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickCerrarModal}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAgregarHabitacion;