import React, { useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';


export const ConfirmModal = ({ isOpen, toggle, message, onConfirm }) => {
    const ref = useRef(null);


    return (
        <Modal ref={ref} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirmación</ModalHeader>
            <ModalBody>{message}</ModalBody>
            <ModalFooter>            
                <Button className="mr-1 mb-2 card-button" color="primary" onClick={onConfirm}>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    Confirmar
                </Button>
                <Button className="mr-1 mb-2 danger-button" color="danger" onClick={toggle}>
                    <FontAwesomeIcon icon={faX} className="mr-2" />
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

