import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AlertDismissible } from '../../components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import { SpinnerPersonalizado } from "../../components/spinnerPersonalizado";
import * as XLSX from 'xlsx';
import { ImportarAbonosClub } from '../../servicios/ServicioCobro'
const ImportarCobros = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [showAlert, setShowAlert] = useState(false);
    const [showImportButton, setShowImportButton] = useState(false);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [dataFromExcel, setDataFromExcel] = useState([]); // Nuevo estado para almacenar datos del archivo
    const [dataArray, setDataArray] = useState([]); // Nuevo estado para almacenar datos en un array
    const [file, setFile] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const encabezadoCobros = [
        { id: 'id', name: 'Club', selector: row => row.id, head: "id"},
        { id: 'socioRebajo', name: 'Socio Rebajo', selector: row => row.socioRebajo, head: "SocioRebajo", sortable: true, width: '15%' },
        { id: 'tipoDescargo', name: 'Tipo Descargo', selector: row => row.tipoDescargo, head: "TipoDescargo", sortable: true, width: '18%' },
        { id: 'Numero', name: 'Numero', selector: row => row.numero, head: "Número", sortable: true, width: '15%' },
        {
            id: 'FecVencimiento', name: 'Fecha de Vencimiento', selector: row => row.nFecVencimiento,
            head: "Fecha de Vencimiento", sortable: true, width: '20%'
        },
        { id: 'Monto', name: 'Monto', selector: row => row.monto, head: "Monto", sortable: true },
        {
            id: 'FechaPago', name: 'Fecha de Descargo', selector: row => row.fechaPago,
            head: "Fecha de Pago", sortable: true, width: '14%', omit: true
        },
        {
            id: 'correoElectronico', name: 'Correo Electronico', selector: row => row.correoElectronico,
            head: "Correo Electrónico", sortable: true, width: '20%'
        },
        { id: 'nombre', name: 'Nombre Socio', selector: row => row.nombre, head: "Nombre", sortable: true, width: '20%' },
        { id: 'estado', name: 'Estado Cobro', selector: row => row.estado, head: "Estado Cobro", sortable: true, width: '20%' },
    ];

    const importarArchivoExcel = async () => {


        setIsLoading(true);
        let errorEnFechas = false;

        var cobros = dataArray.map(item => {
            //typeof item.nFecVencimiento !== 'string'


            if (typeof item.fechaDescargo !== 'string') {
                setMensajeRespuesta({
                    "indicador": 1,
                    "mensaje": `La fecha de descargo no cumple con el formato esperado.`
                })
                errorEnFechas = true;
            }

            if (typeof item.fechaDescargo !== 'string') {
                setMensajeRespuesta({
                    "indicador": 1,
                    "mensaje": `La fecha de descargo no cumple con el formato esperado.`
                })
                errorEnFechas = true;
            }

            const day = item.fechaDescargo.substring(0, 2);
            const month = item.fechaDescargo.substring(2, 4);
            const year = item.fechaDescargo.substring(4);
            return {
                idClubViaje: item.id,
                socioRebajo: item.socioRebajo,
                tipoDescargo: item.tipoDescargo,
                numeroTarjeta: item.numero,
                fechaVencimiento: item.nFecVencimiento,
                monPagar: item.monto, // Cambio de nombre a "monPagar"
                fechaPago: new Date(year + "/" + month + "/" + day),
                correoElectronico: item.correoElectronico,
                nombre: item.nombre,
                estado: (item.estado.toUpperCase() === "R") ? "Rechazado" : ((item.estado.toUpperCase() === "A") ? "Aprobado" : null),
                estadoCobro: (item.estado.toUpperCase() === "R") ? false : ((item.estado.toUpperCase() === "A") ? true : null),
            };
        });

        if (errorEnFechas) {
            setIsLoading(false);
            setShowAlert(true);
            return; // Detiene el proceso si el formato de las fechas no es correcto
        }

        const respuesta = await ImportarAbonosClub(cobros);

        if (respuesta && respuesta.length > 0) {
            const errores = respuesta.filter(item => item.indicador === 1);
            setIsLoading(false);
            if (errores.length > 0) {
                // Procesar mensajes de error y extraer IDs de clubes
                const idsConError = errores.map(error => {
                    const match = error.mensaje.match(/club (\d+)/);
                    return match ? match[1] : null;
                }).filter(id => id !== null);

                // Mostrar mensaje de error indicando los IDs de los clubes que arrojaron error
                const idsConErrorStr = idsConError.join(', ');
                const fechaError = errores[0].mensaje.split(' ').pop(); // Tomar la fecha del primer error
                setMensajeRespuesta({
                    "indicador": 1,
                    "mensaje": `Error: Ya existen los pagos de los clubes ${idsConErrorStr} en la fecha ${fechaError}`
                })
                setShowAlert(true);
            } else {
                // Mostrar mensaje de éxito si no hay errores
                setMensajeRespuesta({
                    "indicador": 0,
                    "mensaje": 'Todos los abonos fueron registrados exitosamente.'
                })
                setShowAlert(true);
            }
        }

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setShowImportButton(false);
        setDataArray([]);
        setFile(file);
    };

    const importarExcel = () => {
        setIsLoading(true);
        let InfoValida = true;
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                // Obtener nombres de propiedades desde la primera fila
                const properties = jsonData[0];


                let FormatoValido = true;
                let socioRebajoError = false;
                let numeroError = false;
                let fechaDescargoError = false;
                let FechaVencimientoError = false;
                let mensaje = "";

                // Crear un array de objetos utilizando los nombres de propiedades
                const formattedData = jsonData.slice(1).map(row => {
                    const obj = {};
                    properties.forEach((property, index) => {
                        const value = row[index];

                        //Valida que la informacion no pueda ir vacia (solo el correo puede ir vacio)
                        if (property === 'id' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'socioRebajo' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'tipoDescargo' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'numero' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'nFecVencimiento' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'Monto' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'fechaDescargo' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'nombre' && (value === undefined || value === '')) { InfoValida = false; return; }
                        if (property === 'estado' && (value === undefined || value === '')) { InfoValida = false; return; }

                       
                         //Modificar la propiedad "nFecVencimiento" según el formato requerido
                        if (property === "nFecVencimiento") {
                            const stringValue = value.toString();
                            if (/^\d{6}$/.test(stringValue)) {
                                const month = stringValue.substring(0, 2);
                                const year = stringValue.substring(2);
                                obj[property] = `${month}/${year}`;
                            } else {
                                FechaVencimientoError = true;
                            }
                        } else {
                            obj[property] = value;
                        }
                    });
                    return obj;
                });
                if (!InfoValida) {
                    setMensajeRespuesta({
                        "indicador": 1,
                        "mensaje": 'Informacion incompleta. Verifique los campos requeridos en el archivo.'
                    })
                    setShowAlert(true);
                    setIsLoading(false);
                    return;
                }


              


                const errores = [];
                formattedData.forEach(({ socioRebajo, numero, fechaDescargo, nFecVencimiento }) => {
                    if (typeof socioRebajo !== 'string') errores.push('Socio Rebajo');
                    if (typeof numero !== 'string') errores.push('Numero');
                    if (typeof fechaDescargo !== 'string') errores.push('Fecha de Descargo');
                    if (typeof nFecVencimiento !== 'string') errores.push('Fecha de Vencimiento');
                });


                //Asignar un mensaje segun las columnas que el formato haya dado error
                if (errores.length > 0) {
                    const columnasErroneas = Array.from(new Set(errores)); // Elimina duplicados

                    const mensaje = columnasErroneas.length === 1
                        ? `La columna ${columnasErroneas[0]} no cumple con el formato esperado.`
                        : `Las siguientes columnas no cumplen con el formato esperado: ${columnasErroneas.join(', ')}.`;

                    setMensajeRespuesta({
                        "indicador": 1,
                        "mensaje": mensaje
                    });
                    setShowAlert(true);
                    setIsLoading(false);
                    return;
                }

                if (!FormatoValido) {
                    setMensajeRespuesta({
                        "indicador": 1,
                        "mensaje": mensaje
                    })
                    setShowAlert(true);
                    setIsLoading(false);
                    return;
                }
                
                setDataArray(formattedData);
                setShowImportButton(true);
            };
            reader.readAsBinaryString(file);
            setIsLoading(false);
        }
    }



    const onSubmit = (formData) => {
        // Lógica para procesar el formulario después de la importación
        // Puedes utilizar los datos de 'dataArray' aquí

        // Mostrar los datos del archivo xlsx como un array de objetos
    };

    return (
        <>
            <SpinnerPersonalizado show={isLoading} />

            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <Card className="card-container" style={{ width: '100%' }}>
                <Card.Body>
                    <Card.Title className="card-title"> Importar Documentos de Cobros </Card.Title>
                    <Container className='d-Grid align-content-center'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group controlId="file">
                                <Row className="align-items-left">
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form.Label className="mr-2"><strong>Archivo: </strong></Form.Label>
                                    </Col>
                                </Row>
                                <Row className="align-items-center justify-content-center">
                                    <Col md={6} className="d-flex align-items-center">
                                        <Form.Control
                                            type="file"
                                            {...register('file')}
                                            accept=".xlsx"
                                            onChange={handleFileChange}
                                        />
                                        {errors.file && <Form.Text className="text-danger">Este campo es requerido.</Form.Text>}
                                    </Col>
                                    <Col md={6} className="d-flex align-items-center">
                                        <Button
                                            className="mr-1 card-button"
                                            variant="primary"
                                            type="submit"
                                            style={{ height: "32px" }}
                                            size="sm"
                                            onClick={importarExcel}
                                        >
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                            Importar archivo
                                        </Button>
                                    </Col>
                                </Row>
                                
                                
                            </Form.Group>
                        </Form>
                    </Container>
                    <br></br>
                    <Col md={3} class="d-flex align-items-center justify-content-center">
                        <br></br>
                        <Button
                            variant="Dark"
                            style={{
                                display: showImportButton ? 'inline-block' : 'none',
                                textAlign: 'center',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                border: '2px solid #d85c2c',
                                color: '#d85c2c',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                fontSize: '15px',
                            }}
                            onClick={importarArchivoExcel}
                        >
                            <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                            Guardar
                        </Button>
                    </Col>
                    <br></br>
                    <div className="container-fluid">
                        <Grid
                            gridHeading={encabezadoCobros}
                            gridData={dataArray}
                            selectableRows={false}
                        />
                        <br></br>
                        <h6 style={{ color: '#d85c2c', textAlign: 'center'}} ><b>Nota:</b> Los estados del documento de cobros deben ser "A" para aprobados y "R" para rechazados.</h6>
                    </div>
                </Card.Body>
                
            </Card>
        </>
    );
};

export default ImportarCobros;