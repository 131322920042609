import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { InputsFormsReactHook } from '../../components/Forms/InputsFormsReactHook';
import { ObtenerDocumentosPaqueteID,AgregarDocumentosPaquetes } from '../../servicios/ServicioDocumentos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye } from '@fortawesome/free-solid-svg-icons';
import { SpinnerPersonalizado } from "../../components/spinnerPersonalizado";
import { ModalVisorDocumentos } from '../../components/fileViewer';
import { Grid } from "../../components/grid";

const DocumentosPaqueteDeViajeComponet = ({ idPaquete, PermisoDocumentoP } ) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setError
    } = useForm();

    const [isSummitInfo, setIsSummitInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [documentoIndicador, setDocumentoIndicador] = useState(null);
    const [documentoId, setDocumentoId] = useState(null);

    const fileInputRef = useRef(null);


    const [listaDeDocumentos, setListaDeDocumentos] = useState([]);

    const encabezadoDocumentos = [
        { id: 'idDocumento', name: 'ID', selector: (row) => row.idDocumento, head: 'ID', omit: true },
        {
            id: "Documento",
            name: "Documento",
            selector: (row) => row.nomDocumento,
            head: "Documento",
            sortable: true,
        },
        {
            id: "FechaCreacion",
            name: "Fecha Carga",
            selector: (row) => row.fechaCreacion,
            head: "Fecha Carga",
        },
        {
            id: 'Ver',
            name: 'Abrir Documento',
            head: 'Ver',
            cell: (row) => (
                <Button variant="link" size="sm" onClick={() => handleAbrirDocumento(row)}>
                    <FontAwesomeIcon size="lg" icon={faEye} style={{ color: '#d65e28' }} />
                </Button>
            ),
        },
    ];

    useEffect(() => {
        if (idPaquete) {
            obtenerDocumentos();
        }
    }, [idPaquete]);

    const obtenerDocumentos = async () => {
        try {
            setIsLoading(true);
            if (idPaquete) {
                console.log("ObtenerDocumentosPaquete");
                console.log(idPaquete);
                const documentos = await ObtenerDocumentosPaqueteID(idPaquete);
                //const documentos = await ObtenerDocumentosClubDeViajeID(35);

                setListaDeDocumentos(documentos);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };


    const handleAbrirDocumento = async (fila) => {
        setDocumentoId(fila.idDocumento);
        setDocumentoIndicador(2);
        setShowModal(true);
    };


    const onSubmit = async (data) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', data.file[0]);
        formData.append('idPaquete', JSON.stringify(idPaquete.idPaquete));
        formData.append('esDeAbono', false);
        formData.append('numAbono', null);

        try {
  
            const response = await fetch(`/Documentos/InsertarDocumentosGrupos/${idPaquete}`, {
                method: 'POST',
                body: formData,
                
            });
            if (response.ok) {
                obtenerDocumentos();
            }
            setIsLoading(false);

            fileInputRef.current.value = null;
            // Manejar la respuesta del servidor aquí, si es necesario.
        } catch (error) {
            setIsLoading(false);
            setError('file', { type: 'server', message: 'Error al enviar el archivo.' });
            console.log("Error: " , error.message);
        }
    };

    const onClickSeleccionarFila = (fila) => { }

    return (
        <>
            <SpinnerPersonalizado show={isLoading} />
            <div className='d-flex justify-content-around mt-3'>
                <Card className="card-container mb-5" style={{ width: '100%' }}>
                    <Card.Body>
                        <Card.Title className="card-title">Documentos</Card.Title>
                        <Container className='d-Grid align-content-center'>

                            {/*  <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <input className='' type="file" {...register('file')} />
                        </div>
                        <div>
                            <button type="submit">Enviar</button>
                        </div>
                        </form> */}
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <ModalVisorDocumentos
                                    show={showModal}
                                    handleClose={() => setShowModal(false)}
                                    titulo="Documento Club de Viaje"
                                    tamano="lg"
                                    idDocumento={documentoId}
                                    indicador={documentoIndicador}
                                />

                                {PermisoDocumentoP && (
                                    <>
                                    <Form.Group className='col-md-12' controlId="file">
                                        <Form.Label>Archivo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            {...register('file', { required: 'Este campo es requerido.' })}
                                            ref={e => {
                                                register('file').ref(e);
                                                fileInputRef.current = e;
                                            }}
                                        />
                                        {errors.file && <Form.Text className="text-danger">{errors.file.message}</Form.Text>}
                                    </Form.Group>
                                    <Button className='ml-3 mt-2 card-button' variant="primary" size="sm" type="submit" style={{ fontSize: '18px' }}>
                                        <FontAwesomeIcon icon={ faPlus } className="mr-1" />
                                        Agregar Documentos
                                    </Button>
                                    </>
                                )}
                            </form>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <span>Listado de Documentos</span>
                                    <Grid
                                        gridHeading={encabezadoDocumentos}
                                        gridData={listaDeDocumentos}
                                        selectableRows={false}
                                        setFilaSeleccionada={onClickSeleccionarFila}
                                        idBuscar="id"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default DocumentosPaqueteDeViajeComponet;