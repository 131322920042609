import React, { useEffect, useState } from 'react';
import { Grid } from '../../../components/grid';
import { AlertDismissible } from '../../../components/alerts';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { ObtenerComisiones, ObtenerComision } from '../../../servicios/ServicioComisiones' 
import { ObtenerVendedores, ObtenerVendedor } from '../../../servicios/ServicioVendedor'

const ComisionesVendedor = () => {  
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [listaDeVendedores, setListaDeVendedores] = useState([]);
    const [listaDeComisiones, setListaDeComisiones] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});

    const encabezadoVendedor = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'codVendedor', name: 'Cod. Vendedor', selector: row => row.codVendedor, head: "Cod. Vendedor", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        { id: 'nombreSucursal', name: 'Sucursal', selector: row => row.nombreSucursal, head: "Sucursal", sortable: true },
        { id: 'fechaContratacion', name: 'F. Contratación', selector: row => new Date(row.fechaContratacion).toLocaleDateString('es-ES'), head: "F. Contratación", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true },
        { id: 'NumContrato', name: 'Numero Contrato', selector: row => row.NumContrato, head: "Numero Contrato", sortable: true }
    ]   

    const encabezadoComisiones = [
        { id: 'idComisionUsuariosClub', name: 'idComisionUsuariosClub', selector: row => row.idComisionUsuariosClub, head: "id", omit: true },
        { id: 'idRol', name: 'idRol', selector: row => row.idRol, head: "idRol", omit: true },
        { id: 'fecha', name: 'fecha', selector: row => row.fecha, head: "fecha", omit: true },
        { id: 'idUsuarioComision', name: 'Usuario Comisionado', selector: row => row.idUsuarioComision, head: "Usuarrio Comisionado", sortable: true },
        { id: 'montoComision', name: 'Monto Comisión', selector: row => row.montoComision, head: "Monto Comisión", sortable: true },
        { id: 'idClub', name: 'Id Club', selector: row => row.idClub, head: "Id Club", sortable: true },
        { id: 'idPersonaClub', name: 'Cliente', selector: row => row.idPersonaClub, head: "Cliente", sortable: true },
        { id: 'numCuota', name: 'Número Cuota', selector: row => row.numCuota, head: "Número Cuota", sortable: true },
        { id: 'NumContrato', name: 'Numero Contrato', selector: row => row.NumContrato, head: "Numero Contrato", sortable: true }
    ]



    const filterColumnsVentas = ['codVendedor', 'nombre', 'primerApellido', 'segundoApellido', 'nombreSucursal'];

    useEffect(() => {
        ObtenerListadoDeComisiones();
        ObtenerListadoDeVendedores();
    }, []);  

    const ObtenerListadoDeVendedores = async () => {
        setPendiente(true);
        setListaDeVendedores(await ObtenerVendedores());
        setPendiente(false);
    }
    const ObtenerListadoDeComisiones = async (idUsuario) => {
        setPendiente(true);
        //setListaDeComisiones(await ObtenerComisionesUsuario(idUsuario));
        setPendiente(false);
    }  

    const ObtenerComisionesUsuario = async (idUsuario) => {
        try {
            const comisionesUsuario = await ObtenerComisiones(idUsuario);
            console.log("Respuesta de ObtenerComisiones:", comisionesUsuario);
            setListaDeComisiones(comisionesUsuario);
            return comisionesUsuario;
        } catch (error) {
            console.log("Error al obtener las comisiones del usuario: ", error);
        }
    }   

    const onClickSeleccionarFila = async (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setFilaSeleccionada(fila);

        const idFilaSeleccionada = fila.id || fila.idVendedor || fila.idSupervisor;
        console.log(idFilaSeleccionada, "ID de la fila");
        if (idFilaSeleccionada) {
            try {
                const comisionesUsuario = await ObtenerComisionesUsuario(idFilaSeleccionada);
                setListaDeComisiones(comisionesUsuario);
            } catch (error) {
                console.error("Error al obtener las comisiones del usuario: ", error);
            }
        } else {
            console.error("idFilaSeleccionada es undefined");
            setListaDeComisiones([]);
        }
    }

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;



    return (
        <>

            <div className="container-fluid">
                <h1>Listado de comisiones de Vendedores</h1>
                <hr />

                <br /><br />
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )}

                        {/*Listado de vendedores*/}
                        <Grid gridHeading={encabezadoVendedor} gridData={listaDeVendedores} selectableRows={true}
                    pending={pendiente} setFilaSeleccionada={onClickSeleccionarFila}
                            idBuscar="idVendedor" />
                   
                 
                
               
                <br /><br />
                <h3>Comisiones del usuario</h3>
                <Grid gridHeading={encabezadoComisiones} gridData={listaDeComisiones} selectableRows={false} pending={pendiente} />
                {/*<br /><br />  setFilaSeleccionada={onClickSeleccionarFila}      idBuscar="idComisionUsuariosClub"   */}


                
            </div>




        </>
    ) 


}






export default ComisionesVendedor;
