import React from 'react';
import { Form } from "react-bootstrap"

export const InputText = ({ id, label, type, placeholder, value, text, onChange, mensajeValidacion, className, onClick, readOnly, disabled, required = true, max, maxLength }) => {
    return (
        <Form.Group className={"mb-3 " + className} controlId={id}>
            <Form.Label><b>{label}</b></Form.Label>
            <Form.Control type={type} placeholder={placeholder} value={value} onChange={onChange} onClick={onClick} required={required} readOnly={readOnly} disabled={disabled} max={max} maxLength={maxLength} />
            <Form.Text className="text-muted">{text}</Form.Text>
            <Form.Control.Feedback type="invalid">{mensajeValidacion}</Form.Control.Feedback>
        </Form.Group>
    )
}

export const InputSelect = ({ className, controlId, label, data, onChange, value, optionValue, optionLabel, classGroup}) => {
    const ObtenerOptions = () => {
      
        return data.map((option, index) => {
            return <option key={index} value={option[optionValue]}>{option[optionLabel]}</option>
        })
    }

    return (
        <Form.Group controlId={controlId} className={classGroup}>
            <Form.Label>{label}</Form.Label>
            <Form.Select className={className} onChange={onChange} defaultValue={value}>
               {/* <option value="">{"Seleccione..."}</option>*/}
                {ObtenerOptions()}
            </Form.Select>
        </Form.Group>
    )
}

export const InputNumber = ({ id, label, placeholder, value, onChange, mensajeValidacion, className, onClick, readOnly, disabled, required = true, max, min, step }) => {
    return (
        <Form.Group className={"mb-3 " + className} controlId={id}>
            <Form.Label><b>{label}</b></Form.Label>
            <Form.Control
                type="number"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onClick={onClick}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                max={max}
                min={min}
                step={step}

            />
            <Form.Control.Feedback type="invalid">{mensajeValidacion}</Form.Control.Feedback>
        </Form.Group>
    );
};
