import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import { BuscarPaquetesDeViajes } from '../../servicios/ServicioPaquetesDeViajes'


export const BuscarPaquetesDeViaje = ({ show, handleClose, className, tamano, handleAceptar }) => {

    const [listaDePaquetesDeViaje, setListaDePaquetesDeViaje] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);

    const encabezado = [
        { id: 'id', name: 'Serie', selector: row => row.id, head: "id", omit: true },
        { id: 'Descripcion', name: 'Descripción', selector: row => row.descripcion, head: "Descripción", sortable: true },
        { id: 'fechaSalida', name: 'F. Salida', selector: row => row.fechaSalida.split('T')[0], head: "Fecha Salida", sortable: true },
        { id: 'fechaLLegada', name: 'F. LLegada', selector: row => row.fechaLLegada.split('T')[0], head: "Fecha Llegada", sortable: true },
        { id: 'precioContado', name: 'Contado ($)', selector: row => row.precioContado, head: "Contado", sortable: true },
        { id: 'precioPlazos', name: 'A PLazos ($)', selector: row => row.precioPlazos, head: "A PLazos", sortable: true },
        { id: 'cantidadCampos', name: 'Pasajeros', selector: row => row.cantidadCampos, head: "Pasajeros", sortable: true },
        { id: 'cantidadGuias', name: 'Guias', selector: row => row.cantidadGuias, head: "Guias", sortable: true },
        { id: 'cantidadCamposDisponibles', name: 'Disponibles', selector: row => row.cantidadCamposDisponibles, head: "Disponibles", sortable: true },  
    ]

    //Se indica las columnas para aplicar filtro
    const filterColumns = ['descripcion'];

    useEffect(() => {
        ObtenerListadoDePaquetesDeViaje();
    }, []);


    const ObtenerListadoDePaquetesDeViaje = async () => {
        setPendiente(true);
        setListaDePaquetesDeViaje(await BuscarPaquetesDeViajes());
        setPendiente(false);
    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setFilaSeleccionada(fila);
    }

    const handleAceptarClick = () => {
        if (typeof handleAceptar === 'function') {
            handleAceptar(filaSeleccionada);
        }
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName={className}
                aria-labelledby="contained-modal-title-vcenter" centered size={tamano} >
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{"Paquetes de viaje"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid gridHeading={encabezado} gridData={listaDePaquetesDeViaje} selectableRows={true} pending={pendiente}
                        setFilaSeleccionada={onClickSeleccionarFila} idBuscar="id" filterColumns={filterColumns} />


                    <div className='text-right'>
                        <br />
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            size="sm"
                            disabled={bloquearBoton}
                            onClick={handleAceptarClick}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Aceptar
                        </Button>{' '}
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            size="sm"
                            onClick={handleClose}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            Cancelar
                        </Button>{' '}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}