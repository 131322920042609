import React, {useState} from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form'
import { InputSelectHookForm, InputsFormsReactHook, TexAreaFormsReactHook } from '../../components/Forms/InputsFormsReactHook';

const FormularioPaquetesDeViajes = ({ labelButton, data, proceso, PermisoActualizar, onClickProcesar }) => {
    if(proceso === 1) {
        data = {}
    }
 
    const { register, handleSubmit, formState: { errors }, trigger } = useForm({
        defaultValues: {            
            Descripcion: data?.descripcion,
            ObservacionesGenerales: data?.observacionesGenerales,
            PrecioContado: data?.precioContado,
            PrecioPlazos: data?.precioPlazos,
            CantidadCampos: data?.cantidadCampos,
            CantidadGuias: data?.cantidadGuias,
            FechaSalida: data?.fechaSalida ? data.fechaSalida.replace('T00:00:00', '') : '',
            FechaLLegada: data?.fechaLLegada ? data.fechaLLegada.replace('T00:00:00', '') : '',
        }
    });
    
    const [isSummit, setIsSummit] = useState(false);

    const onClickAceptar = (event) => {
        onClickProcesar({ ...event });     
    }
    const handleManualValidation = async () => {
        const isValid = await trigger();
        if (!isValid) {
            setIsSummit(true)
        }
    };


    return (
        <>
            <Form  className='flex-row justify-content-center' onSubmit={handleSubmit(onClickAceptar)}>
                <Row className='m-2'>
                    <InputsFormsReactHook
                        //className="input-form-hook"
                        id='txt-Descripcion'
                        label='Descripción:'
                        classDiv={"col-md-12"}
                        placeholder='Descripción'
                        errors={errors?.Descripcion?.type}
                        mensajeValidacion="La descripción es requerida"
                        isSummit={isSummit}
                        register={{ ...register('Descripcion', { 
                            required: true,
                            maxLength: 100
                            })
                        }}
                    />
                </Row>   
                <Row className='m-2'>
                    <InputsFormsReactHook
                        //className="input-form-hook"
                        id='txt-PrecioContado'
                        type={"number"}
                        label='Precio Contado ($):'                        
                        classDiv={"col-md-4"}
                        placeholder='Precio Contado'
                        errors={errors?.PrecioContado?.type}
                        mensajeValidacion="El precio es requerido"
                        isSummit={isSummit}
                        register={{ ...register('PrecioContado', { 
                            required: true,                            
                            })
                        }}
                    />


                    <InputsFormsReactHook
                        //className="input-form-hook"
                        id='txt-PrecioPlazos'
                        type={"number"}
                        label='Precio Plazos ($):'
                        classDiv={"col-md-4"}
                        placeholder='Precio a Plazos'
                        errors={errors?.PrecioPlazos?.type}
                        mensajeValidacion="El precio es requerido"
                        isSummit={isSummit}
                        register={{
                            ...register('PrecioPlazos', {
                                required: true,
                            })
                        }}
                        />
                    <InputsFormsReactHook
                        //className="input-form-hook"
                        id='txt-CantidadCampos'
                        type={"number"}
                        step={"1"}
                        classDiv={"col-md-4"}
                        label='Cantidad de Pasajeros:'
                        placeholder='Cantidad de Pasajeros'
                        errors={errors?.CantidadCampos?.type}                        
                        mensajeValidacion="Cantidad de Pasajeros Requeridos"
                        isSummit={isSummit}
                        register={{ ...register('CantidadCampos', {  
                            required: true
                            })
                        }}
                    />
                    <InputsFormsReactHook
                        //className="input-form-hook"
                        id='txt-CantidadGuias'
                        type={"number"}
                        label='Guías :'
                        classDiv={"col-md-4"}
                        placeholder='Guías'
                        errors={errors?.CantidadGuias?.type}
                        mensajeValidacion="El número de guías es requerido"
                        isSummit={isSummit}
                        register={{...register('CantidadGuias', {
                                required: true,
                            })
                        }}
                    />
                </Row>               

                <Row className='m-2'>
                    <InputsFormsReactHook
                        //className="input-form-hook"
                        type={"Date"}
                        classDiv={"col-md-6"}
                        id='txt-FechaSalida'
                        label='Fecha de Salida:'
                        placeholder='Fecha de Salida'
                        errors={errors?.FechaSalida?.type}
                        mensajeValidacion="La fecha de salida es requerida"
                        isSummit={isSummit}
                        register={{ ...register('FechaSalida', { 
                            required: true,                            
                            })
                        }}
                    />
                    <InputsFormsReactHook
                        //className="input-form-hook"
                        id='txt-FechaLLegada'
                        classDiv={"col-md-6"}
                        type={"Date"}
                        label='Fecha de llegada:'
                        placeholder='Fecha de llegada'
                        errors={errors?.FechaLLegada?.type}                        
                        mensajeValidacion="La fecha de llegada es requerida"
                        isSummit={isSummit}
                        register={{ ...register('FechaLLegada', {  
                            required: true
                            })
                        }}
                    />
                </Row> 
                <Row className='m-2'>
                    
                    <TexAreaFormsReactHook
                        //className="input-form-hook"
                        type={""}
                        rows={3}
                        classDiv={"col-md-12"}
                        id='txt-ObservacionesGenerales'
                        label='Observaciones Generales:'
                        placeholder='Observaciones Generales'
                        errors={errors?.ObservacionesGenerales?.type}                        
                        mensajeValidacion=""
                        isSummit={isSummit}
                        register={{ ...register('ObservacionesGenerales', {                             
                            maxLength: 1000
                            })
                        }}
                    />
                </Row>
                <div className='text-right'>

                    {proceso === 2 && PermisoActualizar && (
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            {labelButton}
                        </Button>
                    )}

                    {labelButton === "Registrar" && (
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            {labelButton}
                        </Button>
                    )}


                </div>
            </Form>
        </>
    )
}

export default FormularioPaquetesDeViajes;
