import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faCoins, faFloppyDisk, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form'
import { BuscarPersona } from '../../components/buscarPersona';
import { BuscarPaquetesDeViaje } from '../../components/BuscarPaquetesDeViaje/buscarPaquetesDeViaje';
import { Grid } from '../../components/grid';
import { InputText, InputNumber } from '../../components/inputs';
import { FormularioModal } from '../../components/ventanaModal';
import FormularioTour from './formularioTour';
import { InputSelectHookForm, InputsFormsReactHook, TexAreaFormsReactHook } from '../../components/Forms/InputsFormsReactHook';
import { AlertDismissible } from '../../components/alerts';
import { ActualizarComentarioPaquete } from "../../servicios/ServicioPaquete";
import { ComboBox } from '../../components/combobox';
import {
    AgregarPaquete, ObtenerPaquetePorId, AgregarComentarioPaquete,
    InactivarAcompanante, InactivarTour, InsertarAcompanante, InsertarTour, ActualizarAcompanante,
    InsertarHabitacion, InactivarHabitacion, ActualizarEstadoPaquete
} from '../../servicios/ServicioPaquete';
import { ObtenerSupervisores } from '../../servicios/ServicioSupervisor';
import { ObtenerVendedores } from '../../servicios/ServicioVendedor';
import { ObtenerFormasDePagos } from '../../servicios/ServicioFormasDePago';
import { ConfirmModal } from '../../components/ConfirmModal';
import '../../styles/PaqueteDeVieje.css'
import DocumentosPaqueteDeViajeComponet from '../Documentos/DocumentosPaqueteDeViaje';
import Formularioabonospaquetesdeviaje from './formularioabonospaquetesdeviaje';
import Notificar from '../Notificaciones/notificacion'

//ajustar import
import ModalComentarios from "../../components/Comentarios/ModalComentarios";
import ModalAgregarAcompanantes from '../../components/AgregarAcompanantes/ModalAgregarAcompanantes';

import ModalAgregarHabitacion from '../../components/PaqueteDeViaje/ModalAgregarHabitacion'
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'; //Reanudar Icono

//const PaqueteDeViajeComponet: React.FC<propPaqueteViaje> = ({ idPaquete } = propPaqueteViaje, proceso = 1, data) => {
const PaqueteDeViajeComponet = (proceso = 1, data) => {
    const [buscarTours, setBuscarTours] = useState(false);
    const [seguroClienteValor, setseguroClienteValor] = useState(false);
    const [buscarToursAcomapante, setbuscarToursAcomapante] = useState(false);
    const [IdPaqueteDeViaje, setIdPaqueteDeViaje] = useState(0);
    const [CampoSeguro, setCampoSeguro] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isSummitInfo, setIsSummitInfo] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [fechaLimitePago, setFechaLimitePago] = useState('');
    //MENSAJES DE ERROR
    const mensajeSeguro = { indicador: '1', mensaje: 'El campo de seguro requiere un valor mayor a 0' };
    const mensajeTourRepetido = { indicador: '1', mensaje: 'El tour ya fue agregado' };
    const mensajeAcompananteRepetido = { indicador: '1', mensaje: 'El acompanante ya fue agregado' };
    const mensajeAcompananteInsertado = { indicador: '0', mensaje: 'Se ha ingresado el acompañante correctamente' };
    const mensajeAcompananteComoCliente = { indicador: '1', mensaje: 'No puede ingresar el cliente como un acompañante' };
    const mensajeTourInsertado = { indicador: '0', mensaje: 'Se ha agregado el tour' };

    const mensajeHabitacionInsertada = { indicador: '0', mensaje: 'Se ha agregado la habitación' };


    const mensajeSinCliente = { indicador: '1', mensaje: 'No se ha agregado un cliente' };
    const mensajeFechaInvalida = {
        indicador: '1',
        mensaje: 'La fecha proporcionada no se encuentra entre la fecha de salida y la fecha de llegada de su paquete'
    };
    const mensajeCupos = {
        indicador: '1', mensaje: 'No hay suficientes cupos disponibles en el paquete'
    };
    const mensajeTourNoSeleccionado = {
        indicador: '1', mensaje: 'No ha seleccionado un paquete'
    };

    const mensajeComentarioError = {
        indicador: '1', mensaje: 'Para actualizar un comentario debe guardar el Paquete de Viaje'
    };

    const mensajeEliminarError = {
        indicador: '1', mensaje: 'No tienes permiso para Eliminar'
    };

    const mensajeEditarError = {
        indicador: '1', mensaje: 'No tienes permiso para Editar'
    };


    const mensajeToursError = {
        indicador: '1', mensaje: 'No tienes permiso para Agregar Tours'
    };

    const mensajeHabitacionError = {
        indicador: '1', mensaje: 'No tienes permiso para Agregar Habitaciones'
    };

    const mensajeSeguroError = {
        indicador: '1', mensaje: 'No tienes permiso para Agregar Seguros'
    };


    

    //CONTROL DE MODALES DE ELIMINACIÓN
    const [eliminaTour, setEliminaTour] = useState(false);
    const [eliminaTourAcompanante, setEliminaTourAcompanante] = useState(false);
    const [eliminaAcompanante, setEliminaAcompanante] = useState(false);
    const [tourEliminando, setTourEliminando] = useState({});
    const [acompaEliminando, setAcompaEliminando] = useState({});

    const [personaSeleccionada, setPersonaSeleccionada] = useState({});
    const [paqueteDeViajeSeleccionado, setPaqueteDeViajeSeleccionado] = useState({});
    const [acompananteSeleccionado, setAcompananteSeleccionado] = useState({});


    const [buscarPersonas, setBuscarPersonas] = useState(false);

    const [buscarPaquetesDeViaje, setBuscarPaquetesDeViaje] = useState(false);


    const [estadoSeguroClienteActivo, setEstadoSeguroClienteActivo] = useState(false);


    const [buscarAcompanante, setBuscarAcompanante] = useState(false);
    const [listaDeAcompanantes, setListaDeAcompanantes] = useState([]);

    const [agregarAcompanante, setAgregarAcompanante] = useState(false);
    const [agregarPersonas, setAgregarPersonas] = useState(false);


    //{ cantidadTours: 0, montoTours: 0, montoSeguro: 0, subtotal: 0 }
    const [resumenToursCliente, setResumenToursCliente] = useState([]);

    //{ cantidadTours: 0, montoTours: 0, seguros: 0, subtotal: 0 }
    const [resumenToursAcompanantes, setResumenToursAcompanantes] = useState([]);

    const [usuario, setUsuario] = useState('');

    const [dataTourDeViaje, setDataTourDeViaje] = useState({});
    const [procesoModalTour, setProcesoModalTour] = useState(1);
    const [modalTour, setModalTour] = useState(false);
    const [listaDeTour, setListaDeTour] = useState([]);
    const [listaDeTourAcompanante, setListaDeTourAcompanante] = useState([]);
    const [tourSeleccionado, setTourSeleccionado] = useState({});

    const [HabitacionEliminada, setHabitacionEliminada] = useState({});

    const [listaHabitacionesCliente, setListaHabitacionesCliente] = useState([]);

    const [listaHabitacionesAcompanante, setListaHabitacionesAcompanante] = useState([]);

    //Abonos
    const [mostrarFormularioAbono, setMostrarFormularioAbono] = useState(false);

    const [actualizarSaldo, setActualizarSaldo] = useState(false);

    // Estados
    const [descuento, setDescuento] = useState(0);
    const [seguro, setSeguro] = useState('');

    const [seguroInput, setSeguroInput] = useState(0);

    //cuposDisponibles
    const [cantidadCamposDisponibles, setcantidadCamposDisponibles] = useState('');

    // const [pendiente, setPendiente] = useState(false);
    const [listaDeComentarios, setListaDeComentarios] = useState([]);
    const [pendienteComentarios, setPendienteComentarios] = useState(false);

    // Estado para almacenar los costos
    const [costoPaquete, setCostoPaquete] = useState(0);
    const [costoTours, setCostoTours] = useState(0);
    const [costoAcompanantesPaquete, setCostoAcompanantesPaquete] = useState(0);
    const [costoAcompanantesTours, setCostoAcompanantesTours] = useState(0);
    const [costoSeguro, setCostoSeguro] = useState(0);
    const [costoSumarValorSeguro, setcostoSumarValorSeguro] = useState(0);
    const [costoSeguroAcompante, setcostoSeguroAcompante] = useState(0);
    const [costoTotal, setCostoTotal] = useState(0);
    const [numeroAcompanantes, setNumeroAcompanantes] = useState(0);
    const [numeroAcompanantesSeguro, setNumeroAcompanantesSeguro] = useState(0);

    const [showFormulario, setShowFormulario] = useState(true);

    const [showModalHabitacion, setShowModalHabitacion] = useState(true);

    const [TipoDeHabitacion, setTipoDeHabitacion] = useState("");
    const [MontoExtraHabitacion, setMontoExtraHabitacion] = useState(0);


    const { register: register, handleSubmit: handleSubmit, formState: { errors: errors }, trigger: trigger } = useForm();



    const { register: registerComentario, handleSubmit: handleSubmitComentario, formState: { errors: errorsComentario }, trigger: triggerComentario, setValue: setearValor } = useForm();
    const [isSummit, setIsSummit] = useState(false);
    //variables de combo box
    const [idTourDeViaje, setIdTourDeViaje] = useState(proceso == 2 ? data.idTourDeViaje : 0);
    //campos de form
    const [descripcion, setDescripcion] = useState(proceso == 2 ? data.descripcion : '');

    const [listaSupervisores, setListaSupervisores] = useState([]);
    const [listaVendedores, setListaVendedores] = useState([]);
    const [listaFormasDePago, setListaFormasDePago] = useState([]);

    const [idSupervisor, setIdSupervisor] = useState('');
    const [idVendedor, setIdVendedor] = useState('');
    const [idFormaDePago, setIdFormaDePago] = useState('');


    const [HabitacionPersonalizadaCliente, setHabitacionPersonalizadaCliente] = useState(false);
    const [HabitacionPersonalizadaAcompanante, setHabitacionPersonalizadaAcompanante] = useState(false);

    const [ActivarPaquete, setActivarPaquete] = useState(false);
    const [InactivarPaquete, setInactivarPaquete] = useState(false);
    const [EstadoPaquete, setEstadoPaquete] = useState(0);


    //Permisos generales
    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);



    //Permisos Paquete de viajes
    const [PermisoAbonoP, setPermisoAbonoP] = useState(false);
    const [PermisoSeguroCliente, setPermisoSeguroCliente] = useState(false);
    const [PermisoHabitacionCliente, setPermisoHabitacionCliente] = useState(false);
    const [PermisoTourCliente, setPermisoTourCliente] = useState(false);
    const [PermisoAcompanante, setPermisoAcompanante] = useState(false);
    const [PermisoTourAcompanante, setPermisoTourAcompanante] = useState(false);
    const [PermisoHabitacionAcompanante, setPermisoHabitacionAcompanante] = useState(false);
    const [PermisoSeguroAcompanante, setPermisoSeguroAcompanante] = useState(false);
    const [PermisoComentarioP, setPermisoComentarioP] = useState(false);
    const [PermisoDocumentoP, setPermisoDocumentoP] = useState(false);



    //ENCABEZADOS DE LOS GRIDS
    const encabezadoAcompanantes = [
        { id: 'IdAcompanantePaquete', name: 'id', selector: row => row.IdAcompanantePaquete, head: "id", omit: true },
        { id: 'NomAcompanante', name: 'Nombre', selector: row => row.NomAcompanante, head: "Nombre", sortable: true, width: '18%' },
        { id: 'NumIdentificacionAcompanante', name: 'Identificación', selector: row => row.numIdentificacionAcompanante, head: "Identificación", sortable: true, width: '22%' },
        {
            id: 'tour', name: 'Tour', head: "Tour", width: '10%',
            cell: (row) => (
                <Button variant="link" size="sm" onClick={() => onClickBuscarTourAcompanante(row)}>
                    <FontAwesomeIcon icon={faPlus} style={{ color: '#005cb8' }} />
                </Button>
            ),
        },
        {
            id: 'Habitacion', name: 'Habitacion', head: "Habitacion", width: '18%',
            cell: (row) => (
                <Button variant="link" size="sm" onClick={() => onClickHabitacionPersonalizadaAcompanante(row)}>
                    <FontAwesomeIcon icon={faPlus} style={{ color: '#005cb8', marginLeft: '18px' }} />
                </Button>
            ),
        },
        {
            id: 'seguro', name: 'Seguros', head: 'Seguros', width: '15%',
            cell: (row) => (
                <BootstrapSwitchButton
                    id={`switch${row.id}`}
                    onlabel="Sí"
                    offlabel="No"
                    checked={(row.Seguro > 0) ? true : false}
                    onChange={() => handleCheckboxSeguroAcompanante(row)}
                    size="xs"
                />
            ),
        },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar', width: '15%',
            cell: (row) => (
                <Button variant="link" size="sm" onClick={() => handleEliminarAcompanante(row)}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red', marginLeft: '7px' }} />
                </Button>
            ),
        },
    ];

    const encabezadoTour = [
        { id: 'id', name: 'id', selector: row => row.Id, head: "id", omit: true },
        { id: 'Descripcion', name: 'Descripción', selector: row => row.descripcion, head: "Descripción", sortable: true, width: '29%' },
        { id: 'NumIdentificacion', name: 'numIdentificacion', selector: row => row.numIdentificacion, head: "numIdentificacion", omit: true },
        { id: 'Pais', name: 'País', selector: row => row.pais, head: "País", sortable: true, width: '29%' },
        { id: 'monto', name: 'Monto', selector: row => `$${row.Monto}`, head: "Monto", sortable: true, width: '29%' },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar', width: '29%',
            cell: (row) => (
                <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <Button variant="link" size="sm" onClick={() => handleEliminarSoloTour(row)}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red', marginLeft: '10px' }} />
                    </Button>
                </div>
            ),
        },
    ];


    const encabezadoHabitaciones = [
        { id: 'IdPaquete', name: 'IdPaquete', selector: row => row.IdPaquete, head: "id", omit: true },
        { id: 'NombrePersona', name: 'Nombre', selector: row => row.NombrePersona, head: "NombrePersona", sortable: true, with: '60%' },
        { id: 'IdentificacionPersona', name: 'Identificacion', selector: row => row.IdentificacionPersona, head: "IdentificacionPersona", omit: true, with: '60%' },
        { id: 'TipoDeHabitacion', name: 'Tipo', selector: row => row.TipoDeHabitacion, head: "TipoDeHabitacion", sortable: true },
        { id: 'MontoExtra', name: 'Monto Extra', selector: row => `$${row.MontoExtra}`, head: "MontoExtra", sortable: true, width: '29%' },
        { id: 'Tipo', name: 'Tipo', selector: row => `$${row.Monto}`, head: "Monto", omit: true },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar', width: '20%',
            cell: (row) => (
                <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <Button variant="link" size="sm" onClick={() => inactivarHabitacion(row)}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red', marginLeft: '10px' }} />
                    </Button>
                </div>
            ),
        },
    ];

    const encabezadoHabitacionesAcompanantes = [
        { id: 'IdPaquete', name: 'IdPaquete', selector: row => row.IdPaquete, head: "id", omit: true },
        { id: 'NombrePersona', name: 'Nombre', selector: row => row.NombrePersona, head: "NombrePersona", sortable: true, with: '15%' },
        { id: 'IdentificacionPersona', name: 'Identificacion', selector: row => row.IdentificacionPersona, head: "IdentificacionPersona", sortable: true, with: '35%' },
        { id: 'TipoDeHabitacion', name: 'Tipo', selector: row => row.TipoDeHabitacion, head: "TipoDeHabitacion", sortable: true, with: '5%' },
        { id: 'MontoExtra', name: 'Monto Extra', selector: row => `$${row.MontoExtra}`, head: "MontoExtra", sortable: true, width: '22%' },
        { id: 'Tipo', name: 'Tipo', selector: row => `$${row.Monto}`, head: "Tipo", omit: true },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar', width: '15%',
            cell: (row) => (
                <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <Button variant="link" size="sm" onClick={() => inactivarHabitacionAcompanante(row)}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red', marginLeft: '10px' }} />
                    </Button>
                </div>
            ),
        },
    ];

    //--------------------------------------------------------------------------------------------------------------





    const encabezadoTourAcompanante = [
        { id: 'id', name: 'id', selector: row => row.Id, head: "id", omit: true },
        { id: 'NomAcompanantes', name: 'Nombre', selector: row => row.NomAcompanante, head: "Nombre", sortable: true, width: '22%' },
        { id: 'IdAcompanante', name: 'IdAcompanante', selector: row => row.numIdentificacion, head: "Identificacion", omit: true },
        { id: 'Descripcion', name: 'Descripción', selector: row => row.descripcion, head: "Descripción", sortable: true, width: '22%' },
        { id: 'Pais', name: 'País', selector: row => row.pais, head: "País", sortable: true, width: '22%' },
        { id: 'monto', name: 'Monto', selector: row => `$${row.Monto}`, head: "Monto", sortable: true, width: '22%' },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar',
            cell: (row) => (
                <div style={{ width: '22%', paddingRight: '0px', borderRight: 'none' }}>
                    <Button variant="link" size="sm" onClick={() => handleEliminarSoloTourAcompanante(row)}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red', marginLeft: '5px' }} />
                    </Button>
                </div>
            ),

        },
    ];

    // Function to handle editing

    const [showModal, setShowModal] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);
    const [idComentarioPaquete] = useState(null);
    const [idUsuario, setIdUsuario] = useState(null);
    const [respuesta, setRespuesta] = useState(false);

    useEffect(() => {
        ObtenerIdUsuario();
        ObtenerPermisosUsuario();
    }, []);


    useEffect(() => {
        obtenerDatosDePaquete();
    }, [actualizarSaldo]);



    const ObtenerIdUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        if (usuario) {
            setIdUsuario(String(usuario.idUsuario));
        }
    };

    //evento de clic en el comentario
    const handleCommentClick = (comment) => {
        // Verificar que idUsuario esté inicializado antes de usarlo


        if (!PermisoActualizar) {
            setMensajeRespuesta(mensajeEditarError)
            setShowAlert(true);
        }
        else {

            if (comment.idComentarioPaquete) {

                if (idUsuario) {
                    setSelectedComment({
                        ...comment,
                        idUsuario: idUsuario,
                        //IndicadorPlan: 2,
                    });
                    setShowModal(true);
                }
            }
            else {
                setMensajeRespuesta(mensajeComentarioError);
                setShowAlert(true);

            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedComment(null);
    };

    const handleSaveComment = async () => {
        try {


            const response = await ActualizarComentarioPaquete(selectedComment);

            if (response.indicador === 0) {
                handleResponse(response);
            }

            setRespuesta(response);
            handleModalClose();
            return

        } catch (error) {
            console.error('Error al actualizar el comentario:', error);

        }
    };

    const handleResponse = (handleResponse) => {

        if (handleResponse.indicador == 0) {

            setMensajeRespuesta(handleResponse.mensaje);
            if (handleResponse.indicador == 0) {
                setMensajeRespuesta(handleResponse);

            }

            setShowAlert(true);
            obtenerDatosDePaquete();
        }
    };


    const encabezadoComentarios = [
        {
            id: "Comentario",
            name: "Descripción Comentario",
            selector: (row) => (
                <div onClick={() => handleCommentClick(row)}>
                    {row.Comentario}
                </div>
            ),
            head: "Comentario",
            sortable: true,
        },
        {
            id: "FechaCreacion",
            name: "Fecha Creación",
            selector: (row) => (
                <div onClick={() => handleCommentClick(row)}>
                    {row.FechaCreacion}
                </div>
            ),
            head: "Fecha Creación",
            width: '150px',
        },
    ];


    //Monto paquete - Monto Tours - Seguro - Total
    const encabezadoResumenToursCliente = [
        { id: 'paquete', name: 'Paquete', selector: row => row.montoPaquete, head: "Monto Paquete" },
        { id: 'monto', name: 'Tours', selector: row => row.montoTours, head: "Monto Tours" },
        { id: 'seguro', name: 'Seguro', selector: row => row.montoSeguro, head: "Seguro" },
        { id: 'Habitaciones', name: 'Habitaciones', selector: row => row.montoHabitaciones, head: "Habitaciones" },
        { id: 'subtotal', name: 'Total', selector: row => row.subtotal, head: "Total" },
    ]

    const encabezadoResumenToursAcompanantes = [
        { id: 'cantidad', name: 'Paquete', selector: row => row.montoPaquete, head: "Paquete" },
        { id: 'monto', name: 'Tours', selector: row => row.montoTours, head: "Tours" },
        { id: 'seguros', name: 'Seguros', selector: row => row.seguros, head: "Seguros" },
        { id: 'Habitaciones', name: 'Habitaciones', selector: row => row.montoHabitaciones, head: "Habitaciones" },
        { id: 'subtotal', name: 'Total', selector: row => row.subtotal, head: "Total" },
    ]

    //CAMBIO EN LISTAS
    const onChangeSupervisor = (e) => {
        setIdSupervisor(Number(e.target.value));
    };

    const onChangeVendedor = (e) => {
        setIdVendedor(Number(e.target.value));
    };

    const onChangeFormaDePago = (e) => {
        if (Object.keys(paqueteDeViajeSeleccionado).length === 0) {
            // El objeto paqueteDeViajeSeleccionado está vacío
            setIdFormaDePago(0);
            setMensajeRespuesta(mensajeTourNoSeleccionado);
            setShowAlert(true);
            return;
        }
        setIdFormaDePago(Number(e.target.value));


        let montoNuevo = 0;
        if (Number(e.target.value) > 0) {
            montoNuevo = (Number(e.target.value) === 2)
                ? paqueteDeViajeSeleccionado.precioContado : paqueteDeViajeSeleccionado.precioPlazos;
        }


        const sumaMontoExtra = listaHabitacionesCliente.reduce((total, habitacion) => {
            return Number(total) + Number(habitacion.MontoExtra);
        }, 0);


        if (resumenToursCliente[0]) { //SI YA SE ESTÁ MOSTRANDO EL RESUMEN, HAY QUE ACTUALIZARLO
            setResumenToursCliente([{
                ...resumenToursCliente[0],
                montoPaquete: Number(montoNuevo),
                montoHabitaciones: Number(resumenToursCliente[0].montoHabitaciones) ?? 0 + Number(sumaMontoExtra),
                subtotal: Number(montoNuevo) + Number(resumenToursCliente[0].montoTours)
                    + Number(resumenToursCliente[0].montoSeguro) + Number(sumaMontoExtra)
            }]);
        } else { //SI NO, HAY QUE CREARLO
            setResumenToursCliente([{
                montoPaquete: Number(montoNuevo),
                montoTours: 0,
                montoSeguro: 0,
                montoHabitaciones: Number(sumaMontoExtra),
                subtotal: Number(montoNuevo) + Number(sumaMontoExtra)
            }]);
        }

        const nuevoMontoAcompanantes = Number(montoNuevo) * (numeroAcompanantes);
        if (resumenToursAcompanantes[0]) { //SI YA EXISTE EL RESUMEN DE ACOMPAÑANTES, SE ACTUALIZA
            setResumenToursAcompanantes([{
                ...resumenToursAcompanantes[0],
                montoPaquete: nuevoMontoAcompanantes,
                montoHabitaciones: resumenToursAcompanantes[0].montoHabitaciones,
                subtotal: resumenToursAcompanantes[0].subtotal - resumenToursAcompanantes[0].montoPaquete
                    + nuevoMontoAcompanantes
            }]);
        } else { //SI NO, SE CREA
            setResumenToursAcompanantes([{
                ...resumenToursAcompanantes[0],
                montoPaquete: nuevoMontoAcompanantes,
                montoTours: 0,
                seguros: 0,
                montoHabitaciones: 0,
                subtotal: nuevoMontoAcompanantes
            }]);

        }
    };


    //LLAMADO A SERVICIOS

    const ObtenerNombreUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setUsuario(String(usuario.idUsuario));
    }

    const ObtenerListadoDeSupervisores = async () => {
        const Supervisores = await ObtenerSupervisores();
        var data = Supervisores.map(
            supervisor => (
                { idSupervisor: supervisor.idSupervisor, nombre: supervisor.nombre + ' ' + supervisor.primerApellido })
        );
        if (Supervisores !== undefined) {
            setListaSupervisores(data);
        }
    }

    const ObtenerListadoDeVendedores = async () => {
        const Vendedores = await ObtenerVendedores();
        var data = Vendedores.map(
            vendedor => ({ idVendedor: vendedor.idVendedor, nombre: vendedor.nombre + ' ' + vendedor.primerApellido })
        );
        if (Vendedores !== undefined) {
            setListaVendedores(data);
        }
    }

    const ObtenerListadoFormasDePago = async () => {
        const formasDePago = await ObtenerFormasDePagos();
        var data = formasDePago.map(
            formaDePago => ({ idFormaDePago: formaDePago.id, nombre: formaDePago.descripcion })
        );
        if (formasDePago !== undefined) {
            setListaFormasDePago(data);
        }
    }

    const obtenerDatosDePaquete = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const idPaquete = urlParams.get('idPaquete');

        if (idPaquete != null) {

            const datosPaquete = await ObtenerPaquetePorId(idPaquete);

            cargarValores(datosPaquete);

            setIdPaqueteDeViaje(idPaquete);

        }
    };

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));


        //Generales
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false);
        setPermisoEliminar(usuario.PermisoEliminar ?? false);

        //Paquete de viajes
        setPermisoAbonoP(usuario.PermisoAbonoP ?? false);
        setPermisoSeguroCliente(usuario.PermisoSeguroCliente ?? false);
        setPermisoHabitacionCliente(usuario.PermisoHabitacionCliente ?? false);
        setPermisoTourCliente(usuario.PermisoTourCliente ?? false);
        setPermisoAcompanante(usuario.PermisoAcompanante ?? false);
        setPermisoTourAcompanante(usuario.PermisoTourAcompanante ?? false);
        setPermisoHabitacionAcompanante(usuario.PermisoHabitacionAcompanante ?? false);
        setPermisoSeguroAcompanante(usuario.PermisoSeguroAcompanante ?? false);
        setPermisoComentarioP(usuario.PermisoComentarioP ?? false);
        setPermisoDocumentoP(usuario.PermisoDocumentoP ?? false);

    }



    const registrarPaquete = async (DataPaquete, mostrarMensaje) => {


        DataPaquete.Seguro = Number(CampoSeguro) ?? 0;


        if (paqueteCargado()) { //SE ESTÁ ACTUALIZANDO
            DataPaquete.actualizando = true;
            DataPaquete.IdPaquete = IdPaqueteDeViaje;
        } else {
            DataPaquete.actualizando = false;
        }

        var respuesta = await AgregarPaquete(DataPaquete);
        obtenerDatosDePaquete();
        if (respuesta.indicador == 0) {
            if (!paqueteCargado()) {
                setIdPaqueteDeViaje(respuesta.lastInserted); //DEVUELVE EL ID DEL PAQUETE INSERTADO
                /*actualizarCuposDisponibles(listaDeAcompanantes.length + 1); //CANTIDAD DE ACOMPAÑANTES + EL CLIENTE*/
                window.location.href = "PaqueteDeViaje?idPaquete=" + respuesta.lastInserted;
            }
        }

        if (mostrarMensaje) {

            setMensajeRespuesta(respuesta);
            setShowAlert(true);
        }

        return respuesta;
    }

    const registrarComentarioPaquete = async (comentario) => {
        const respuesta = await AgregarComentarioPaquete(comentario);
        setMensajeRespuesta(respuesta);
        setShowAlert(true);
        return respuesta;
    }


    const registrarAcompanante = async (acompanante) => {


        const respuesta = await InsertarAcompanante(acompanante);

        setMensajeRespuesta(respuesta);
        setShowAlert(true);

        if (respuesta.indicador == 0) { //ACOMPAÑANTE INSERTADO
            //var DataPaquete = DatosPaquete();
            //DataPaquete.CostoTotal = Number(resumenToursCliente[0].subtotal) + 
            //    Number((idFormaDePago == 1) ? paqueteDeViajeSeleccionado.precioContado : paqueteDeViajeSeleccionado.precioPlazos);
            const segundaRespuesta = true;
            obtenerDatosDePaquete();
            if (segundaRespuesta.indicador == 0) { //COSTO TOTAL ACTUALIZADO
                /*actualizarCuposDisponibles(-1);*/
                return true;
            }
        } else {
            return false;
        }


    }

    const actualizarAcompanante = async (acompanante) => {
        const respuesta = await ActualizarAcompanante(acompanante);
        setMensajeRespuesta(respuesta);
        setShowAlert(true);
        if (respuesta.indicador == 0) { //ACOMPAÑANTE ACTUALIZADO
            var DataPaquete = DatosPaquete();
            //DataPaquete.CostoTotal = DataPaquete.CostoTotal + (acompanante.Seguro > 0 ? acompanante.Seguro : -CampoSeguro);
           
            const segundaRespuesta = await registrarPaquete(DataPaquete, true);
            obtenerDatosDePaquete();
            if (segundaRespuesta.indicador == 0) { //COSTO TOTAL ACTUALIZADO
                /*actualizarCuposDisponibles(-1);*/
                return true;
            }
        } else {
            return false;
        }
    }







    const eliminarAcompanante = async (acompanante) => {


        var respuesta = await InactivarAcompanante(acompanante);
        setMensajeRespuesta(respuesta);
        setShowAlert(true);

        if (respuesta.indicador == 0) { //ACOMPAÑANTE ELIMINADO

            const totalToursEliminados = listaDeTourAcompanante.reduce((total, tourAcompanante) => { //TOTAL POR TOURS
                if (tourAcompanante.numIdentificacion === acompaEliminando.identificacion) {
                    return Number(total) + Number(tourAcompanante.Monto);
                } else {
                    return Number(total);
                }
            }, 0);

            const totalHabitacionesEliminadas = listaHabitacionesAcompanante.reduce((total, habitacion) => { //TOTAL POR TOURS
                if (habitacion.IdentificacionPersona === acompanante.NumIdentificacionAcompanante) {
                    return Number(total) + Number(habitacion.MontoExtra);
                } else {
                    return Number(total);
                }
            }, 0);


            const totalSeguro = Number(acompanante.Seguro);

            //var DataPaquete = DatosPaquete();
            //DataPaquete.CostoTotal = DataPaquete.CostoTotal - costoPaquete - totalToursEliminados - totalSeguro - totalHabitacionesEliminadas ?? 0;
            obtenerDatosDePaquete();
            var DataPaquete = DatosPaquete();
            const segundaRespuesta = await registrarPaquete(DataPaquete, false);
            if (segundaRespuesta.indicador == 0) { //COSTO TOTAL ACTUALIZADO
               /* actualizarCuposDisponibles(1);*/
                return true;
            }
        } else {
            return false;
        }
    }

    const registrarTour = async (tour) => {

        var respuesta = await InsertarTour(tour);

        setMensajeRespuesta(respuesta);
        setShowAlert(true);

        if (respuesta.indicador == 0) { //TOUR AGREGADO
            var DataPaquete = DatosPaquete();
            DataPaquete.CostoTotal = Number(DataPaquete.CostoTotal) + Number(tour.Monto);
            const segundaRespuesta = await registrarPaquete(DataPaquete, false);  //ACTUALIZAR COSTO TOTAL

            return (segundaRespuesta.indicador == 0) ? true : false;
        } else {
            return false;
        }
    }


    const registrarHabitacion = async (HabitacionNueva) => {


        var respuesta = await InsertarHabitacion(HabitacionNueva);

        setMensajeRespuesta(respuesta);
        setShowAlert(true);

        if (respuesta.indicador === 0) { //HABITACION AGREGADA
            var DataPaquete = DatosPaquete();
            DataPaquete.CostoTotal = parseFloat(DataPaquete.CostoTotal) + parseFloat(HabitacionNueva.MontoExtra);
            var segundaRespuesta = await registrarPaquete(DataPaquete, true);  //ACTUALIZAR COSTO TOTAL
            return (segundaRespuesta.indicador == 0) ? true : false;

        } else {
            return false;
        }
    }



    const inactivarTour = async (tourEliminar) => {

        var respuesta = await InactivarTour(tourEliminar);
        setMensajeRespuesta(respuesta);
        if (respuesta.indicador === 1) {

        }
        setShowAlert(true);

        if (respuesta.indicador == 0) { //TOUR ELIMINADO
            var DataPaquete = DatosPaquete();
            DataPaquete.CostoTotal = DataPaquete.CostoTotal - tourEliminar.Monto;
            const segundaRespuesta = await registrarPaquete(DataPaquete, false); //ACTUALIZAR COSTO TOTAL

            return (segundaRespuesta.indicador == 0) ? true : false;
        } else {
            return false;
        }
    }

    const inactivarHabitacion = async (habitacionEliminar) => {


        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {
            var respuesta = await InactivarHabitacion(habitacionEliminar);
            setMensajeRespuesta(respuesta);
            if (respuesta.indicador === 1) {

            }


            setShowAlert(true);

            if (respuesta.indicador == 0) { //TOUR ELIMINADO
                var DataPaquete = DatosPaquete();


                const indexToRemove = listaHabitacionesCliente.findIndex((habitacion) =>
                    habitacion.NombrePersona === habitacionEliminar.NombrePersona &&
                    habitacion.IdentificacionPersona === habitacionEliminar.IdentificacionPersona &&
                    habitacion.TipoDeHabitacion === habitacionEliminar.TipoDeHabitacion
                );

                // Filtra la lista excluyendo el índice encontrado
                let HabitacionesFiltradas = [...listaHabitacionesCliente];
                if (indexToRemove !== -1) {
                    HabitacionesFiltradas.splice(indexToRemove, 1);
                }

                const cantidadTemp = resumenToursCliente[0].cantidadTours;

                const nuevoResumen = [{
                    ...resumenToursCliente[0],
                    montoHabitaciones: Number(resumenToursCliente[0].montoHabitaciones ?? 0) - Number(habitacionEliminar.MontoExtra ?? 0),
                    subtotal: Number(resumenToursCliente[0].subtotal) - Number(habitacionEliminar.MontoExtra ?? 0)
                }];

                setResumenToursCliente(nuevoResumen);
                setListaHabitacionesCliente(HabitacionesFiltradas);

                DataPaquete.CostoTotal = DataPaquete.CostoTotal - Number(habitacionEliminar.MontoExtra ?? 0);

                setMontoExtraHabitacion(MontoExtraHabitacion - Number(habitacionEliminar.MontoExtra));
                const segundaRespuesta = await registrarPaquete(DataPaquete, false); //ACTUALIZAR COSTO TOTAL

                return (segundaRespuesta.indicador == 0) ? true : false;
            } else {
                return false;
            }
        }
    }

    const inactivarHabitacionAcompanante = async (habitacionEliminar) => {


        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {



            var respuesta = await InactivarHabitacion(habitacionEliminar);
            setMensajeRespuesta(respuesta);
            if (respuesta.indicador === 1) {

            }

            setShowAlert(true);

            if (respuesta.indicador == 0) { //TOUR ELIMINADO
                var DataPaquete = DatosPaquete();


                const indexToRemove = listaHabitacionesAcompanante.findIndex((habitacion) =>
                    habitacion.NombrePersona === habitacionEliminar.NombrePersona &&
                    habitacion.IdentificacionPersona === habitacionEliminar.IdentificacionPersona &&
                    habitacion.TipoDeHabitacion === habitacionEliminar.TipoDeHabitacion
                );

                // Filtra la lista excluyendo el índice encontrado
                let HabitacionesFiltradasAcompa = [...listaHabitacionesAcompanante];
                if (indexToRemove !== -1) {
                    HabitacionesFiltradasAcompa.splice(indexToRemove, 1);
                }


                const cantidadTemp = resumenToursAcompanantes[0].cantidadTours;

                const nuevoResumen = [{
                    ...resumenToursAcompanantes[0],
                    montoHabitaciones: Number(resumenToursAcompanantes[0].montoHabitaciones ?? 0) - Number(habitacionEliminar.MontoExtra),
                    subtotal: Number(resumenToursAcompanantes[0].subtotal) - Number(habitacionEliminar.MontoExtra)
                }];

                setResumenToursAcompanantes(nuevoResumen);
                setListaHabitacionesAcompanante(HabitacionesFiltradasAcompa);


                DataPaquete.CostoTotal = DataPaquete.CostoTotal - Number(habitacionEliminar.MontoExtra);


                setMontoExtraHabitacion(MontoExtraHabitacion - Number(habitacionEliminar.MontoExtra));
                const segundaRespuesta = await registrarPaquete(DataPaquete, false); //ACTUALIZAR COSTO TOTAL

                return (segundaRespuesta.indicador == 0) ? true : false;
            } else {
                return false;
            }
        }
    }



    const cargarValores = (Datos) => {


        //HabitacionesGrupo
        const HabitacionesGrupo = Object.values(Datos.habitacionesGrupo); //CONVERTIR A UNA LISTA

        const HabitacionesCliente = HabitacionesGrupo.filter(
            (habitacion) => habitacion.tipo != "Acompañante"
        );
        const HabitacionesAcompanante = HabitacionesGrupo.filter(
            (habitacion) => habitacion.tipo == "Acompañante"

        );

        CargarHabitacionesCliente(HabitacionesCliente);
        CargarHabitacionesAcompanante(HabitacionesAcompanante);

        //toursGrupo
        const toursCargadosSinFormato = Object.values(Datos.toursGrupo); //CONVERTIR A UNA LISTA

        const toursCliente = toursCargadosSinFormato.filter(
            (tour) => tour.tipo != "Acompañante"
        );
        const toursAcompa = toursCargadosSinFormato.filter(
            (tour) => tour.tipo == "Acompañante"
        );

        CargarToursCliente(toursCliente);
        CargarToursAcompanante(toursAcompa);

        setEstadoPaquete(Datos.estado ? 1 : 0);

        var persona = {
            primerApellido: Datos.primerApellido,
            nombre: Datos.nombre,
            segundoApellido: Datos.segundoApellido,
            identificacion: Datos.identificacion,
            telefono: Datos.telefono,
            idPersona: Datos.idPersona,
            provincia: Datos.provincia,
            canton: Datos.canton,
            distrito: Datos.distrito,
            direccion: Datos.direccion,
            correoElectronico: Datos.correoElectronico
        }

        CargarCliente(persona);

        var paquete = {
            id: Datos.idPaqueteDeViaje,
            descripcion: Datos.descripcion,
            precioContado: Datos.precioContado,
            precioPlazos: Datos.precioPlazos,
            cantidadCampos: Datos.cantidadCampos,
            cantidadCamposDisponibles: Datos.cantidadCamposDisponibles,
            cantidadGuias: Datos.cantidadGuias,

            cantidadAcompañantes: Datos.cantidadAcompañantes,

            fechaSalida: Datos.fechaSalida,
            fechaLLegada: Datos.fechaLLegada,
            saldo: Datos.saldo,
            descuento: Datos.descuento || 0,
            tipoPago: Datos.tipoPago,
            seguro: Datos.seguro,

            estadoSeguro: Datos.estadoSeguro,

            idSupervisor: Datos.idSupervisor,
            idVendedor: Datos.idVendedor,
            fechaLimitePago: Datos.fechaLimitePago
        }

        CargarPaquete(paquete);


        CrearResumenCliente(toursCliente, paquete, HabitacionesCliente);

        const acompas = Object.values(Datos.acompanantes);

        const acompasFormateados =
            acompas.map((acompa) => {
                return {
                    IdAcompanantePaquete: acompa.idAcompanantePaquete,
                    IdPaquete: acompa.idPaquete,
                    numIdentificacionAcompanante: acompa.numIdentificacionAcompanante,
                    NomAcompanante: acompa.nomAcompanante,
                    Seguro: acompa.seguro
                };
            });

        if (acompasFormateados.length > 0) { //SOLO SI HAY ACOMPAÑANTES PARA EL PAQUETE
            CrearResumenAcompanantes(acompasFormateados, toursAcompa, paquete, HabitacionesAcompanante);
            setNumeroAcompanantes(acompasFormateados.length);
        }


        const comentarios = Object.values(Datos.comentarios);
        CargarComentarios(comentarios);
    }

    const CargarCliente = (persona) => {
        setPersonaSeleccionada(persona);
    }

    const CargarToursCliente = (toursCliente) => {
        const toursClienteFormateados =
            toursCliente.map((tour) => {
                return {
                    IdPaquete: tour.idPaquete,

                    numIdentificacion: tour.numIdentificacionPersona,
                    IdTourDeViaje: tour.idTourDeViaje,
                    Monto: tour.monto,
                    Fecha: tour.fecha,
                    descripcion: tour.descripcion,
                    pais: tour.pais,
                    Tipo: tour.tipo
                };
            });
        setListaDeTour(toursClienteFormateados);
    }


    //habitacionesGrupo
    const CargarHabitacionesCliente = (HabitacionesCliente) => {

        const HabitacionesClienteFormateados =
            HabitacionesCliente.map((data) => {
                return {
                    IdPaquete: data.idPaquete,
                    TipoDeHabitacion: data.tipoDeHabitacion,
                    NombrePersona: data.nombrePersona,
                    IdentificacionPersona: data.identificacionPersona,
                    MontoExtra: data.montoExtra,

                    Tipo: data.tipo
                };
            });

        setListaHabitacionesCliente(HabitacionesClienteFormateados)
    }


    const CargarHabitacionesAcompanante = (HabitacionesAcompanante) => {
        const HabitacionesAcompananteFormateados =
            HabitacionesAcompanante.map((data) => {
                return {
                    IdPaquete: data.idPaquete,
                    TipoDeHabitacion: data.tipoDeHabitacion,
                    NombrePersona: data.nombrePersona,
                    IdentificacionPersona: data.identificacionPersona,
                    MontoExtra: data.montoExtra,
                    Tipo: data.tipo
                };
            });
        setListaHabitacionesAcompanante(HabitacionesAcompananteFormateados);
    }


    const CargarToursAcompanante = (toursAcompa) => {
        const toursAcompaFormateados =
            toursAcompa.map((tour) => {
                return {
                    idPaquete: tour.idPaquete,
                    numIdentificacion: tour.numIdentificacionPersona,
                    IdTourDeViaje: tour.idTourDeViaje,
                    Monto: tour.monto,
                    Fecha: tour.fecha,
                    descripcion: tour.descripcion,
                    pais: tour.pais,
                    NomAcompanante: tour.nombre,
                    Tipo: tour.tipo
                };
            });
        setListaDeTourAcompanante(toursAcompaFormateados);

    }

    const CargarPaquete = (paquete) => {
        setDescuento(Number(paquete.descuento));
        setSeguro(Number(paquete.seguro));

        setEstadoSeguroClienteActivo(paquete.estadoSeguro)

        /*setcantidadCamposDisponibles(paquete.cantidadCamposDisponibles);*/


        setIdSupervisor(paquete.idSupervisor);
        setIdVendedor(paquete.idVendedor);

        setIdFormaDePago(paquete.tipoPago);

        if (paquete.descuento === null || paquete.descuento === '') {
            paquete.descuento = 0
        }
        setPaqueteDeViajeSeleccionado(paquete);
        setFechaLimitePago(paquete.fechaLimitePago)
    }

    const CrearResumenCliente = (toursCliente, paquete, HabitacionesCliente) => {
        let montoPaqueteCargado = (paquete.tipoPago === 2) ? paquete.precioContado : paquete.precioPlazos;
        let montoSeguro = 0;



        setCampoSeguro(paquete.seguro);
        if (paquete.seguro > 0 && paquete.estadoSeguro) { //SI EL CLIENTE TRAE SEGURO

            setseguroClienteValor(true);
            montoSeguro = paquete.seguro;
        }

        const costoTours = toursCliente.reduce((total, tour) => { //COSTO DE TOURS DEL CLIENTE (PUEDE SER 0)
            return Number(total) + Number(tour.monto);
        }, 0);

        const sumaMontoExtra = HabitacionesCliente.reduce((total, habitacion) => {
            return Number(total) + Number(habitacion.montoExtra);
        }, 0);

        const nuevoResumen = [{
            montoPaquete: montoPaqueteCargado,
            montoTours: costoTours,
            montoSeguro: montoSeguro,
            montoHabitaciones: sumaMontoExtra,
            subtotal: Number(montoPaqueteCargado) + Number(montoSeguro) + Number(costoTours) + Number(sumaMontoExtra ?? 0)
        }];

        setResumenToursCliente(nuevoResumen);
    }


    const CrearResumenAcompanantes = (acompanantes, toursAcompa, paquete, HabitacionesAcompanantes) => {

        setListaDeAcompanantes(acompanantes);

        let montoPaqueteCargado = (paquete.tipoPago === 2) ? paquete.precioContado : paquete.precioPlazos;

        const costoTours = toursAcompa.reduce((total, tour) => { //COSTO TOTAL DE TOURS DE ACOMPAÑANTES (PUEDE SER 0)
            return Number(total) + Number(tour.monto);
        }, 0);

        const montoSeguros = acompanantes.reduce((total, acompa) => { //MONTO TOTAL DE LOS SEGUROS DE ACOMPAÑANTES (PUEDE SER 0)
            if (acompa.Seguro > 0) {
                return total + Number(acompa.Seguro);
            } else {
                return total;
            }
        }, 0);

        const sumaMontoExtra = HabitacionesAcompanantes.reduce((total, habitacion) => {
            return Number(total) + Number(habitacion.montoExtra);
        }, 0);


        const nuevoResumen = [{
            montoPaquete: montoPaqueteCargado * acompanantes.length,
            montoTours: costoTours,
            seguros: montoSeguros,
            montoHabitaciones: sumaMontoExtra,
            subtotal: Number(montoPaqueteCargado * acompanantes.length)
                + Number(montoSeguros) + Number(costoTours)
                + Number(sumaMontoExtra ?? 0)
        }];
        setResumenToursAcompanantes(nuevoResumen);
    }

    const CargarComentarios = (coments) => {
        const comentarios =
            coments.map((comentario) => {
                return {
                    idPaquete: comentario.idPaquete,
                    idComentarioPaquete: comentario.idComentarioPaquete,
                    Comentario: comentario.comentario,
                    FechaCreacion: comentario.fechaCreacion,
                };
            });
        setListaDeComentarios(comentarios);
    }

    useEffect(() => {
        obtenerDatosDePaquete();
        ObtenerListadoDeSupervisores();
        ObtenerListadoDeVendedores();
        ObtenerListadoFormasDePago();
        ObtenerNombreUsuario();
    }, []);

    //SI SE ESCOGE UNA FORMA DE PAGO, SE CAMBIA EL COSTO DEL PAQUETE (PARA RESUMEN DE COSTOS)
    useEffect(() => {
        if (Number(idFormaDePago) > 0) {
            setCostoPaquete(idFormaDePago == 1
                ? paqueteDeViajeSeleccionado.precioContado : paqueteDeViajeSeleccionado.precioPlazos);
        }

    }, [idFormaDePago, paqueteDeViajeSeleccionado]);

    useEffect(() => { //NO MOSTRAR EL RESUMEN SI NO HAY MONTOS
        if (resumenToursCliente[0]) {
            if (resumenToursCliente[0].montoPaquete == 0
                && resumenToursCliente[0].montoTours == 0
                && resumenToursCliente[0].montoHabitaciones == 0
                && resumenToursCliente[0].montoSeguro == 0) {
                setResumenToursCliente([]);
            }
        }
    }, [resumenToursCliente]);

    useEffect(() => { //NO MOSTRAR EL RESUMEN SI NO HAY MONTOS
        if (resumenToursAcompanantes[0]) {
            if (resumenToursAcompanantes[0].montoPaquete == 0
                && resumenToursAcompanantes[0].montoTours == 0
                && resumenToursAcompanantes[0].montoHabitaciones == 0
                && resumenToursAcompanantes[0].seguros == 0) {
                setResumenToursAcompanantes([]);
            }
        }
    }, [resumenToursAcompanantes]);


    //MANEJO DE MODALES

    const onClickCerrarBuscarPersonas = () => {
        setBuscarPersonas(false);
    }

    const onClickAceptarBuscarPersonas = (persona) => {
        setPersonaSeleccionada({ ...persona });
        setBuscarPersonas(!buscarPersonas);
        setListaDeTour([]);
    }

    const onClickBuscarPersona = () => {
        setBuscarPersonas(!buscarPersonas);
        setShowFormulario(false);
    }

    const onClickCerrarBuscarPaquetesDeViaje = () => {
        setBuscarPaquetesDeViaje(false);
        setShowFormulario(true);
    }

    const onClickAceptarPaquetesDeViaje = (paqueteDeViaje) => {
        setFechaLimitePago('');
        setIdFormaDePago(0);
        setResumenToursCliente([]);
        setPaqueteDeViajeSeleccionado({ ...paqueteDeViaje });
        setBuscarPaquetesDeViaje(!buscarPaquetesDeViaje);
    }

    const onClickBuscarPaquetesDeViaje = () => {
        setBuscarPaquetesDeViaje(!buscarPaquetesDeViaje);
        setShowFormulario(false);

    }


    const onClickAgregarAcompanante = () => {

        setAgregarAcompanante(!agregarPersonas);
        setShowFormulario(false);
    }

    const onClickCerrarBuscarAcompanante = () => {
        setBuscarAcompanante(false);
        setShowFormulario(true);
    }

    const onClickCerrarAgregarAcompanante = () => {
        setAgregarAcompanante(false);
        setShowFormulario(true);
    }



    //Para abrir los modales
    const onClickHabitacionPersonalizadaCliente = () => {
        if (Object.keys(personaSeleccionada).length > 0) { //DEBE EXISTIR UNA PERSONA SELECCIONADA PARA AGREGAR TOUR
            setHabitacionPersonalizadaCliente(true);
        } else {
            setShowAlert(true);
            setMensajeRespuesta(mensajeSinCliente)
        }
    }
    const onClickHabitacionPersonalizadaAcompanante = async (acompanante) => {

        if (!PermisoHabitacionAcompanante) {
            setMensajeRespuesta(mensajeHabitacionError)
            setShowAlert(true);
        }
        else {
            setAcompananteSeleccionado({ ...acompanante })
            setHabitacionPersonalizadaAcompanante(true);
        }
    }
    //====================================================================================


    const onClickCerrarHabitacionPersonalizadaCliente = () => {
        setHabitacionPersonalizadaCliente(false);
    }

    const onClickCerrarHabitacionPersonalizadaAcompanante = () => {
        setHabitacionPersonalizadaAcompanante(false);
    }


    const onClickBuscarTour = () => {
        if (Object.keys(personaSeleccionada).length > 0) { //DEBE EXISTIR UNA PERSONA SELECCIONADA PARA AGREGAR TOUR
            setBuscarTours(true);
            setShowFormulario(false);
        } else {
            setShowAlert(true);
            setMensajeRespuesta(mensajeSinCliente)
        }
    }

    const onClickBuscarTourAcompanante = async (acompanante) => {

        if (!PermisoTourAcompanante) {
            setMensajeRespuesta(mensajeToursError)
            setShowAlert(true);
        }
        else {

            setAcompananteSeleccionado({ ...acompanante })
            setbuscarToursAcomapante(true);
            setShowFormulario(false);
        }
    }

    const onClickCerrarBuscarTour = () => {
        setBuscarTours(false);
        setShowFormulario(true);
    }
    const onClickCerrarBuscarTourAcompanante = () => {
        setbuscarToursAcomapante(false);
        setShowFormulario(true);
    }


    //ACCIONES DE LOS MODALES





    const onClickProcesarHabitacionCliente = async (datosHabitacion) => {

        //setModalTour(false);

        var actualizarResumen = true;

        var HabitacionNueva = {
            IdPaquete: IdPaqueteDeViaje,
            TipoDeHabitacion: datosHabitacion.TipoDeHabitacion,
            NombrePersona: personaSeleccionada.nombre,
            IdentificacionPersona: personaSeleccionada.identificacion,
            MontoExtra: datosHabitacion.MontoExtra,
            Estado: 1,
            Tipo: "Cliente"
        }

        setHabitacionPersonalizadaCliente(false);

        if (paqueteCargado()) {

            actualizarResumen = await registrarHabitacion(HabitacionNueva);

        }

        if (actualizarResumen) { //REGISTRO EN BD EXITOSO O AGREGANDO POR PRIMERA VEZ
            setListaHabitacionesCliente([
                ...listaHabitacionesCliente,
                HabitacionNueva
            ]);


            //AQUI TIENE QUE AGARRAR LA SUMA
            const sumaMontoExtra = listaHabitacionesCliente.reduce((total, habitacion) => {
                return total + (habitacion.MontoExtra);
            }, 0);


            let nuevoResumen = [];
            if (resumenToursCliente[0]) { //SI YA HAY UN RESUMEN, SE ACTUALIZA

                nuevoResumen = [{
                    ...resumenToursCliente[0],
                    montoPaquete: resumenToursCliente[0].montoPaquete,
                    montoTours: resumenToursCliente[0].montoTours,
                    montoSeguro: resumenToursCliente[0].montoSeguro,
                    montoHabitaciones: resumenToursCliente[0].montoHabitaciones + Number(datosHabitacion.MontoExtra),
                    subtotal: Number(resumenToursCliente[0].subtotal) + Number(datosHabitacion.MontoExtra)
                }];
            } else { //SI NO, HAY QUE CREARLO
                nuevoResumen = [{
                    ...resumenToursCliente[0],
                    montoPaquete: resumenToursCliente[0].montoPaquete ?? 0,
                    montoTours: resumenToursCliente[0].montoTours ?? 0,
                    montoSeguro: resumenToursCliente[0].montoSeguro ?? 0,
                    montoHabitaciones: sumaMontoExtra ?? 0,
                    subtotal: Number(resumenToursCliente[0].subtotal ?? 0) + Number(datosHabitacion.MontoExtra)
                }];
            }


            setResumenToursCliente(nuevoResumen);
        } else {

        }

    }

    const onClickProcesarHabitacionAcompanante = (datosHabitacion) => {

        var actualizarResumen = true;

        var HabitacionNueva = {
            IdPaquete: IdPaqueteDeViaje,
            TipoDeHabitacion: datosHabitacion.TipoDeHabitacion,
            NombrePersona: acompananteSeleccionado.NomAcompanante,
            IdentificacionPersona: acompananteSeleccionado.numIdentificacionAcompanante,
            MontoExtra: datosHabitacion.MontoExtra,
            Estado: 1,
            Tipo: "Acompañante"
        }

        setHabitacionPersonalizadaAcompanante(false);

        if (paqueteCargado()) {

            //actualizarResumen = registrarTour(HabitacionNueva);
            actualizarResumen = registrarHabitacion(HabitacionNueva);
        }

        if (actualizarResumen) {

            setListaHabitacionesAcompanante([
                ...listaHabitacionesAcompanante,
                HabitacionNueva
            ]);


            if (resumenToursAcompanantes[0]) { //SI YA EXISTE UN RESUMEN, SE ACTUALIZA
                setResumenToursAcompanantes([{
                    ...resumenToursAcompanantes[0],
                    montoPaquete: resumenToursAcompanantes[0].montoPaquete,
                    montoTours: resumenToursAcompanantes[0].montoTours,
                    montoSeguro: resumenToursAcompanantes[0].montoSeguro,
                    montoHabitaciones: Number(resumenToursAcompanantes[0].montoHabitaciones) + Number(datosHabitacion.MontoExtra),
                    subtotal: Number(resumenToursAcompanantes[0].subtotal) + Number(datosHabitacion.MontoExtra)
                }]);
            } else {
                setResumenToursAcompanantes([{ //SI NO, HAY QUE CREARLO
                    montoPaquete: (Number(idFormaDePago) > 0) ? (costoPaquete * numeroAcompanantes) : 0,
                    montoTours: Number(costoAcompanantesTours),
                    seguros: Number(numeroAcompanantesSeguro) * Number(CampoSeguro),
                    montoHabitaciones: Number(datosHabitacion.MontoExtra),
                    subtotal: ((Number(idFormaDePago) > 0) ? (costoPaquete * numeroAcompanantes) : 0)
                        + Number(datosHabitacion.MontoExtra)
                }]);
            }
        }



    }

    const onClickAceptarBuscarAcompanante = async (acompanante) => {

        let refrescarResumen = true;

        setAgregarAcompanante(!agregarAcompanante);

        const acompananteId = acompanante.identificacion;

        const idExiste = listaDeAcompanantes.some((item) => item.numIdentificacionAcompanante === acompananteId
            || personaSeleccionada.identificacion === acompananteId);

        var clienteExistente = false

        if (personaSeleccionada.identificacion === acompananteId) {
            clienteExistente = true
        }

        if (!idExiste && !clienteExistente) { //SI NO HA AGREGADO A ESE ACOMPAÑANTE

            if (paqueteCargado()) {

                if (paqueteDeViajeSeleccionado.cantidadCamposDisponibles > 0) {
                    var nuevoAcompanante = {
                        IdPaquete: IdPaqueteDeViaje,
                        numIdentificacionAcompanante: acompanante.identificacion,
                        NomAcompanante: acompanante.nombreCompleto,
                        Seguro: 0,
                        UsuarioModificacion: usuario
                    }

                    refrescarResumen = await registrarAcompanante(nuevoAcompanante);
                    setMensajeRespuesta(mensajeAcompananteInsertado);
                    setShowAlert(true);


                } else {
                    setMensajeRespuesta(mensajeCupos);
                    setShowAlert(true);
                    refrescarResumen = false;
                }
            }

            if (refrescarResumen) {
                const numAcompanantes = numeroAcompanantes + 1;
                setNumeroAcompanantes(numAcompanantes);

                const sumaMontoExtra = listaHabitacionesCliente.reduce((total, habitacion) => {
                    return total + (habitacion.MontoExtra);
                }, 0);


                //suma del monto

                let montoNuevo = 0;
                if (Number(idFormaDePago) > 0) {
                    montoNuevo = (Number(idFormaDePago) === 2)
                        ? paqueteDeViajeSeleccionado.precioContado : paqueteDeViajeSeleccionado.precioPlazos;
                }
                const nuevoMontoAcompanantes = Number(montoNuevo) * (numAcompanantes);

                //ACTUALIZAR EL TOTAL DEL PAQUETE
                setCostoAcompanantesPaquete(nuevoMontoAcompanantes);

                setListaDeAcompanantes([...listaDeAcompanantes, {
                    IdPaquete: paqueteDeViajeSeleccionado.id,
                    numIdentificacionAcompanante: acompanante.identificacion,
                    NomAcompanante: acompanante.nombreCompleto,
                    Seguro: 0
                }]);

                if (resumenToursAcompanantes[0]) { //SI YA HAY UN RESUMEN, SE ACTUALIZA
                    setResumenToursAcompanantes([{
                        ...resumenToursAcompanantes[0],
                        montoPaquete: nuevoMontoAcompanantes,
                        montoHabitaciones: Number(resumenToursAcompanantes[0].montoHabitaciones) ?? 0,
                        subtotal: Number(resumenToursAcompanantes[0].subtotal) + Number(montoNuevo)
                    }]);
                } else {
                    setResumenToursAcompanantes([{ //SI NO, SE CREA
                        montoPaquete: nuevoMontoAcompanantes,
                        montoTours: 0,
                        seguros: 0,
                        montoHabitaciones: 0,
                        subtotal: nuevoMontoAcompanantes
                    }]);
                }

                setMensajeRespuesta(mensajeAcompananteInsertado);
                setShowAlert(true);
            }
        } else {

            if (clienteExistente) {
                setMensajeRespuesta(mensajeAcompananteComoCliente);
            } else {
                setMensajeRespuesta(mensajeAcompananteRepetido);
            }
            setShowAlert(true);
        }
    }

    //ACCIONES DE LAS TABLAS

    const handleEliminarAcompanante = async (fila) => {


        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {

            setAcompaEliminando(fila);
            setEliminaAcompanante(!eliminaAcompanante);
        }
    };



    const confirmEliminarAcompanante = async () => {
        let refrescarResumen = true;

        if (paqueteCargado()) {
            var acompananteInactivar = {
                IdAcompanantePaquete: acompaEliminando.IdAcompanantePaquete,
                NumIdentificacionAcompanante: acompaEliminando.numIdentificacionAcompanante,
                IdPaquete: IdPaqueteDeViaje,
                Seguro: acompaEliminando.Seguro,
                UsuarioModificacion: usuario,
                NomAcompanante: acompaEliminando.NomAcompanante
            }

            refrescarResumen = await eliminarAcompanante(acompananteInactivar);


        }

        setEliminaAcompanante(!eliminaAcompanante);
        if (refrescarResumen) {
            //NUEVA LISTA DE ACOMPAÑANTES

            const acompanantesFiltrados = listaDeAcompanantes.filter(
                (acompanante) => acompanante.numIdentificacionAcompanante !== acompaEliminando.numIdentificacionAcompanante
            );

            //CALCULAR EL MONTO TOTAL A ELIMINAR DE TOURS DEL ACOMPAÑANTE (PUEDE SER 0)
            const totalToursEliminados = listaDeTourAcompanante.reduce((total, tourAcompanante) => {
                if (tourAcompanante.numIdentificacionAcompanante === acompaEliminando.identificacion) {
                    return Number(total) + Number(tourAcompanante.Monto);
                } else {
                    return Number(total);
                }
            }, 0);

            //NUEVA LISTA DE TOURS DE ACOMPAÑANTES
            const toursAcompanantesFiltrados = listaDeTourAcompanante.filter(
                (tourAcompanante) => tourAcompanante.numIdentificacion !== acompaEliminando.numIdentificacionAcompanante
            );



            const totalHabitacionesEliminadas = listaHabitacionesAcompanante.reduce((total, habitacionAcompanante) => {
                if (habitacionAcompanante.IdentificacionPersona === acompaEliminando.numIdentificacionAcompanante &&
                    habitacionAcompanante.NombrePersona === acompaEliminando.NomAcompanante) {
                    return Number(total) + Number(habitacionAcompanante.MontoExtra);
                } else {
                    return Number(total);
                }
            }, 0);


            const HabitacionesAcompanantesFiltrados = listaHabitacionesAcompanante.filter(
                (habitacionAcompanante) =>
                    habitacionAcompanante.IdentificacionPersona !== acompaEliminando.numIdentificacionAcompanante &&
                    habitacionAcompanante.NombrePersona !== acompaEliminando.NomAcompanante
            );

            setListaDeAcompanantes(acompanantesFiltrados);
            setListaDeTourAcompanante(toursAcompanantesFiltrados);
            setListaHabitacionesAcompanante(HabitacionesAcompanantesFiltrados);


            //RESTAR 1 A EL NUMERO DE ACOMPAÑANTES
            const numAcompanantes = numeroAcompanantes - 1;
            setNumeroAcompanantes(numAcompanantes);

            let montoNuevo = 0;
            if (Number(idFormaDePago) > 0) {
                montoNuevo = (Number(idFormaDePago) === 2)
                    ? paqueteDeViajeSeleccionado.precioContado : paqueteDeViajeSeleccionado.precioPlazos;
            }
            const nuevoMontoAcompanantes = Number(montoNuevo) * (numAcompanantes);


            const sumaMontoExtra = listaHabitacionesCliente.reduce((total, habitacion) => {
                return Number(total) + Number(habitacion.MontoExtra);
            }, 0);




            setCostoAcompanantesPaquete(nuevoMontoAcompanantes);

            //ACTUALIZAR EL RESUMEN DE TOURS DE ACOMPAÑANTES
            if (resumenToursAcompanantes[0]) {
                setResumenToursAcompanantes([{
                    ...resumenToursAcompanantes[0],
                    montoPaquete: Number(nuevoMontoAcompanantes),
                    montoTours: Number(resumenToursAcompanantes[0].montoTours) - Number(totalToursEliminados),
                    seguros: (Number(acompaEliminando.Seguro) > 0)
                        ? (Number(resumenToursAcompanantes[0].seguros) - Number(CampoSeguro))
                        : Number(resumenToursAcompanantes[0].seguros),

                    montoHabitaciones: Number(resumenToursAcompanantes[0].montoHabitaciones) - Number(totalHabitacionesEliminadas),

                    subtotal: Number(resumenToursAcompanantes[0].subtotal) - Number(totalToursEliminados) - Number(totalHabitacionesEliminadas)
                        - ((Number(acompaEliminando.Seguro) > 0) ? Number(CampoSeguro) : 0)
                        - Number(montoNuevo)
                    //- Number(acompaEliminando.MontoExtra)
                }]);
            }
        }

        
    };

    //ACCIONES DE LOS BOTONES PARA ACTIVAR SEGURO
    const handleCheckboxSeguroAcompanante = async (fila) => {


        if (!PermisoSeguroAcompanante) {
            setMensajeRespuesta(mensajeSeguroError);
            setShowAlert(true);
        }
        else {

            if (CampoSeguro > 0 && (CampoSeguro != "")) {
                var refrescarResumen = true;

                var acompananteSeguro = {
                    IdAcompanantePaquete: fila.IdAcompanantePaquete,
                    IdPaquete: IdPaqueteDeViaje,
                    numIdentificacionAcompanante: fila.numIdentificacionAcompanante,
                    Seguro: ((fila.Seguro > 0) ? 0 : CampoSeguro),
                    UsuarioModificacion: usuario
                }

                if (paqueteCargado()) {
                    refrescarResumen = await actualizarAcompanante(acompananteSeguro);
                }
            
                if (refrescarResumen) {
                    //CREAR UNA NUEVA LISTA DE ACOMPAÑANTES
                    const nuevoEstadoAcompanantes = listaDeAcompanantes.map((acompanante) => {

                        if (acompanante.numIdentificacionAcompanante == fila.numIdentificacionAcompanante) {
                            let numAcompaNuevo = numeroAcompanantesSeguro;
                            if (acompanante.Seguro > 0) { //SI EL ACOMPAÑANTE TENÍA UN SEGURO, ES PORQUE LO ESTÁ QUITANDO
                                numAcompaNuevo = numAcompaNuevo - 1;
                                setNumeroAcompanantesSeguro(numAcompaNuevo);
                                setResumenToursAcompanantes([{
                                    ...resumenToursAcompanantes[0],
                                    seguros: Number(resumenToursAcompanantes[0].seguros) - Number(CampoSeguro),
                                    subtotal: Number(resumenToursAcompanantes[0].subtotal) - Number(CampoSeguro)
                                }]);

                                return {
                                    ...acompanante,
                                    Seguro: 0,
                                };
                            } else { //ESTÁ ACTIVANDO EL SEGURO
                                numAcompaNuevo = numAcompaNuevo + 1;
                                setNumeroAcompanantesSeguro(numAcompaNuevo);

                                if (resumenToursAcompanantes[0]) { //SI YA HAY UN RESUMEN, SE ACTUALIZA
                                    setResumenToursAcompanantes([{
                                        ...resumenToursAcompanantes[0],
                                        seguros: Number(resumenToursAcompanantes[0].seguros) + Number(CampoSeguro),
                                        subtotal: Number(resumenToursAcompanantes[0].subtotal) + Number(CampoSeguro)
                                    }]);
                                } else {
                                    setResumenToursAcompanantes([{ //SI NO, HAY QUE CREARLO
                                        montoPaquete: (Number(idFormaDePago) > 0) ? (costoPaquete * numeroAcompanantes) : 0,
                                        montoTours: 0,
                                        seguros: Number(CampoSeguro),
                                        subtotal: ((Number(idFormaDePago) > 0)
                                            ? (costoPaquete * numeroAcompanantes) : 0) + Number(CampoSeguro)
                                    }]);
                                }

                                return {
                                    ...acompanante,
                                    Seguro: Number(CampoSeguro),
                                };
                            }
                        }
                        return acompanante; // Devuelve el acompañante sin cambios si el ID no coincide
                    });
                    setListaDeAcompanantes(nuevoEstadoAcompanantes);
                }
                //eventoSumaSeguroAcompanante(nuevoEstadoAcompanantes);
            } else {
                setMensajeRespuesta(mensajeSeguro);
                setShowAlert(true)
            }
        }
    };

    const onClickSeguroPersona = async () => {

        if (!PermisoSeguroCliente) {
            setMensajeRespuesta(mensajeSeguroError);
            setShowAlert(true)
            return;
        }
        else {


            if (CampoSeguro <= 0 || CampoSeguro == "") {
                setMensajeRespuesta(mensajeSeguro);
                setShowAlert(true)
            } else {
                var refrescarResumen = true;
                let seguroPersona = 0;


                if (paqueteCargado()) {

                    if (estadoSeguroClienteActivo) { //SI EL SEGURO ESTABA ACTIVADO (LO ESTÁ DESACTIVANDO)
                        setPersonaSeleccionada({ ...personaSeleccionada, seguro: false });
                        setEstadoSeguroClienteActivo(false);
                    } else { //SI EL SEGURO ESTABA DESACTIVADO (LO ESTÁ ACTIVANDO)
                        setPersonaSeleccionada({ ...personaSeleccionada, seguro: true });
                        setEstadoSeguroClienteActivo(true);
                        seguroPersona = Number(CampoSeguro);
                    }

                
                    var datosPaquete = DatosPaquete();

                    datosPaquete.estadoSeguro = (!estadoSeguroClienteActivo);


                    const montoSeguroActualizado = (resumenToursCliente[0].montoSeguro > 0)
                        ? Number(-resumenToursCliente[0].montoSeguro) : Number(CampoSeguro);
                    datosPaquete.CostoTotal = datosPaquete.CostoTotal + montoSeguroActualizado;
                    datosPaquete.Seguro = (montoSeguroActualizado < 0) ? 0 : montoSeguroActualizado;
                
                    var respuesta = await registrarPaquete(datosPaquete, true);
                    if (respuesta.indicador != 0) {
                        refrescarResumen = false;
                    }
                }

                if (refrescarResumen) {
                    obtenerDatosDePaquete();
                    if (!paqueteCargado()) {

                        if (!estadoSeguroClienteActivo) {
                            seguroPersona = Number(CampoSeguro);
                            setEstadoSeguroClienteActivo(!estadoSeguroClienteActivo);
                        }
                        else {
                            seguroPersona = 0;
                            setEstadoSeguroClienteActivo(!estadoSeguroClienteActivo);
                        }
                    }

                    let nuevoResumen = [];
                    if (resumenToursCliente[0]) { //SI YA HAY UN RESUMEN, SE ACTUALIZA
                        nuevoResumen = [{
                            ...resumenToursCliente[0],
                            montoSeguro: seguroPersona,
                            subtotal: Number(resumenToursCliente[0].subtotal -
                                resumenToursCliente[0].montoSeguro) + Number(seguroPersona)
                        }];
                    } else { //SI NO, HAY QUE CREARLO
                        nuevoResumen = [{
                            montoPaquete: 0,
                            montoTours: 0,
                            montoSeguro: seguroPersona,
                            subtotal: Number(seguroPersona)
                        }];
                    }
                    setResumenToursCliente(nuevoResumen);
                } else {
                    setseguroClienteValor(!seguroClienteValor);
                }
            }
        }
    }


    const onChangeSeguroValue = async (valor) => {

        setCampoSeguro(Number(valor));

        if (estadoSeguroClienteActivo) { //SI EL CLIENTE TIENE ACTIVADO EL SEGURO, HAY QUE ACTUALIZAR SU VALOR
            if (resumenToursCliente[0]) {
                const nuevoResumen = [{
                    ...resumenToursCliente[0],
                    montoSeguro: valor,
                    subtotal: Number(resumenToursCliente[0].subtotal - resumenToursCliente[0].montoSeguro) + Number(valor)
                }];
                setResumenToursCliente(nuevoResumen);
            }

        }

        //TOTAL DE SEGUROS PARA ACOMPAÑANTES (PUEDE SER 0)
        const segurosAcompa = Number(valor) * Number(numeroAcompanantesSeguro);
        // ACTUALIZAR EL MONTO DE SEGUROS DE ACOMPAÑANTES
        //if (resumenToursCliente[0]) {
        //    setResumenToursCliente([{
        //        ...resumenToursCliente[0],
        //        seguros: Number(valor),
        //        subtotal: Number(resumenToursCliente[0].subtotal) -
        //            Number(resumenToursCliente[0].seguros) + Number(valor),
        //    }])
        //}

        if (resumenToursAcompanantes[0]) {
            setResumenToursAcompanantes([{
                ...resumenToursAcompanantes[0],
                seguros: segurosAcompa,
                subtotal: Number(resumenToursAcompanantes[0].subtotal) -
                    Number(resumenToursAcompanantes[0].seguros) + Number(segurosAcompa),
            }])
        }

    };

    //DESCUENTO

    const onChangeDescuentoValue = (valor) => {
        setDescuento(Number(valor));

    };



    const onChangeFechaLimitePago = (valor) => {
        var fechaSalida = new Date(paqueteDeViajeSeleccionado.fechaSalida);
        var fechaLimite = new Date(valor);

        var fechaPago = new Date(fechaSalida);
        fechaPago.setDate(fechaPago.getDate() - 1);

        if (fechaPago <= fechaLimite) {
            setMensajeRespuesta({ indicador: '1', mensaje: "La fecha límite de pago no puede ser mayor o igual que la fecha de salida. Se ha ajustado a un día antes de la fecha de salida." });
            setShowAlert(true);
            setFechaLimitePago(fechaPago.toISOString().split('T')[0]);
        } else {
            setFechaLimitePago(valor);
        }
    };

    const formatFechaLabels = (fechaString) => {
        const fecha = new Date(fechaString);
        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = String(fecha.getMonth() + 1).padStart(2, '0');
        const año = fecha.getFullYear();
        return `${dia}/${mes}/${año}`;
    }

    const formatDateForInput = (inputDate) => {
        const dateObject = new Date(inputDate);
        if (isNaN(dateObject.getTime())) {
            return "";
        }

        // Adjust for time zone offset
        const timezoneOffset = dateObject.getTimezoneOffset();
        const adjustedDate = new Date(dateObject.getTime() + timezoneOffset * 60000);

        const year = adjustedDate.getFullYear();
        const month = (adjustedDate.getMonth() + 1).toString().padStart(2, '0');
        const day = adjustedDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };



    //MANEJO DE TOURS
    const onClickProcesarTourAcompañante = (tour) => {

        setTourSeleccionado({})
        setModalTour(false);

        var actualizarResumen = true;

        if (fechaValida(tour.fechaSalida)) {
            setProcesoModalTour(1);
            setDataTourDeViaje({});

            const idExiste = listaDeTourAcompanante.some((item) =>
            ((item.IdTourDeViaje === tour.idTourDeViaje)
                && (item.numIdentificacion == acompananteSeleccionado.numIdentificacionAcompanante)));

            if (!idExiste) { //SI NO HA AGREGADO EL TOUR PARA EL ACOMPAÑANTE CON ANTERIORIDAD
                var tourRegistrar = {
                    numIdentificacion: acompananteSeleccionado.numIdentificacionAcompanante,
                    NomAcompanante: `${acompananteSeleccionado.NomAcompanante}`,
                    IdTourDeViaje: tour.idTourDeViaje,
                    descripcion: tour.descripcion,
                    pais: tour.pais,
                    Fecha: tour.fechaSalida,
                    Monto: tour.monto,
                    IdPaquete: IdPaqueteDeViaje,
                    Tipo: "Acompañante",
                    UsuarioModificacion: usuario
                }

                setbuscarToursAcomapante(false);

                if (paqueteCargado()) {

                    actualizarResumen = registrarTour(tourRegistrar);

                }

                setMensajeRespuesta(mensajeTourInsertado);
                setShowAlert(true)



                if (actualizarResumen) {
                    //AGREGAR EL COSTO DEL TOUR
                    setCostoAcompanantesTours(Number(costoAcompanantesTours) + Number(tour.monto));
                    setListaDeTourAcompanante([...listaDeTourAcompanante, tourRegistrar]);

                    if (resumenToursAcompanantes[0]) { //SI YA EXISTE UN RESUMEN, SE ACTUALIZA
                        setResumenToursAcompanantes([{
                            ...resumenToursAcompanantes[0],
                            montoTours: Number(resumenToursAcompanantes[0].montoTours) + Number(tour.monto),
                            subtotal: Number(resumenToursAcompanantes[0].subtotal) + Number(tour.monto)
                        }]);
                    } else {
                        setResumenToursAcompanantes([{ //SI NO, HAY QUE CREARLO
                            montoPaquete: (Number(idFormaDePago) > 0) ? (costoPaquete * numeroAcompanantes) : 0,
                            montoTours: Number(tour.monto),
                            seguros: Number(numeroAcompanantesSeguro) * Number(CampoSeguro),
                            subtotal: ((Number(idFormaDePago) > 0) ? (costoPaquete * numeroAcompanantes) : 0)
                                + Number(tour.monto)
                        }]);
                    }
                }
            } else {
                setbuscarToursAcomapante(false);
                setMensajeRespuesta(mensajeTourRepetido);
                setShowAlert(true)
            }
        } else {
            setDataTourDeViaje(tour);
            setProcesoModalTour(2);
            setbuscarToursAcomapante(false);
            setMensajeRespuesta(mensajeFechaInvalida);
            setShowAlert(true)
        }

    }

    const onClickProcesarTourCliente = (tour) => {

        setTourSeleccionado({})
        setModalTour(false);

        var actualizarResumen = true;

        if (fechaValida(tour.fechaSalida)) {
            setProcesoModalTour(1);
            setDataTourDeViaje({});

            const idExiste = listaDeTour.some((item) => item.IdTourDeViaje === tour.idTourDeViaje);
            if (!idExiste) { //SI NO HA AGREGADO EL TOUR PARA EL CLIENTE CON ANTERIORIDAD
                var tourRegistrar = {
                    numIdentificacion: personaSeleccionada.identificacion,  //para la tabla de tour del cliente
                    IdTourDeViaje: tour.idTourDeViaje,
                    descripcion: tour.descripcion,
                    pais: tour.pais,
                    Fecha: tour.fechaSalida,
                    Monto: tour.monto,
                    IdPaquete: IdPaqueteDeViaje,
                    Tipo: "Cliente",
                    UsuarioModificacion: usuario
                }

                setBuscarTours(false);

                if (paqueteCargado()) {

                    actualizarResumen = registrarTour(tourRegistrar);

                }

                setMensajeRespuesta(mensajeTourInsertado);
                setShowAlert(true)


                if (actualizarResumen) { //REGISTRO EN BD EXITOSO O AGREGANDO POR PRIMERA VEZ
                    setListaDeTour([
                        ...listaDeTour,
                        tourRegistrar
                    ]);

                    let nuevoResumen = [];
                    if (resumenToursCliente[0]) { //SI YA HAY UN RESUMEN, SE ACTUALIZA
                        const montoToursTemp = resumenToursCliente[0].montoTours;
                        nuevoResumen = [{
                            ...resumenToursCliente[0],
                            montoTours: Number(montoToursTemp) + Number(tour.monto),
                            subtotal: Number(resumenToursCliente[0].subtotal) + Number(tour.monto)
                        }];
                    } else { //SI NO, HAY QUE CREARLO
                        nuevoResumen = [{
                            montoPaquete: 0,
                            montoTours: Number(tour.monto),
                            montoSeguro: 0,
                            subtotal: Number(tour.monto)
                        }];
                    }
                    setResumenToursCliente(nuevoResumen);
                } else {

                }
            } else {
                setBuscarTours(false);
                setMensajeRespuesta(mensajeTourRepetido);
                setShowAlert(true)
            }
        } else {
            setDataTourDeViaje(tour);
            setProcesoModalTour(2);
            setBuscarTours(false);
            setMensajeRespuesta(mensajeFechaInvalida);
            setShowAlert(true)
        }

    }

    //Abonos
    const onClickHacerAbono = async () => {
        setMostrarFormularioAbono(true);

        setSeguro('Nuevo Seguro');
    }




    //Cerrar modal abonos
    const onClickCerrarmodalAbonos = () => {
        setMostrarFormularioAbono(false);
    }

    const handleEliminarSoloTour = async (fila) => {

        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {
            setEliminaTour(!eliminaTour);
            setTourEliminando(fila);
        }
    };



    const confirmEliminarTour = async () => {
        let refrescarResumen = true;

        if (paqueteCargado()) { //DESACTIVAR INMEDIATAMENTE EN BD, SI SE ESTÁ ACTUALIZANDO
            var tourEliminar = {
                IdPaquete: IdPaqueteDeViaje,
                numIdentificacion: tourEliminando.numIdentificacion,
                IdTourDeViaje: tourEliminando.IdTourDeViaje,
                Monto: tourEliminando.Monto,
                UsuarioModificacion: usuario
            }

            refrescarResumen = await inactivarTour(tourEliminar);
        }

        if (refrescarResumen) {
            const toursFiltrados = listaDeTour.filter((tour) => tour.IdTourDeViaje !== tourEliminando.IdTourDeViaje);
            const cantidadTemp = resumenToursCliente[0].cantidadTours;

            const nuevoResumen = [{
                ...resumenToursCliente[0], cantidadTours: Number(cantidadTemp) - 1,
                montoTours: Number(resumenToursCliente[0].montoTours) - Number(tourEliminando.Monto),
                subtotal: Number(resumenToursCliente[0].subtotal) - Number(tourEliminando.Monto)
            }];

            setResumenToursCliente(nuevoResumen);
            setListaDeTour(toursFiltrados);
        }

        setEliminaTour(!eliminaTour);
    }




    const handleEliminarSoloTourAcompanante = async (fila) => {
        setEliminaTourAcompanante(!eliminaTourAcompanante);
        setTourEliminando(fila);
    };

    const confirmEliminarTourAcompanante = async () => {
        let refrescarResumen = true;

        if (paqueteCargado()) {  //DESACTIVAR INMEDIATAMENTE EN BD, SI SE ESTÁ ACTUALIZANDO
            var tourEliminar = {
                NumIdentificacion: tourEliminando.numIdentificacion,
                IdPaquete: IdPaqueteDeViaje,
                IdTourDeViaje: tourEliminando.IdTourDeViaje,
                Monto: tourEliminando.Monto,
                UsuarioModificacion: usuario
            }

            refrescarResumen = await inactivarTour(tourEliminar);
        }

        if (refrescarResumen) {
            const toursFiltrados = listaDeTourAcompanante.filter(
                (tour) => tour.numIdentificacion !== tourEliminando.numIdentificacion);
            setListaDeTourAcompanante(toursFiltrados);

            setResumenToursAcompanantes([{
                ...resumenToursAcompanantes[0],
                cantidadTours: Number(resumenToursAcompanantes[0].cantidadTours) - 1,
                montoTours: Number(resumenToursAcompanantes[0].montoTours) - Number(tourEliminando.Monto),
                subtotal: Number(resumenToursAcompanantes[0].subtotal) - Number(tourEliminando.Monto)
            }]);
        }

        setEliminaTourAcompanante(!eliminaTourAcompanante);

    }

    //COMENTARIOS
    const handleSubmitComentarios = async (data) => {
        var refrescarResumen = [{}]

        console.log("2292");
        if (paqueteCargado()) {

            var comentario = {
                IdPaquete: IdPaqueteDeViaje,
                IdComentarioPaquete: data.idComentarioPaquete,
                Comentario: data.DscComentario,
                UsuarioCreacion: usuario
            }

           refrescarResumen = await registrarComentarioPaquete(comentario);

        }
        else{
            setListaDeComentarios([...listaDeComentarios, {
                Comentario: data.DscComentario,
                FechaCreacion: obtenerFechaActual(),
            }]);
        }

        if (refrescarResumen.indicador === 0) {
            setListaDeComentarios([...listaDeComentarios, {
                idComentarioPaquete: refrescarResumen.lastInserted,
                Comentario: data.DscComentario,
                FechaCreacion: obtenerFechaActual(),
            }]);
            setearValor('DscComentario', '');
        }
    };


    const handleManualValidationComentarios = async () => {
        const isValid = await triggerComentario();
        if (!isValid) {
            setIsSummit(true)
        }
    };


    //METODOS
    const fechaValida = (fecha) => {
        if (fecha >= paqueteDeViajeSeleccionado.fechaSalida
            && fecha <= paqueteDeViajeSeleccionado.fechaLLegada) {
            return true;
        }
        return false;
    }

    function obtenerFechaActual() {
        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = fecha.getMonth() + 1;
        var año = fecha.getFullYear();

        // Agregar un cero inicial si el día o el mes son menores a 10
        dia = dia < 10 ? '0' + dia : dia;
        mes = mes < 10 ? '0' + mes : mes;

        // Formato de cadena: DD/MM/AAAA
        var fechaActual = dia + '/' + mes + '/' + año;

        return fechaActual;
    }

    function paqueteCargado() {
        return (IdPaqueteDeViaje != null && IdPaqueteDeViaje > 0);
    }

    function costoTotalPaquete() {
        return parseFloat(resumenToursCliente[0] ? resumenToursCliente[0].subtotal : 0)
            + parseFloat(resumenToursAcompanantes[0] ? resumenToursAcompanantes[0].subtotal : 0)
            - parseFloat(descuento);
    }


    //Ese es el metodo de guardar
    const handleSubmitInfoGeneral = async () => {


        if (personaSeleccionada.idPersona === null || personaSeleccionada.idPersona === undefined) {
            setConfirmModalOpen(true)
        }
        else if (paqueteDeViajeSeleccionado.id === null || paqueteDeViajeSeleccionado.id === undefined) {
            setConfirmModalOpen(true)
        }
        else if (paqueteDeViajeSeleccionado.cantidadCamposDisponibles < (numeroAcompanantes + 1) && !paqueteCargado()) {
            setMensajeRespuesta(mensajeCupos);
            setShowAlert(true);
        }
        else {

            var DataPaquete = DatosPaquete();

            if (DataPaquete.Seguro !== seguroInput) {
                DataPaquete.Seguro = seguroInput
            }
            if (estadoSeguroClienteActivo) {
                DataPaquete.EstadoSeguro = estadoSeguroClienteActivo
            }
            await registrarPaquete(DataPaquete, true);
        }
    }

    function DatosPaquete() {
        var DataPaquete = {
            IdPaqueteDeViaje: paqueteDeViajeSeleccionado.id,
            Acompanantes: listaDeAcompanantes,
            IdPersona: personaSeleccionada.idPersona,
            IdSupervisor: idSupervisor,
            IdVendedor: idVendedor,
            TipoPago: idFormaDePago,
            CostoTotal: costoTotalPaquete(),
            Descuento: descuento,
            cantidadCamposDisponibles: cantidadCamposDisponibles,

            Seguro: (Number(CampoSeguro) ?? 0),
            EstadoSeguro: estadoSeguroClienteActivo,

            Tours: listaDeTour,
            ToursAcompanante: listaDeTourAcompanante,

            HabitacionesCliente: listaHabitacionesCliente,
            HabitacionesAcompanante: listaHabitacionesAcompanante,

            Comentarios: listaDeComentarios,
            IdComentarioPaquete: listaDeComentarios.id,
            UsuarioCreacion: usuario,
            FechaLimitePago: fechaLimitePago,
            CorreoCliente: personaSeleccionada.correoElectronico
        }

        return DataPaquete;
    }

    //Ese es el de validar
    const handleManualValidationInformacionGeneral = async () => {
        const isValid = await trigger();
        if (!isValid) {
            setIsSummitInfo(true)
        }
    };


    const onConfirmActivarPaquete = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const idPaquete = urlParams.get('idPaquete');
        var EstadoPaquete = {
            IdPaquete: idPaquete,
            Estado: 1,
            UsuarioModificacion: usuario
        }

        var respuesta = await ActualizarEstadoPaquete(EstadoPaquete, true);

        if (respuesta.indicador === 0) {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
            setEstadoPaquete(1);
        } else {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
        }
        obtenerDatosDePaquete();
        setActivarPaquete(false);
    };

    const onConfirmInactivarPaquete = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const idPaquete = urlParams.get('idPaquete');
        var EstadoPaquete = {
            IdPaquete: idPaquete,
            Estado: 0,
            UsuarioModificacion: usuario
        }

        var respuesta = await ActualizarEstadoPaquete(EstadoPaquete, true);

        if (respuesta.indicador === 0) {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
            setEstadoPaquete(0);
        } else {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
        }
        obtenerDatosDePaquete();
        setInactivarPaquete(false);
    };

    return (
        <>
            {showAlert && (
                <AlertDismissible indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <ConfirmModal
                isOpen={ActivarPaquete}
                toggle={() => setActivarPaquete(false)}
                message={`¿Desea Activar este Paquete de Viaje? `}
                onConfirm={onConfirmActivarPaquete}
            />
            <ConfirmModal
                isOpen={InactivarPaquete}
                toggle={() => setInactivarPaquete(false)}
                message={`¿Desea Inactivar este Paquete de Viaje? `}
                onConfirm={onConfirmInactivarPaquete}
            />
            <Form onSubmit={handleSubmit(handleSubmitInfoGeneral)}>
                <div className='d-flex justify-content-end'>
                    <div>
                        {EstadoPaquete === 0 && IdPaqueteDeViaje !== 0 && PermisoEliminar && (
                        <Button variant="primary"
                            onClick={() => setActivarPaquete(true)}
                            className="mr-3 mb-3 card-button">
                            <FontAwesomeIcon icon={faPlay} className="mr-2" />
                            Activar Paquete de viaje
                        </Button>
                        )}
                    {EstadoPaquete === 1 && PermisoEliminar && (
                            <Button style={{ backgroundColor: 'red', borderColor: 'red', color: 'white' }}
                            variant="primary"
                            onClick={() => setInactivarPaquete(true)} className="mr-2 mb-2 card-button">
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                            Inactivar
                        </Button>
                        )}
                    </div>
                    <div>
                        {IdPaqueteDeViaje !== 0 && EstadoPaquete === 1 && PermisoAbonoP && (
                            <Button variant="primary" onClick={onClickHacerAbono} className="mr-2 mb-2 card-button">
                                <FontAwesomeIcon icon={faCoins} className="mr-2" />
                                Hacer Abono
                            </Button>
                        )}
                    </div>

                    {paqueteCargado() && PermisoActualizar && (
                        <Button variant="primary" type='submit' onClick={handleManualValidationInformacionGeneral} className="mr-2 mb-2 card-button">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            Actualizar Información
                        </Button>
                    
                    )}

                    {!(paqueteCargado()) && PermisoAgregar && (
                        <Button variant="primary" type='submit' onClick={handleManualValidationInformacionGeneral} className="mr-2 mb-2 card-button">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            Guardar Información
                        </Button>
                    )}
                </div>

                <div className='d-flex justify-content-around mt-3'>
                    <Card className="card-container" style={{ width: '60rem' }}>
                        <Row className='mt-2'>
                            <Col md={5}>
                                {IdPaqueteDeViaje === 0 && (
                                    <Button variant="primary" onClick={onClickBuscarPersona} size="sm" className="mr-1 mb-2 card-button" style={{ fontSize: '18px' }}>
                                        <FontAwesomeIcon icon={faSearch} className="mr-1" />
                                        Buscar Cliente
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Card.Body>
                            <Card.Title className="card-title">Información del Cliente
                            </Card.Title>
                            <Container className='d-Grid align-content-center'>
                                <Row className="mt-4">
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="nombre"><b>Nombre:</b></label>
                                        <p id="nombre">{personaSeleccionada.nombre}</p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="apellido"><b>Primer Apellido:</b></label>
                                        <p id="apellido">{personaSeleccionada.primerApellido}</p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="apellido"><b>Segundo Apellido:</b></label>
                                        <p id="apellido">{personaSeleccionada.segundoApellido}</p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="identificacion"><b>Identificación:</b></label>
                                        <p id="identificacion">{personaSeleccionada.identificacion}</p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="Telefono"><b>Teléfono:</b></label>
                                        <p id="Telefono">{personaSeleccionada.telefono}</p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="Email"><b>Email:</b></label>
                                        <p id="Email">{personaSeleccionada.correoElectronico}</p>
                                    </Col>
                                    <Col md={6} className="mb-3 d-flex align-items-center, marginTop: -70px" >
                                        <label id="Div_Seguro" htmlFor="Seguro"><b>Seguro:</b>
                                        </label>
                                        <div Style="left: 10px; position: inherit;">
                                            <BootstrapSwitchButton
                                                id={`switch`} onlabel="Sí"
                                                offlabel="No" checked={estadoSeguroClienteActivo}
                                                onChange={() => onClickSeguroPersona()}
                                                onstyle="outline-primary" offstyle="outline-secondary" size="sm"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label htmlFor="direccion"><b>Dirección:</b></label>
                                        <p id="direccion">{personaSeleccionada.idPersona != null ? `${personaSeleccionada.provincia}, ${personaSeleccionada.canton}, ${personaSeleccionada.distrito}, ${personaSeleccionada.direccion}` : ""}</p>
                                    </Col>
                                </Row>

                                {/*--HABITACIONES-----------------------------------------------------------------------------------------------------*/}
                                <Row className="mt-4">
                                    <Col>
                                        {PermisoHabitacionCliente && (EstadoPaquete === 1 || personaSeleccionada.identificacion != null) ? (
                                        <Button variant="primary" onClick={onClickHabitacionPersonalizadaCliente} className="mr-1 mb-2 card-button" style={{ fontSize: '14px', width: 'auto' }}>
                                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                            Habitacion Personalizada
                                        </Button>
                                        ) : null} 
                                        <Card.Body>
                                            <Card.Title className="card-title">Habitación{/*:*/}</Card.Title>
                                            <Container className='d-Grid align-content-center'>
                                                <Row className='d-flex mt-2'>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={12}>
                                                        <Grid gridHeading={encabezadoHabitaciones} gridData={listaHabitacionesCliente}
                                                            selectableRows={false}
                                                            /*setFilaSeleccionada={setTourEliminando}*/
                                                            idBuscar="id" />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Col>
                                </Row>
                                <br></br>
                                {/*--END HABITACIONES-----------------------------------------------------------------------------------------------------*/}


                                <Row className="mt-4">
                                    <Col>
                                        { PermisoTourCliente && (EstadoPaquete === 1 || personaSeleccionada.identificacion != null ) ?(
                                        <Button variant="primary" onClick={onClickBuscarTour} size="sm" className="mr-1 mb-2 card-button" style={{ fontSize: '14px', width: '146.16px' }}>
                                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                            Agregar Tour
                                        </Button>
                                        ):null}
                                        <Card.Body>
                                            <Card.Title className="card-title">Tour{/*:*/}</Card.Title>
                                            <Container className='d-Grid align-content-center'>
                                                <Row className='d-flex mt-2'>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={12}>
                                                        <Grid gridHeading={encabezadoTour} gridData={listaDeTour}
                                                            selectableRows={false}
                                                            setFilaSeleccionada={setTourEliminando}
                                                            idBuscar="id" />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Col>
                                </Row>




                                <Row className="mt-4">
                                    <Col>
                                        <Card.Body>
                                            <Card.Title className="card-title">Resumen de Costos</Card.Title>
                                            <Container className='d-Grid align-content-center'>
                                                <Row className='d-flex mt-2'>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md={12}>
                                                        <Grid gridHeading={encabezadoResumenToursCliente}
                                                            gridData={resumenToursCliente} selectableRows={false}
                                                            idBuscar="id" />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    <Card className="card-container" style={{ width: '50rem' }}>
                        <Row className='d-flex justify-content-start mt-2'>


                            <Col md={5}>
                                {IdPaqueteDeViaje === 0 && (
                                    <Button
                                        variant="primary"
                                        onClick={onClickBuscarPaquetesDeViaje}
                                        className="mr-1 mb-2 card-button"
                                        size="sm"
                                    >
                                        <FontAwesomeIcon icon={faSearch} className="mr-1" style={{ fontSize: '18px' }} />
                                        Buscar Paquete
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Card.Body>
                            <Card.Title>Información del Paquete </Card.Title>
                            <Container className='d-Grid align-content-center'>
                                <Row className="mt-4">
                                    <Col md={12}>
                                        <label htmlFor="descripcion"><b>Destino:</b></label>
                                        <p id="descripcion">{paqueteDeViajeSeleccionado.descripcion}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <label htmlFor="PrecioContado"><b>Precio Contado($):</b></label>
                                        <p id="PrecioContado">{paqueteDeViajeSeleccionado.precioContado}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="PrecioPlazos"><b>Precio a Plazos($):</b></label>
                                        <p id="PrecioPlazos">{paqueteDeViajeSeleccionado.precioPlazos}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <label htmlFor="cantidadCampos"><b>Total de Cupos:</b></label>
                                        <p id="cantidadCampos">{paqueteDeViajeSeleccionado.cantidadCampos}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="cantidadCamposDisponibles"><b>Cupos Disponibles:</b></label>
                                        <p id="cantidadCamposDisponibles">{paqueteDeViajeSeleccionado.cantidadCamposDisponibles}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <label htmlFor="FechaSalida"><b>Fecha de Salida:</b></label>
                                        <p id="fechaSalida">
                                            {paqueteDeViajeSeleccionado.fechaSalida ? formatFechaLabels(paqueteDeViajeSeleccionado.fechaSalida) : ''}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="FechaLLegada"><b>Fecha de Llegada:</b></label>
                                        <p id="fechaLLegada">
                                            {paqueteDeViajeSeleccionado.fechaLLegada ? formatFechaLabels(paqueteDeViajeSeleccionado.fechaLLegada) : ''}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <label htmlFor="cantidadGuias"><b>Cantidad Guias:</b></label>
                                        <p id="cantidadGuias">{paqueteDeViajeSeleccionado.cantidadGuias}</p>
                                    </Col>

                                    <Col>
                                        <div Style="font-weight: bolder;">
                                            <ComboBox data={listaFormasDePago} label="Tipo de Pago:" controlId="sel-tipPag"
                                                onChange={onChangeFormaDePago} value={idFormaDePago}
                                                optionValue="idFormaDePago" optionLabel="nombre" indicacion="Seleccione el tipo de pago" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <label htmlFor="descuento"><b>Descuentos($):</b></label>
                                        <InputNumber id="descuento" mensajeValidacion="Monto requerido"
                                            /*className="" placeholder={`Ingrese el descuento (${paqueteDeViajeSeleccionado.descuento})`}*/
                                            className="" placeholder={'0'}
                                            min={0.00} max={99999999.99} value={descuento}
                                            onChange={(e) => onChangeDescuentoValue(e.target.value)}
                                            style={{
                                                fontSize: '13px', width: '153px', border: '1px solid #ccc',
                                                borderRadius: '4px', padding: '8px',
                                                marginBottom: '10px',
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="seguro"><b>Seguro($):</b></label>

                                        <InputNumber mensajeValidacion="Monto requerido" id="seguro"
                                            className="" placeholder="Ingrese el seguro"
                                            min={0.00} max={99999999.99}
                                            value={CampoSeguro} required={estadoSeguroClienteActivo}
                                            onChange={(e) => onChangeSeguroValue(e.target.value)}
                                            style={{
                                                fontSize: '13px', width: '153px', border: '1px solid #ccc',
                                                borderRadius: '4px', padding: '14px',
                                            }}
                                        />

                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <div Style="font-weight: bolder;">
                                            <ComboBox data={listaSupervisores} label="Supervisor" controlId="sel-superv" onChange={onChangeSupervisor} value={idSupervisor}
                                                optionValue="idSupervisor" optionLabel="nombre" indicacion="Seleccione el supervisor" />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div Style="font-weight: bolder;">
                                            <ComboBox data={listaVendedores} label="Vendedor" controlId="sel-vend" onChange={onChangeVendedor} value={idVendedor}
                                                optionValue="idVendedor" optionLabel="nombre" indicacion="Seleccione el vendedor" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                </Row>
                                <Card.Title>Resumen del Paquete</Card.Title>
                                <Row className="mt-4">
                                    <Col md={6}>
                                        <label htmlFor="subCliente"><b>Monto de Cliente</b></label>
                                        <p id="subCliente">{(
                                            parseFloat(resumenToursCliente[0] ? resumenToursCliente[0].subtotal : 0)
                                        ).toLocaleString()}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="subAcompa"><b>Monto de Acompañantes:</b></label>
                                        <p id="subAcompa">{(
                                            parseFloat(resumenToursAcompanantes[0]
                                                ? resumenToursAcompanantes[0].subtotal : 0)).toLocaleString()}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="total"><b>Subtotal:</b></label>
                                        <p id="total">{(
                                            parseFloat(resumenToursCliente[0] ? resumenToursCliente[0].subtotal : 0)
                                            + parseFloat(resumenToursAcompanantes[0] ? resumenToursAcompanantes[0].subtotal : 0)
                                            /*+ parseFloat(MontoExtraHabitacion ? MontoExtraHabitacion : 0)*/
                                        ).toLocaleString()}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="descuento"><b>Descuento:</b></label>
                                        <p id="descuento">{(parseFloat(descuento) || 0).toLocaleString()}</p>
                                    </Col>
                                    <Col md={6}>

                                        <label htmlFor="total"><b>Total:</b></label>
                                        <p id="total">
                                            {Math.max(0, (
                                                (isNaN(parseFloat(resumenToursCliente[0]?.subtotal)) ? 0 : parseFloat(resumenToursCliente[0]?.subtotal)) +
                                                (isNaN(parseFloat(resumenToursAcompanantes[0]?.subtotal)) ? 0 : parseFloat(resumenToursAcompanantes[0]?.subtotal)) -
                                                (isNaN(parseFloat(descuento)) ? 0 : parseFloat(descuento))
                                            )).toLocaleString()}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor="Saldo"><b>Saldo($):</b></label>
                                        <p id="Saldo">
                                            {Math.max(0, isNaN(parseFloat(paqueteDeViajeSeleccionado.saldo)) ? 0 : parseFloat(paqueteDeViajeSeleccionado.saldo)).toLocaleString()}
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <InputText
                                            id="fechaLimitePago"
                                            name="fechaLimitePago"
                                            label={<b>Fecha Límite de Pago:</b>}
                                            className=""
                                            type="date"
                                            placeholder={`Ingrese la fecha límite (${paqueteDeViajeSeleccionado.fechaLimitePago})`}
                                            onChange={(e) => onChangeFechaLimitePago(e.target.value)}
                                            value={formatDateForInput(fechaLimitePago)}
                                            style={{
                                                fontSize: '13px',
                                                width: '153px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                padding: '6px',
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </Form>
            <div className='d-flex justify-content-around mt-3'>
                <Card className="card-container" style={{ width: '60rem' }}>
                    <Row className='mt-2'>
                        <Col md={5}>
                            {PermisoAcompanante && (EstadoPaquete === 1 || personaSeleccionada.identificacion != null) ?(
                            <Button variant="primary" onClick={onClickAgregarAcompanante} size="sm" className="mr-1 mb-2 card-button" style={{ fontSize: '18px', width: '286.16px' }}>
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                Agregar Acompañantes
                                </Button>
                            ):null}
                        </Col>
                    </Row>
                    <Card.Body>
                        <Card.Title className="card-title">Acompañantes{/*:*/}</Card.Title>
                        <Container className='d-Grid align-content-center'>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <Grid gridHeading={encabezadoAcompanantes} gridData={listaDeAcompanantes}
                                        selectableRows={false}
                                        idBuscar="id" />
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                    <Card.Body>
                        <Card.Title className="card-title">Tour{/*:*/}</Card.Title>
                        <Container className='d-Grid align-content-center'>
                            <Row className='d-flex mt-2'></Row>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <Grid gridHeading={encabezadoTourAcompanante} gridData={listaDeTourAcompanante}
                                        selectableRows={false}
                                        setFilaSeleccionada={setTourEliminando}
                                        idBuscar="id" />
                                </Col>
                            </Row>


                            <Row className="mt-4">
                                <Col>
                                    <Card.Body>
                                        <Card.Title className="card-title">Habitación{/*:*/}</Card.Title>
                                        <Container className='d-Grid align-content-center'>
                                            <Row className='d-flex mt-2'>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col md={12}>
                                                    <Grid gridHeading={encabezadoHabitacionesAcompanantes} gridData={listaHabitacionesAcompanante}
                                                        selectableRows={false}
                                                        idBuscar="id" />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <Card.Body>
                                        <Card.Title className="card-title">Resumen de Costos:</Card.Title>
                                        <Container className='d-Grid align-content-center'>
                                            <Row className="mt-4">
                                                <Col md={12}>
                                                    <Grid gridHeading={encabezadoResumenToursAcompanantes}
                                                        gridData={resumenToursAcompanantes} selectableRows={false} />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                <Card className="card-container" style={{ width: '50rem' }}>
                    <Card.Body>
                        <Card.Title className="card-title">Bitacora de Comentarios</Card.Title>
                        <Container className='d-Grid align-content-center'>
                            <Form onSubmit={handleSubmitComentario(handleSubmitComentarios)}>

                                {PermisoComentarioP && (
                                    <>
                                        <TexAreaFormsReactHook type={""} rows={3}
                                            id='txt-Comentarios' label={<strong>Comentarios: </strong>}
                                            placeholder='Comentario' errors={errorsComentario?.Comentario?.type}
                                            mensajeValidacion="" isSummit={isSummit}
                                            register={{
                                                ...registerComentario('DscComentario', {
                                                    required: true, maxLength: 1000
                                                })
                                            }}
                                        />
                                        <Button className='mr-1  card-button' variant="primary"
                                            type="submit" style={{ height: "37px", alignSelf: "flex-end" }}
                                            onClick={handleManualValidationComentarios} size="sm">
                                            <FontAwesomeIcon icon={faPlus} className="mr-2"
                                            />Agregar Comentario
                                        </Button>
                                    </>
                                )}
                            </Form>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <span>Listado de Comentarios</span>
                                    <Grid gridHeading={encabezadoComentarios}
                                        gridData={listaDeComentarios}
                                        selectableRows={false} pending={pendienteComentarios}
                                        /*setFilaSeleccionada={onClickSeleccionarFilaComentarios}*/
                                        idBuscar="id" />
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                <ModalComentarios
                    show={showModal}
                    handleClose={handleModalClose}
                    comment={selectedComment}
                    setComment={setSelectedComment}
                    handleSave={handleSaveComment}
                />
            </div>



            {IdPaqueteDeViaje != 0 ? (<DocumentosPaqueteDeViajeComponet idPaquete={IdPaqueteDeViaje} PermisoDocumentoP={PermisoDocumentoP} />) : null}
            <BuscarPersona show={buscarPersonas} handleClose={onClickCerrarBuscarPersonas} handleAceptar={onClickAceptarBuscarPersonas} className='' tamano="lg">
                <h5>Personas buscador</h5>
            </BuscarPersona>





            <BuscarPaquetesDeViaje show={buscarPaquetesDeViaje} handleClose={onClickCerrarBuscarPaquetesDeViaje} handleAceptar={onClickAceptarPaquetesDeViaje} className='bucadorPaquetes' tamano="xl">
                <h5>Paquetes de Viaje</h5>
            </BuscarPaquetesDeViaje>



            <ModalAgregarAcompanantes
                show={agregarAcompanante}
                handleClose={onClickCerrarAgregarAcompanante}
                handleAceptar={onClickAceptarBuscarAcompanante}
                className=''
            />

            <ModalAgregarHabitacion
                show={HabitacionPersonalizadaCliente}
                handleClose={onClickCerrarHabitacionPersonalizadaCliente}
                handleAceptar={onClickProcesarHabitacionCliente}
                className=''

            />

            <ModalAgregarHabitacion
                show={HabitacionPersonalizadaAcompanante}
                handleClose={onClickCerrarHabitacionPersonalizadaAcompanante}
                handleAceptar={onClickProcesarHabitacionAcompanante}
                className=''
            />


            <FormularioModal show={buscarTours} handleClose={onClickCerrarBuscarTour} titulo={"Tours"} className='' tamano="lg">
                <FormularioTour labelButton={"Guardar"} data={dataTourDeViaje} proceso={procesoModalTour} onClickProcesarTour={onClickProcesarTourCliente} />
            </FormularioModal>




            {confirmModalOpen && (
                <ConfirmModal isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`Campos Requeridos`}
                    onConfirm={() => {
                        setConfirmModalOpen(false)
                    }}
                />
            )}



            <FormularioModal show={mostrarFormularioAbono} handleClose={onClickCerrarmodalAbonos} className='' tamano="lg">
                <Formularioabonospaquetesdeviaje idPaquete={IdPaqueteDeViaje} PermisoEliminar={PermisoEliminar} PermisoDocumentoP={PermisoDocumentoP} setActualizarSaldo={setActualizarSaldo} />
            </FormularioModal>



            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`Campos Requeridos`}
                    onConfirm={() => {
                        setConfirmModalOpen(false)
                    }}
                />
            )}


            <FormularioModal show={buscarToursAcomapante} handleClose={onClickCerrarBuscarTourAcompanante} titulo={"Tours Acompañante"} className='' tamano="lg">
                <FormularioTour labelButton={"Guardar"} data={dataTourDeViaje} proceso={procesoModalTour} onClickProcesarTour={onClickProcesarTourAcompañante} />
            </FormularioModal>




            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`Campos Requeridos`}
                    onConfirm={() => {
                        setConfirmModalOpen(false)
                    }}
                />
            )}
            {eliminaTour && (
                <ConfirmModal
                    isOpen={eliminaTour}
                    toggle={() => setEliminaTour(!eliminaTour)}
                    message={`¿Desea eliminar el tour ${tourEliminando.descripcion}?`}
                    onConfirm={confirmEliminarTour}
                />
            )}
            {eliminaTourAcompanante && (
                <ConfirmModal
                    isOpen={eliminaTourAcompanante}
                    toggle={() => setEliminaTourAcompanante(!eliminaTourAcompanante)}
                    message={`¿Desea eliminar el tour ${tourEliminando.descripcion}?`}
                    onConfirm={confirmEliminarTourAcompanante}
                />
            )}
            {eliminaAcompanante && (
                <ConfirmModal
                    isOpen={eliminaAcompanante}
                    toggle={() => setEliminaAcompanante(!eliminaAcompanante)}
                    message={`¿Desea eliminar al acompañante ${acompaEliminando.NomAcompanante} y sus tours?`}
                    onConfirm={confirmEliminarAcompanante}
                />
            )}
        </>
    )
}

export default PaqueteDeViajeComponet;