import React, { useEffect, useState } from "react";
import { Button, Row, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { BuscarPersona } from "../../components/buscarPersona";
import "../../styles/clubDeViaje.css";
import { ObtenerCuotas } from "../../servicios/ServicioCuotas";
import { SpinnerPersonalizado } from "../../components/spinnerPersonalizado";

import {InputsFormsReactHook} from "../../components/Forms/InputsFormsReactHook";
import {
    AgregarComentario,
    AgregarClubDeViaje,
    ActualizarClubDeViaje,
    ObtenerFrecuenciasDePago,
    ObtenerTiposDeCuentas,
    ObtenerTiposDeTarjetas,
    ObtenerClubDeViajePorId,
    ActualizarEstadoCuota,
    ObtenerEstadoCuotasClub,
    ActivarRegaliaMontania,
    ActivarRegaliaPlaya,
} from "../../servicios/ServicioClubDeViaje";
import {
    ObtenerUltimoAbonoAprobadoClubDeViaje
} from "../../servicios/ServicioCobro";

import { ConfirmModal } from "../../components/ConfirmModal";
import { AlertDismissible } from "../../components/alerts";
import DocumentosClubDeViajeComponet from "../Documentos/DocumentosClubDeViaje";
import "react-datepicker/dist/react-datepicker.css";
import CardInformacionContrato from "../../components/ClubViaje/CardInformacionContrato";
import CardBitacoraComentarios from "../../components/ClubViaje/CardBitacoraComentarios";
import CardBuscarBeneficiario from "../../components/ClubViaje/CardBuscarBeneficiario";
import CardInformacionCuenta from "../../components/ClubViaje/CardInformacionCuenta";
import CardInformacionCliente from "../../components/ClubViaje/CardInformacionCliente";
import FormularioRegistroCobroClub from './FormularioRegistroCobroClubViaje'; //Abonos
import FormularioLoteriaClubViaje from './FormularioLoteriaClubViaje'; //Abonos
import { FormularioModal } from '../../components/ventanaModal';  //Abonos
import { faCoins } from '@fortawesome/free-solid-svg-icons'; //Abono Icono
import { faTicket, faCheck } from '@fortawesome/free-solid-svg-icons'; //Abono Icono
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'; //Reanudar Icono

import FormularioCompletarClubViaje from './formularioCompletarClubViaje'; //Completar ClubViaje

const ClubDeViajesComponet = () => {
    const [clubData, setClubData] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [IdClub, setIdClub] = useState(null);
    const [isSummit, setIsSummit] = useState(false);
    const [isSummitInfo, setIsSummitInfo] = useState(false);
    const [IdClubDeViaje, setIdClubDeViaje] = useState(0);
    const [listaDeCuotas, setListaDeCuotas] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionadaCuotas, setFilaSeleccionadaCuotas] = useState(0);
    const [selectedOption, setSelectedOption] = useState("");
    const [mostrarSemanal, setMostrarSemanal] = useState(false);
    const [mostrarQuincenal, setMostrarQuincenal] = useState(false);
    const [mostrarMensual, setMostrarMensual] = useState(false);
    const [listaDeComentarios, setListaDeComentarios] = useState([]);
    const [pendienteComentarios, setPendienteComentarios] = useState(false);
    const [personaSeleccionada, setPersonaSeleccionada] = useState({});
    const [BeneficiarioSeleccionado, setBeneficiarioSeleccionado] = useState({});
    const [buscarPersonas, setBuscarPersonas] = useState(false);
    const [buscarBeneficiario, setBuscarBeneficiario] = useState(false);
    const [showFormulario, setShowFormulario] = useState(true);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [lastInserted, setLastInserted] = useState(null);
    const [valoresCuota, setvaloresCuota] = useState({});
    const [valoresCuenta, setvaloresCuenta] = useState({});
    const [valoresContrato, setvaloresContrato] = useState({});
    const [monPrima, setMonPrima] = useState(0);
    const [FecContrato, setFecContrato] = useState(null);
    const [FecPrimerRebajo, setFecPrimerRebajo] = useState(null); // Fecha de vencimiento
    const [FechaValida, setFechaValida] = useState(false); // Fecha de vencimiento
    const [FechaContratoValida, setFechaContratoValida] = useState(false); // Fecha de vencimiento
    const [errorFechaPrimerPago, setErrorFechaPrimerPago] = useState(null);
    const [listaTiposDeCuenta, setListaTiposDeCuenta] = useState([]);
    const [listaTiposDeTarjeta, setListaTiposDeTarjeta] = useState([]);
    const [listaFrecuenciaDePago, setListaFrecuenciaDePago] = useState([]);
    const [idUsuario, setIdUsuario] = useState('');
    const [pagoMensual, setPagoMensual] = useState(0);
    const [pagoQuincenal, setPagoQuincenal] = useState(0);
    const [pagoQuincenal2, setPagoQuincenal2] = useState(0);
    const [mostrarFormularioAbono, setMostrarFormularioAbono] = useState(false);
    const [mostrarFormularioLoteria, setMostrarFormularioLoteria] = useState(false);
    const [accionCuota, setAccionCuota] = useState(false);
    const [detenerCuotas, setDetenerCuotas] = useState(false);
    const [idSupervisor, setIdSupervisor] = useState('');
    const [idVendedor, setIdVendedor] = useState('');


    //Regalias
    const [idEstadoRegaliaMontania, setIdEstadoRegaliaMontania] = useState(1);
    const [idEstadoRegaliaPlaya, setIdEstadoRegaliaPlaya] = useState(1);
    const [ultimoAbonoAprobado, setUltimoAbonoAprobado] = useState(0);
    const [activarRegaliaMontania, setActivarRegaliaMontania] = useState(false);
    const [activarRegaliaPlaya, setActivarRegaliaPlaya] = useState(false);

    //completar club
    const [mostrarFormularioCompletarClubViaje, setMostrarFormularioCompletarClubViaje] = useState(false);

    const [NumContratoInicial, setNumContratoInicial] = useState("");
    const [mostrarTipoCuotas, setmostrarTipoCuotas] = useState(false);
    const [EstadoClubDeViaje, setEstadoClubDeViaje] = useState(0);

    const [TipoDeCobro, setTipoDeCobro] = useState(1);
    const [PagoAutomatico, setPagoAutomatico] = useState(true);

    const [CuotasRegaladas, setCuotasRegaladas] = useState(50);
    const [CuotasRestantes, setCuotasRestantes] = useState(50);

    const [MonCuotasRegalia, setMonCuotasRegalia] = useState(0);
    const [MontoAhorrado, setMontoAhorrado] = useState(0);

    const [NomBeneficiario, setNomBeneficiario] = useState("");
    const [NumIdentificacionBeneficiario, setNumIdentificacionBeneficiario] = useState("");

    const [Destino, setDestino] = useState("");

    const [actualizarSaldo, setActualizarSaldo] = useState(false);

    //PERMISOS GENERALES
    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);

    //PERMISOS CLUB DE VIAJES
    const [PermisoAbonoC, setPermisoAbonoC] = useState(false);
    const [PermisoLoteria, setPermisoLoteria] = useState(false);
    const [PermisoComentarioC, setPermisoComentarioC] = useState(false);
    const [PermisoDocumentoC, setPermisoDocumentoC] = useState(false);



  
    const actualizarEstadoCuotas = async (cuota) => {
        const respuesta = await ActualizarEstadoCuota(cuota);
        setMensajeRespuesta(respuesta);
        setShowAlert(true);
    }

    const [isLoading, setIsLoading] = useState(false);



    
        const loadData = async () => {
            try {
                setIsLoading(true);

            // Cargar los valores primero
            const tiposCuentas = await ObtenerTiposDeCuentas();
            const tiposTarjeta = await ObtenerTiposDeTarjetas();
            const frecuenciaDePago = await ObtenerFrecuenciasDePago();
            ObtenerListadoDeCuotas();

            setListaTiposDeCuenta(tiposCuentas);
            setListaTiposDeTarjeta(tiposTarjeta);
            setListaFrecuenciaDePago(frecuenciaDePago);


                const url = new URL(window.location.href);
                // Obtén el valor del parámetro 'idClub'
                const aux = url.searchParams.get("idClub");
                const idClub = aux == "" || !aux ? 0 : parseInt(aux);
                setIdClub(idClub);
                if (idClub !== 0) {
                    obtenerEstadoCuotasClub(idClub);
                    setIdClubDeViaje(idClub);
                    <>
                    {
                        IdClubDeViaje !== 0 && (
                            <Button variant="primary" onClick={onClickHacerAbono} className="mr-2 mb-2 card-button">
                                <FontAwesomeIcon icon={faCoins} className="mr-2" />
                                Hacer Abono
                            </Button>
                        )
                    }
                    </>


                    const DatosClubCargados = async () => {
                        const datosClub = await ObtenerClubDeViajePorId(idClub);
                        setNumContratoInicial(datosClub.numContrato)
                        cargarValores(datosClub);
                        setClubData(datosClub);
                        setIdEstadoRegaliaPlaya(datosClub.idEstadoRegaliaPlaya);
                        setIdEstadoRegaliaMontania(datosClub.idEstadoRegaliaMontania);

                        setMonCuotasRegalia(datosClub.monCuotasRegalia); 

                        setMontoAhorrado(datosClub.montoAhorrado);

                        setDestino(datosClub.destino);

                        if (datosClub.nomBeneficiario == null) {

                            if (datosClub.idPersonaBeneficiario != null) {


                                const nombreCompleto = `${datosClub.nombreBeneficiario} ${datosClub.primerApellido} ${datosClub.segundoApellido}`;

                                setValue("NomBeneficiario", nombreCompleto);
                                setValue("NumIdentificacionBeneficiario", datosClub.identificacionBeneficiario);
                            }
                            else {
                                setValue("NomBeneficiario", datosClub.nomBeneficiario);
                                setValue("NumIdentificacionBeneficiario", datosClub.numIdentificacionBeneficiario);
                            }

                        }
                        else {
                            setValue("NomBeneficiario", datosClub.nomBeneficiario);
                            setValue("NumIdentificacionBeneficiario", datosClub.numIdentificacionBeneficiario);
                        }
                        setCuotasRegaladas(datosClub.cuotasRegaladas); 
                        setCuotasRestantes(datosClub.cuotasRestantes);

                        setEstadoClubDeViaje(datosClub.estado);

                        var tipocobro = datosClub.tipoDeCobro ?? 1;
                        
                        setTipoDeCobro(tipocobro);

                        datosClub.cuotasAprobadas > 0 ? setmostrarTipoCuotas(false) : setmostrarTipoCuotas(true) 


                    };
                    DatosClubCargados();
                    setIsLoading(false);
                    obtenerUltimoAbonoAprobado(idClub);
                }
                else {
                    setmostrarTipoCuotas(true);
                    setPagoAutomatico(true);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            }
        }
  


    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [actualizarSaldo]);



    useEffect(() => {
        ObtenerListadoDeCuotas();
        ObtenerIdUsuario();
        ObtenerPermisosUsuario();
    }, []);

    useEffect(() => {
        setValue("NumCuenta", valoresCuenta.numCuenta === "" ? null : valoresCuenta.numCuenta);
        setValue("Emisor", valoresCuenta.emisor === "" ? null : valoresCuenta.emisor);
        setValue("FecVenceCuenta", valoresCuenta.fecVenceCuenta === "" ? null : valoresCuenta.fecVenceCuenta);

        setValue("NumContrato", valoresContrato.numContrato);

        setValue("IdTipoCuenta", valoresCuenta.idTipoCuenta);

        setValue("IdTipoTarjeta", valoresCuenta.idTipotarjeta);
        setValue("IdFrecuenciaPago", valoresCuenta.idFrecuenciaPago);
        setidFrecuenciaPago(valoresCuenta.idFrecuenciaPago);

        setValue("IdVendedor", valoresCuenta.idVendedor);
        setValue("IdSupervisor", valoresCuenta.idSupervisor);


        setValue("idCuota", valoresCuota.idCuota);
        setValue("MonCuota", valoresCuota.MonCuota);
        setValue("Monto", valoresCuota.Monto);
        setFilaSeleccionadaCuotas("idCuota", valoresCuota.idCuota);
    }, [
        valoresCuenta.numCuenta,
        valoresCuenta.emisor,
        valoresCuenta.fecVenceCuenta,
        valoresContrato.numContrato,
        valoresCuenta.idTipoCuenta,
        valoresCuenta.idTipotarjeta,
        valoresCuenta.idFrecuenciaPago,
        valoresCuota.idVendedor,
        valoresCuota.idSupervisor,
        valoresCuota.idCuota,
        valoresCuota.MonCuota,
        valoresCuota.Monto,
    ]);


    const {
        register: register,
        handleSubmit: handleSubmit,
        formState: { errors: errors },
        trigger: trigger,
        setValue,
    } = useForm();

    const {
        register: registerDocumentos,
        handleSubmit: handleSubmitDocumentos,
        formState: { errors: errorsDocumentos },
        trigger: triggerDocumentos,
        control: controlDocumentos,
    } = useForm();

    const {
        register: registerComentario,
        handleSubmit: handleSubmitComentario,
        formState: { errors: errorsComentario },
        trigger: triggerComentario,
        setValue: setearValor,
    } = useForm();

    const cargarComentarios = (Comentarios) => {
        setListaDeComentarios(Comentarios);
    };

    //aca carga todos los datos


    const cargarValores = (Datos) => {
        var persona = {
            idPersona: Datos.idPersona,
            nombre: Datos.nombre,
            primerApellido: Datos.primerApellido,
            segundoApellido: Datos.segundoApellido,
            identificacion: Datos.identificacion,
            provincia: Datos.provincia,
            canton: Datos.canton,
            distrito: Datos.distrito,
            direccion: Datos.direccion,
            telefono: Datos.telefono,
            correoElectronico: Datos.correoElectronico,
        };
        var beneficiario = {
            idPersona: Datos.idPersonaBeneficiario,
            nombre: Datos.nombreBeneficiario,
            primerApellido: Datos.primerApellidoBeneficiario,
            segundoApellido: Datos.segundoApellidoBeneficiario,
            identificacion: Datos.identificacionBeneficiario,
            profesion: Datos.profesionBeneficiario,
            correoElectronico: Datos.correoElectronicoBeneficiario,
        };

        var cuota = {
            idCuota: Datos.idCuota,
            MonCuota: Datos.monCuota,
            Monto: Datos.monto
        };
        setvaloresCuota(cuota);
        setFilaSeleccionadaCuotas(Datos.idCuota)

        var cuenta = {
            numCuenta: Datos.numCuenta ?? '',
            fecVenceCuenta: Datos.fecVenceCuenta ?? '',
            emisor: Datos.banco ?? '',
            idFrecuenciaPago: Datos.idFrecuenciaPago ?? '',
            idTipoCuenta: Datos.tipoCuenta ?? '',
            idTipotarjeta: Datos.tipoTarjeta ?? 0,
            idSupervisor: Datos.idSupervisor ?? '',
            idVendedor: Datos.idVendedor ?? ''
        };



        setvaloresCuenta(cuenta);

        var contrato = {
            numContrato: Datos.numContrato,
        };
        setvaloresContrato(contrato);

        setMonPrima(Datos.monPrima);

        
        const comentarios =
            Object.values(Datos.comentarios).map((comentario) => {
            
                return {
                    IdClubDeViaje: comentario.idClubDeViaje,
                    Comentario: comentario.comentario,
                    FechaCreacion: comentario.fechaCreacion,
                    idComentarioClubDeViaje: comentario.idComentarioClubDeViaje,
                    Estado: comentario.Estado,
                };
            });
        setListaDeComentarios(comentarios);

        if (Datos.idFrecuenciaPago == "1") {
            setMostrarSemanal(true);
            setSelectedOption(nombreDia(Datos.fechaDePago));
        }
        if (Datos.idFrecuenciaPago != "1") setMostrarSemanal(false);
        if (Datos.idFrecuenciaPago == "2") {
            setMostrarQuincenal(true);


            const [pagoQuincenal, pagoQuincenal2] = Datos.fechaDePago
                .split(",")
                .map(Number);
            setPagoQuincenal(pagoQuincenal);
            setPagoQuincenal2(pagoQuincenal2);
        }
        if (Datos.idFrecuenciaPago != "2") setMostrarQuincenal(false);
        if (Datos.idFrecuenciaPago == "3") {
            setMostrarMensual(true);
            setSelectedOption(Datos.fechaDePago);
        }
        if (Datos.idFrecuenciaPago != "3") setMostrarMensual(false);
        setPagoMensual(Datos.fechaDePago);

        setIdVendedor(Datos.idVendedor);
        setIdSupervisor(Datos.idSupervisor);

        // Función para formatear la fecha
        const formatearFecha = (fecha) => {
            try {
                const fechaObjeto = new Date(fecha);
                const año = fechaObjeto.getFullYear();
                const mes = fechaObjeto.getMonth() + 1;
                const dia = fechaObjeto.getDate();
                return `${mes < 10 ? "0" : ""}${mes}-${dia < 10 ? "0" : ""
                    }${dia}-${año}`;
            } catch (error) {
                console.error("Error al formatear la fecha:", error);
                return null;
            }
        };
        // Formatear FecContrato
        const FecContratoFormateada = new Date(formatearFecha(Datos.fecContrato));

        // Formatear FecVencimiento
        const FecPrimerRebajoFormateada = new Date(
            formatearFecha(Datos.fecPrimerRebajo)
        );
        setFecContrato(FecContratoFormateada);
        setFecPrimerRebajo(FecPrimerRebajoFormateada);


        if (beneficiario.nombre != null) {
            const nombreCompleto = `${beneficiario.nombre} ${beneficiario.primerApellido} ${beneficiario.segundoApellido}`;
            setValue("NomBeneficiario", nombreCompleto);
            setValue("NumIdentificacionBeneficiario", beneficiario.identificacion.toString());
          
        }
        setPersonaSeleccionada(persona);
        setBeneficiarioSeleccionado(beneficiario);
    };

    const handleDateChange = (date) => {
        setFecPrimerRebajo(date);


        // Lógica de validación personalizada
        if (!date) {
            setErrorFechaPrimerPago("La fecha de primer rebajo es requerida");
           
        }

        else if (!FecContrato || date < FecContrato) {
            setFechaValida(true);
            setErrorFechaPrimerPago("La fecha debe ser mayor o igual a la fecha del contrato");
        }

        var diaDepago = date.getDate().toString();
        if (mostrarQuincenal) {


            if (
                diaDepago !== pagoQuincenal &&
                diaDepago !== pagoQuincenal2
            ) {
                setErrorFechaPrimerPago(
                    "El día de la fecha del primer rebajo debe ser igual al primer o segundo dia de rebajo seleccionado en Pagos quincenales:"
                );
                setFechaValida(true);
                return
            } else {
                setFechaValida(false);
                setErrorFechaPrimerPago(null);
            }
        }

        if (mostrarMensual) {
            if (diaDepago !== pagoMensual) {
                setErrorFechaPrimerPago("El día de la fecha del primer rebajo debe ser igual al día del mes seleccionado en Pago Mensual");
                setFechaValida(true);
                //return;
            } else {
                setFechaValida(false);
                setErrorFechaPrimerPago(null);
                
            }
        }
        else {
            setFechaValida(false);
            setFechaContratoValida(false);
            setErrorFechaPrimerPago(null); // Borra el mensaje de error si la fecha es válida
            //isValid(true);
        }
    };

    const handleDateContratoChange = (date) => {
        setFecContrato(date);
        // Lógica de validación personalizada
        if (!date) {
            //setErrorFechaPrimerPago("La fecha no puede estar vacía");
            setFechaContratoValida(true);
        } else if (date > FecPrimerRebajo) {
            setFechaValida(true);
            setErrorFechaPrimerPago("La fecha debe ser mayor o igual a la fecha del contrato");
        } else if (date <= FecPrimerRebajo) {
            setFechaValida(false);
            setFechaContratoValida(false);
            setErrorFechaPrimerPago(null); // Borra el mensaje de error si la fecha es válida
        }

        else {
            if (mostrarQuincenal) {

                if (
                    date.getDate().toString() !== pagoQuincenal ||
                    date.getDate().toString() !== pagoQuincenal2
                ) {
                    setErrorFechaPrimerPago(
                        "El día de la fecha del primer rebajo debe ser igual al primer o segundo dia de rebajo seleccionado en Pagos quincenales:"
                    );
                    setFechaValida(true);
                    return;
                } else {
                    setFechaValida(false);
                    setErrorFechaPrimerPago(
                        null
                    );
                }
            }
            if (mostrarMensual) {
                if (date.getDate().toString() !== pagoMensual) {
                    setErrorFechaPrimerPago("El día de la fecha del primer rebajo debe ser igual al día del mes seleccionado en Pago Mensual");
                    setFechaValida(true);
                    return;
                } else {
                    setFechaValida(false);
                    setErrorFechaPrimerPago(
                        null
                    );
                }
            }// Borra el mensaje de error si la fecha es válida
        }
    };
    const handlePrimaChange = (valor) => {
        const redondeado = parseFloat(valor).toFixed(2);
        setMonPrima(Number(redondeado));
    }


    const ObtenerListadoDeCuotas = async () => {
        setPendiente(true);
        setListaDeCuotas(await ObtenerCuotas());
        setPendiente(false);
    };

    const onClickCerrarBuscarPersonas = () => {
        setBuscarPersonas(false);
        setShowFormulario(true);
    };

    const onClickCerrarBuscarBeneficiario = () => {
        setBuscarBeneficiario(false);
        setShowFormulario(true);
    };

    const onClickAceptarBuscarPersonas = (persona) => {
        setPersonaSeleccionada({ ...persona });
        setBuscarPersonas(!buscarPersonas);
    };

    const onClickAceptarBuscarBeneficiario = (benficiario) => {
        setBeneficiarioSeleccionado({ ...benficiario });
        setBuscarBeneficiario(!buscarBeneficiario);
    };

    const onClickBuscarPersona = () => {
        setBuscarPersonas(!buscarPersonas);
        setShowFormulario(false);
    };

    const onClickBuscarBeneficiario = () => {
        setBuscarBeneficiario(!buscarPersonas);
        setShowFormulario(false);
    };

    const handleManualValidationComentarios = async () => {
        const isValid = await triggerComentario();

        if (!isValid) {
            setIsSummit(true);
        }
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth' 
        });
    };
    
    const handleManualValidationInformacionGeneral = async () => {
        const isValid = await trigger();

        if (!FecPrimerRebajo) {
            setFechaValida(true);
            var respuestaInvalida = {
                indicador: 3,
                mensaje: "Hay campos vacios en Informacion del Contrato",
            };
            scrollToBottom()

            setMensajeRespuesta(respuestaInvalida);
            setShowAlert(true);
        }
        if (!FecContrato) {
            setFechaContratoValida(true);
            scrollToBottom()

        }
        if (!isValid) {
            setIsSummitInfo(true);

        }
        
    };


    function obtenerFechaActual() {
        var fecha = new Date();
        var dia = fecha.getDate();
        var mes = fecha.getMonth() + 1;
        var año = fecha.getFullYear();

        // Agregar un cero inicial si el día o el mes son menores a 10
        dia = dia < 10 ? "0" + dia : dia;
        mes = mes < 10 ? "0" + mes : mes;

        // Formato de cadena: DD/MM/AAAA
        var fechaActual = dia + "/" + mes + "/" + año;
        return fechaActual;
    }

    const listaDias = [];
    for (let i = 1; i <= 30; i++) {
        listaDias.push({ id: i, Dias: `${i}` });
    }






    //aca se agrega el comentario


    const handleResponse = (handleResponse) => {

        if (handleResponse.indicador == 0) {

            loadData();
            setMensajeRespuesta(handleResponse.mensaje);
            if (handleResponse.indicador == 0) {
                setMensajeRespuesta(handleResponse);

            } else {
                setMensajeFormulario(handleResponse);
            }

            setShowAlert(true);

            const Datos = clubData

            const comentarios =
                Object.values(Datos.comentarios).map((comentario) => {

                    return {
                        IdClubDeViaje: comentario.idClubDeViaje,
                        Comentario: comentario.comentario,
                        FechaCreacion: comentario.fechaCreacion,
                        idComentarioClubDeViaje: comentario.idComentarioClubDeViaje,
                        Estado: comentario.Estado,
                    };
                });
            setListaDeComentarios(comentarios);
        }
    };

    const handleSubmitComentarios = async (data) => {


        if (IdClub == null || IdClub == 0) {
            setListaDeComentarios([
                ...listaDeComentarios,
                {
                    Comentario: data.Comentario,
                    FechaCreacion: obtenerFechaActual(),
                },
            ]);
            setearValor("Comentario", "");
        }

        //Agregar Validación de si es diferente de nulo y mayor o diferente de cero
        if (IdClub !== null && IdClub !== 0) {
            var NewComentario = {
                Comentario: data.Comentario,
                FechaCreacion: null,
                IdClubDeViaje: IdClub,
                UsuarioCreacion: idUsuario,
            };

            var respuesta = await AgregarComentario(NewComentario);
            if (respuesta.indicador === 0) {
                setMensajeRespuesta(respuesta);
            } else {
                setMensajeFormulario(respuesta.mensaje);
            }
            setShowAlert(true);
            setListaDeComentarios([
                ...listaDeComentarios,
                {
                    idComentarioClubDeViaje: respuesta.lastInserted,
                    Comentario: data.Comentario,
                    FechaCreacion: obtenerFechaActual(),
                },
            ]);

            setearValor("Comentario", "");

        };

    };

    const ObtenerIdUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setIdUsuario(String(usuario.idUsuario));
  
    }

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));

        //Generales
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false );
        setPermisoEliminar(usuario.PermisoEliminar ?? false);

        //Club de viajes
        setPermisoAbonoC(usuario.PermisoAbonoC ?? false)
        setPermisoLoteria(usuario.PermisoLoteria ?? false)
        setPermisoComentarioC(usuario.PermisoComentarioC ?? false)
        setPermisoDocumentoC(usuario.PermisoDocumentoC ?? false)


    }

    const obtenerEstadoCuotasClub = async (idClub) => {
        var result = await ObtenerEstadoCuotasClub(Number(idClub));
        setAccionCuota((result.idEstadoCuota == 1) ? false : true);
    }

    const obtenerUltimoAbonoAprobado = (idClub) => {
        ObtenerUltimoAbonoAprobadoClubDeViaje(Number(idClub))
            .then((result) => {
                

                setUltimoAbonoAprobado(result.numAbono); //Cantidad de Cuotas <Aprobadas>.
            })
            .catch((error) => {
                //console.error("Error al obtener el último abono aprobado", error);
            });
    };


    const [respuesta, setrespuesta] = useState([]);
    const handleSubmitInfoGeneral = async (data) => {

       
        if (errorFechaPrimerPago !== null || FechaValida) {

            scrollToBottom()
            return;
        }
        else {


            if (IdClub == null || IdClub == 0) {
                if (
                    personaSeleccionada.idPersona === null ||
                    personaSeleccionada.idPersona === undefined
                ) {
                    setConfirmModalOpen(true);
                }
                else if (
                    filaSeleccionadaCuotas === null ||
                    filaSeleccionadaCuotas === {}
                ) {
                    setConfirmModalOpen(true);
                }
                else {
                    var monPrimaValidado = isNaN(monPrima) ? 0 : monPrima;

                    var DataClub = {
                        ...data,
                        IdPersona: personaSeleccionada.idPersona,
                        IdPersonaBeneficiario: BeneficiarioSeleccionado.idPersona,
                        IdCuota: filaSeleccionadaCuotas,
                        comentarios: listaDeComentarios,
                        FecContrato: FecContrato,
                        MonPrima: monPrimaValidado,
                        FecPrimerRebajo: FecPrimerRebajo,
                        IdClub: null,
                        UsuarioCreacion: idUsuario,
                        IdVendedor: idVendedor,
                        IdSupervisor: idSupervisor,
                        correoCliente: personaSeleccionada.correoElectronico
                    };
                    DataClub.PagoSemanal = DataClub.PagoSemanal ? numeroDia(DataClub.PagoSemanal) : null;

                    setIsLoading(true);
                    //DataClub.ModificoNumContrato = 1;

                    DataClub.NomBeneficiario = data.NomBeneficiario
                    DataClub.NumIdentificacionBeneficiario = data.NumIdentificacionBeneficiario
                    DataClub.Estado = null; 
                    DataClub.TipoDeCobro = TipoDeCobro;


                    console.log(DataClub);


                    if (DataClub.correoCliente) {
                        var respuesta = await AgregarClubDeViaje(DataClub);
                    } else {
                        respuesta = {
                            indicador: 1,
                            mensaje: "Verifique la información del cliente",
                        };
                    }

                    setIsLoading(false);

                    if (respuesta.indicador == 0 || respuesta.mensaje == "Exito: Club de Viaje Registrado Exitosamente") {
                        setIdClubDeViaje(respuesta.lastInserted);
                        setIdClub(respuesta.lastInserted);

                        var lastInserted = respuesta.lastInserted;
                        //ObtenerListadoDeClientes();
                        respuesta = {
                            indicador: 0,
                            mensaje: "Exito: Club de Viaje Registrado Exitosamente",
                        };

                        setMensajeRespuesta(respuesta);
                        setShowAlert(true);

                        window.location.href = "ClubDeViajes?idClub=" + lastInserted;
                    }
                    else {
                        setMensajeRespuesta(respuesta);
                        setShowAlert(true);
                        scrollToBottom();
                    }
                }//en else antes de declarar Dataclub 

            }
            else {
                var monPrimaValidado = isNaN(monPrima) ? 0 : monPrima;
                var DataClub = {
                    ...data,
                    IdPersona: personaSeleccionada.idPersona,
                    IdPersonaBeneficiario: BeneficiarioSeleccionado.idPersona,
                    IdCuota: filaSeleccionadaCuotas.idCuota,
                    comentarios: listaDeComentarios,
                    FecContrato: FecContrato,
                    MonPrima: monPrimaValidado,
                    FecPrimerRebajo: FecPrimerRebajo,
                    idClub: IdClub,
                    UsuarioCreacion: idUsuario,
                    IdVendedor: idVendedor,
                    IdSupervisor: idSupervisor
                };
                DataClub.PagoSemanal = DataClub.PagoSemanal ? numeroDia(DataClub.PagoSemanal) : null;

                
                if (NumContratoInicial === DataClub.NumContrato) {
                    DataClub.ModificoNumContrato = 2;
                } else {
                    DataClub.ModificoNumContrato = 1;
                }

                DataClub.TipoDeCobro = TipoDeCobro; 



                DataClub.NomBeneficiario = data.NomBeneficiario ?? "";
                DataClub.NumIdentificacionBeneficiario = data.NumIdentificacionBeneficiario.toString() ?? ""


                
                setValue("IdFrecuenciaPago", DataClub.IdFrecuenciaPago);


                setIsLoading(true);
                var respuesta = await ActualizarClubDeViaje(DataClub);
                setIsLoading(false);

                if (respuesta.indicador == 0) {
                    setMensajeRespuesta(respuesta);
                    setShowAlert(true);
                    setidFrecuenciaPago(DataClub.IdFrecuenciaPago);
                    loadData();
                }
                else {
                    setMensajeRespuesta(respuesta);
                    setShowAlert(true);
                }
            }
        }//end if de error de fecha
    };

    const [idFrecuenciaPago, setidFrecuenciaPago] = useState(0);


    //mostrar modal abonos
    const onClickHacerAbono = async () => {


        if (valoresCuenta.idFrecuenciaPago === 1) {

            setValue('MonPagar', valoresCuenta.MonCuota);

        } else if (valoresCuenta.idFrecuenciaPago === 2) {

            setValue('MonPagar', valoresCuenta.MonCuota * 2);

        } else if (valoresCuenta.idFrecuenciaPago === 3) {

            setValue('MonPagar', valoresCuenta.MonCuota * 4);
        }
        setMostrarFormularioAbono(true);
    }

    const onClickLoteria = async () => {

        setMostrarFormularioLoteria(true);
    }

    //Cerrar modal abonos
    const onClickCerrarmodalAbonos = () => {

        setMostrarFormularioAbono(false);
    }

    const onClickCerrarModalLoteria = () => {

        setMostrarFormularioLoteria(false);
    }

    //Cambiar el estado de cobros
    const handleCambioCuota = async () => {
        if (!accionCuota) {
            setDetenerCuotas(true);
        } else {
            await confirmarCuotas();
        }
    }

    //Cambiar el estado de regalía montaña
    const handleAplicarRegaliaMontania = async () => {
        setActivarRegaliaMontania(true);
    }

    //Cambiar el estado de regalía playa
    const handleAplicarRegaliaPlaya = async () => {
        setActivarRegaliaPlaya(true);
    }

    const confirmarActivarRegaliaMotania = async () => {
        setIdEstadoRegaliaMontania(3);
        setActivarRegaliaMontania(false)
        var reagaliaMontania = {
            IdClub: IdClubDeViaje,
            EstadoRegalia: 3,
            UsuarioModificacion: idUsuario
        }
        await ActivarRegaliaMontania(reagaliaMontania);

    }
    const confirmarActivarRegaliaPlaya = async () => {
        setIdEstadoRegaliaPlaya(3);
        setActivarRegaliaPlaya(false)
        var reagaliaPlaya = {
            IdClub: IdClubDeViaje,
            EstadoRegalia: 3,
            UsuarioModificacion: idUsuario
        }
        await ActivarRegaliaPlaya(reagaliaPlaya);
    }

    const confirmarCuotas = async () => {
        setDetenerCuotas(false);
        setAccionCuota(!accionCuota);
        var cuota = {
            IdClub: IdClubDeViaje,
            IdEstadoCuota: accionCuota ? 1 : 2,
            UsuarioCreacion: idUsuario
        }
        await actualizarEstadoCuotas(cuota);
    }

    function numeroDia(dia) {
        const diaSinTildes = dia.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        const mapaDias = {
            Domingo: "1",
            Lunes: "2",
            Martes: "3",
            Miercoles: "4",
            Jueves: "5",
            Viernes: "6",
            Sabado: "7"
        };

        return mapaDias[diaSinTildes];
    }

    function nombreDia(dia) {

        const mapaDias = {
            1: 'Domingo',
            2: 'Lunes',
            3: 'Martes',
            4: 'Miércoles',
            5: 'Jueves',
            6: 'Viernes',
            7: 'Sábado'
        };

        return mapaDias[dia];
    }


    //Cerrar modal completar club
    const onClickCerrarmodalCompletarClubViaje = () => {

        setMostrarFormularioCompletarClubViaje(false);
    }

    //mostrar modal comletar club de viaje
    const onClickCompletarClub = async () => {


        var DataClub = {
            idClub: IdClub,
            Estado: 0
        };

        var respuesta = await ActualizarClubDeViaje(DataClub);

        if (respuesta.indicador === 0) {
            setEstadoClubDeViaje(0);

        } else {
            setEstadoClubDeViaje(1);
        }




    }

    const actualizarDatosCierreClub = (nuevosDatos) => {
        setClubData({
            destino: nuevosDatos.destino,
            montoReserva: nuevosDatos.montoReserva,
            montoCobradoAlSocio: nuevosDatos.montoCobradoAlSocio
        });

    };

    const [ActivarClub, setActivarClub] = useState(false);
    const [InactivarClub, setInactivarClub] = useState(false);


    const onConfirmInactivarClub = async () => {
        var DataClub = {
            idClub: IdClub,
            Estado: 0
        };

        var respuesta = await ActualizarClubDeViaje(DataClub);

        if (respuesta.indicador === 0) {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
            setEstadoClubDeViaje(0);
        } else {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
        }

        setInactivarClub(false);
    };

    const onConfirmActivarClub = async () => {
        var DataClub = {
            idClub: IdClub,
            Estado: 1
        };


        var respuesta = await ActualizarClubDeViaje(DataClub);

        if (respuesta.indicador === 0) {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
            setEstadoClubDeViaje(1);
        } else {
            setMensajeRespuesta(respuesta);
            setShowAlert(true);
        }

        setActivarClub(false);
    };

    return (     

        <>
            <SpinnerPersonalizado show={isLoading} />

            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <ConfirmModal
                isOpen={ActivarClub}
                toggle={() => setActivarClub(false)}
                message={`¿Desea Activar este Club de Viajes? `}
                onConfirm={onConfirmActivarClub}
            />
            <ConfirmModal
                isOpen={InactivarClub}
                toggle={() => setInactivarClub(false)}
                message={`¿Desea Inactivar este Club de Viajes? `}
                onConfirm={onConfirmInactivarClub}
            />
            <Form onSubmit={handleSubmit(handleSubmitInfoGeneral)}>
                <div className="d-flex justify-content-end">
                    {EstadoClubDeViaje === 0 && IdClubDeViaje !== 0 && CuotasRestantes > 0 && PermisoEliminar && (
                        <Button variant="primary"
                            onClick={() => setActivarClub(true)}
                            className="mr-3 mb-3 card-button">
                            <FontAwesomeIcon icon={faPlay} className="mr-2" />
                            Activar Club de viaje
                        </Button>
                    )}
                    {EstadoClubDeViaje === 1 && CuotasRestantes > 0 && PermisoEliminar && (
                        <Button style={{ backgroundColor: 'red', borderColor: 'red', color: 'white' }}
                            variant="primary"
                            onClick={() => setInactivarClub(true)} className="mr-2 mb-2 card-button">
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                            Inactivar
                        </Button>
                    )}
                    {IdClubDeViaje !== 0 && CuotasRestantes === 0  && EstadoClubDeViaje === 1 && (//cambiar a 130
                        <Button variant="primary" onClick={onClickCompletarClub} className="mr-2 mb-2 card-button">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            Finalizar Contrato
                        </Button>
                    )}


                    {IdClubDeViaje !== 0 && CuotasRestantes === 0 && EstadoClubDeViaje === 0 && (//cambiar a 130
                        <>
                            <Row className="d-flex justify-content-end" style={{ backgroundColor: '#d4edda', alignItems: 'center', justifyContent: 'center', padding: '5px 0', marginRight: "1%" }}>
                                <label style={{ color: 'black', width: "auto" , fontSize: '22px', fontWeight: 'bold', padding: "0 50px" }}>
                                        Contrato Finalizado     
                                    <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '18px' }} />
                                </label>
                            </Row>
                        </>
                    )}
                                {/*{CuotasRestantes === 0 && (*/}

                                {/*)}*/}
                    {IdClubDeViaje !== 0 &&  (
                        <>
                        {EstadoClubDeViaje === 1 &&(
                            <>

                                {CuotasRestantes > 0 && PermisoLoteria && (
                                    <Button variant="primary" onClick={onClickLoteria} className="mr-2 mb-2 card-button">
                                            <FontAwesomeIcon icon={faTicket} className="mr-2" />
                                            Premios de Lotería
                                    </Button>
                                )}

                                    {CuotasRestantes > 0 && PermisoAbonoC && (
                                    <Button variant="primary" onClick={onClickHacerAbono} className="mr-2 mb-2 card-button">
                                        <FontAwesomeIcon icon={faCoins} className="mr-2" />
                                        Hacer Abono
                                    </Button>
                                )}


                                
                            </>
                        )}
                            {CuotasRestantes > 0 && EstadoClubDeViaje === 1 && PermisoActualizar && (
                            <>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={handleManualValidationInformacionGeneral}
                                    className="mr-10px mb-2 , card-button"
                                >
                                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                    Guardar Información
                                </Button>
                            </>
                        )}
                        </>
                    )}

                    {IdClubDeViaje === 0 && PermisoAgregar && (
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={handleManualValidationInformacionGeneral}
                            className="mr-10px mb-2 , card-button"
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            Guardar Información
                        </Button>

                    )}


                </div>

                <div className="d-flex justify-content-around">
                    <CardInformacionCliente
                        onClickBuscarPersona={onClickBuscarPersona}
                        personaSeleccionada={personaSeleccionada}
                        InputsFormsReactHook={InputsFormsReactHook}
                        errors={errors}
                        isSummitInfo={isSummitInfo}
                        register={register}
                        NomBeneficiario={NomBeneficiario}
                        NumIdentificacionBeneficiario={NumIdentificacionBeneficiario}
                        IdClubDeViaje={IdClubDeViaje}
                    />



                    {/* aca se envia la informacion del cliente*/}


                    <CardInformacionCuenta
                        errors={errors}
                        isSummitInfo={isSummitInfo}
                        valoresCuenta={valoresCuenta}
                        listaDias={listaDias}
                        register={register}
                        isSummit={isSummit}
                        setMostrarMensual={setMostrarMensual}
                        setMostrarQuincenal={setMostrarQuincenal}
                        setMostrarSemanal={setMostrarSemanal}
                        mostrarMensual={mostrarMensual}
                        mostrarQuincenal={mostrarQuincenal}
                        mostrarSemanal={mostrarSemanal}
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                        setPagoMensual={setPagoMensual}
                        setPagoQuincenal={setPagoQuincenal}
                        setPagoQuincenal2={setPagoQuincenal2}
                        pagoMensual={pagoMensual}
                        pagoQuincenal={pagoQuincenal}
                        pagoQuincenal2={pagoQuincenal2}
                        idSupervisor={idSupervisor}
                        idVendedor={idVendedor}
                        setIdSupervisor={setIdSupervisor}
                        setIdVendedor={setIdVendedor}
                        setFecPrimerRebajo={setFecPrimerRebajo}
                        fecPrimerRebajo={FecPrimerRebajo}
                        TipoDeCobro={TipoDeCobro}
                        setTipoDeCobro={setTipoDeCobro}
                        PagoAutomatico={PagoAutomatico}
                        setPagoAutomatico={setPagoAutomatico}
                    />
                </div>
            </Form>

            <div className="d-flex justify-content-around mt-3">
                <CardBuscarBeneficiario
                    setFilaSeleccionadaCuotas={setFilaSeleccionadaCuotas}
                    pendiente={pendiente}
                    listaDeCuotas={listaDeCuotas}
                    register={register}
                    setValue={setValue}
                    mostrarTipoCuotas={mostrarTipoCuotas}
                />
                <CardBitacoraComentarios
                    errorsComentario={errorsComentario}
                    registerComentario={registerComentario}
                    isSummit={isSummit}
                    handleSubmitComentario={handleSubmitComentario}
                    handleSubmitComentarios={handleSubmitComentarios}
                    handleManualValidationComentarios={handleManualValidationComentarios}
                    listaDeComentarios={listaDeComentarios}
                    pendienteComentarios={pendienteComentarios}
                    lastInserted={lastInserted}
                    handleResponse={handleResponse} // Nueva propiedad
                    setMensajeRespuesta={setMensajeRespuesta}
                    PermisoComentarioC={PermisoComentarioC}
                    setShowAlert={setShowAlert }
                />
            </div>

            <div className="d-flex justify-content-start mt-3 mb-4">
                <CardInformacionContrato
                    errors={errors}
                    isSummitInfo={isSummitInfo}
                    register={register}
                    FecContrato={FecContrato}
                    handleDateContratoChange={handleDateContratoChange}
                    FechaContratoValida={FechaContratoValida}
                    FecPrimerRebajo={FecPrimerRebajo}
                    handleDateChange={handleDateChange}
                    FechaValida={FechaValida}
                    handleCambioCuota={handleCambioCuota}
                    accionCuota={accionCuota}
                    saldo={clubData?.saldo}
                    CuotasAprobadas={clubData?.cuotasAprobadas}
                    IdClubDeViaje={IdClubDeViaje}
                    prima={monPrima}
                    valoresContrato={valoresContrato ? valoresContrato : "Contrato"}
                    handlePrimaChange={handlePrimaChange}
                    errorFechaPrimerPago={errorFechaPrimerPago}
                    ultimoAbonoAprobado={ultimoAbonoAprobado}
                    idEstadoRegaliaMontania={idEstadoRegaliaMontania}
                    handleAplicarRegaliaMontania={handleAplicarRegaliaMontania}
                    idEstadoRegaliaPlaya={idEstadoRegaliaPlaya}
                    handleAplicarRegaliaPlaya={handleAplicarRegaliaPlaya}
                    MonCuotasRegalia={MonCuotasRegalia}
                    CuotasRegaladas={CuotasRegaladas}
                    MontoAhorrado={MontoAhorrado}
                    Destino={Destino}
                />
                {(IdClubDeViaje !== 0 && IdClubDeViaje !== null) && (
                    <DocumentosClubDeViajeComponet idClub={IdClubDeViaje} PermisoDocumentoC={PermisoDocumentoC} />
                )}
                {detenerCuotas && (
                    <ConfirmModal
                        isOpen={detenerCuotas}
                        toggle={() => setDetenerCuotas(!detenerCuotas)}
                        message={`¿Desea detener cuotas del paquete de 
                        ${personaSeleccionada.nombre} ${personaSeleccionada.primerApellido} ${personaSeleccionada.segundoApellido}?`}
                        onConfirm={confirmarCuotas}
                    />
                )}
                {activarRegaliaMontania && (
                    <ConfirmModal
                        isOpen={activarRegaliaMontania}
                        toggle={() => setActivarRegaliaMontania(false)}
                        message={`¿Desea activar la regalía de montaña?`}
                        onConfirm={confirmarActivarRegaliaMotania}
                    />
                )}
                {activarRegaliaPlaya && (
                    <ConfirmModal
                        isOpen={activarRegaliaPlaya}
                        toggle={() => setActivarRegaliaPlaya(false)}
                        message={`¿Desea activar la regalía de playa?`}
                        onConfirm={confirmarActivarRegaliaPlaya}
                    />
                )}
            </div>

            <BuscarPersona
                show={buscarPersonas}
                handleClose={onClickCerrarBuscarPersonas}
                handleAceptar={onClickAceptarBuscarPersonas}
                className=""
                tamano="lg"
            >
                <h5>Personas buscador</h5>
            </BuscarPersona>
            <BuscarPersona
                show={buscarBeneficiario}
                handleClose={onClickCerrarBuscarBeneficiario}
                handleAceptar={onClickAceptarBuscarBeneficiario}
                className=""
                tamano="lg"
            >
                <h5>Personas buscador</h5>
            </BuscarPersona>
            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`Campos Requeridos`}
                    onConfirm={() => {
                        setConfirmModalOpen(false);
                    }}
                />
            )}


            <FormularioModal show={mostrarFormularioAbono} handleClose={onClickCerrarmodalAbonos} titulo={"Abonos"} className='' tamano="lg">

                <FormularioRegistroCobroClub idClub={IdClubDeViaje} MonCuota={valoresCuota.MonCuota} PermisoDocumentoC={PermisoDocumentoC} PermisoEliminar={PermisoEliminar} setActualizarSaldo={setActualizarSaldo} idFrecuenciaPago={idFrecuenciaPago } />

            </FormularioModal>

            <FormularioModal show={mostrarFormularioCompletarClubViaje} handleClose={onClickCerrarmodalCompletarClubViaje} titulo={"Finalizar"} className='' tamano="lg">

                {clubData && (
                    <FormularioCompletarClubViaje idClub={IdClubDeViaje} destino={clubData.destino} montoReserva={clubData.montoReserva} montoCobradoAlSocio={clubData.montoCobradoAlSocio} idUsuario={idUsuario} actualizarDatosCierreClub={actualizarDatosCierreClub} />
                )}

            </FormularioModal>


            <FormularioModal show={mostrarFormularioLoteria} handleClose={onClickCerrarModalLoteria} titulo={"Premios de Lotería"} className='' tamano="lg">

                <FormularioLoteriaClubViaje idClub={IdClubDeViaje} CuotasRegaladas={CuotasRegaladas} CuotasRestantes={CuotasRestantes} />

            </FormularioModal>


        </>
    );
};

export default ClubDeViajesComponet;
