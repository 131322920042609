import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

export const ModalAgregarComprobante = ({ show, handleClose, indicador, setMensajeRespuesta, setShowAlert ,id_Viaje, handleAgregarDocumento, numAbono ,obtenerAbonos }) => {
    const fileInputRef = useRef(null);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSave = async (data) => {
        const file = fileInputRef.current?.files[0];

        // Crear un objeto FormData
        const formData = new FormData();
        formData.append('file', file);
        formData.append('numAbono', numAbono); 
        formData.append('esDeAbono', true); 
        let response; 

        try {

            if (indicador === 1) {
                // Llamar al servicio InsertarDocumento con parámetros adicionales
                response = await fetch(`/Documentos/InsertarDocumento/${id_Viaje}`, {
                    method: 'POST',
                    body: formData,
                });

            }
            else {
                // Llamar al servicio InsertarDocumentosGrupos con parámetros adicionales
                response = await fetch(`/Documentos/InsertarDocumentosGrupos/${id_Viaje}`, {
                    method: 'POST',
                    body: formData,
                });
            }

            // Procesar la respuesta si es necesario
            const result = await response.json();

            setMensajeRespuesta(result);

            setShowAlert(true);


            
        } catch (error) {
            console.error(error);
        } finally {
            // Limpiar campos después de enviar
            handleClose();
            obtenerAbonos();
            fileInputRef.current.value = '';
        }
    };
    const onClickCerrarModal = () => {
        handleClose();
        fileInputRef.current.value = '';
    };
    const backdropStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Color oscuro con opacidad
        backdropFilter: 'blur(2px)', // Opcional: aplicar un desenfoque al fondo
    };

    const modalStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Asegura que ocupe la altura completa de la pantalla
        marginTop: '10vh', // Ajusta el margen superior para bajar el modal
    };

    return (
        <>
            { show && (
                <div style={backdropStyle} className="modal-backdrop" />
            )}
            <Modal show={show} onHide={onClickCerrarModal} backdrop="static" keyboard={false} style={modalStyle}>
                <Modal.Header closeButton>
                    <Modal.Title>Agrega un Documento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Archivo</Form.Label>
                            <Form.Control
                                type="file"
                                {...register('file', { required: 'Este campo es requerido.' })}
                                ref={e => {
                                    register('file').ref(e);
                                    fileInputRef.current = e;
                                }}
                            />
                            {errors.file && <p>{errors.file.message}</p>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClickCerrarModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={onSave} type="submit" form="fileForm">
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalAgregarComprobante;