import React, { useEffect, useState, useRef} from 'react';
import { Button, Card, Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye } from '@fortawesome/free-solid-svg-icons';
import { ObtenerDocumentosClubDeViajeID } from '../../servicios/ServicioDocumentos';
import { Grid } from "../../components/grid";
import { SpinnerPersonalizado } from "../../components/spinnerPersonalizado";
import { ModalVisorDocumentos } from '../../components/fileViewer';


const DocumentosClubDeViajeComponet = ({idClub, PermisoDocumentoC}) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setError
    } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [documentoIndicador, setDocumentoIndicador] = useState(null);
    const [documentoId, setDocumentoId] = useState(null);
    const fileInputRef = useRef(null);


    const [listaDeDocumentos, setListaDeDocumentos] = useState([]);

    const encabezadoDocumentos = [
        { id: 'idDocumento', name: 'ID', selector: (row) => row.idDocumento, head: 'ID', omit: true },        
        {
            id: "Documento",
            name: "Documento",
            selector: (row) => row.nomDocumento,
            head: "Documento",
            sortable: true,
        },
        {
            id: "FechaCreacion",
            name: "Fecha Carga",
            selector: (row) => row.fechaCreacion,
            head: "Fecha Carga",
        },
        {
            id: 'Ver',
            name: 'Abrir Documento',
            head: 'Ver',
            cell: (row) => (
               <div style={{ marginLeft: '40px' }}>
                  <Button variant="link" size="sm" onClick={() => handleAbrirDocumento(row)}>
                    <FontAwesomeIcon size="lg" icon={faEye} style={{ color: '#d65e28' }} />
                  </Button>
                </div>

            ),
        },
    ];


    useEffect(() => {
        if (idClub) {
            obtenerDocumentos();
        }
    }, [idClub]);


    const obtenerDocumentos = async () => {
        try {
            setIsLoading(true);
            if (idClub) {
                const documentos = await ObtenerDocumentosClubDeViajeID(idClub);
                setListaDeDocumentos(documentos);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', data.file[0]);
            formData.append('idClub', JSON.stringify(idClub.idClub));
            formData.append('esDeAbono', false); //Insertar el documento indicando que es de un Abono
            formData.append('numAbono', null);
       
           
          const response = await fetch(`/Documentos/InsertarDocumento/${idClub}`, {
            method: 'POST',
            body: formData,
          });  

        if (response.ok) {
            obtenerDocumentos();
        }
        setIsLoading(false);
        fileInputRef.current.value = null;



        } catch (error)  {
            console.log(error.message);
            setIsLoading(false);
          setError('file', { type: 'server', message: 'Error al enviar el archivo.' });
        }
      };


    const handleAbrirDocumento = async (fila) => {
        setDocumentoId(fila.idDocumento);
        setDocumentoIndicador(1);
        setShowModal(true);
    };

    const onClickSeleccionarFila = (fila) => { }

    return (
        <>
            <SpinnerPersonalizado show={isLoading} />

                <Card className="card-container mb-5" style={{ width: '45%' }}>
                    <Card.Body>
                        <Card.Title className="card-title">Documentos</Card.Title>
                        <Container className='d-Grid align-content-center'>

                        
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <ModalVisorDocumentos
                                show={showModal}
                                handleClose={() => setShowModal(false)}
                                titulo="Documento Club de Viaje"
                                tamano="lg"
                                idDocumento={documentoId}
                                indicador={documentoIndicador}
                            />
                            {PermisoDocumentoC && (
                                <>
                                <Form.Group className='col-md-12' controlId="file">
                                    <Form.Label>Archivo</Form.Label>
                              
                                <Form.Control
                                    type="file"
                                    {...register('file', { required: 'Este campo es requerido.' })}
                                    ref={e => {
                                        register('file').ref(e);
                                        fileInputRef.current = e;
                                    }}
                                />
                                {errors.file && <Form.Text className="text-danger">{errors.file.message}</Form.Text>}

                                </Form.Group>
                                <Button className='ml-3 mt-2 card-button' variant="primary" size="sm" type="submit" style={{ fontSize: '18px' }}>
                                    <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                    Agregar Documentos
                                </Button>
                                </>
                            )}
                        </form>
                        <Row className="mt-4">
                            <Col md={12}>
                                <span>Listado de Documentos</span>
                                <Grid
                                    gridHeading={encabezadoDocumentos}
                                    gridData={listaDeDocumentos}
                                    selectableRows={false}
                                    setFilaSeleccionada={onClickSeleccionarFila}
                                    idBuscar="id"
                                />
                            </Col>
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
        </>
    )
  
}

export default DocumentosClubDeViajeComponet;