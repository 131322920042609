import { ObtenerTokenUsuario } from "../utils/utilidades";

export const ProcesarDatosApi = async (method, url, data) => {
    const { token } = ObtenerTokenUsuario();
    //console.log("procesar datos api",token, data)
    const myInit = {
        method: method,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",  // Tipo de contenido JSON
            "Accept": "application/json",  // Aceptar respuesta JSON
            "Authorization": `Bearer ${token}`,  // Token de autorización
            "Access-Control-Allow-Origin": "*",  // **Nota**: Este encabezado es gestionado por el servidor
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",  // Métodos permitidos
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",  // Encabezados permitidos
        },
        mode: 'cors',  // Modo CORS para permitir solicitudes cross-origin
        cache: 'default',  // Configuración de cache
        body: JSON.stringify(data)  // Convertir los datos a JSON
    };

    const myRequest = new Request(url, myInit);
    try {
        const response = await fetch(myRequest);
        //console.log("respuesta: ",response,"my request:", myRequest)
        if (response.ok) {
            return await response.json();
        } else {
            return {indicador: 500, mensaje: 'Ocurrio un error en el proceso!'}
        }
    } catch (error) {
        console.error(error);
    }
    
}
export const ProcesarDatosApiFormData = async (method, url, data) => {
    const { token } = ObtenerTokenUsuario();
    const myInit = {
        method: method,
        headers: {
            'Content-Type': 'multipart/form-data',
            
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        cache: 'default',
        body: data
    };
    const myRequest = new Request(url, myInit);
    try {
        const response = await fetch(myRequest);
        if (response.ok) {
            return await response.json();
        } else {
            return {indicador: 500, mensaje: 'Ocurrio un error en el proceso!'}
        }
    } catch (error) {
        console.log(error);
    }
    
}