import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import logo from '../../../images/logo.webp';
import SubMenu from "./SubMenu";
import { useEffect, useState } from "react";
import { ObtenerAccesoMenuPorRol } from "../../../servicios/ServicioRoles";

import {
    faHome,
    faBriefcase,
    faPaperPlane,
    faQuestion,
    faImage,
    faCopy,
    faPlaneDeparture,
    faCreditCard,
    faList,
    faChartSimple,
    faUserTie,
    faPerson,
    faCashRegister,
    faUser,
    faPersonMilitaryPointing
    //importar mas iconos segun sea necesario
} from "@fortawesome/free-solid-svg-icons";

const iconMapping = {
    faHome: faHome,
    faBriefcase: faBriefcase,
    faPaperPlane: faPaperPlane,
    faQuestion: faQuestion,
    faImage: faImage,
    faCopy: faCopy,
    faPlaneDeparture: faPlaneDeparture,
    faCreditCard: faCreditCard,
    faList: faList,
    faChartSimple: faChartSimple,
    faPerson: faPerson,
    faCashRegister: faCashRegister,
    faUser: faUser,
    faPersonMilitaryPointing: faPersonMilitaryPointing
    // Agrega m�s iconos seg�n sea necesario
};

const SideBar = ({ isOpen, toggle }) => {
    const [rol, setRol] = useState(null);
    const [accesosRol, setAccesosRol] = useState([]);
    useEffect(() => {
        const ObtenerRol = async () => {
            let rol = JSON.parse(sessionStorage.getItem("data_usuario"));
            setRol(rol.descripcion);
            const resultado = await ObtenerAccesoMenuPorRol(rol.idRol);
            setAccesosRol(resultado)
        };

        ObtenerRol();
    }, []);

    const renderHomeSubMenu = () => {
        if (accesosRol.length > 0) {
            const categorizedMenus = {};
            accesosRol.forEach((acceso) => {
                const { categoriaMenuDescripcion, opcionMenuDescripcion, target, icon } = acceso;

                if (categoriaMenuDescripcion === opcionMenuDescripcion) {
                    // NavItem directly
                    categorizedMenus[opcionMenuDescripcion] = {
                        title: opcionMenuDescripcion,
                        target,
                        icon: iconMapping[icon],
                    };
                } else {
                    // Submenu item
                    categorizedMenus[categoriaMenuDescripcion] = {
                        ...(categorizedMenus[categoriaMenuDescripcion] || {}),
                        icon: iconMapping[icon],
                        items: [
                            ...(categorizedMenus[categoriaMenuDescripcion]?.items || []),
                            {
                                title: opcionMenuDescripcion,
                                target,
                               
                            },

                        ],
                    };
                }
            });

            return Object.entries(categorizedMenus).map(([title, { items, target, icon }]) => (
                items ? (
                    <SubMenu key={title} title={title} icon={icon} items={items} />
                ) : (
                    <NavItem key={title}>
                        <NavLink tag={Link} to={target}>
                                <FontAwesomeIcon icon={icon} className="mr-2" />
                            {title}
                        </NavLink>
                    </NavItem>
                )
            ));
        }
        return null;
    };

    return (
        <div className={classNames("sidebar", { "is-open": isOpen })}>
            <div className="sidebar-header" style={{ backgroundColor: "#005CB8" }}>

                <Link to="/">
                    <h3>
                        <img src={logo} className="img-fluid" style={{ width: "150px" }} />
                    </h3>
                </Link>
            </div>
            <div className="side-menu">
                <Nav vertical className="list-unstyled pb-3">
                    {renderHomeSubMenu()}
                    {rol === "Administrador" ?
                        (<NavItem key={"Roles"}>
                            <NavLink tag={Link} to="Roles">
                                <FontAwesomeIcon icon={faUserTie} className="mr-2" />
                                {"Roles"}
                            </NavLink>
                        </NavItem>) : null
                    }
                    {rol === "Cliente" ?
                        (<NavItem key={"Cliente"}>
                            <NavLink tag={Link} to="Cliente">
                                <FontAwesomeIcon icon={faUser} className="mr-2" />
                                {"Cliente"}
                            </NavLink>
                        </NavItem>) : null
                    }
                    {rol === "Ventas" ?
                        (<NavItem key={"Ventas"}>
                            <NavLink tag={Link} to="vendedor">
                                <FontAwesomeIcon icon={faCashRegister} className="mr-2" />
                                {"Vendedor"}
                            </NavLink>
                        </NavItem>

                        ) : null
                    }
                    {rol === "Supervisor" ?
                        (<NavItem key={"Ventas"}>
                            <NavLink tag={Link} to="Supervisor">
                                <FontAwesomeIcon icon={faPersonMilitaryPointing} className="mr-2" />
                                {"Supervisor"}
                            </NavLink>
                        </NavItem>

                        ) : null
                    }
                </Nav>
            </div>
        </div>
    );
};

export default SideBar;
