import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ModalAgregarAcompanantes = ({ show, handleClose, handleAceptar }) => {
    const [nombreCompleto, setNombreCompleto] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [nombreCompletoError, setNombreCompletoError] = useState('');
    const [identificacionError, setIdentificacionError] = useState('');

    const onSave = () => {
        let valid = true;

        if (!nombreCompleto) {
            setNombreCompletoError('El nombre completo es requerido');
            valid = false;
        } else {
            setNombreCompletoError('');
        }

        if (!identificacion) {
            setIdentificacionError('La identificación es requerida');
            valid = false;
        } else if (identificacion.length > 50) {
            setIdentificacionError('La identificación no debe tener mas de 50 caracteres');
            valid = false;
        } else {
            setIdentificacionError('');
        }

        if (valid) {
            handleAceptar({ nombreCompleto, identificacion });
            setNombreCompleto('');
            setIdentificacion('');
        }
    };

    const onClickCerrarModal = () => {
        handleClose();
        setNombreCompleto('');
        setIdentificacion('');
        setNombreCompletoError('');
        setIdentificacionError('');
    };

    return (
        <Modal show={show} onHide={onClickCerrarModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Acompañante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Nombre Completo</Form.Label>
                    <Form.Control
                        type="text"
                        value={nombreCompleto}
                        onChange={(e) => setNombreCompleto(e.target.value)}
                        isInvalid={!!nombreCompletoError}
                    />
                    <Form.Control.Feedback type="invalid">
                        {nombreCompletoError}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Identificación</Form.Label>
                    <Form.Control
                        type="text"
                        value={identificacion}
                        onChange={(e) => setIdentificacion(e.target.value)}
                        isInvalid={!!identificacionError}
                    />
                    <Form.Control.Feedback type="invalid">
                        {identificacionError}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickCerrarModal}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAgregarAcompanantes;
