import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import graphIcon from '../../images/bar-graph.png';
import '../../styles/reportes.css';
import Comisiones from "./Comisiones";
import Expedientes from './ExpedientesPaquetes';
import EstadosDeCuenta from './EstadosDeCuenta';




const Reportes = () => {

    return(
        <>
            <div className="container">
                <br />
                <br />
                <Row style={{ marginBottom: '20px' }} >
                    <Col className="title-reporte">
                        <h1 style={{ color: 'orangered' }} >Reportes</h1>
                </Col>
                <Col>
                    <img src={graphIcon} className="logo-reporte" />
                </Col>
                </Row>

                <Tabs defaultActiveKey="Reportes" id="paquetes-reportes">
                    <Tab eventKey="expedientespaquetes" title="Paquetes de Viaje">
                        <div>
                            <Expedientes />
                        </div>
                    </Tab>
                    <Tab eventKey="reportecomisiones" title="Comisiones">
                        <div>
                            <Comisiones />
                        </div>
                    </Tab>
                    <Tab eventKey="estadosdecuenta" title="Estados de Cuenta">
                        <div>
                            <EstadosDeCuenta />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Reportes;