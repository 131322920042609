import { ProcesarDatosApi } from "../api/ApiFetch";
const controlador = "Cobro";

export const ObtenerCobros = async (data) => {
    const url = `${controlador}/ObtenerCobros`;
    return await ProcesarDatosApi('POST', url, data);
}

export const InsertarAbonoPaquete = async (data) => {
    const url = `${controlador}/InsertarAbonoPaquete`;
    return await ProcesarDatosApi('POST', url, data);
}

export const InsertarAbonoClub = async (data) => {
    const url = `${controlador}/InsertarAbonoClub`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ImportarAbonosClub = async (data) => {
    const url = `${controlador}/ImportarAbonosClub`;
    return await ProcesarDatosApi('POST', url, data);
}

export const InsertarLoteriaClub = async (data) => {
    const url = `${controlador}/InsertarLoteriaClub`;
    return await ProcesarDatosApi('POST', url, data);
}

export const ObtenerLoteriaClub = async (id) => {
    const url = `${controlador}/ObtenerLoteriaClub/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerAbonoClubDeViaje = async (id) => {
    const url = `${controlador}/ObtenerAbonoClubDeViaje/${id}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerAbonos = async (idpaquete) => {
    const url = `${controlador}/ObtenerAbonosPaqueteDeViaje/${idpaquete}`;
    return await ProcesarDatosApi('GET', url);
}

export const EliminarAbonoPaqueteDeViaje = async (id , numAbono) => {
    const url = `${controlador}/EliminarAbonoPaqueteDeViaje?idpaquete=${id}&NumAbono=${numAbono}`;
    return await ProcesarDatosApi('DELETE', url);
}  

export const EliminarAbonoClubDeViajes = async (id, numAbono) => {
    const url = `${controlador}/EliminarAbonoClub?IdClubViaje=${id}&NumAbono=${numAbono}`;
    return await ProcesarDatosApi('DELETE', url);
}


export const ActualizarAprobacionCobros = async (data) => {
    const url = `${controlador}/actualizarAprobacionCobros`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ObtenerUltimoAbonoAprobadoClubDeViaje = async (idClub) => {
    const url = `${controlador}/ObtenerUltimoAbonoAprobadoClubDeViaje/${idClub}`;
    return await ProcesarDatosApi('GET', url);
}

export const ActualizarDesaprobarCobros = async (data) => {
    const url = `${controlador}/ActualizarDesAprobacionCobro`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const ActualizarAprobacionCobrosPaquetesDeViajes = async (data) => {
    const url = `${controlador}/ActualizarAprobacionCobrosPaquetesDeViajes`;
    return await ProcesarDatosApi('PUT', url, data);
}

export const CierrePeriodoClubDeViajes = async (id)=> {
    const url = `${controlador}/CierrePeriodoClubDeViajes?IdUsuario=${id}`;
    return await ProcesarDatosApi('POST', url);
}
export const ValidarCierre = async (id) => {
    const url = `${controlador}/ValidarCierre`;
    return await ProcesarDatosApi('GET', url);
}