import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { InputText } from '../../components/inputs';
import {
    ObtenerOpcionesMenuPorCategoria, ObtenerCategoriasMenu,
    AgregarAccesoMenuPorRol, ObtenerAccesoMenuPorRol, EliminarAccesoMenu, ActualizarPermisos
} from '../../servicios/ServicioRoles';
import { Grid } from '../../components/grid';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AlertDismissible } from "../../components/alerts";
import { ConfirmModal } from "../../components/ConfirmModal";
const Formulario = ({ labelButton, data, proceso, onClickProcesarRol, mensaje }) => {
    const [showFormulario, setShowFormulario] = useState(true);

    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'categoria', name: 'Categoría', selector: row => row.categoriaMenuDescripcion, head: "Categoria", sortable: true },
        { id: 'opcion', name: 'Opción', selector: row => row.opcionMenuDescripcion, head: "Opción", sortable: true },
        { id: 'idOpcionMenu', name: 'idOpcionMenu', selector: row => row.idOpcionMenu, head: "idOpcionMenu", omit: true },
        {
            id: 'eliminar',
            name: 'Eliminar',
            head: 'Eliminar',
            width: '29%',
            cell: (row) => {
                return (
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Button variant="link" size="sm" onClick={() => onClickEliminar(row.id)}>
                            <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red', marginLeft: '10px' }} />
                        </Button>
                    </div>
                );
            },
        },

    ]
    const [datos, setDatos] = useState([])

    const [idUsuario, setIdUsuario] = useState(0);

    //campos de form
    const [descripcion, setDescripcion] = useState(proceso == 2 ? data.descripcion : '');
    const [key, setKey] = useState('');
    const [validated, setValidated] = useState(false);
    const [opciones, setOpciones] = useState([{ "id": 0, "descripcion": "Seleccione" },]);
    const [datosFiltrados, setDatosFiltrados] = useState([]);
    const [categorias, setCategorias] = useState([{ "id": 0, "descripcion": "Seleccione" },]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(0);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [idEliminar, setIdEliminar] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    //Permisos
    const [permisos, setPermisos] = useState({
        idRol: data.idRol ?? 0,
        usuarioModificacion: idUsuario,

        //generales
        permisoAgregar: proceso === 2 ? data.permisoAgregar : false,
        permisoActualizar: proceso === 2 ? data.permisoActualizar : false,
        permisoEliminar: proceso === 2 ? data.permisoEliminar : false,

        //club de viajes
        permisoAbonoC: proceso === 2 ? data.permisoAbonoC : false,
        permisoLoteria: proceso === 2 ? data.permisoLoteria : false,
        permisoComentarioC: proceso === 2 ? data.permisoComentarioC : false,
        permisoDocumentoC: proceso === 2 ? data.permisoDocumentoC : false,

        //paquete de viajes
        permisoAbonoP: proceso === 2 ? data.permisoAbonoP : false,
        permisoSeguroCliente: proceso === 2 ? data.permisoSeguroCliente : false,
        permisoHabitacionCliente: proceso === 2 ? data.permisoHabitacionCliente : false,
        permisoTourCliente: proceso === 2 ? data.permisoTourCliente : false,
        permisoAcompanante: proceso === 2 ? data.permisoAcompanante : false,
        permisoTourAcompanante: proceso === 2 ? data.permisoTourAcompanante : false,
        permisoHabitacionAcompanante: proceso === 2 ? data.permisoHabitacionAcompanante : false,
        permisoSeguroAcompanante: proceso === 2 ? data.permisoSeguroAcompanante : false,
        permisoComentarioP: proceso === 2 ? data.permisoComentarioP : false,
        permisoDocumentoP: proceso === 2 ? data.permisoDocumentoP : false,
    });


    //envio de datos
    const onClickAceptar = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = {
                descripcion: descripcion,
                accesosMenuPorRol: datos,
                permisos: permisos
            }
            onClickProcesarRol(data);
        }
        setValidated(true);
        event.preventDefault();
    }


    const onClickEliminar = (event) => {
        setIdEliminar(event);
        setConfirmModalOpen(true);
        
    }
    const EliminarAccesoMenuRol = async () => {
        let respuesta = await EliminarAccesoMenu(idEliminar);
        setMensajeRespuesta(respuesta)
        setShowAlert(true);
        setConfirmModalOpen(!confirmModalOpen);
        // Utiliza el spread operator para crear un nuevo array y agregar la nueva opción
        let res = await ObtenerAccesoMenuPorRol(data.idRol);
        setDatos(res);
        filtrarPorCategoria(categoriaSeleccionada);
    }

    useEffect(() => {
        const ObtenerOpcionesMenuPorCategoria = async () => {
            let respuesta = await ObtenerCategoriasMenu();
            setCategorias(categorias.concat(respuesta));
            setCategoriaSeleccionada(0);
            if (proceso===1) {
                setDatos([])
            } else {
                let res = await ObtenerAccesoMenuPorRol(data.idRol);
                setDatos(res)
            }
            
        };
        ObtenerOpcionesMenuPorCategoria();
        ObtenerIdUsuario();
    }, []);



    const ObtenerIdUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setIdUsuario(usuario.idUsuario);

    }

    //eventos del form
    const onChangeDescripcion = (e) => setDescripcion(e.target.value);

    const ObtenerOpcionesMenu = async (id) => {
        if (id !== 0) {
            let respuesta = await ObtenerOpcionesMenuPorCategoria(id);
            setOpciones([{ "id": 0, "descripcion": "Seleccione" }].concat(respuesta));
            setOpcionSeleccionada(0);
        } else {
            setOpciones([]);
        }

    }


    const handleCategoria = (e) => {
        filtrarPorCategoria(parseInt(e.target.value));
        setCategoriaSeleccionada(parseInt(e.target.value))
        let registroEncontrado = categorias.find(registro => registro.id === parseInt(e.target.value));
        ObtenerOpcionesMenu(parseInt(e.target.value))
        cambiarKey(registroEncontrado.descripcion);
    };
    const cambiarKey = (k) => {
        let registroEncontrado = categorias.find(registro => registro.descripcion === k);
        filtrarPorCategoria(registroEncontrado.id); 
        setCategoriaSeleccionada(registroEncontrado.id);
        ObtenerOpcionesMenu(registroEncontrado.id);
        setKey(k);
    }
    const handleOpcion = (e) => {
        setOpcionSeleccionada(parseInt(e.target.value));
    };

    const onClickAgregarOpcion = async () => {
        if (opcionSeleccionada !== 0 && !datos.find(registro => registro.idOpcionMenu === opcionSeleccionada)) {
            let categoriaEncontrada = categorias.find(registro => registro.id === categoriaSeleccionada);
            let opcionEncontrada = opciones.find(registro => registro.id === opcionSeleccionada);

            let nuevaOpcion = {
                idOpcionMenu: opcionEncontrada.id,
                categoriaMenuDescripcion: categoriaEncontrada.descripcion,
                opcionMenuDescripcion: opcionEncontrada.descripcion,
                idCategoriaMenu: categoriaEncontrada.id,

            };
            let opcion = {
                idOpcionMenu: opcionEncontrada.id,
                idRol: data.idRol,
            };
            if (proceso === 2) {
                let respuesta = await AgregarAccesoMenuPorRol(opcion);

                setMensajeRespuesta(respuesta)
                setShowAlert(true);
                // Utiliza el spread operator para crear un nuevo array y agregar la nueva opción
                let res = await ObtenerAccesoMenuPorRol(data.idRol);
                setDatos(res);
                
            } else {
                setDatos([...datos, nuevaOpcion]);
            }
            filtrarPorCategoria(categoriaEncontrada.id);
        }
        
    };
    async function filtrarPorCategoria(idCategoria) {
        // Utilizamos el método filter para obtener un nuevo array con los registros que cumplen la condición
        if (proceso === 2) {
            let res = await ObtenerAccesoMenuPorRol(data.idRol);
            setDatosFiltrados(res.filter(registro => registro.idCategoriaMenu === idCategoria));
        } else {
            setDatosFiltrados(datos.filter(registro => registro.idCategoriaMenu === idCategoria));
        }
    }

    const handleCheckboxChange = async (e) => {
        const { id, checked } = e.target;

        // Mapea el id del checkbox a las propiedades del useState
        const permisoKey =
            id === "PermisoAgregar" ? "permisoAgregar" :
            id === "PermisoActualizar" ? "permisoActualizar" :
            id === "PermisoEliminar" ? "permisoEliminar" :
            id === "PermisoAbonoC" ? "permisoAbonoC" :
            id === "PermisoLoteria" ? "permisoLoteria" :
            id === "PermisoComentarioC" ? "permisoComentarioC" :
            id === "PermisoDocumentoC" ? "permisoDocumentoC" :
            id === "PermisoAbonoP" ? "permisoAbonoP" :
            id === "PermisoSeguroCliente" ? "permisoSeguroCliente" :
            id === "PermisoHabitacionCliente" ? "permisoHabitacionCliente" :
            id === "PermisoTourCliente" ? "permisoTourCliente" :
            id === "PermisoAcompanante" ? "permisoAcompanante" :
            id === "PermisoTourAcompanante" ? "permisoTourAcompanante" :
            id === "PermisoHabitacionAcompanante" ? "permisoHabitacionAcompanante" :
            id === "PermisoSeguroAcompanante" ? "permisoSeguroAcompanante" :
            id === "PermisoComentarioP" ? "permisoComentarioP" :
            id === "PermisoDocumentoP" ? "permisoDocumentoP" :
            null;

            // Actualiza el estado del checkbox
            const updatedPermisos = {
                ...permisos,
                [permisoKey]: checked,
            };

            // Actualiza el estado local
            setPermisos(updatedPermisos);

            if (proceso === 2) {

                // Convierte el objeto de permisos en una lista para el servicio
                const entityPermisos = {
                    idRol: data.idRol,
                    usuarioModificacion: idUsuario,


                    //GENERALES
                    permisoAgregar: updatedPermisos.permisoAgregar,
                    permisoActualizar: updatedPermisos.permisoActualizar,
                    permisoEliminar: updatedPermisos.permisoEliminar,

                    //CLUB DE VIAJES
                    permisoAbonoC: updatedPermisos.permisoAbonoC,
                    permisoLoteria: updatedPermisos.permisoLoteria,
                    permisoComentarioC: updatedPermisos.permisoComentarioC,
                    permisoDocumentoC: updatedPermisos.permisoDocumentoC,

                    //PAQUETE DE VIAJES
                    permisoAbonoP: updatedPermisos.permisoAbonoP,
                    permisoSeguroCliente: updatedPermisos.permisoSeguroCliente,
                    permisoHabitacionCliente: updatedPermisos.permisoHabitacionCliente,
                    permisoTourCliente: updatedPermisos.permisoTourCliente,
                    permisoAcompanante: updatedPermisos.permisoAcompanante,
                    permisoTourAcompanante: updatedPermisos.permisoTourAcompanante,
                    permisoHabitacionAcompanante: updatedPermisos.permisoHabitacionAcompanante,
                    permisoSeguroAcompanante: updatedPermisos.permisoSeguroAcompanante,
                    permisoComentarioP: updatedPermisos.permisoComentarioP,
                    permisoDocumentoP: updatedPermisos.permisoDocumentoP
                };

                let respuesta = await ActualizarPermisos(entityPermisos);

                setMensajeRespuesta(respuesta);
                setShowAlert(true);
            }
        
    };


    return (
        <>
            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`¿Desea Eliminar el acceso del menu?`}
                    onConfirm={EliminarAccesoMenuRol
                    }
                />
            )}

            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}

            {showFormulario && (

                <Form noValidate validated={validated} onSubmit={onClickAceptar}>
                    <Row className="align-items-center">
                        <Col md={6} className="d-flex flex-column">
                            <InputText
                                id='txt-descripcion'
                                label='Descripcion:'
                                type='text'
                                value={descripcion}
                                onChange={onChangeDescripcion}
                                mensajeValidacion="La descripcion del rol es requerida"
                                placeholder='Descripcion del rol'
                                className="col-md-12"
                            />
                            {mensaje !== "" ? <p className="text-info text-center">{mensaje}</p> : ""}
                        </Col>
                        {proceso === 2 && (
                            <Col md={6}>
                                <Button
                                    style={{ width: '100%' }}
                                    className="mr-1 mb-2 card-button"
                                    variant="primary"
                                    type="submit"
                                    size="sm"
                                >
                                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                    {labelButton}
                                </Button>
                            </Col>
                        )}
                    </Row>

                    {/*//permisos generales*/}
                    <Row>
                        <h3 className='col-md-6' style={{ color: 'orangered', fontSize: '1.2rem', marginBottom: '20px', textAlign: 'left' }}>Permisos Generales</h3>
                    </Row>

                    <Row>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoAgregar"
                                label="Agregar"
                                checked={permisos.permisoAgregar}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 10px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoActualizar"
                                label="Actualizar"
                                checked={permisos.permisoActualizar}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoEliminar"
                                label="Eliminar"
                                checked={permisos.permisoEliminar}
                                onChange={handleCheckboxChange}
                            />
                        </Col>

                    </Row>
                    <br></br>
                    <hr></hr>
                    {/*//permisos Club de viajes*/}
                    <Row>
                        <h3 className='col-md-6' style={{ color: 'orangered', fontSize: '1.2rem', marginBottom: '20px', textAlign: 'left' }}>Permisos Club de Viajes</h3>
                    </Row>

                    <Row>
                        <Col md={3} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoAbonoC"
                                label="Abonos"
                                checked={permisos.permisoAbonoC}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={3} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoLoteria"
                                label="Loteria"
                                checked={permisos.permisoLoteria}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={3} className="d-flex flex-column" style={{ padding: '0 10px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoComentarioC"
                                label="Comentario"
                                checked={permisos.permisoComentarioC}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={3} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoDocumentoC"
                                label="Documentos"
                                checked={permisos.permisoDocumentoC}
                                onChange={handleCheckboxChange}
                            />
                        </Col>

                    </Row>
                    <br></br>
                    <hr></hr>

                    {/*//permisos Paquete de viajes*/}
                    <Row>
                        <h3 className='col-md-6' style={{ color: 'orangered', fontSize: '1.2rem', marginBottom: '20px', textAlign: 'left' }}>Permisos Paquete de Viajes</h3>
                    </Row>

                    <Row>
                        
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoSeguroCliente"
                                label="Seguro Cliente"
                                checked={permisos.permisoSeguroCliente}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 5px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoHabitacionCliente"
                                label="Habitacion Cliente"
                                checked={permisos.permisoHabitacionCliente}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 10px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoTourCliente"
                                label="Tour Cliente"
                                checked={permisos.permisoTourCliente}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                    </Row>
                    <br></br>
                    
                    <Row>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoAcompanante"
                                label="Agregar Acompañantes"
                                checked={permisos.permisoAcompanante}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 5px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoTourAcompanante"
                                label="Tour Acompañante"
                                checked={permisos.permisoTourAcompanante}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 10px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoHabitacionAcompanante"
                                label="Habitacion Acompañante"
                                checked={permisos.permisoHabitacionAcompanante}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                    </Row>
                    
                    <br></br>
                    <Row>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoSeguroAcompanante"
                                label="Seguro Acompañante"
                                checked={permisos.permisoSeguroAcompanante}
                                onChange={handleCheckboxChange}
                            />
                        </Col>

                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 5px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoComentarioP"
                                label="Comentarios"
                                checked={permisos.permisoComentarioP}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 10px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoDocumentoP"
                                label="Documentos"
                                checked={permisos.permisoDocumentoP}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 20px' }}>
                            <Form.Check
                                type="checkbox"
                                id="PermisoAbonoP"
                                label="Abonos"
                                checked={permisos.permisoAbonoP}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                    </Row>
                    <br></br>


                    <hr></hr>
                    <Row> <h3 className='col-md-6' style={{ color: 'orangered', fontSize: '1.2rem', marginBottom: '20px', textAlign: 'left' }}>Accesos del Menu del Rol</h3></Row>
                    <Row>
                       
                        <br></br>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>  
                                <Form.Group controlId="formCategoria">
                                    <label htmlFor="buscar"><b>Categoría:</b></label>
                                    <Form.Control as="select" onChange={handleCategoria} value={categoriaSeleccionada}>
                                            {categorias.map(categoria => (
                                                <option key={categoria.id} value={categoria.id}>
                                                    {categoria.descripcion}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>
                                
                        </Col>
                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>  
                            <Form.Group controlId="formOpcion">
                                <label htmlFor="buscar"><b>Opción:</b></label>
                                <Form.Control as="select" onChange={handleOpcion} value={opcionSeleccionada}>
                                    {opciones.map(opcion => (
                                        <option key={opcion.id} value={opcion.id}>
                                            {opcion.descripcion}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                        <br></br>
                            <Button
                                className="mr-1 mb-2 card-button"
                                variant="primary"

                                size="sm"
                                onClick={() => onClickAgregarOpcion()}>
                                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                Agregar
                            </Button>{' '}
                        </Col>
                       
                      
                    </Row>
                        <br></br>
                    <Tabs activeKey={key}
                        onSelect={cambiarKey} id="categoriasTabs">
                        {categorias
                            .filter(categoria => categoria.id !== 0) // Filtra las categorías con id diferente de 0
                            .map(categoria => (
                                <Tab key={categoria.descripcion} eventKey={categoria.descripcion} title={categoria.descripcion}>
                                    {/* Contenido específico de cada pestaña */}
                                    <Grid gridHeading={encabezado} gridData={datosFiltrados} selectableRows={false} idBuscar="id" />
                                </Tab>
                            ))}
                    </Tabs>



                    {proceso === 1 && (
                        <div className='text-right'>
                            <Button
                                className="mr-1 mb-2 card-button"
                                variant="primary"
                                type="submit"
                                size="sm">
                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                                {labelButton}
                            </Button>
                        </div>
                    )}
                </Form>


            )}
        </>
    )
}

export default Formulario;