import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import Formulario from './formulario';
import { FormularioModal } from '../../components/ventanaModal';
import { AlertDismissible } from '../../components/alerts';
import { ConfirmModal } from '../../components/ConfirmModal';

import { AgregarVendedor, ActualizarVendedor, InactivarVendedor, ObtenerVendedores, ObtenerVendedor } from '../../servicios/ServicioVendedor'

const Vendedores = () => {
    const [proceso, setProceso] = useState(1);
    const [modal, setModal] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("Registrar vendedor");
    const [labelButton, setLabelButton] = useState("Registrar");
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);


    const [listaDeVendedores, setListaDeVendedores] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [data, setData] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [textoBotonInactivar, setTextoBotonInactivar] = useState("Inactivar");
    const [textoBotonActualizar, setTextoBotonActualizar] = useState("Actualizar");

    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);

    const [IdUsuario, setIdUsuario] = useState(0)




    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'codVendedor', name: 'Cod. Vendedor', selector: row => row.codVendedor, head: "Cod. Vendedor", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        { id: 'nombreSucursal', name: 'Sucursal', selector: row => row.nombreSucursal, head: "Sucursal", sortable: true },
        { id: 'fechaContratacion', name: 'F. Contratación', selector: row => new Date(row.fechaContratacion).toLocaleDateString('es-ES'), head: "F. Contratación", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true }
    ]

    //Se indica las columnas en las que se aplica el filtro
    const filterColumns = ['codVendedor', 'nombre', 'primerApellido', 'segundoApellido', 'nombreSucursal'];

    useEffect(() => {
        ObtenerListadoDeVendedores();
        ObtenerPermisosUsuario();
    }, []);

    const onClickNuevoVendedor = () => {
        setProceso(1);
        setModal(!modal);
        setLabelButton("Registrar");
        setModalTitulo("Registrar vendedor");
    }

    const onClickActualizarVendedor = async () => {
        setData(await ObtenerVendedor(filaSeleccionada.idVendedor));
        setProceso(2);
        setModal(!modal);


        if (!PermisoActualizar) {
            setModalTitulo("Información vendedor");
        }
        else {
            setModalTitulo("Actualizar vendedor");
        }
        setLabelButton("Actualizar");
    }

    const onClickInactivarVendedor = async () => {
        setConfirmModalOpen(true);         
    }


    const onConfirmCambioEstado = async () => {
        const respuesta = await InactivarVendedor(filaSeleccionada.idVendedor)
        if (respuesta.indicador === 0)
            ObtenerListadoDeVendedores();
        setMensajeRespuesta(respuesta);
        setTextoBotonInactivar(textoBotonInactivar === "Activar" ? "Inactivar" : "Activar");

        setConfirmModalOpen(false);
        setShowAlert(true);

    }

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false);
        setPermisoEliminar(usuario.PermisoEliminar ?? false);


        //aprovecha el metodo para obtener el id del usuario logueado
        setIdUsuario(usuario.idUsuario);

        //aprovecha el metodo para asignar el texto del boton dependiendo del permiso
        if (usuario.PermisoActualizar) {
            setTextoBotonActualizar("Actualizar")
        }
        else {
            setTextoBotonActualizar("Ver Información")
        }

    }

    const ObtenerListadoDeVendedores = async () => {
        setPendiente(true);
        setListaDeVendedores(await ObtenerVendedores());
        setPendiente(false);
    }

    const onClickProcesarVendedor = async (data) => {

        data.usuarioCreacion = IdUsuario;
        setMensajeFormulario("");
        let respuesta = {};
        if (proceso === 1)
            respuesta = await AgregarVendedor(data);
        else {
            data.idVendedor = filaSeleccionada.idVendedor;
            data.estado = true;
            respuesta = await ActualizarVendedor(data);
        }

        if (respuesta.indicador == 0) {
            setModal(false);
            ObtenerListadoDeVendedores();
            setMensajeRespuesta(respuesta);

        } else {
            setMensajeRespuesta(respuesta);
            ObtenerListadoDeVendedores();
            setModal(false);
        //    setMensajeFormulario(respuesta.mensaje);
        }
        setShowAlert(true);

    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setTextoBotonInactivar(!filaValida ? "Inactivar" : (fila.estado == "Activo" ? "Inactivar" : "Activar"));
        setFilaSeleccionada(fila);
    }

    const onClickCerrarModal = () => {
        setModal(false);
        setMensajeFormulario("");
    }


    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    return (
        <>
            <div className="container-fluid">
                <h1>Mantenimiento de vendedores</h1>
                <hr />
                {PermisoAgregar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickNuevoVendedor()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Registrar
                        </Button>{' '}
                    </>
                )}
                <Button
                    className="mr-1 mb-2 card-button"
                    variant="primary"
                    type="submit"
                    size="sm"
                    onClick={() => onClickActualizarVendedor()}
                    disabled={bloquearBoton}>
                    <FontAwesomeIcon icon={faPen} className="mr-2" />
                    {textoBotonActualizar}
                </Button>{' '}

                {PermisoEliminar && (
                    <>
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            type="submit"
                            size="sm"
                            onClick={() => onClickInactivarVendedor()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            {textoBotonInactivar}
                        </Button>
                    </>
                )}

                <br /><br />
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )}
                <span>Listado de todos los vendedores registrados</span>
                <Grid gridHeading={encabezado} gridData={listaDeVendedores} selectableRows={true} pending={pendiente}
                    setFilaSeleccionada={onClickSeleccionarFila} idBuscar="idVendedor" filterColumns={filterColumns} />
                <br /><br />
            </div>
            <FormularioModal show={modal} handleClose={onClickCerrarModal} titulo={modalTitulo} className='' tamano="lg">
                <Formulario labelButton={labelButton} data={data} proceso={proceso} onClickProcesarVendedor={onClickProcesarVendedor} PermisoActualizar={PermisoActualizar} mensaje={mensajeFormulario} />
            </FormularioModal>
            
            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`¿Desea cambiar el estado del vendedor a ${textoBotonInactivar === "Activar" ? "activo" : "inactivo"
                        }?` }
                    onConfirm={onConfirmCambioEstado}
                />
            )}
        </>
    )
}

export default Vendedores;