import React, { useEffect, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { InputText, InputNumber } from '../../components/inputs';
import { BuscarToursDeViaje } from '../../components/BuscarTourDeViaje/buscarTourDeViaje';



const Formulario = ({ labelButton, data, proceso, onClickProcesarTour, mensaje }) => {
    const [buscarTours, setBuscarTours] = useState(false);
    const [showFormulario, setShowFormulario] = useState(true);
    //campos de form
    const [nombre, setDescripcionnombre] = useState(proceso == 2 ? data.nombre : '');
    const [descripcion, setDescripcion] = useState(proceso == 2 ? data.descripcion : ''); 
    const [pais, setPais] = useState(proceso == 2 ? data.pais : ''); 
    const [fechaSalida, setFechaSalida] = useState(proceso == 2 ? data.fechaSalida.replace('T00:00:00', '') : '');
    const [monto, setMonto] = useState(proceso == 2 ? data.monto : '');
    const [documento, setDocumento] = useState(proceso == 2 ? data.documento : '');
    const [globo, setGlobo] = useState(proceso == 2 ? data.globo : '');
    const [seguro, setSeguro] = useState(proceso == 2 ? data.seguro : '');

    //variables de combo box
    const [idTourDeViaje, setIdTourDeViaje] = useState(proceso == 2 ? data.idTourDeViaje : 0);
    const [validated, setValidated] = useState(false);

    //envio de datos
    const onClickAceptar = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const data = {
                idTourDeViaje: idTourDeViaje,
                descripcion: descripcion,
                pais: pais,
                fechaSalida: fechaSalida,
                monto: monto,               
                documento: documento,
                globo: globo,
                seguro: seguro,
            }
            onClickProcesarTour(data);
        }
        setValidated(true);
        event.preventDefault();
    }

    //eventos del form
    const onClickBuscarTour = () => {
        if (proceso === 1) {
            setBuscarTours(!buscarTours);
            setShowFormulario(false);
        }
    }

    const onClickCerrarBuscarTour = () => {
        setBuscarTours(false);
        setShowFormulario(true);
    }

    const onClickAceptarBuscarTour = (tour) => {
        setIdTourDeViaje(tour.id);
        setDescripcion(tour.descripcion);
        setPais(tour.pais);
        setBuscarTours(false);
        setShowFormulario(true);
    }

    const onChangeDescripcion = (e) => setDescripcion(e.target.value);
    const onChangePais = (e) => setPais(e.target.value);
    const onChangefechaSalida= (e) => setFechaSalida(e.target.value);
    const onChangeMonto = (e) => setMonto(e.target.value);
    const onDocumentoChange = (event) => {
        const file = event.target.files[0];
        setDocumento(file);
    };
    const onChangeGlobo = (e) => setGlobo(e.target.value);
    const onChangeSeguro = (e) => setSeguro(e.target.value);


    return (
        <>
            {showFormulario && (
                <Form noValidate validated={validated} onSubmit={onClickAceptar}>
                    <Row>
                        <InputText id='txt-tour' label='Tour:' type='text' value={descripcion}
                            onChange={onChangeDescripcion} mensajeValidacion="Tour requerido" placeholder='Nombre del Tour' className="col-md-8" onClick={onClickBuscarTour} readOnly={proceso === 2} />
                        <InputNumber id='num-monto' label='Monto($):' min={1.00} max={99999999.99} placeholder='Ingrese el monto' value={monto}
                            onChange={onChangeMonto} mensajeValidacion={monto === '' ? "Monto requerido" : "El monto debe ser mayor a 0" } className="col-md-4" />
                        <InputText id='txt-fechaSalida' label='Fecha:' type='date' placeholder='Ingrese la fecha' value={fechaSalida}
                            onChange={onChangefechaSalida} mensajeValidacion="La fecha es requerida" className="col-md-4" />
                        <InputText id='txt-pais' label='País:' type='text' value={pais}
                            onChange={onChangePais} mensajeValidacion="País requerido" className="col-md-4" readOnly />
                    </Row>
                   
                    <br />
                    {mensaje !== "" ? <p className="text-info text-center">{mensaje}</p> : ""}
                    <div className='text-right'>
                        <Button className="mr-1 mb-2, card-button" variant="primary"
                            type="submit" size="sm">
                            <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                            {labelButton}
                        </Button>
                    </div>

                </Form>
            )}

            <BuscarToursDeViaje show={buscarTours} handleClose={onClickCerrarBuscarTour} handleAceptar={onClickAceptarBuscarTour} className='' tamano="lg">
                <h5>Tours</h5>
            </BuscarToursDeViaje>
        </>
    )
}

export default Formulario;