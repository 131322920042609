import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
//import { GridComponente } from '../../components/gridComponente';
import { Grid, label } from '../../components/grid';
import { ObtenerCorreosPersona, ActualizarCorreosPersona, ActualizarCorreoPrincipalPersona, EliminarCorreoPersona, AgregarCorreoPersona } from '../../servicios/ServicioPersonas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

export const CorreosPersona = ({ show, handleClose, className, tamano, IdPersona, handleAceptar }) => {
    const [listaDeCorreos, setListaDeCorreos] = useState([]);
    const [correo, setCorreo] = useState('');
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [mostrarBotonPrincipal, setMostrarBotonPrincipal] = useState(false);
    const [id, setId] = useState(-1);

    const encabezado = [
        { id: 'idCorreoElectronico', name: 'ID', selector: (row) => row.idCorreoElectronico, head: 'ID', omit: true },
        { id: 'correoElectronico', name: 'Correo Electrónico', selector: (row) => row.correoElectronico, head: 'Correo Electrónico', sortable: true },
        //{
        //    id: 'principal', name: 'Principal', selector: (row) => row.principal,
        //    head: 'Principal',
        //    cell: (row) => (
        //        <input
        //            type="checkbox"
        //            checked={row.principal}
        //            disabled
        //        />
        //    ),
        //},
        {
            id: 'principal', name: 'Principal', head: 'Principal', width: '25%',
            cell: (row) => (
                <div className="EstiloCheck">
                    <input
                        id={`switch${row.id}`}
                        type="checkbox"
                        checked={row.principal}
                        onClick={() => handleHacerPrincipal(row)}
                        style={{
                            marginBottom: '10px',
                            marginLeft: '20px',
                        }}
                    />
                    <label htmlFor={`switch${row.id}`}>Toggle</label>
                </div>
            ),
        },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar',
            cell: (row) => (
                <Button variant="link" size="sm" onClick={() => handleEliminarCorreo(row)}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red' }} />
                </Button>
            ),
        },
    ];

    useEffect(() => {
        //console.log("Id persona al abrir nuevamente: " + IdPersona)
        setFilaSeleccionada({});
        if (IdPersona) {
            ObtenerListadoDeCorreos();
        }
    }, [IdPersona, show]);

    const ObtenerListadoDeCorreos = async () => {
        setPendiente(true);
        const correos = await ObtenerCorreosPersona(IdPersona);
        setListaDeCorreos(Object.values(correos));
        setPendiente(false);
    };

    const handleCorreoChange = (e) => {
        setCorreo(e.target.value);
    };

    const handleAgregarCorreo = async () => {
        const nuevoCorreo = {
            idCorreoElectronico: generarIdCorreoElectronico(), correoElectronico: correo, idPersona: IdPersona ? IdPersona : null,
            principal: listaDeCorreos.length===0,//principal: false,
            estado: 'Activo'
        };
        await InsertarCorreo(nuevoCorreo);
        setListaDeCorreos([...listaDeCorreos, nuevoCorreo]);
        setCorreo('');
        setFilaSeleccionada({});
    };

    const generarIdCorreoElectronico = () => {
        const maxId = listaDeCorreos.reduce((max, correo) => (correo.idCorreoElectronico > max ? correo.idCorreoElectronico : max), 0);
        const nuevoId = maxId + 1;
        return nuevoId;
    };

    const handleEditarCorreo = async () => {    
        const correosActualizados = listaDeCorreos.map((correoIndividual) => {
            if (correoIndividual.idCorreoElectronico === filaSeleccionada.idCorreoElectronico) {             
                return { ...correoIndividual, correoElectronico: correo };
            }
            return correoIndividual;
        });
        //console.log("fila seleccionada: " + filaSeleccionada);
        await ActualizarCorreo(filaSeleccionada);
        setListaDeCorreos(correosActualizados);
        setFilaSeleccionada((prevFilaSeleccionada) => ({ ...prevFilaSeleccionada, correoElectronico: correo }));
        setFilaSeleccionada({});
        setCorreo("")
        
    };

    const handleEliminarCorreo = async (fila) => {
        const correosFiltrados = listaDeCorreos.filter((correo) => correo.idCorreoElectronico !== fila.idCorreoElectronico);
        await EliminarCorreo(fila);
        setListaDeCorreos(correosFiltrados);
        setFilaSeleccionada({});
    };

   
    const handleFilaSeleccionada = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        filaValida ? setCorreo(fila.correoElectronico) : setCorreo("")
        setFilaSeleccionada(fila);
        setMostrarBotonPrincipal(!fila.principal && fila.idCorreoElectronico);
    };
    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    const handleHacerPrincipal = async () => {
        const correosActualizados = listaDeCorreos.map((correo) => ({
            ...correo,
            principal: correo.idCorreoElectronico === filaSeleccionada.idCorreoElectronico,
        }));
        await ActualizarCorreoPrincipal(filaSeleccionada);
        setListaDeCorreos(correosActualizados);
        //  //setMostrarBotonPrincipal(false);
    };

    const handleAceptarClick = () => {
        
       handleAceptar(listaDeCorreos);
        setCorreo("");
        handleClose();
    };

    const isEmailValid = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const isFormValid = () => {
        const isCorreoValido = correo && correo.trim() !== '' && isEmailValid(correo);
        //const isFilaSeleccionadaValida = filaSeleccionada && Object.keys(filaSeleccionada).length !== 0;

        return isCorreoValido //&& isFilaSeleccionadaValida;
    };


    //Acciones base datos

    const InsertarCorreo = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await AgregarCorreoPersona(data);
        //console.log("respuesta agregar : " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const ActualizarCorreo = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await ActualizarCorreosPersona(data);
        //console.log("respuesta actualizar: " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const ActualizarCorreoPrincipal = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await ActualizarCorreoPrincipalPersona(data);
        //console.log("respuesta actualizar principal: " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const EliminarCorreo = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await EliminarCorreoPersona(data.idCorreoElectronico);
        //console.log("respuesta eliminar correo: " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }



    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName={className}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size={tamano}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="h5">{"Emails"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col-6">
                            <Form.Group>
                                <Form.Control
                                    size="sm"
                                    type="email"
                                    placeholder={filaSeleccionada.idCorreoElectronico ? "Edite el correo seleccionado" : "Ingrese un nuevo correo"}
                                    value={correo}
                                    maxlength={100}
                                    onChange={handleCorreoChange}
                                    required
                                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                />
                                {/*<Form.Control.Feedback type="invalid">*/}
                                {/*    Por favor, ingrese un correo electrónico válido.*/}
                                {/*</Form.Control.Feedback>*/}
                            </Form.Group>
                        </div>
                        <div className="col-auto">
                            <Button variant="primary" size="sm" onClick={filaSeleccionada.idCorreoElectronico ? handleEditarCorreo : handleAgregarCorreo} disabled={!isFormValid()}>
                                {filaSeleccionada.idCorreoElectronico ? "Editar" : "Agregar"}
                            </Button>
                        </div>
                        {/*<div className="col-auto">*/}
                        {/*    {mostrarBotonPrincipal && filaSeleccionada.idCorreoElectronico && (*/}
                        {/*        <Button variant="secondary" size="sm" onClick={handleHacerPrincipal}>*/}
                        {/*            Hacer Principal*/}
                        {/*        </Button>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                    <Grid
                        gridHeading={encabezado}
                        gridData={listaDeCorreos}
                        selectableRows={true}
                        pending={pendiente}
                        setFilaSeleccionada={handleFilaSeleccionada}
                        idBuscar="idCorreoElectronico"
                    />
                    <div className="text-right">
                        <br />
                        <Button variant="primary" size="sm" onClick={handleAceptarClick}>
                            Aceptar
                        </Button>{' '}
                        {/*<Button variant="secondary" size="sm" onClick={handleClose}>*/}
                        {/*    Cancelar*/}
                        {/*</Button>{' '}*/}
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};
