import { ProcesarDatosApi, ProcesarDatosApiFormData } from "../api/ApiFetch";
const controlador = "Documentos";


export const AgregarDocumentos = async (data) => {
    const url = `${controlador}/InsertarDocumento`;
    return await ProcesarDatosApiFormData('POST', url, data);
}

export const ObtenerDocumentosClubDeViajeID = async (idClub) => {
    const url = `${controlador}/ObtenerDocumentosClubDeViajeID/${idClub}`;
    return await ProcesarDatosApi('GET', url);
}

export const ObtenerDocumentosPaqueteID = async (idPaquete) => {
    const url = `${controlador}/ObtenerDocumentosPaqueteID/${idPaquete}`;
    return await ProcesarDatosApi('GET', url);
}

export const AgregarDocumentosPaquetes = async (data) => {
    const url = `${controlador}/InsertarDocumentosGrupos`;
    return await ProcesarDatosApiFormData('POST', url, data);
}

export const ObtenerDocumento = async (indicador, idDocumento) => {
    const url = `${controlador}/ObtenerDocumento/${indicador}/${idDocumento}`;
    return await ProcesarDatosApi('GET', url);
};

export const EliminarDocumento = async (indicador, idDocumento) => {
    const url = `${controlador}/EliminarDocumento/${indicador}/${idDocumento}`;
    return await ProcesarDatosApi('POST', url);
};