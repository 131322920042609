import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';

import { FormularioModal } from '../../components/ventanaModal';
import { ActualizarTourDeViaje, AgregarTourDeViaje, InactivarTourDeViaje, ObtenerTourDeViaje, ObtenerTourDeViajePorId } from '../../servicios/ServicioTourDeViaje';
import FormularioTourDeViaje from './FormularioTourDeViaje';


const TourDeViajeComponet = () => {
    const [proceso, setProceso] = useState(1);
    const [modal, setModal] = useState(false);
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [data, setData] = useState({});
    const [modalTitulo, setModalTitulo] = useState("Registrar Tour");
    const [labelButton, setLabelButton] = useState("Registrar");
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [idBuscar, setidBuscar] = useState("");

    const [listaRespaldo, setListaRespaldo] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [textoBotonInactivar, setTextoBotonInactivar] = useState("Inactivar");
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [lista, setLista] = useState([]);

    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);


    const encabezado = [
        { id: 'id', name: 'Serie', selector: row => row.id, head: "id", omit: true},        
        { id: 'Descripcion', name: 'Descripción', selector: row => row.descripcion, head: "Descripción", sortable: true },
        { id: 'Pais', name: 'País', selector: row => row.pais, head: "Pais", sortable: true },
        //{ id: 'fechaCreacion', name: 'F. Creación', selector: row => row.fechaCreacion.split('T')[0], head: "F. Creación" },
        //{ id: 'fechaSalida', name: 'Fecha Salida', selector: row => row.fechaSalida.split('T')[0], head: "Fecha Salida" },
        //{ id: 'fechaLLegada', name: 'Fecha LLegada', selector: row => row.fechaLLegada.split('T')[0], head: "Fecha LLegada" },        
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true },
    ]

    //Se indica las columnas en las que se aplica el filtro
    const filterColumns = ['descripcion','pais'];


    useEffect(() => {
        ObtenerListado();
        ObtenerPermisosUsuario();
    }, []);

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false);
        setPermisoEliminar(usuario.PermisoEliminar ?? false);
    }

    const ObtenerListado = async () => {
        setPendiente(true);
        setLista(await ObtenerTourDeViaje());
        setListaRespaldo(await ObtenerTourDeViaje());
        setPendiente(false);
    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setTextoBotonInactivar(!filaValida ? "Inactivar" : (fila.estado == "Activo" ? "Inactivar" : "Activar"));
        setFilaSeleccionada(fila);
    }

    const onClickCerrarModal = () => {
        setModal(false);
        setMensajeFormulario("");
    }

    const onClickNuevo = () => {
        setProceso(1);
        setModal(!modal);
        setLabelButton("Registrar");
        setModalTitulo("Registrar Tour de Viaje");
    }

    const onClickActualizar = async () => {
        console.log(filaSeleccionada.id);
        setData(await ObtenerTourDeViajePorId(filaSeleccionada.id));
        setProceso(2);
        setModal(!modal);
        setLabelButton("Actualizar");
        setModalTitulo("Actualizar Tour de Viaje");
    }

    const onClickInactivar = async () => {
        const respuesta = await InactivarTourDeViaje(filaSeleccionada.id)
        if (respuesta.indicador === 0)
            ObtenerListado();
        setMensajeRespuesta(respuesta);        
        setTextoBotonInactivar(textoBotonInactivar == "Activar" ? "Inactivar" : "Activar");
    }



    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;


    const onClickProcesar = async(data) => {        
        let respuesta = {};
        
        if(proceso === 1) {
            respuesta = await AgregarTourDeViaje(data);
        }
        else{
            data.id = filaSeleccionada.id;
            data.estado = true;
            respuesta = await ActualizarTourDeViaje(data);
        }

        if(respuesta.indicador == 0){
            setModal(false);
            ObtenerListado();
            setMensajeRespuesta(respuesta);
        }else{     
            setMensajeFormulario(respuesta.mensaje);  
        }             
    }

    return (
        <>
            <div className="container-fluid text-no">
                <h1>Administración Tours de Viajes</h1>
                <hr />
                {PermisoAgregar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickNuevo()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Registrar
                        </Button>{' '}
                    </>
                )}
                {PermisoActualizar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickActualizar()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faPen} className="mr-2" />
                            Actualizar
                        </Button>{' '}
                    </>
                )}
                {PermisoEliminar && (
                    <>
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            type="submit"
                            size="sm"
                            onClick={() => onClickInactivar()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            {textoBotonInactivar}
                        </Button>
                    </>
                )}


                <br />

                <span>Listado de Todos los Tours Registrados</span>
                <br />
                <Grid
                    gridHeading={encabezado}
                    gridData={listaRespaldo}
                    selectableRows={true}
                    filterColumns={filterColumns}
                    pending={pendiente}
                    setFilaSeleccionada={onClickSeleccionarFila}
                    idBuscar="id" />
                <br />
                <br />

                <FormularioModal 
                show={modal} 
                handleClose={onClickCerrarModal} 
                titulo={modalTitulo} tamano="lg" className=''>
                    <FormularioTourDeViaje 
                    labelButton={labelButton} 
                    data={data} 
                    proceso={proceso} 
                    onClickProcesar={onClickProcesar} 
                    mensaje={mensajeFormulario}/>
                </FormularioModal>

            </div>

        </>
    )
}

export default TourDeViajeComponet;