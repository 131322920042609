import React from 'react';
import { Form } from "react-bootstrap"

export const InputsFormsReactHook = ({
    label,
    type,
    id,
    register,
    placeholder,
    Mask,
    onInput,
    step = "any",
    mensajeValidacion,
    className,
    readOnly = false,
    isSummit = false,
    classDiv,
    value,
    onClick, // Nueva prop para manejar el evento onClick
    errors }) => {

    return (
        <>
            <div className={"mb-3 " + classDiv}>
                <label htmlFor="txtname" className="form-label ml-1">
                    <b>{label}</b>
                </label>
                <input
                    id={id}
                    //onInput={onInput}
                    step={step}
                    className={`form-control ${errors != undefined ? 'is-invalid' : ''} ${errors == undefined && isSummit ? 'is-valid' : ''}  ` + className}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    type={type}
                    {...register}
                    onClick={onClick} // Agregar el evento onClick al input
                />
                <div className="invalid-feedback">

                    {errors === 'required' && <small>{mensajeValidacion}</small>}
                    {errors === 'maxLength' && <small>No cumple con el máximo de caracteres</small>}
                    {errors === 'minLength' && <small>No cumple con el mínimo de caracteres</small>}
                </div>
            </div>
        </>
    )
}


export const InputSelectHookForm = ({ className, customWidth,
    controlId, label, data, onChange, isSummit, errors, value, mensajeValidacion, register, optionValue, optionLabel, classGroup, placeholder }) => {
    const defaultPlaceholder = "Seleccione una opción...";
    const ObtenerOptions = (value) => {

        return data.map((option, index) => {
            return <option defaultValue={value == option[optionValue] ? true : false}
                key={index} value={option[optionValue]}>{option[optionLabel]}</option>
        })
    }
    //console.log("opciones select hook: data/optionvalue", data, optionValue)
    const inputStyle = customWidth ? { width: customWidth } : null;
    return (
        <Form.Group controlId={controlId} className={classGroup}>
            <Form.Label>{label}</Form.Label>
            <Form.Select
                {...register}
                className={`${errors != undefined ? 'is-invalid' : ''} ${errors == undefined && isSummit ? 'is-valid' : ''} ` + className}
                size="sm"
                style={inputStyle}
                onChange={onChange}

                defaultValue={value}
            >

                {<option key={""} value={""}>{placeholder || defaultPlaceholder}</option>}
                {ObtenerOptions(value)}
            </Form.Select>
            <div className="invalid-feedback">

                {errors === 'required' && <small>{mensajeValidacion}</small>}
                {errors === 'maxLength' && <small>No cumple con el máximo de caracteres</small>}
                {errors === 'minLength' && <small>No cumple con el mínimo de caracteres</small>}
            </div>
        </Form.Group>
    )
}


export const TexAreaFormsReactHook = ({
    label,
    type,
    id,
    rows,
    register,
    placeholder,
    mensajeValidacion,
    className,
    readOnly = false,
    isSummit = false,
    classDiv,
    errors }) => {
    return (
        <>
            <div className={"mb-3 " + classDiv}>
                <label htmlFor="txtname" className="form-label ml-1">
                    {label}
                </label>
                <textarea
                    rows={rows}
                    id={id}
                    className={`form-control ${errors != undefined ? 'is-invalid' : ''} ${errors == undefined && isSummit ? 'is-valid' : ''}  ` + className}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    type={type}
                    {...register}

                />
                <div className="invalid-feedback">

                    {errors === 'required' && <small>{mensajeValidacion}</small>}
                    {errors === 'maxLength' && <small>No cumple con el máximo de caracteres</small>}
                    {errors === 'minLength' && <small>No cumple con el mínimo de caracteres</small>}
                </div>
            </div>
        </>
    )
}

export const CheckBoxFormsReactHook = (props) => {
    const { options, selectedOption, onChange, register, errors, mensajeValidacion } = props;


    const handleOptionChange = (option) => {
        onChange(option);
    };

    return (
        <div>
            <div>
                <b>{props.label}</b>
            </div>
            {options.map((option, index) => (
                <div key={index} className="form-check form-check-inline">
                    <input
                        type="radio"
                        id={`radio-${option}`}
                        name="radio-group"
                        value={option}
                        {...register}
                        checked={selectedOption === option}
                        onChange={() => handleOptionChange(option)}
                        className="form-check-input"
                    />
                    <label htmlFor={`radio-${option}`} className="form-check-label">
                        {option}
                    </label>
                </div>
            ))}

            <div style={{
                display: 'inline',
                width: '100%',
                marginTop: '0.25rem',
                fontSize: '80%',
                color: '#dc3545'}} >

                {errors === 'required' && !selectedOption && <small>{mensajeValidacion}</small>}

            </div>
        </div>
    );
};