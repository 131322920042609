import { ProcesarDatosApi } from "../api/ApiFetch";

const controlador = "BuscadorExpediente";


export const ObtenerExpedientes = async (data) => {

    const url = `${controlador}/ObtenerExpedientes`;
    return await ProcesarDatosApi('POST', url, data);
}


