import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
//import { GridComponente, label } from '../../components/gridComponente';
import { Grid } from '../../components/grid';
import { ComboBox } from '../../components/combobox';
import { ObtenerTelefonosPersona, ActualizarTelefonosPersona, ActualizarTelefonoPrincipalPersona, EliminarTelefonoPersona, AgregarTelefonoPersona, ObtenerTiposTelefono } from '../../servicios/ServicioPersonas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPen, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

export const TelefonosPersona = ({ show, handleClose, className, tamano, IdPersona, handleAceptar }) => {
    const [listaDeTelefonos, setListaDeTelefonos] = useState([]);
    const [telefono, setTelefono] = useState('');
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [mostrarBotonPrincipal, setMostrarBotonPrincipal] = useState(false);
    //const [id, setId] = useState(-1);
    //const [disableSeleccion, setDisableSeleccion] = useState(false);

    //variables de combo box
    const [idTipoDeTelefono, setIdTipoDeTelefono] = useState(1);
    const [descripcionTipoDeTelefono, setDescripcionTipoDeTelefono] = useState('');

    const [listaTiposTelefono, setListaTiposTelefono] = useState([]);


    const encabezado = [
        { id: 'idTelefono', name: 'ID', selector: (row) => row.idTelefono, head: 'ID', omit: true },        
        { id: 'numero', name: 'Número', selector: (row) => row.numero, head: 'Numero', sortable: true },
        { id: 'idTipoTelefono', name: 'Tipo', selector: (row) => row.descripcion, head: 'Tipo', sortable: true },
        //{
        //    id: 'principal', name: 'Principal', selector: (row) => row.principal,
        //    head: 'Principal',
        //    cell: (row) => (
        //        <input
        //            type="checkbox"
        //            checked={row.principal}
        //            disabled
        //        />
        //    ),
        //},
        {
            id: 'principal', name: 'Principal', head: 'Principal', width: '25%',
            cell: (row) => (
                <div className="EstiloCheck">
                    <input
                        id={`switch${row.id}`}
                        type="checkbox"
                        checked={row.principal}
                        onClick={() => handleHacerPrincipal(row)}
                        style={{
                            marginBottom: '10px',
                            marginLeft: '20px',
                        }}
                    />
                    <label htmlFor={`switch${row.id}`}>Toggle</label>
                </div>
            ),
        },
        {
            id: 'eliminar', name: 'Eliminar', head: 'Eliminar',
            cell: (row) => (
                <Button variant="link" size="sm" onClick={() => handleEliminarTelefono(row)}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red' }} />
                </Button>
            ),
        },
    ];

    useEffect(() => {
        //console.log("Id persona al abrir nuevamente: " + IdPersona)
        ObtenerListadoDeTiposTelefonos();
        //setIdTipoDeTelefono(1);
        //setFilaSeleccionada({});
        if (IdPersona) {
            ObtenerListadoDeTelefonos();             
        }
    }, [IdPersona, show]);


    const ObtenerListadoDeTelefonos = async () => {
        setPendiente(true);
        const telefonos = await ObtenerTelefonosPersona(IdPersona);
        setListaDeTelefonos(Object.values(telefonos));
        setPendiente(false);
    };

    //llenado de combo box
    const ObtenerListadoDeTiposTelefonos = async () => {
        const tiposTelefonos = await ObtenerTiposTelefono();
        // console.log(tiposIdentificacion);
        if (tiposTelefonos !== undefined) {
            setListaTiposTelefono(tiposTelefonos);
            setIdTipoDeTelefono(1);
            setDescripcionTipoDeTelefono(...tiposTelefonos.map((tipoTelefono) => tipoTelefono.descripcion));
        }
        //console.log(idTipoDeTelefono);
    }

    const onChangeTiposTelefonos = (event) => {
        setIdTipoDeTelefono(event.target.value);
        const selectedOption = listaTiposTelefono.find(
            (option) => option.idTipoDeTelefono === parseInt(event.target.value)
        );
        setDescripcionTipoDeTelefono(selectedOption ? selectedOption.descripcion : '');
    }
    const handleTelefonoChange = (e) => {
        setTelefono(e.target.value);
    };

    const handleAgregarTelefono = async () => {
        const nuevoTelefono = {
            idTelefono: generarIdTelefono(), numero: telefono, idPersona: IdPersona ? IdPersona : null,
            principal: listaDeTelefonos.length === 0,//principal: false,
            idTipoDeTelefono: idTipoDeTelefono, descripcion: descripcionTipoDeTelefono, estado: 'Activo'
        };
        await InsertarTelefono(nuevoTelefono);
        setListaDeTelefonos([...listaDeTelefonos, nuevoTelefono]);
        setTelefono('');
        setFilaSeleccionada({});
    };

    const generarIdTelefono = () => {
        const maxId = listaDeTelefonos.reduce((max, telefono) => (telefono.idTelefono > max ? telefono.idTelefono : max), 0);
        const nuevoId = maxId + 1;
        return nuevoId;
    };

    const handleEditarTelefono = async () => {
        const telefonosActualizados = listaDeTelefonos.map((telefonoIndividual) => {
            if (telefonoIndividual.idTelefono === filaSeleccionada.idTelefono) {
                return { ...telefonoIndividual, numero: telefono, idTipoDeTelefono, descripcion: descripcionTipoDeTelefono };
            }
            return telefonoIndividual;
        });
        
        //setFilaSeleccionada((prevFilaSeleccionada) => ({ ...prevFilaSeleccionada, numero: telefono, idTipoDeTelefono, descripcion: descripcionTipoDeTelefono }));
        
        await ActualizarTelefono({
            ...filaSeleccionada,
            numero: telefono,
            IdTipoDeTelefono: idTipoDeTelefono
        });
        setListaDeTelefonos(telefonosActualizados);
        setTelefono("");
        setIdTipoDeTelefono(1);

    };

    const handleEliminarTelefono = async (fila) => {
        const telefonosFiltrados = listaDeTelefonos.filter((telefono) => telefono.idTelefono !== fila.idTelefono);
        await EliminarTelefono(fila);
        setListaDeTelefonos(telefonosFiltrados);
        setFilaSeleccionada({});
    };


    const handleFilaSeleccionada = (fila) => {
        //if (disableSeleccion) {
        //    return;
        //}
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        filaValida ? setTelefono(fila.numero) : setTelefono("")
        filaValida ? setIdTipoDeTelefono(fila.idTipoDeTelefono) : setIdTipoDeTelefono(1);
        filaValida ? setDescripcionTipoDeTelefono(fila.descripcion) : setDescripcionTipoDeTelefono('');

        setFilaSeleccionada(fila);
        setMostrarBotonPrincipal(!fila.principal && fila.idTelefono);
    };
    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    const handleHacerPrincipal = async () => {
        const telefonosActualizados = listaDeTelefonos.map((telefono) => ({
            ...telefono,
            principal: telefono.idTelefono === filaSeleccionada.idTelefono,
        }));

        await ActualizarTelefonoPrincipal(filaSeleccionada);
        setListaDeTelefonos(telefonosActualizados);
        //setMostrarBotonPrincipal(false);
    };

    const handleAceptarClick = () => {

        handleAceptar(listaDeTelefonos);
        setTelefono("");
        handleClose();
    };

    const isTelefonoValid = (telefono) => {
    const telefonoPattern = /^[0-9]{8}$/;
    return telefonoPattern.test(telefono);
};
    
    const isFormValid = () => {
        const isTelefonoValido = telefono && telefono.trim() !== '' && isTelefonoValid(telefono);
        //const isFilaSeleccionadaValida = filaSeleccionada && Object.keys(filaSeleccionada).length !== 0;

        return isTelefonoValido //&& isFilaSeleccionadaValida;
    };


    //Acciones base datos

    const InsertarTelefono = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await AgregarTelefonoPersona(data);
        //console.log("respuesta agregar : " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const ActualizarTelefono = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await ActualizarTelefonosPersona(data);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const ActualizarTelefonoPrincipal = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await ActualizarTelefonoPrincipalPersona(data);
        //console.log("respuesta actualizar principal: " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const EliminarTelefono = async (data) => {
        let respuesta = {};

        if (IdPersona)
            respuesta = await EliminarTelefonoPersona(data.idTelefono);
        //console.log("respuesta eliminar telefono: " + respuesta);
        //if (respuesta.indicador === 0) {         
        //    setMensajeRespuesta(respuesta);
        //} else {
        //    setMensajeFormulario(respuesta.mensaje);
        //}
        //setShowAlert(true);
    }

    const ObtenerOptions = (data, optionValue, optionLabel) => {
        return data.map((option, index) => {
            return <option key={index} value={option[optionValue]}>{option[optionLabel]}</option>
        })
    }

    //const handleSetId = (id) => {
    //    setId(id)
    //}

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName={className}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size={tamano}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="h5">{"Teléfonos"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col-auto" > <Form.Label>Número: </Form.Label></div>
                        <div className="col-2">
                            <Form.Group>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder={filaSeleccionada.idTelefono ? "Edición" : "Nuevo"}
                                    value={telefono}
                                    onChange={handleTelefonoChange}
                                    required
                                    pattern="[0-9]+"
                                    maxlength={8}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-auto" > <Form.Label>Tipo: </Form.Label></div>
                        <div className="col-3">
                            <Form.Group ccontrolId="sel-tipoTelefono">
                                <Form.Control as="select" value={idTipoDeTelefono} onChange={onChangeTiposTelefonos} size="sm" required >
                                    {
                                        ObtenerOptions(listaTiposTelefono, "idTipoDeTelefono", "descripcion")
                                    }
                                </Form.Control>
                            </Form.Group>
                        </div>                                       
                        <div className="col-auto">
                            <Button className="mr-1 mb-2 card-button" variant="primary" size="sm"
                                onClick={filaSeleccionada.idTelefono ? handleEditarTelefono : handleAgregarTelefono}
                                disabled={!isFormValid()}>
                                {filaSeleccionada.idTelefono ?
                                    <FontAwesomeIcon icon={faPen} className="mr-2" /> :
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />}
                                {filaSeleccionada.idTelefono ? "Editar" : "Agregar"}
                            </Button>
                        </div>
                        {/*<div className="col-auto">*/}
                        {/*    {mostrarBotonPrincipal && filaSeleccionada.idTelefono && (                            */}
                        {/*        <Button variant="secondary" size="sm" onClick={handleHacerPrincipal}>*/}
                        {/*            Hacer Principal*/}
                        {/*        </Button>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                    <Grid
                        gridHeading={encabezado}
                        gridData={listaDeTelefonos}
                        selectableRows={true}
                        pending={pendiente}
                        setFilaSeleccionada={handleFilaSeleccionada}
                        idBuscar="idTelefono"
                       
                    />
                    <div className="text-right">
                        <br />
                        <Button className="mr-1 mb-2 card-button" variant="primary" size="sm" onClick={handleAceptarClick}>
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Aceptar
                        </Button>{' '}
                        {/*<Button variant="secondary" size="sm" onClick={handleClose}>*/}
                        {/*    Cancelar*/}
                        {/*</Button>{' '}*/}
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};
