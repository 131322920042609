import { Form } from "react-bootstrap"
import '../styles/clubDeViaje.css'
import React, { useState, useEffect } from 'react'





export const InputsFormsReactHookMaskCard = ({
    label,
    type,
    id,
    register,
    placeholder,
    onInput,
    step = "any",
    mensajeValidacion,
    className,
    readOnly = false,
    isSummit = false,
    classDiv,
    onClick,
    applyMask = false,
    customWidth,
    errors,
    defaultValue, // Nueva prop para el valor por defecto
    externalValue // Nueva prop para el valor externo
}) => {

    const [formattedValue, setFormattedValue] = useState('');

    useEffect(() => {
        if (externalValue !== undefined) {
            const formatted = applyMask ? applyCardNumberMask(externalValue) : externalValue;
            setFormattedValue(formatted);
        }
    }, [externalValue, applyMask]);

    useEffect(() => {
        // Actualizar formattedValue cuando se monta el componente
        if (defaultValue !== undefined && externalValue === undefined) {
            const formatted = applyMask ? applyCardNumberMask(defaultValue) : defaultValue;
            setFormattedValue(formatted);
        }
    }, [defaultValue, applyMask, externalValue]);

    const handleChange = (event) => {
        const { value } = event.target;
        const formatted = applyMask ? applyCardNumberMask(value) : value;
        setFormattedValue(formatted);
        if (onInput) {
            onInput(formatted);
        }
    };

    const applyCardNumberMask = (value) => {

        if (!value) {
            return setFormattedValue('');;
        }
        const digits = value.replace(/\D/g, '');
        //if (digits.length !== 16) {
        //    return digits;
        //}
        const formatted = digits.substring(0, 16).replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
        return formatted;
    };

    const inputStyle = customWidth ? { width: customWidth } : null;

    return (
        <div className={"mb-3 " + classDiv}>
            <label htmlFor={id} className="form-label ml-1">
                <b>{label}</b>
            </label>
            <input
                id={id}
                step={step}
                style={inputStyle}
                className={`form-control ${errors !== undefined ? 'is-invalid' : ''} ${errors === undefined && isSummit ? 'is-valid' : ''}  ` + className}
                readOnly={readOnly}
                placeholder={placeholder}
                type={type}
                value={formattedValue}
                {...register}
                onClick={onClick}
                onChange={handleChange}
            />
            <div className="invalid-feedback">
                {errors === 'required' && <small>{mensajeValidacion}</small>}
                {errors === 'maxLength' && <small>No cumple con el máximo de caracteres</small>}
                {errors === 'minLength' && <small>No cumple con el mínimo de caracteres</small>}
            </div>

        </div>
    );
}
