import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import FileViewer from 'react-file-viewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { ObtenerDocumento } from '../servicios/ServicioDocumentos';
import { SpinnerPersonalizado } from "../components/spinnerPersonalizado";


export const ModalVisorDocumentos = ({ show, handleClose, titulo, className, children, tamano, idDocumento, indicador }) => {
    const [documento, setDocumento] = useState(null);
    const [nombre, setNombre] = useState("");

    const [error, setError] = useState(null);
    const supportedFileTypes = [
        '.pdf', '.docx', '.doc', '.rtf', '.txt',
        '.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.svg',
        '.xls', '.xlsx', '.csv',
        '.ppt', '.pptx',
        '.mp3', '.wav', '.aac',
        '.mp4', '.avi', '.mov', '.wmv', '.flv', '.mkv', '.webm',
        '.zip', '.rar', '.7z', '.tar', '.gz'
    ].join(',');


    //spinner
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const obtenerDocumento = async () => {
            setIsLoading(true);
            try {
                if (indicador && idDocumento) { 

                    console.log(indicador);
                    console.log(idDocumento);
                    setDocumento(null);
                    setNombre("");
                    const documento = await ObtenerDocumento(indicador, idDocumento);

                    console.log(documento);
                    setDocumento(documento);
                    setNombre(documento.nomDocumento)
                }
            } catch (error) {
                setIsLoading(false);
                setDocumento(null);
                setError('Error al obtener el documento.');
            }
            setError(null);
            setIsLoading(false);
        };

        obtenerDocumento();
    }, [idDocumento, indicador]); 

    const renderDocument = () => {
        if (error) {
            return <p>{error}</p>;
        }

        if (documento) {

            if (!supportedFileTypes.includes(documento.extension)) {
                return <p>{"Formato no soportado"}</p>;
            } else { 
            const base64Data = documento.documento;
            const contentType = documento.formato;

            if (documento.extension === '.docx') {
                const fileType = documento.extension.substring(1).toLowerCase();
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: contentType });
                const fileURL = URL.createObjectURL(blob);

                return (
                    <FileViewer
                        fileType={fileType}
                        filePath={fileURL}
                        onError={(e) => {
                            setError('Error al cargar el documento.');
                        }}
                    />
                );
            } else{
                const fileURL = `data:${contentType};base64,${base64Data}`;

                return (
                    <iframe title={documento.nomDocumento} src={fileURL} className='' style={{ width: '100%', height: '600px' }} />
                );
            }
            }
        } 
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName={className} aria-labelledby="contained-modal-title-vcenter" centered size={tamano}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{titulo + (nombre !== "" && nombre !== undefined ? ": " + nombre : "")}</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <SpinnerPersonalizado show={isLoading} />
                {renderDocument()}
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="mr-1 mb-2 danger-button"
                    variant="danger"
                    size="sm"
                    onClick={handleClose}>
                    <FontAwesomeIcon icon={faX} className="mr-2" />
                    Cerrar
                </Button>{' '}                             
            </Modal.Footer>
        </Modal>
    );
};

