import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Row, Card, Col } from 'react-bootstrap';
import { Grid } from '../../components/grid';
import { useForm } from 'react-hook-form'
import { InputsFormsReactHook, InputSelectHookForm } from '../../components/Forms/InputsFormsReactHook'
import { AlertDismissible } from '../../components/alerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye } from '@fortawesome/free-solid-svg-icons';
import { InsertarAbonoClub, ObtenerAbonoClubDeViaje, EliminarAbonoClubDeViajes } from '../../servicios/ServicioCobro'
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ConfirmModal } from "../../components/ConfirmModal";

import { EliminarDocumento } from '../../servicios/ServicioDocumentos';

import { ModalAgregarComprobante } from '../../components/ModalAgregarComprobante';

import { ModalVisorDocumentos } from '../../components/fileViewer';

const FormularioRegistroCobroClub = ({ idClub, dataAbonos, show, PermisoDocumentoC, PermisoEliminar, setActualizarSaldo, MonCuota, idFrecuenciaPago }) => {
    const [isSummit, setIsSummit] = useState(false);
    const [showFormulario, setShowFormulario] = useState(true);
    const [IdClubDeViaje, setidclubDeViaje] = useState(idClub);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    //Para Obtener Abonos
    const [listaAbonosClub, setListaAbonosClub] = useState([]);
    const [tipoMoneda, setTipoMoneda] = useState('Colones');
    const [filasEliminadas, setFilasEliminadas] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [EliminarDoc, setEliminarDoc] = useState(false);
    const [EliminarAbonoConfirm, setEliminarAbonoConfirm] = useState(false);
    const usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
    const [pendiente, setPendiente] = useState(false);
    const fileInputRef = useRef(null);

    const [showModal, setShowModal] = useState(false);

    const [showModalDoc, setShowModalDoc] = useState(false);
    const [documentoIndicador, setDocumentoIndicador] = useState(null);
    const [documentoId, setDocumentoId] = useState(null);

    const [NumeroAbono, setNumeroAbono] = useState(null);


    const mensajeEliminarError = {
        indicador: '1', mensaje: 'No tienes permiso para Eliminar'
    };

    const mensajeAgregarDOCError = {
        indicador: '1', mensaje: 'No tienes permiso para Agregar Documentos'
    };




    const { register, handleSubmit, setValue, formState: { errors }, trigger } = useForm({
        defaultValues: {

            IdClubViaje: idClub,
            MonColones: dataAbonos?.MonColones,
            TipoCambio: dataAbonos?.TipoCambio,
            FechaPago: dataAbonos?.FechaPago ? dataAbonos.FechaPago.replace('T00:00:00', '') : '',
            MonPagar: dataAbonos?.MonPagar,


        }
    });

    const [formData, setFormData] = useState({
        MonColones: dataAbonos?.MonColones || 0,
        TipoCambio: dataAbonos?.TipoCambio || 0,
        FechaPago: dataAbonos?.FechaPago ? dataAbonos.FechaPago.replace('T00:00:00', '') : '',
        MonPagar: dataAbonos?.MonPagar || 0,
        UsuarioCreacion: 0
    });
    const encabezado = [
        { id: 'idClubViaje', name: 'idClubViaje', selector: row => row.idClubViaje, head: "idClubViaje", omit: true },
        { id: 'IdDocumento', name: 'IdDocumento', selector: row => row.idDocumento !== null ? row.idDocumento : null, head: "IdDocumento", omit: true },
        { id: 'numAbono', name: 'numAbono', selector: row => row.numAbono, head: "numAbono", omit: true, width: '7%' },
        { id: 'fecPago', name: 'Fecha Pago', selector: row => new Date(row.fecPago).toLocaleDateString('es-ES'), head: "Fecha Pago", sortable: true },
        { id: 'monPagar', name: 'Colones', selector: row => row.monPagar, cell: (row) => `₡ ${row.monPagar}`, head: "M.Dolares", sortable: true },

        /*columna de saldo comentada*/
        { id: 'idEstado', name: 'Estado', selector: row => row.idEstado, head: "Estado", omit: true },
        { id: 'descEstCobros', name: 'Estado', selector: row => row.descEstCobros, head: "Estado", sortable: true },
        { id: 'ganadoLoteria', name: 'Lot', selector: row => row.ganadoLoteria === true ? "Si" : "No", head: "Lot", omit: false, width: "10%" },
        {
            id: 'verDoc',
            name: 'Comprobante',
            head: 'verDoc',
            cell: (row) => {
                const icon = row.idDocumento !== null ? faEye : faPlus;
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '75%',
                    }}>
                        <Button
                            variant="link"
                            size="sm"
                            onClick={() => {
                                if (icon === faPlus) {
                                    handleAgregarDocumento(row);
                                } else {
                                    handleAbrirDocumento(row);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={icon} />
                        </Button>
                        {icon === faEye && (
                            <Button
                                variant="link"
                                size="sm"
                                style={{ color: 'red' }}
                                onClick={() => EliminarDOC(row)}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        )}
                    </div>
                );
            },
        },
        {
            id: 'Eliminar',
            name: 'Eliminar',
            head: 'Eliminar',
            width: '15%',
            cell: (row) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '75%',
                    }}>
                        <Button
                            variant="link"
                            size="sm"
                            style={{ color: 'red' }}
                            onClick={() => EliminarAbonoClub(row)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </div>
                );
            },
        },
    ]


    //const [montoPagar, setMontoPagar] = useState(0);

    useEffect(() => {
        setValue('MonPagar', MonCuota);
    }, [idFrecuenciaPago, MonCuota, setValue]);




    useEffect(() => {
        if (idClub) {
            obtenerAbonos();
        }
    }, [idClub, showFormulario, showModalDoc]);


    useEffect(() => {
        if (tipoMoneda === 'Dolares') {
            handleConversion();
        }
    }, [register, setValue, tipoMoneda]);



    const handleAbrirDocumento = async (fila) => {
        setDocumentoId(fila.idDocumento ?? null);
        setNumeroAbono(fila.numAbono);

        setDocumentoIndicador(1); // 1 = club
        setShowModal(true);
    };


    const EliminarDOC = async (fila) => {

        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {
            setDocumentoId(fila.idDocumento ?? null);
            //abre el modal
            setEliminarDoc(true);
            setNumeroAbono(fila.numAbono);
        }

    };


    const EliminarAbonoClub = async (fila) => {

        if (!PermisoEliminar) {
            setMensajeRespuesta(mensajeEliminarError);
            setShowAlert(true);
        }
        else {

            //abre el modal
            setEliminarAbonoConfirm(true);
            setNumeroAbono(fila.numAbono);
        }

    };


    // Funcion ObtenerDatosClubViaje
    const obtenerAbonos = async (row, index) => {
        try {
            setPendiente(true);
            const abonos = await ObtenerAbonoClubDeViaje(IdClubDeViaje);

            setListaAbonosClub(abonos);
            setPendiente(false);

        } catch (error) {
            console.log("Error al obtener los abonos:", error);

        }

    }


    const onClickAceptar = async (formData) => {

        
        const nuevoValorIdClub = formData.IdClubViaje;
        setidclubDeViaje(nuevoValorIdClub);
        setMensajeFormulario('');
        let respuesta = {};
        formData.UsuarioCreacion = usuario.idUsuario;
        if (tipoMoneda === 'Colones') {
            formData.MonColones = 0;
            formData.TipoCambio = 0;
        }
        else if (tipoMoneda === 'Dolares') {
            formData.MonPagar = formData.MonColones * formData.TipoCambio; //MonColones es el realidad el $
        }
        respuesta = await InsertarAbonoClub(formData);


        if (respuesta.indicador == 0) {
            const mensajeCompleto = respuesta.mensaje;
            const mensajeEnPartes = mensajeCompleto.split(" ");
            const numAbono = parseInt(mensajeEnPartes.pop(), 10);
            const mensajeSinNumero = mensajeEnPartes.join(" ");

            setMensajeRespuesta({
                indicador: respuesta.indicador,
                mensaje: mensajeSinNumero
            });

            // Si el abono fue exitoso y hay un archivo, insertar el documento
            if (formData.file && formData.file[0]) {
                const newFormData = new FormData();
                newFormData.append('file', formData.file[0]);
                newFormData.append('idClub', JSON.stringify(nuevoValorIdClub));
                newFormData.append('esDeAbono', true); //Insertar el documento indicando que es de un Abono
                newFormData.append('numAbono', numAbono);
                const response = await fetch(`/Documentos/InsertarDocumento/${nuevoValorIdClub}`, {
                    method: 'POST',
                    body: newFormData,
                });
            }

            await Promise.all([obtenerAbonos()]);


        } else {
            setMensajeFormulario(respuesta.mensaje);
            console.log('Mensaje error: ', respuesta.mensaje)
        }
        setValue('MonColones', '');
        setValue('TipoCambio', '');
        setValue('FechaPago', '');
        //setValue('MonPagar', '');
        fileInputRef.current.value = '';
        // }
        setShowAlert(true);
    };


    const handleConversion = () => {
        const monColones = parseFloat(formData.MonColones) || 0;
        const tipoCambio = parseFloat(formData.TipoCambio) || 0;
        const nuevoMonPagar = (monColones * tipoCambio).toFixed(2);

        setValue('MonPagar', nuevoMonPagar);
    };

    function cobrosPendientesDeAprobacion() {
        return listaAbonosClub.some(abono => abono.estadoCobro == false);
    }

    const handleManualValidation = async () => {
        const isValid = await trigger();
        if (!isValid) {

            setIsSummit(true)
        }
        if (tipoMoneda === 'Dolares') {
            handleConversion();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        //setFormData((prevData) => ({
        //    ...prevData,
        //    [name]: value,
        //}));
        if ((tipoMoneda === 'Dolares') && (name === 'MonColones' || name === 'TipoCambio')) {
            handleConversion();
        }
    };

    const onClickTipoCambio = async () => {
        setTipoMoneda((prevTipoMoneda) => (prevTipoMoneda === 'Colones' ? 'Dolares' : 'Colones'));
        handleConversion();
    }



    const onClickEliminar = async () => {
        try {
            const respuesta = await EliminarAbonoClubDeViajes(IdClubDeViaje, NumeroAbono);
            setEliminarAbonoConfirm(false);
            if (respuesta.indicador === 0) {
                await Promise.all([obtenerAbonos(), setShowAlert(true)]);
                setMensajeRespuesta(respuesta);
            } else {
                setMensajeFormulario(respuesta.mensaje);
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error al eliminar abono:", error);
        }
        finally {
            setActualizarSaldo(prevState => !prevState);
        }
    };


    // validaciones.js
    const validarNumeroNoNegativo = (value, setErrorMessage) => {
        const isValid = parseFloat(value) >= 0;

        if (!isValid) {
            setErrorMessage("No se permite ingresar números negativos");

            // Limpiar el mensaje de error después de tres segundos (3000 milisegundos)
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        } else {
            setErrorMessage("");
        }

        return isValid;
    };

    const listaCuotas = [
        { id: 1, valor: MonCuota },
        { id: 2, valor: MonCuota * 2 },
        { id: 3, valor: MonCuota * 4 },
    ];

    const handleClose = () => {
        setShowModalDoc(false);

        setShowModal(false);

        setDocumentoId(null);
        //obtenerAbonos();
    };

    const handleAgregarDocumento = (row) => {

        if (!PermisoDocumentoC) {
            setMensajeRespuesta(mensajeAgregarDOCError);
            setShowAlert(true);
        }
        else {
            setShowModalDoc(true);
            setNumeroAbono(row.numAbono)
        }

        //obtenerAbonos();
    };


    const onConfirmEliminarDocumento = async () => {

        //indicador 1 -club  2 -paquete
        const respuesta = await EliminarDocumento(1, documentoId)
        setMensajeRespuesta(respuesta);
        setShowAlert(true);
        setEliminarDoc(false);
        obtenerAbonos();
    }


    return (
        <>

            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <ConfirmModal
                isOpen={EliminarDoc}
                toggle={() => setEliminarDoc(false)}
                message={`¿Desea eliminar este Documento? `}
                onConfirm={onConfirmEliminarDocumento}
            />

            <ConfirmModal
                isOpen={EliminarAbonoConfirm}
                toggle={() => setEliminarAbonoConfirm(false)}
                message={`¿Desea eliminar este Abono? `}
                onConfirm={onClickEliminar}
            />


            <ModalAgregarComprobante
                show={showModalDoc}
                handleClose={handleClose}
                handleAgregarDocumento={handleAgregarDocumento}
                numAbono={NumeroAbono}
                id_Viaje={idClub}
                obtenerAbonos={obtenerAbonos}
                indicador={1} // 1 = Club
                setMensajeRespuesta={setMensajeRespuesta}
                setShowAlert={setShowAlert}

            />

            <ModalVisorDocumentos
                show={showModal}
                handleClose={() => setShowModal(false)}
                titulo="Información del Pago"
                tamano="lg"
                idDocumento={documentoId}
                indicador={documentoIndicador}
                numAbono={NumeroAbono}

            />


            {showFormulario && (

                <Form onSubmit={handleSubmit(onClickAceptar)}>
                    <Card.Title className="card-title mb-4">Información de Abonos</Card.Title>
                    <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        {tipoMoneda === 'Dolares' && (
                            <>
                                <InputsFormsReactHook
                                    id='txt-MonColones'
                                    name='MonColones'
                                    label='Monto Dólares:'
                                    type='number'
                                    placeholder='Ingrese monto Dólares'
                                    min={0.00}
                                    max={99999999.99}
                                    mensajeValidacion="Monto Dólares requerido"
                                    errors={errors?.MonColones?.type}
                                    isSummit={isSummit}
                                    register={{
                                        ...register('MonColones', {
                                            required: true,
                                            validate: (value) => validarNumeroNoNegativo(value, setErrorMessage),
                                        }),
                                    }}
                                    classDiv="col-md-4 mt-4"
                                    onChange={handleInputChange}
                                />

                                <InputsFormsReactHook
                                    id='txt-TipoCambio'
                                    name='TipoCambio'
                                    label='Tipo de Cambio:'
                                    type='number'
                                    placeholder='Ingrese Tipo  Cambio'
                                    min={0.00}
                                    max={99999999.99}
                                    mensajeValidacion="Tipo Cambio requerido"
                                    errors={errors?.TipoCambio?.type}
                                    isSummit={isSummit}
                                    register={{
                                        ...register('TipoCambio', {
                                            required: true,
                                            validate: (value) => validarNumeroNoNegativo(value, setErrorMessage),
                                        }),
                                    }}
                                    classDiv="col-md-4 mt-4"
                                    onChange={handleInputChange}
                                />
                            </>
                        )}
                        <InputsFormsReactHook
                            id='txt-MonPagar'
                            label='Monto a pagar:'
                            type='number'
                            placeholder='Ingrese monto a Pagar'
                            min={0.00}
                            max={99999999.99}
                            mensajeValidacion="Monto a Pagar colones requerido"
                            errors={errors?.MonPagar?.type}
                            isSummit={isSummit}
                            disabled={tipoMoneda === 'Dolares'}
                            readOnly={true}
                            register={{ ...register('MonPagar', { required: tipoMoneda === 'Colones', validate: (value) => validarNumeroNoNegativo(value, setErrorMessage), }) }}
                            classDiv="col-md-4 mt-4"
                        />
                        <InputsFormsReactHook
                            id='txt-FecPago'
                            label='Fecha de Pago:'
                            type='date'
                            placeholder='Ingrese la fecha de pago'
                            mensajeValidacion="Fecha Pago requerido"
                            errors={errors?.FechaPago?.type}
                            isSummit={isSummit}
                            register={{ ...register('FechaPago', { required: true }) }}
                            classDiv="col-md-4 mt-4"
                        />
                    </Row>

                    <Row style={{
                        display: "flex", alignItems: "center", justifyContent: "center",
                        padding: "0 130px"
                    }} >
                        <Form.Control
                            type="file"
                            {...register('file')}
                            ref={e => {
                                register('file').ref(e);
                                fileInputRef.current = e;
                            }}
                        />

                    </Row>


                    {errorMessage && (
                        <label className="text-danger">{errorMessage}</label>
                    )}

                    <br />
                    <div className="d-flex flex-column" >
                        <Row className="justify-content-end">
                            <div className="mt-auto">
                                <Button
                                    className='card-button ml-3 mr-3'
                                    variant="primary"
                                    type="submit"
                                    onClick={handleManualValidation}
                                    size="sm"
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Agregar
                                </Button>
                            </div>
                        </Row>
                    </div>
                </Form>
            )}
            <span>Lista de Abonos Registrados </span>
            <Grid gridHeading={encabezado} gridData={listaAbonosClub} selectableRows={false} idBuscar=" idClub" pending={pendiente} />

            <br /><br />
        </>


    )
}

export default FormularioRegistroCobroClub;