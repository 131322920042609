import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';

import { FormularioModal } from '../../components/ventanaModal';
import { ActualizarFormasDePago, AgregarFormasDePago, InactivarFormasDePago, ObtenerFormasDePagoPorId, ObtenerFormasDePagos } from '../../servicios/ServicioFormasDePago';
import FormularioFormasDePago from './FormularioFormasDePago';
import { AlertDismissible } from '../../components/alerts';
import { ConfirmModal } from '../../components/ConfirmModal';

const FormasDePagoComponet = () => {
    const [proceso, setProceso] = useState(1);
    const [modal, setModal] = useState(false);
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [data, setData] = useState({});
    const [modalTitulo, setModalTitulo] = useState("Registrar Producto");
    const [labelButton, setLabelButton] = useState("Registrar");
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [idBuscar, setidBuscar] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [listaRespaldo, setListaRespaldo] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [textoBotonInactivar, setTextoBotonInactivar] = useState("Inactivar");
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [lista, setLista] = useState([]);

    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);


    const encabezado = [
        { id: 'CodProducto', name: 'Codigo Forma de Pago', selector: row => row.codFormaDePago, head: "Codigo Forma de Pago" },
        { id: 'Descripcion', name: 'Descripción', selector: row => row.descripcion, head: "Descripción" },
        { id: 'id', name: 'Serie', selector: row => row.id, head: "id", },
        { id: 'fechaCreacion', name: 'F. Creación', selector: row => row.fechaCreacion.split('T')[0], head: "F. Creación" },
        { id: 'estado', name: 'Estado', selector: row => row.estado, head: "Estado" },
    ]
    useEffect(() => {
        ObtenerListado();
        ObtenerPermisosUsuario();
    }, []);


    const ObtenerListado = async () => {
        setPendiente(true);
        setLista(await ObtenerFormasDePagos());
        setListaRespaldo(await ObtenerFormasDePagos());
        setPendiente(false);
    }

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false);
        setPermisoEliminar(usuario.PermisoEliminar ?? false);
    }


    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setTextoBotonInactivar(!filaValida ? "Inactivar" : (fila.estado == "Activo" ? "Inactivar" : "Activar"));
        setFilaSeleccionada(fila);
    }

    const onClickCerrarModal = () => {
        setModal(false);
        setMensajeFormulario("");
    }

    const onClickNuevo = () => {
        setProceso(1);
        setModal(!modal);
        setLabelButton("Registrar");
        setModalTitulo("Registrar Forma de Pago");
    }

    const onClickActualizar = async () => {
        setData(await ObtenerFormasDePagoPorId(filaSeleccionada.id));
        setProceso(2);
        setModal(!modal);
        setLabelButton("Actualizar");
        setModalTitulo("Actualizar Forma de Pago");
    }

    const onClickInactivar = async () => {
        setConfirmModalOpen(true);
    }
    const onConfirmCambioEstado = async () => {
        const respuesta = await InactivarFormasDePago(filaSeleccionada.id)
        if (respuesta.indicador === 0)
            ObtenerListado();
        setMensajeRespuesta(respuesta);        
        setTextoBotonInactivar(textoBotonInactivar === "Activar" ? "Inactivar" : "Activar");
        setConfirmModalOpen(false);
        setShowAlert(true);
    }



    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;


    const onClickProcesar = async(data) => {
        let respuesta = {};
        if(proceso === 1)
            respuesta = await AgregarFormasDePago(data);
        else{
            data.id = filaSeleccionada.id;
            data.estado = true;
            respuesta = await ActualizarFormasDePago(data);
        }

        if(respuesta.indicador == 0){
            setModal(false);
            ObtenerListado();
            setMensajeRespuesta(respuesta);
        }else{     
            setMensajeFormulario(respuesta.mensaje);  
        }  
        setShowAlert(true);

    }

    return (
        <>
            <div className="container-fluid text-no">
                <h1>Catálogo de Formas de Pago</h1>
                <hr />
                {PermisoAgregar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickNuevo()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Registrar
                        </Button>{' '}
                    </>
                )}
                {PermisoActualizar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickActualizar()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faPen} className="mr-2" />
                            Actualizar
                        </Button>{' '}
                    </>
                )}
                {PermisoEliminar && (
                    <>
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            type="submit"
                            size="sm"
                            onClick={() => onClickInactivar()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            {textoBotonInactivar}
                        </Button>
                    </>
                )}
                <br />
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )} 
                <span>Listado de Todas los Formas de Pago Registradas</span>
                <br />
                <Grid
                    gridHeading={encabezado}
                    gridData={listaRespaldo}
                    selectableRows={true}
                    pending={pendiente}
                    setFilaSeleccionada={onClickSeleccionarFila}
                    idBuscar="id" />
                <br />
                <br />
                <FormularioModal 
                show={modal} 
                handleClose={onClickCerrarModal} 
                titulo={modalTitulo} tamano="lg" className=''>
                    <FormularioFormasDePago 
                    labelButton={labelButton} 
                    data={data} 
                    proceso={proceso} 
                    onClickProcesar={onClickProcesar} 
                    mensaje={mensajeFormulario}/>
                </FormularioModal>
                {confirmModalOpen && (
                    <ConfirmModal
                        isOpen={confirmModalOpen}
                        toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                        message={`¿Desea cambiar el estado de la forma de pago a ${textoBotonInactivar === "Activar" ? "activo" : "inactivo"
                            }?`}
                        onConfirm={onConfirmCambioEstado}
                    />
                )}
            </div>

        </>
    )
}

export default FormasDePagoComponet;