import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Grid } from '../../components/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faX } from '@fortawesome/free-solid-svg-icons';
import Formulario from './formulario';
import { FormularioModal } from '../../components/ventanaModal';
import { AlertDismissible } from '../../components/alerts';
import { ConfirmModal } from '../../components/ConfirmModal';
import { AgregarContador, ActualizarContador, InactivarContador, ObtenerContadores, ObtenerContador } from '../../servicios/ServicioContabilidad'


const Contadores = () => {
    const [proceso, setProceso] = useState(1);
    const [modal, setModal] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("Registrar contador");
    const [labelButton, setLabelButton] = useState("Registrar");
    const [mensajeFormulario, setMensajeFormulario] = useState("");
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [listaDeContadores, setListaDeContadores] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [data, setData] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [textoBotonInactivar, setTextoBotonInactivar] = useState("Inactivar");

    const [PermisoAgregar, setPermisoAgregar] = useState(false);
    const [PermisoActualizar, setPermisoActualizar] = useState(false);
    const [PermisoEliminar, setPermisoEliminar] = useState(false);

    const [IdUsuario, setIdUsuario] = useState(0)

    const encabezado = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'codContabilidad', name: 'Cod. Contador', selector: row => row.codContabilidad, head: "Cod. Contador", sortable: true },
        { id: 'nombre', name: 'Nombre', selector: row => row.nombre, head: "Nombre", sortable: true },
        { id: 'apellido1', name: 'P. Apellido', selector: row => row.primerApellido, head: "P. Apellido", sortable: true },
        { id: 'apellido2', name: 'S. Apellido', selector: row => row.segundoApellido, head: "S. Apellido", sortable: true },
        { id: 'nombreSucursal', name: 'Sucursal', selector: row => row.nombreSucursal, head: "Sucursal", sortable: true },
        { id: 'fechaContratacion', name: 'F. Contratación', selector: row => new Date(row.fechaContratacion).toLocaleDateString('es-ES'), head: "F. Contratación", sortable: true },
        { id: 'Estado', name: 'Estado', selector: row => row.estado, head: "Estado", sortable: true }
    ]

    //Se indica las columnas en las que se aplica el filtro
    const filterColumns = ['codContabilidad', 'nombre', 'primerApellido', 'segundoApellido', 'nombreSucursal'];

    useEffect(() => {
        ObtenerListadoDeContadores();
        ObtenerPermisosUsuario();
    }, []);

    const onClickNuevoContador = () => {
        setProceso(1);
        setModal(!modal);
        setLabelButton("Registrar");
        setModalTitulo("Registrar contador");
    }

    const onClickActualizarContador = async () => {
        setData(await ObtenerContador(filaSeleccionada.idContabilidad));
        setProceso(2);
        setModal(!modal);
        setLabelButton("Actualizar");
        setModalTitulo("Actualizar contador");
    }

    const onClickInactivarContador = async () => {
        setConfirmModalOpen(true);
    }


    const onConfirmCambioEstado = async () => {
        const respuesta = await InactivarContador(filaSeleccionada.idContabilidad)
        if (respuesta.indicador === 0)
            ObtenerListadoDeContadores();
        setMensajeRespuesta(respuesta);
        setTextoBotonInactivar(textoBotonInactivar === "Activar" ? "Inactivar" : "Activar");

        setConfirmModalOpen(false);
        setShowAlert(true);

    }

    const ObtenerPermisosUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        setPermisoAgregar(usuario.PermisoAgregar ?? false);
        setPermisoActualizar(usuario.PermisoActualizar ?? false);
        setPermisoEliminar(usuario.PermisoEliminar ?? false);


        //aprovecha el metodo para obtener el id de usuario de la persona logueada
        setIdUsuario(usuario.idUsuario);
    }


    const ObtenerListadoDeContadores = async () => {
        setPendiente(true);
        setListaDeContadores(await ObtenerContadores());
        setPendiente(false);
    }

    const onClickProcesarContador = async (data) => {


        data.usuarioCreacion = IdUsuario;
        setMensajeFormulario("");
        let respuesta = {};
        if (proceso === 1)
            respuesta = await AgregarContador(data);
        else {
            data.idContabilidad = filaSeleccionada.idContabilidad;
            data.estado = true;
            respuesta = await ActualizarContador(data);
        }

        if (respuesta.indicador == 0) {
            setModal(false);
            ObtenerListadoDeContadores();
            setMensajeRespuesta(respuesta);

        } else {
            setMensajeRespuesta(respuesta);
            ObtenerListadoDeContadores();
            setModal(false);
        }
        setShowAlert(true);

    }

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);
        setTextoBotonInactivar(!filaValida ? "Inactivar" : (fila.estado == "Activo" ? "Inactivar" : "Activar"));
        setFilaSeleccionada(fila);
    }

    const onClickCerrarModal = () => {
        setModal(false);
        setMensajeFormulario("");
    }


    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    return (
        <>
            <div className="container-fluid">
                <h1>Mantenimiento de contadores</h1>
                <hr />
                {PermisoAgregar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => onClickNuevoContador()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Registrar
                        </Button>{' '}
                    </>
                )}
                {PermisoActualizar && (
                    <>
                        <Button
                            className="mr-1 mb-2 card-button"
                            variant="primary"
                            type="submit" size="sm"
                            onClick={() => onClickActualizarContador()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faPen} className="mr-2" />
                            Actualizar
                        </Button>{' '}
                    </>
                )}
                {PermisoEliminar && (
                    <>
                        <Button
                            className="mr-1 mb-2 danger-button"
                            variant="danger"
                            type="submit" size="sm"
                            onClick={() => onClickInactivarContador()}
                            disabled={bloquearBoton}>
                            <FontAwesomeIcon icon={faX} className="mr-2" />
                            {textoBotonInactivar}
                        </Button>
                    </>
                )}
                <br /><br />
                {showAlert && (
                    <AlertDismissible
                        indicador={mensajeRespuesta.indicador}
                        mensaje={mensajeRespuesta.mensaje}
                        setShow={setShowAlert}
                    />
                )}
                <span>Listado de todos los contadores registrados</span>
                <Grid gridHeading={encabezado} gridData={listaDeContadores} selectableRows={true} pending={pendiente}
                    setFilaSeleccionada={onClickSeleccionarFila} idBuscar="idContabilidad" filterColumns={filterColumns} />
                <br /><br />

            </div>
            <FormularioModal show={modal} handleClose={onClickCerrarModal} titulo={modalTitulo} className='' tamano="lg">
                <Formulario labelButton={labelButton} data={data} proceso={proceso} onClickProcesarContador={onClickProcesarContador} mensaje={mensajeFormulario} />
            </FormularioModal>

            {confirmModalOpen && (
                <ConfirmModal
                    isOpen={confirmModalOpen}
                    toggle={() => setConfirmModalOpen(!confirmModalOpen)}
                    message={`¿Desea cambiar el estado del contador a ${textoBotonInactivar === "Activar" ? "activo" : "inactivo"
                        }?`}
                    onConfirm={onConfirmCambioEstado}
                />
            )}
        </>
    )
}
export default Contadores;
