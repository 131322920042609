import React from 'react';

import { Button } from 'react-bootstrap';
import '../../styles/cardViajes.css'
import clubImage from '../../images/club.webp';
import paqueteImage from '../../images/paquete.jpg';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ViajesComponet = () => {



    const onClickVerClubViajes = () => {
        window.location = '/ClubDeViajes';
    }
    const onClickVerPaqueteViajes = () => {
        window.location = '/PaqueteDeViaje';

    }


    return (
        <>


            <div className='grid'>

                <div className='img-wrapper col-10' >
                    {/* <img src="https://cdn.forbescentroamerica.com/2019/10/Costa-Rica-2.jpg" style={{width:"510px", height:"720px" }}  alt="Imagen"/> */}
                    <img className='blur zoom' src={clubImage} alt="Imagen" />
                    <div className='content fade slide-left'>
                        <p style={{ color: '#1a1a66' }}>Club de Viajes</p>
                        <Button
                            variant="primary"
                            type="submit"
                            size="lg"
                            style={{ borderRadius: '20px' }}
                            onClick={() => onClickVerClubViajes()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Crear
                        </Button>{' '}
                    </div>
                </div>

                <div className='img-wrapper col-10'>
                    {/* <img src="https://cdn.forbescentroamerica.com/2019/10/Costa-Rica-2.jpg" style={{width:"510px", height:"720px" }}  alt="Imagen"/> */}
                    <img className='blur zoom' src={paqueteImage} alt="Imagen" />
                    <div className='content fade slide-right'>

                        <p style={{ color: '#1a1a66' }}>Paquetes de Viajes</p>
                        <Button
                            variant="primary"
                            type="submit"
                            size="lg"
                            style={{ borderRadius: '20px' }}

                            onClick={() => onClickVerPaqueteViajes()}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Crear
                        </Button>{' '}
                    </div>
                </div>

            </div>

        </>
    )
}

export default ViajesComponet;